// emit
export const RECEIVE_BROADCAST_LABEL = 'broadcast:receiveLabel';
export const RECEIVE_BROADCAST_ASSIGN_START = 'broadcast:receiveAssignStart';
export const RECEIVE_BROADCAST_STUDENT_CLIENT_IDX = 'broadcast:receiveStudentClientIdx';
export const RECEIVE_BROADCAST_USER_DISCONNECTED = 'broadcast:receiveUserDisconnected';
export const RECEIVE_BROADCAST_ASSIGN_END = 'broadcast:receiveAssignEnd';
export const RECEIVE_BROADCAST_RECEIVE_ROOM_SETTINGS = 'broadcast:receiveUpdateRoomSettings';
export const RECEIVE_BROADCAST_SHOW_SCORE = 'broadcast:showScore';
export const RECEIVE_BROADCAST_END_LESSON = 'broadcast:endLesson';

export const REQUEST_USER_JOIN_ROOM = 'user:requestJoinRoom';
export const REQUEST_TEACHER_ASSIGN_START = 'teacher:requestAssignStart';
export const REQUEST_TEACHER_CLIENT_LIST = 'teacher:requestClientList';
export const REQUEST_TEACHER_ASSIGN_LABEL = 'teacher:requestAssignLabel';
export const REQUEST_TEACHER_ASSIGN_MULTIPLE_LABEL = 'teacher:requestAssignMultipleLabel';
export const REQUEST_TEACHER_ASSIGN_CANCEL = 'teacher:requestAssignCancel';
export const REQUEST_TEACHER_CONFIRM_ASSIGN = 'teacher:requestConfirmAssign';
export const REQUEST_TEACHER_UPDATE_ROOM_SETTINGS = 'teacher:requestUpdateRoomSettings';
export const REQUEST_TEACHER_SHOW_SCORE = 'teacher:requestShowScore';
export const REQUEST_TEACHER_END_LESSON = 'teacher:requestEndLesson';

export const REQUEST_USER_ROOM_STATE = 'user:requestRoomState';
export const REQUEST_USER_ROOM_SETTINGS = 'user:requestRoomSettings';

export const RECEIVE_STUDENT_TOKEN = 'student:receiveToken';

export const KEEP_SERVERS_CONNECTION_ALIVE = 'keepServersAlive';
export const FETCH_SOCKETS = 'fetchSockets';
