import { styled } from '@mui/material';

export const DotBorder = styled('div', {
    shouldForwardProp: (prop) => prop !== 'zoom',
})<{ zoom?: number; disabled?: boolean }>(({ zoom, disabled }) => ({
    width: `calc(100% + (40px * ${zoom}))`,
    height: `calc(100% + (40px * ${zoom}))`,
    border: '1px dashed #FFA400',
    position: 'absolute',
    backgroundColor: 'inherit',
    cursor: 'move',
    margin: `calc(-20px * ${zoom})`,
    padding: `calc(20px * ${zoom})`,
    ...(disabled ? { display: 'none' } : ''),
}));

export const TopDot = styled('div', {
    shouldForwardProp: (prop) => prop !== 'zoom',
})<{ zoom?: number }>(({ zoom }) => ({
    width: `calc(15px * ${zoom})`,
    height: `calc(15px * ${zoom})`,
    backgroundColor: '#FFA400',
    position: 'absolute',
    borderRadius: '50%',
    display: 'inline-block',
    top: `calc(-9px * ${zoom})`,
    left: '50%',
}));

export const LeftDot = styled('div', {
    shouldForwardProp: (prop) => prop !== 'zoom',
})<{ zoom?: number }>(({ zoom }) => ({
    width: `calc(15px * ${zoom})`,
    height: `calc(15px * ${zoom})`,
    backgroundColor: '#FFA400',
    position: 'absolute',
    borderRadius: '50%',
    display: 'inline-block',
    left: `calc(-9px * ${zoom})`,
    // top: '50%',
    bottom: `calc(0px - ${(15 * (zoom ?? 1)) / 2}px)`,
}));

export const BottomDot = styled('div', {
    shouldForwardProp: (prop) => prop !== 'zoom',
})<{ zoom?: number }>(({ zoom }) => ({
    width: `calc(15px * ${zoom})`,
    height: `calc(15px * ${zoom})`,
    backgroundColor: '#FFA400',
    position: 'absolute',
    borderRadius: '50%',
    display: 'inline-block',
    bottom: `calc(-9px * ${zoom})`,
    left: '50%',
}));

export const RightDot = styled('div', {
    shouldForwardProp: (prop) => prop !== 'zoom',
})<{ zoom?: number }>(({ zoom }) => ({
    width: `calc(15px * ${zoom})`,
    height: `calc(15px * ${zoom})`,
    backgroundColor: '#FFA400',
    position: 'absolute',
    borderRadius: '50%',
    display: 'inline-block',
    right: `calc(-9px * ${zoom})`,
    // top: '50%',
    bottom: `calc(0px - ${(15 * (zoom ?? 1)) / 2}px)`,
}));
