import { CSSObject, styled, Theme, IconButton, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 288;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(0)} + 105px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const collapseBtn = (open: boolean, handleDrawerOpen: () => void) => (
    <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={handleDrawerOpen}
        edge='start'
        size='small'
        sx={{
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            color: '#FFA400',
            boxShadow: 4,
            top: 'calc(50% - 20px*2)',
            right: 0,
            zIndex: 100,
            marginRight: '4px',
            '&:hover': {
                backgroundColor: '#eff0ff',
            },
        }}>
        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
);

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} placement='right' />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#7F5200',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#7F5200',
    },
}));
