import { gql, useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { TestroomModel } from '../../testroom/models/TestroomModel';
import { ClassesQueryData } from '../types/timeSlotTypes';
import { TestroomQueryModel } from '../../../queryModels/TestroomQueryModel';
import { useContext } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';

const SAVE_GQL = gql`
    mutation CreateTestroom(
        $name: String!
        $lessonVersion: ID!
        $class: ID!
        $startTime: DateTime!
        $passlock: String!
        $offlineMode: Boolean!
        $writingHints: Boolean!
        $freeStyleMode: Boolean!
        $endTime: DateTime!
    ) {
        createTestroom(
            data: {
                testroomName: $name
                lessonVersion: { connect: { id: $lessonVersion } }
                class: { connect: { id: $class } }
                passlock: $passlock
                offlineMode: $offlineMode
                writingHints: $writingHints
                freeStyleMode: $freeStyleMode
                startTime: $startTime
                endTime: $endTime
            }
        ) {
            id
            testroomName
            otp
            lessonVersion {
                id
                lessonName
            }
            class {
                id
            }
            passlock
            offlineMode
            writingHints
            freeStyleMode
            startTime
            endTime
        }
    }
`;

const UPDATE_GQL = gql`
    mutation UpdateTestroom(
        $id: ID!
        $name: String!
        $lessonVersion: ID!
        $class: ID!
        $passlock: String!
        $offlineMode: Boolean!
        $writingHints: Boolean!
        $freeStyleMode: Boolean!
        $startTime: DateTime!
        $endTime: DateTime!
    ) {
        updateTestroom(
            where: { id: $id }
            data: {
                testroomName: $name
                lessonVersion: { connect: { id: $lessonVersion } }
                class: { connect: { id: $class } }
                passlock: $passlock
                offlineMode: $offlineMode
                writingHints: $writingHints
                freeStyleMode: $freeStyleMode
                startTime: $startTime
                endTime: $endTime
            }
        ) {
            id
            testroomName
            otp
            lessonVersion {
                id
                lessonName
            }
            class {
                id
            }
            passlock
            offlineMode
            writingHints
            freeStyleMode
            startTime
            endTime
        }
    }
`;

const DELETE_GQL = gql`
    mutation delete($id: ID!) {
        deleteTestroom(where: { id: $id }) {
            id
        }
    }
`;

const CLASSES_QUERY = gql`
    query GetRows {
        classes(orderBy: { className: asc }) {
            id
            school {
                schoolName
            }
            className
        }
    }
`;

const TESTROOM_QUERY = gql`
    query GetRows($id: ID!) {
        testroom(where: { id: $id }) {
            id
            testroomName
            otp
            lessonVersion {
                id
                lessonName
            }
            class {
                id
            }
            passlock
            offlineMode
            writingHints
            freeStyleMode
            startTime
            endTime
            screenSharing
        }
    }
`;

export default function useTimeSlotMutation(cb: (data: TestroomModel, action: 'create' | 'update' | 'delete') => void) {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation(SAVE_GQL, {
        variables: {
            name: '',
            lessonVersion: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
            startTime: new Date(),
            endTime: new Date(),
        },
    });
    const [updateForm] = useMutation(UPDATE_GQL, {
        variables: {
            id: '',
            name: '',
            lessonVersion: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
            startTime: new Date(),
            endTime: new Date(),
        },
    });
    const [deleteForm] = useMutation(DELETE_GQL, {
        variables: {
            id: '',
        },
    });

    const { refetch: refetchClasses, data: classesQueryData } = useQuery<ClassesQueryData>(CLASSES_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const { refetch: refetchTestroom, data: testroomQueryData } = useQuery<TestroomQueryModel>(TESTROOM_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
        lessonVersion: Yup.string().required(t('required')),
        class: Yup.string().required(t('required')),
        startTime: Yup.string().required(t('required')),
        endTime: Yup.string().required(t('required')),
    });
    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            otp: '',
            lessonVersion: '',
            class: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
            startTime: new Date(),
            endTime: new Date(),
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (values.id !== '') {
                    const res = await updateForm({
                        variables: {
                            ...values,
                        },
                    });
                    cb(res.data.updateTestroom, 'update');
                } else {
                    const res = await saveForm({
                        variables: {
                            ...values,
                        },
                    });
                    cb(res.data.createTestroom, 'create');
                }
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    enqueueSnackbar(errorMsg, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }
            }
        },
    });

    const handleDelete = async (id: string) => {
        if (id !== '') {
            try {
                const res = await deleteForm({
                    variables: {
                        id: id,
                    },
                });
                cb({ ...res.data.updateTestroom, id: id }, 'delete');
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    enqueueSnackbar(errorMsg, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }
            }
        }
    };

    return {
        formik,
        handleDelete,
        refetchClasses,
        classesQueryData,
        refetchTestroom,
        testroomQueryData,
    };
}
