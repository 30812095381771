import FullCalendar, { DateSelectArg, EventClickArg, EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { Dispatch, SetStateAction, useState } from 'react';
import useTimeSlot from './hooks/useTimeSlot';
import { CalendarData, TimeSlotDialogState } from './types/timeSlotTypes';
import { CalendarAdd } from './CalendarAdd';
import styled from '@emotion/styled';

const renderEventContent = (eventContent: EventContentArg) => {
    return (
        <>
            <b>{eventContent.timeText}</b>&nbsp;&nbsp; <i>{eventContent.event.title}</i>
        </>
    );
};

const handleDateSelect = (selectInfo: DateSelectArg, setCurrentCalendarState: Dispatch<SetStateAction<TimeSlotDialogState>>) => {
    // console.log(typeof selectInfo);
    // no need to add schedula here, change to lesson publish instead
    // setCurrentCalendarState({
    //   isDialogOpen: true,
    //   calendarData: {
    //     calendarAPI: selectInfo.view.calendar,
    //     data: {
    //       id: '',
    //       testroomName: '',
    //       otp: '',
    //       lessonVersion: { id: '' },
    //       class: { id: '' },
    //       passlock: '',
    //       startTime: selectInfo.start,
    //       endTime: selectInfo.end,
    //       screenSharing: false,
    //       writingHints: false,
    //     },
    //   },
    // });
};

const handleEventEdit = (clickInfo: EventClickArg, setCurrentCalendarState: Dispatch<SetStateAction<TimeSlotDialogState>>) => {
    // if (clickInfo.event.id && clickInfo.event.start) {
    //     setCurrentCalendarState({
    //         isDialogOpen: true,
    //         calendarData: {
    //             calendarAPI: clickInfo.view.calendar,
    //             data: {
    //                 id: clickInfo.event.id,
    //                 testroomName: clickInfo.event.title,
    //                 otp: '',
    //                 lessonVersion: { id: '', lessonName: '' },
    //                 class: { id: '' },
    //                 passlock: '',
    //                 startTime: clickInfo.event.start,
    //                 endTime: clickInfo.event.end ?? clickInfo.event.start,
    //                 screenSharing: false,
    //                 writingHints: false,
    //                 freeStyleMode: false,
    //                 offlineMode: false,
    //             },
    //         },
    //     });
    // }
};

export const CalendarPage = () => {
    const [currentCalendarState, setCurrentCalendarState] = useState<TimeSlotDialogState>({
        isDialogOpen: false,
        calendarData: {} as CalendarData,
    });
    const { initData } = useTimeSlot();

    return (
        <StyleWrapper>
            <FullCalendar
                height='calc(100vh - 50px)'
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                initialView='dayGridMonth'
                // editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={initData} // alternatively, use the `events` setting to fetch from a feed
                select={(clickInfo) => handleDateSelect(clickInfo, setCurrentCalendarState)}
                eventContent={renderEventContent} // custom render function
                eventClick={(clickInfo) => handleEventEdit(clickInfo, setCurrentCalendarState)}
                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
            <CalendarAdd currentCalendarState={currentCalendarState} setCurrentCalendarState={setCurrentCalendarState} />
        </StyleWrapper>
    );

    // handleEvents = (events: EventApi[]) => {
    //   this.setState({
    //     currentEvents: events,
    //   });
    // };
};

export const StyleWrapper = styled.div`
    .fc .fc-view {
        background: white;
    }
    .fc .fc-view .fc-day-today {
        background: #f06e3c;
        color: white;
    }
`;
