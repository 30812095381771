import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldsIcon from '@mui/icons-material/TextFields';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect, useState } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentText } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';
import { jyutpingFunction } from '../props/functions/textCommonFunction';
import { createDebouncedSaveHandler } from '../props/functions/debouncedSaveHandler';

const debouncedSaveHandler = createDebouncedSaveHandler();

export const LessonsSlideDataText = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentText = item.content as SlideComponentText;

    const [isFocused, setIsFocused] = useState(false);

    const compRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (compRef.current) {
            const height = compRef.current.clientHeight;
            if (item.size.height !== height) {
                item.size.height = height;
                updateItemProperties(
                    {
                        ...item,
                    },
                    false,
                );
            }
        }
    }, [itemContent.fontSize, itemContent.text, item.size.width]);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <strong
                style={{
                    cursor: 'move',
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                }}>
                <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<TextFieldsIcon />} title={t('text-box')} resize={true} canDelete={!isFocused} />
                <TextField
                    disabled={disabled}
                    ref={compRef}
                    id='outlined-basic'
                    value={itemContent.text}
                    variant='outlined'
                    inputProps={{
                        style: {
                            fontSize: itemContent.fontSize,
                            padding: 0,
                            lineHeight: 1,
                        },
                    }}
                    sx={{ zoom: zoom }}
                    onChange={(e) => {
                        itemContent.text = e.target.value;
                        slideComponentHandler(item, false);
                        debouncedSaveHandler(() => {
                            itemContent.jyutping = jyutpingFunction(itemContent.text);
                            slideComponentHandler(item, true);
                        });
                    }}
                    onFocus={(e) => {
                        setIsFocused(true);
                    }}
                    onBlur={(e) => {
                        setIsFocused(false);
                        // // convert to jyutping
                        // itemContent.jyutping = jyutpingFunction(itemContent.text);
                        // slideComponentHandler(item, true);
                    }}
                    fullWidth
                    multiline
                />
                {/* <Typography
          ref={compRef}
          sx={{ fontSize: itemContent.fontSize, zoom: zoom }}>
          {itemContent.text}
        </Typography> */}
            </strong>
        </Draggable>
    );
};
