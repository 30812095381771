import Typography from '@mui/material/Typography';

export const PrivacyPolicyContent = () => {
    const items = [
        {
            header: 'Information We Collect',
            content: (
                <>
                    <Typography paragraph>
                        We may collect personal information that you provide to us when you use Turtle Teach, such as your name, email address, and any other information you choose to provide. We may
                        also collect certain non-personal information automatically, including your IP address, device information, and usage data.
                    </Typography>
                    <Typography paragraph>
                        Additionally, when using Turtle Teach, we may pass non-personal information, specifically the user's input describing the topic or desired teaching materials, to OpenAI's
                        ChatGPT API for processing. This non-personal information is used solely for generating responses to assist in the teaching process. OpenAI is only aware of the question prompt
                        sent to it and does not have access to any personal information.
                    </Typography>
                </>
            ),
        },
        {
            header: 'How We Use Your Information',
            content: (
                <ul>
                    <li>Facilitate a seamless login experience using your Google ID and email.</li>
                    <li>Authorize access to your Google Docs to enhance your user experience on the Turtle Teach platform, allowing you to export created content to Google Docs conveniently.</li>
                    <li>Provide, operate, and maintain Turtle Teach.</li>
                    <li>Communicate with you, respond to your inquiries, and provide customer support.</li>
                    <li>Send you technical notices, updates, security alerts, and other administrative messages.</li>
                    <li>Personalize your experience on Turtle Teach and tailor the content and resources to your preferences.</li>
                    <li>Analyze usage data to improve Turtle Teach's functionality and performance.</li>
                    <li>Comply with legal obligations and enforce our rights.</li>
                </ul>
            ),
        },
        {
            header: 'Information Sharing and Disclosure',
            content: `We may share your personal information with third-party service providers who assist us in operating Turtle Teach and providing related services. These service providers are authorized to use your personal information only as necessary to provide the requested services to us. We may also disclose your personal information if required by law or to protect our rights or the rights of others.`,
        },
        {
            header: 'Data Retention',
            content: `We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.`,
        },
        {
            header: 'Your Rights',
            content: `You have the right to access, update, or delete your personal information that we hold. You may also have the right to restrict or object to certain processing activities. To exercise these rights, please contact us using the information provided below.`,
        },
        {
            header: 'Security',
            content: `We take reasonable measures to protect the personal information we collect and maintain. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.`,
        },
        {
            header: 'Third-Party Websites',
            content: `Turtle Teach may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy applies only to Turtle Teach, and we are not responsible for the privacy practices of third-party websites or services. We encourage you to review the privacy policies of those third parties.`,
        },
        {
            header: 'Google User Data Access',
            content: `When you grant permission, Turtle Teach will request access to your Google user data, including your Google Docs. This access is required to facilitate the integration between Turtle Teach and Google Docs, allowing you to export content seamlessly from Turtle Teach. We will only use the requested data to authorize access to Google Doc and enhance your user experience on the Turtle Teach platform. We do not store, use, access, or share your Google Doc data. All data remains within your Google account.\n\n
      
      By granting permission, you acknowledge that Turtle Teach may access and use your Google user data as described above. You can revoke this permission at any time by changing the access settings in your Google account.\n\n
      
      Please note that Turtle Teach's access to your Google user data is governed by Google's API Services User Data Policy and the terms and conditions set by Google.
      `,
        },
        {
            header: 'Limited Use Requirements',
            content: (
                <Typography>
                    Turtle Teach’s use and transfer to any other app of information received from Google APIs will adhere to{' '}
                    <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank' rel='noopener noreferrer'>
                        Google API Services User Data Policy
                    </a>
                    , including the Limited Use requirements.
                </Typography>
            ),
        },
        {
            header: 'Changes to this Privacy Policy',
            content: `We may update this Privacy Policy from time to time. The most current version will be posted on Turtle Teach, and your continued use of Turtle Teach after any changes will constitute your acceptance of such changes.`,
        },
    ];

    return (
        <div
            style={{
                color: '#858585',
                paddingLeft: '5%',
                paddingTop: '2%',
                paddingRight: '5%',
            }}>
            <Typography
                variant='h5'
                component='h1'
                style={{
                    fontWeight: 'bold',
                    marginBottom: '16px',
                }}>
                Privacy Policy
            </Typography>
            <div>
                <ol>
                    {items.map((item, idx) => (
                        <div key={idx}>
                            <li
                                style={{
                                    fontWeight: 'bold',
                                    color: '#676767',
                                }}>
                                {item.header}
                            </li>
                            <dl>
                                <dt>{item.content}</dt>
                            </dl>
                        </div>
                    ))}
                    <li style={{ fontWeight: 'bold', color: '#676767' }}>
                        <h4>Contact Us</h4>
                    </li>
                    <dl>
                        <dt>
                            <Typography>If you have any questions or concerns about this Privacy Policy, please contact us at</Typography>
                            <Typography variant='caption' display='block'>
                                Kazoo Technology (Hong Kong) Limited
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Unit 307, 3/F, Building 19W
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Science Park West Avenue
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Hong Kong Science Park
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Shatin, New Territories
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Hong Kong
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Phone: +852 35654523
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Email: support@turtleteach.com
                            </Typography>
                        </dt>
                    </dl>
                </ol>
            </div>
        </div>
    );
};
