import { Box, Button, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { UserAuthContext } from '../../contexts/UserAuthContext';

import 'react-datepicker/dist/react-datepicker.css';
import { StudentCreateForm } from './components/StudentCreateForm';
import { useStudentModelCreateMutation } from 'models/student/useStudentModelCreateMutation';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { UserPermissionContext } from '../../contexts/UserPermissionContext';
import { UpgradePlanPopup } from '../../routes/UpgradePlanPopup';
import { enqueueSnackbar } from 'notistack';

type StudentAddProps = {
    refresh: () => void;
    currentNumStudents: number;
};

export const StudentsAdd = ({ refresh, currentNumStudents }: StudentAddProps) => {
    const { create } = useStudentModelCreateMutation();
    const { t } = useTranslation();

    const auth = useContext(UserAuthContext);
    const [open, setOpen] = useState(false);
    const [isEmailExists, setIsEmailExists] = useState(false);
    const [initValues, setInitValues] = useState({
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        passwordAES: '',
        studentNumber: '',
        familyName: '',
        givenName: '',
        class: '',
        dob: new Date(),
    });
    const { userPermissions } = useContext(UserPermissionContext);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const handleClickOpen = () => {
        if (currentNumStudents + 1 <= userPermissions?.maxStudentID) {
            setOpen(true);
            setInitValues((prev) => {
                return { ...prev };
            });
        } else {
            setShowUpgradePopup(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Button variant='contained' color='secondary' sx={{ textTransform: 'none', borderRadius: '8px' }} onClick={handleClickOpen} startIcon={<AddIcon />}>
                {t('add-student')}
            </Button>

            {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='studentLimitExceeded' />}

            <StudentCreateForm
                isOpened={open}
                onSubmit={async (values, createAnother, createAnotherCb) => {
                    try {
                        await create({
                            ...values,
                            classId: values.class,
                            name: `${values.familyName} ${values.givenName}`,
                        });

                        if (createAnother) {
                            createAnotherCb();
                        } else {
                            handleClose();
                        }
                        refresh();
                    } catch (err) {
                        const errorMsg = handleException(err);
                        if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                            auth.accessDenied(errorMsg);
                        } else if (errorMsg === ERROR_TYPE.FIELD_DUPLICATE) {
                            setIsEmailExists(true);
                        } else {
                            enqueueSnackbar(errorMsg, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            });
                        }
                    }
                }}
                initValues={initValues}
                handleClose={handleClose}
                isEmailExists={isEmailExists}
                resetEmailExists={() => setIsEmailExists(false)}
            />
        </Box>
    );
};
