import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

import { ReactComponent as LogoIcon } from '../../../../assets/logo/logo.svg';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../styles/Colors';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'center',
    },
    button: {
        textTransform: 'none',
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface TermsDialogProps {
    label: string;
    buttonTitle: string;
    dialogTitle: string;
    content: React.ReactNode;
    onClose?: () => void;
    isOpen?: boolean;
}

const TermsDialog: React.FC<TermsDialogProps> = ({ label, buttonTitle, dialogTitle, content, onClose, isOpen }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen ? isOpen : false);
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Typography variant='body2'>
                <span style={{ paddingRight: '.2rem' }}>{label}</span>
                <a
                    style={{
                        textDecoration: 'none',
                        paddingRight: '.2rem',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '150%',
                        color: '#f06e3c',
                        cursor: 'pointer',
                    }}
                    onClick={handleClickOpen('paper')}>
                    {buttonTitle}
                </a>
            </Typography>
            <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
                <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
                    <Box padding={2} justifyContent='center' textAlign='center'>
                        <LogoIcon fill='red' color='red' stroke='#FFA400' />
                        <Typography variant='h5' textAlign='center' paddingBottom={2}>
                            {dialogTitle}
                        </Typography>
                    </Box>
                </BootstrapDialogTitle>
                <DialogContent id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        onClick={handleClose}
                        sx={{
                            width: '50%',
                            borderRadius: '12',
                            backgroundColor: colors.red400,
                        }}>
                        {t('terms-conditions-confirm')}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default TermsDialog;
