import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const UPDATE_GQL = gql`
    mutation ResendLessonShareInvitation($lessonShareId: String!) {
        resendLessonShareInvitation(lessonShareId: $lessonShareId)
    }
`;

type MutationVariableType = {
    lessonShareId: string;
};

type MutationType = {
    resendLessonShareInvitation: boolean;
};

export const useLessonShareModelResendInvitationMutation = () => {
    const [saveForm] = useMutation<MutationType>(UPDATE_GQL);
    const { accessDenied } = useContext(UserAuthContext);

    const resendInvitation = async (variables: MutationVariableType) => {
        try {
            const result = await saveForm({
                variables,
            });

            if (!result || !result.data?.resendLessonShareInvitation) {
                throw new TTError(ERROR_TYPE.UNKNOWN_ERROR);
            }

            return result?.data?.resendLessonShareInvitation;
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    return { resendInvitation };
};
