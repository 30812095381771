import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { TestroomsQueryModel } from '../../../queryModels/TestroomsQueryModel';
import { TestroomModel } from '../models/TestroomModel';
import { t } from 'i18next';

const TESTROOMS_QUERY = gql`
    query GetRows($otp: String!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        testrooms(where: { otp: { equals: $otp } }, orderBy: { endTime: desc }, take: 1) {
            id
            testroomName
            otp
            lessonVersion {
                id
                lessonName
            }
            class {
                id
            }
            passlock
            offlineMode
            startTime
            endTime
            serverTime
            screenSharing
            writingHints
            freeStyleMode
        }
    }
`;

const UPDATE_TESTROOM_SCREEN_SHARING_GQL = gql`
    mutation save($testroomID: ID!, $screenSharing: Boolean!) {
        updateTestroom(data: { screenSharing: $screenSharing }, where: { id: $testroomID }) {
            id
            screenSharing
        }
    }
`;

const UPDATE_TESTROOM_WRITING_HINTS_GQL = gql`
    mutation save($testroomID: ID!, $writingHints: Boolean!) {
        updateTestroom(data: { writingHints: $writingHints }, where: { id: $testroomID }) {
            id
            writingHints
        }
    }
`;

const UPDATE_TESTROOM_FREE_STYLE_MODE_GQL = gql`
    mutation UpdateFreeStyleMode($testroomID: ID!, $freeStyleMode: Boolean!) {
        updateTestroom(data: { freeStyleMode: $freeStyleMode }, where: { id: $testroomID }) {
            id
            freeStyleMode
        }
    }
`;

export default function useTestroom() {
    const { logout, accessDenied } = useContext(UserAuthContext);

    const [testroomData, setTestroomData] = useState<TestroomModel>({} as TestroomModel);
    const { refetch: queryTestroom, data: testroomsQueryData } = useQuery<TestroomsQueryModel>(TESTROOMS_QUERY, {
        variables: {
            otp: '',
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });
    const [updateTestroomScreenSharingMutation] = useMutation(UPDATE_TESTROOM_SCREEN_SHARING_GQL);
    const [updateTestroomWritingHintsMutation] = useMutation(UPDATE_TESTROOM_WRITING_HINTS_GQL);
    const [updateTestroomFreeStyleModeMutation] = useMutation(UPDATE_TESTROOM_FREE_STYLE_MODE_GQL);

    const { authCheck } = useAuthChecking();

    const enterTestroom = async (otp: string): Promise<boolean> => {
        try {
            const test = await queryTestroom({
                otp: otp,
            });

            if (test?.data?.testrooms && test?.data?.testrooms.length <= 0) {
                throw new TTError(t('classroom.notFound'));
            }

            if (test?.data?.testrooms?.[0]?.offlineMode) {
                throw new TTError('Testroom is not able to use while offline mode is enabled');
            }

            const startTime = new Date(test?.data?.testrooms?.[0]?.startTime);
            const endTime = new Date(test?.data?.testrooms?.[0]?.endTime);
            const serverTime = new Date(test?.data?.testrooms?.[0]?.serverTime);
            if (serverTime < startTime || endTime < serverTime) {
                throw new TTError(t('classroom.ended'));
            }

            return true;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            return false;
        }
    };

    /**
     * @deprecated
     * @param testroomID
     * @param enable
     */
    const updateTestroomScreenSharing = async (testroomID: string, enable: boolean) => {
        try {
            await updateTestroomScreenSharingMutation({
                variables: {
                    testroomID: testroomID,
                    screenSharing: enable,
                },
            });
            setTestroomData((prev) => ({ ...prev, screenSharing: enable }));
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    /**
     * @deprecated
     * @param testroomID
     * @param enable
     */
    const updateTestroomWritingHints = async (testroomID: string, enable: boolean) => {
        try {
            await updateTestroomWritingHintsMutation({
                variables: {
                    testroomID: testroomID,
                    writingHints: enable,
                },
            });
            setTestroomData((prev) => ({ ...prev, writingHints: enable }));
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    /**
     * Updates the freeStyleMode of a testroom.
     * @deprecated
     * @param testroomID - The ID of the testroom.
     * @param enable - A boolean indicating whether to enable or disable the freeStyleMode.
     */
    const updateTestroomFreeStyleMode = async (testroomID: string, enable: boolean) => {
        try {
            await updateTestroomFreeStyleModeMutation({
                variables: {
                    testroomID: testroomID,
                    freeStyleMode: enable,
                },
            });
            setTestroomData((prev) => ({ ...prev, freeStyleMode: enable }));
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    useEffect(() => {
        if (testroomsQueryData && testroomsQueryData.testrooms && testroomsQueryData.testrooms.length > 0) {
            if (testroomsQueryData.testrooms[0].lessonVersion) {
                setTestroomData(testroomsQueryData.testrooms[0]);
            }
        }
    }, [testroomsQueryData, testroomsQueryData?.testrooms]);

    // check session expire
    useEffect(() => {
        if (testroomsQueryData) {
            try {
                authCheck(testroomsQueryData?.authenticatedItem);
            } catch (err) {
                logout();
            }
        }
    }, [logout, testroomsQueryData, testroomsQueryData?.authenticatedItem]);

    return {
        enterTestroom,
        testroomData,
        updateTestroomScreenSharing,
        updateTestroomWritingHints,
        updateTestroomFreeStyleMode,
    };
}
