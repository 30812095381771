import { gql, useMutation } from '@apollo/client';
import { ERROR_TYPE, TTError } from 'models/ErrorHandling';

const UPDATE_GQL = gql`
    mutation updateShareWithPublic($lessonID: ID!, $shareWithPublic: Boolean!) {
        updateLesson(data: { shareWithPublic: $shareWithPublic }, where: { id: $lessonID }) {
            id
            shareWithPublic
        }
    }
`;

type LessonShareModelMutationVariableType = {
    shareWithPublic: boolean;
    lessonID: string;
};

type LessonShareModelMutationType = {
    updateLesson: LessonShareModelMutationVariableType;
};

export const useLessonShareModelUpdateMutation = () => {
    const [saveForm] = useMutation<LessonShareModelMutationType>(UPDATE_GQL);

    const update = async (variables: LessonShareModelMutationVariableType) => {
        const result = await saveForm({
            variables,
        });

        if (!result || !result.data?.updateLesson) {
            throw new TTError(ERROR_TYPE.UPDATE_FAILED);
        }

        return result?.data?.updateLesson;
    };

    return { update };
};
