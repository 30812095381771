import { useAuthChecking } from 'hooks/useAuthChecking';
import { LessonModel, LessonVersionModel } from 'models/lesson/LessonModel';
import { usePublicLessonsQuery } from 'models/lesson/usePublicLessonsQuery';
import { useState } from 'react';
import { useEffect } from 'react';

export const usePublicLessonHook = () => {
    const { getPublicLessons, data, loading } = usePublicLessonsQuery();

    const [lessonData, SetLessonData] = useState<LessonModel[]>([]);

    const {} = useAuthChecking(data);

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (data?.lessons) {
            for (let lessonItem of data.lessons) {
                if (lessonItem.latestLessonDraft) {
                    lessonItem.latestLessonDraft.slides = lessonItem.latestLessonDraft?.slides.map((slideItem) => {
                        if (typeof slideItem.data === 'string') {
                            return {
                                ...slideItem,
                                data: JSON.parse(slideItem.data),
                            };
                        } else {
                            return { ...slideItem };
                        }
                    });

                    // Rename latestLessonDraft to latestLessonVersion
                    // lessonItem.latestLessonVersion = {
                    //   ...lessonItem.latestLessonDraft,
                    // };
                    // delete lessonItem.latestLessonDraft;
                } else {
                    lessonItem.latestLessonDraft = {} as LessonVersionModel;
                }
            }
            SetLessonData(data.lessons);
        }
    }, [data]);

    const refresh = () => {
        try {
            getPublicLessons({});
        } catch (err) {}
    };

    return { loading, lessonData };
};
