import { LoadingButton } from '@mui/lab';
import { Box, FormControl, FormHelperText, InputLabel, Typography, FormControlLabel, Checkbox } from '@mui/material';

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { BootstrapStyleInput } from 'components/BootstrapStyleInput';
import { SignUpRules } from 'pages/onBoarding/utils';
import { OnBoardingPageContext } from 'pages/onBoarding/models';
import { SIGN_UP } from 'pages/onBoarding/queries';
import { RemoveRedEye } from '@mui/icons-material';
import { EmailVerificationPath } from 'routes/utils';
import TermsDialog from './TermsDialog';
import { TermsConditionsContent } from './TermsConditionsContent';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GoogleSigninButton } from 'components/GoogleSigninButton';
import { UserPrefContext } from 'contexts/UserPrefContext';
import { ResultType } from '../SignIn/index';
import { UserAuthContext, UserData } from 'contexts/UserAuthContext';

export const SignUpPage = () => {
    // Google Related
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
    const [showTermsDialog, setShowTermsDialog] = useState(false);
    const { setUserData, setRememberMe } = useContext(UserAuthContext);
    const { isMenuOpened, setIsMenuOpened } = useContext(UserPrefContext);

    const handleResponse = async (result: ResultType | undefined, rememberMe: boolean, authenticateUserWithPasswordOrGoogle: string) => {
        return new Promise<void>((resolve, reject) => {
            if (
                result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'UserAuthenticationWithPasswordFailure' ||
                result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'AuthenticateWithGoogleFailure'
            ) {
                setErrorMessage(result.data[authenticateUserWithPasswordOrGoogle].message || 'An error occurred');
                return;
            }

            if (!result?.data?.[authenticateUserWithPasswordOrGoogle]?.item) {
                throw new Error('User data is missing');
            }

            const doLogin = () => {
                const userData: UserData = {
                    id: result.data[authenticateUserWithPasswordOrGoogle].item.id,
                    email: result.data[authenticateUserWithPasswordOrGoogle].item.email,
                    name: result.data[authenticateUserWithPasswordOrGoogle].item.name,
                    role: result.data[authenticateUserWithPasswordOrGoogle].item.role,
                    googleId: result.data[authenticateUserWithPasswordOrGoogle].item.googleId,
                    googleEmail: result.data[authenticateUserWithPasswordOrGoogle].item.googleEmail,
                    googleProfilePicture: result.data[authenticateUserWithPasswordOrGoogle].item.googleProfilePicture,
                };

                // if (
                //   rememberMe &&
                //   result?.data?.[authenticateUserWithPasswordOrGoogle]?.item
                // ) {
                //   const inThirtyDays = new Date();
                //   inThirtyDays.setDate(inThirtyDays.getDate() + 30);

                //   cookies.set('userData', JSON.stringify(userData), {
                //     expires: inThirtyDays,
                //   });
                // }

                setUserData(userData);
                setIsMenuOpened(true); // Make side menu expanded state by default after login
            };

            // console.log(
            //   result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename,
            // );
            if (result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'SignUpWithGoogleSuccess') {
                setShowTermsDialog(true);
                const onClose = () => {
                    setShowTermsDialog(false);
                    doLogin();
                    resolve(); // Resolve the promise when the dialog is closed
                };
                // const dialogRoot = document.getElementById('dialog-root');
                // ReactDOM.render(
                //   <TermsDialog
                //     label={''}
                //     buttonTitle={''}
                //     dialogTitle={t('terms-conditions-card-title')}
                //     content={<TermsConditionsContent />}
                //     onClose={onClose}
                //     isOpen={true}
                //   />,
                //   dialogRoot,
                // );
            } else {
                doLogin();
            }
        });
    };

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setErrorMessage, setIsLoading } = useOutletContext<OnBoardingPageContext>();
    const [signUp, { loading, error }] = useMutation(SIGN_UP);

    // State for checkbox
    const [checked, setChecked] = useState(true);
    const [checkedTermsConditions, setCheckedTermsConditions] = useState(true);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleTermsConditionsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedTermsConditions(event.target.checked);
    };

    const handleSubmit = async ({ name, email, password }: { name: string; email: string; password: string }) => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const fallbackParam = params.get('fallback');

        try {
            const result = await signUp({
                variables: {
                    data: {
                        name: name,
                        email: email,
                        password: password,
                        role: 'teacher',
                        receiveUpdates: checked, // user agreed to receive information or not
                        fallback: fallbackParam,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            // console.log('result:', result);

            if (result.data?.requestSignUp?.__typename == 'RequestSignUpFailure') {
                setErrorMessage(result.data?.requestSignUp?.message);
            } else {
                navigate(`../${EmailVerificationPath}`, {
                    state: {
                        data: {
                            name: name,
                            email: email,
                            password: password,
                            role: 'teacher',
                            receiveUpdates: checked,
                            fallback: fallbackParam,
                        },
                    },
                });
            }
        } catch (error: any) {
            setErrorMessage(error?.message);
        }
    };

    // For handleSubmitGoogle
    const handleSubmitGoogle = async (result: ResultType) => {
        try {
            await handleResponse(result, false, 'authenticateWithGoogle');
        } catch (error) {
            if (error instanceof Error) {
                // type guard
                setErrorMessage(error.message);
            } else {
                // Handle the case when error is not an Error instance
                setErrorMessage('An error occurred.');
            }
        }
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setErrorMessage(error?.message ? error?.message : '');
    }, [error]);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleSigninButton onSuccess={handleSubmitGoogle} />

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '0px 0',
                }}>
                <hr
                    style={{
                        flexGrow: 1,
                        borderTop: '1px solid silver',
                    }}
                />
                <span
                    style={{
                        margin: '0 10px',
                    }}>
                    {t('sign-in-or')}
                </span>
                <hr
                    style={{
                        flexGrow: 1,
                        borderTop: '1px solid silver',
                    }}
                />
            </div>

            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                }}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                validationSchema={SignUpRules}>
                {({ errors, handleChange }) => (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                minWidth: 360,
                            }}>
                            <Box>
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel
                                        shrink
                                        htmlFor='name'
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#533D18',
                                        }}>
                                        <Typography>{`${t('userName')}*`}</Typography>
                                    </InputLabel>
                                    <BootstrapStyleInput id='name' name='name' onChange={handleChange} fullWidth placeholder={t('enterUserName')} />
                                    {errors?.name && <Box sx={{ color: 'red' }}>{errors.name ? <div>{errors.name}</div> : null}</Box>}
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel
                                        shrink
                                        htmlFor='email'
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#533D18',
                                        }}>
                                        <Typography>{`${t('email')}*`}</Typography>
                                    </InputLabel>
                                    <BootstrapStyleInput id='email' name='email' onChange={handleChange} fullWidth placeholder={t('enterEmail')} />
                                    {errors?.email && <Box sx={{ color: 'red' }}>{errors.email ? <div>{errors.email}</div> : null}</Box>}
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel
                                        shrink
                                        htmlFor='password'
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#533D18',
                                        }}>
                                        {`${t('password')}*`}
                                    </InputLabel>
                                    <BootstrapStyleInput
                                        type='password'
                                        id='password'
                                        name='password'
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            endAdornment: (
                                                // <InputAdornment position='end'>
                                                <RemoveRedEye />
                                                // </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('enterPassword')}
                                    />
                                    <FormHelperText
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#858585',
                                        }}>
                                        {t('must8Characters')}
                                    </FormHelperText>
                                    {errors?.password && <Box sx={{ color: 'red' }}>{errors.password ? <div>{errors.password}</div> : null}</Box>}
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={handleCheckboxChange} name='agreeToReceiveInformation' color='primary' />}
                                    label={
                                        <Typography
                                            // style={{ maxWidth: '300px', wordWrap: 'break-word' }}
                                            style={{
                                                maxWidth: '300px',
                                                wordWrap: 'break-word',
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                            }}>
                                            {t('signup-card-agree-receive-label')}
                                        </Typography>
                                    }
                                    style={
                                        {
                                            // alignItems: 'flex-start',
                                        }
                                    }
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={<Checkbox checked={checkedTermsConditions} onChange={handleTermsConditionsCheckboxChange} name='agreeToReceiveInformation' color='primary' />}
                                    label={
                                        <div>
                                            <TermsDialog
                                                label={t('signup-card-agree-terms-conditions')}
                                                buttonTitle={t('signup-card-terms-button-title')}
                                                dialogTitle={t('terms-conditions-card-title')}
                                                content={<TermsConditionsContent />}
                                            />
                                        </div>
                                    }
                                    style={
                                        {
                                            // alignItems: 'flex-start',
                                        }
                                    }
                                />
                            </Box>

                            <Box>
                                <LoadingButton
                                    variant='contained'
                                    disableElevation
                                    fullWidth
                                    size='large'
                                    color='primary'
                                    disabled={checkedTermsConditions ? false : true}
                                    sx={{
                                        textTransform: 'none',
                                        borderRadius: '12px',
                                        background: '#F06E3C',
                                        boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                        fontWeight: '500',
                                        lineHeight: '28px',
                                        fontSize: '18px',
                                        color: '#FDFCFC',
                                    }}
                                    type='submit'>
                                    {t('signUp')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </GoogleOAuthProvider>
    );
};
