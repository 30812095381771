import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ResultsPageContext } from '../models';

import { useTestroomIdsByClass } from 'models/resultPage/useTestroomIdsByClass';
import { Testroom, useGetQuestionTypeData } from 'models/resultPage/useGetQuestionTypeData';
import { StudentId, useGetClassStudentIds } from 'models/resultPage/getClassStudentIds';
import { useTestroomAnswerStatusByType } from 'models/resultPage/useTestroomAnswerStatusByType';
import { useGetQuestionTypeCountHook } from './useGetQuestionTypeCountHook';
import { useGetStudentPlayingTime } from 'models/resultPage/useGetStudentPlayingTime';
import moment from 'moment';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useGetStudentWorkingTime = () => {
    const { getResultTime } = useGetStudentPlayingTime();
    const getTotalTime = async (studentId: string) => {
        try {
            const resultTime = await getResultTime({
                studentId: studentId ?? '',
            });
            let finishTestTime = 0;
            if (resultTime?.results) {
                for (const result of resultTime?.results) {
                    finishTestTime += moment.utc(moment(result?.endTime).diff(moment(result?.startTime))).seconds();
                }
            }
            return finishTestTime;
        } catch (error: any) {
            console.log(error);
            console.log(error?.message);
        }
    };
    return { getTotalTime };
};
