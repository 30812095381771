import { PlanConfigModel } from 'models/planConfig/PlanConfigModel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserAuthContext } from './UserAuthContext';

type UserPermissionContextType = {
    userPermissions: PlanConfigModel;
    setUserPermissions: React.Dispatch<React.SetStateAction<PlanConfigModel>>;
};

export const contextDefaultValues: UserPermissionContextType = {
    userPermissions: {} as PlanConfigModel,
    setUserPermissions: () => {},
};

export const useUserPermissionContext = () => {
    const context = useContext(UserPermissionContext);
    if (!context) {
        throw new Error('useUserPermissionContext must be used within a UserPermissionContextProvider');
    }
    return context;
};

export const UserPermissionContext = createContext<UserPermissionContextType>(contextDefaultValues);

export const UserPermissionContextProvider: React.FC = ({ children }) => {
    const [permissions, setPermissions] = useState<PlanConfigModel>({} as PlanConfigModel);

    const { selectedSchool } = useContext(UserAuthContext);

    useEffect(() => {
        if (selectedSchool) {
            // Assuming numberOfTeachers is available in selectedSchool object
            const numberOfTeachers = selectedSchool?.teachers?.length || 1; // Default to 1 if not available

            let updatedPermissions = selectedSchool.subscriptionHistories?.[0]?.product?.planConfig;

            // If permissions exist, update the number type fields
            if (updatedPermissions) {
                updatedPermissions = Object.entries(updatedPermissions).reduce<PlanConfigModel>((acc: any, [key, value]) => {
                    if (typeof value === 'number') {
                        acc[key as keyof PlanConfigModel] = Math.min(9999, value * numberOfTeachers);
                    } else {
                        acc[key as keyof PlanConfigModel] = value;
                    }
                    return acc;
                }, {} as PlanConfigModel);
            }

            setPermissions(updatedPermissions);
        }
    }, [selectedSchool]);

    // useEffect(() => {
    //   console.log('permissions', permissions);
    // }, [permissions]);

    return (
        <UserPermissionContext.Provider
            value={{
                userPermissions: permissions,
                setUserPermissions: setPermissions,
            }}>
            {children}
        </UserPermissionContext.Provider>
    );
};
