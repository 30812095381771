import { ResultTableProps } from 'components/ResultTable';
import moment from 'moment';
import { useEffect, useState } from 'react';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;
type classOverallProp = {
    studentData?: ResultTableProps;
    averageTime?: number;
    numberOfQuestion: number;
};
export const useClassOverallProp = (props: classOverallProp) => {
    useEffect(() => {
        if (props.studentData) {
            if (props.studentData?.data != undefined) {
                setStudentNumber(props?.studentData?.data?.length);
            }
        }
        if (props.numberOfQuestion) {
            setNumberOfQuestion(props.numberOfQuestion);
        }
        if (props.averageTime) {
            setAverageTime(moment.utc(props.averageTime * 1000).format('mm:ss'));
        }
    }, [props.studentData, props.numberOfQuestion, props.averageTime]);
    const [studentNumber, setStudentNumber] = useState(props?.studentData?.data?.length);
    const [numberOfQuestion, setNumberOfQuestion] = useState(props.numberOfQuestion);
    const [averageTime, setAverageTime] = useState('');
    return {
        studentNumber,
        numberOfQuestion,
        averageTime,
    };
};
