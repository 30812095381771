import { gql, useMutation } from '@apollo/client';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { SlideListContext } from '../contexts/LessonsSlideContext';
import { SlideComponent, SlideComponentAudio, SlideComponentFileModel } from 'models/lesson/LessonModel';
import useLessonSlide from './useLessonSlide';
import { enqueueSnackbar } from 'notistack';
import { t } from 'i18next';

const UPLOAD_GQL = gql`
    mutation save($slide: ID!, $file: Upload!) {
        createSlideFile(data: { file: { upload: $file }, slides: { connect: [{ id: $slide }] } }) {
            id
            file {
                filename
                url
            }
        }
    }
`;

// todo jerry: delete unused file later
// const DELETE_FILE_GQL = gql`
//   mutation delete($slideFileID: ID!) {
//     deleteSlideFile(where: { id: $slideFileID }) {
//       id
//     }
//   }
// `;

export default function useLessonSlideProps() {
    const { accessDenied } = useContext(UserAuthContext);
    const { selectedSlideIndex, lessonData } = useContext(SlideListContext);
    const { updateItemProperties } = useLessonSlide();

    const [uploadFile] = useMutation(UPLOAD_GQL);
    // todo jerry: delete unused file later
    // const [deleteFile] = useMutation(DELETE_FILE_GQL);

    const slideComponentHandler = (item: SlideComponent, save: boolean) => {
        updateItemProperties({ ...item }, save);
    };

    const uploadFileOnStopCallback = async (voFile: File, item: SlideComponent) => {
        let itemContent: SlideComponentAudio = item.content as SlideComponentAudio;
        try {
            let res = await uploadFile({
                variables: {
                    slide: lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id,
                    file: voFile,
                },
            });

            // if only 1 allow can be allowed to upload
            let totalAudio = itemContent.audio.length;
            if (totalAudio > 0) {
                // delete prev audio
                while (totalAudio--) {
                    deleteAudio(itemContent.audio[totalAudio].id, item);
                }
            }
            itemContent.audio = [
                ...itemContent.audio,
                {
                    id: res.data.createSlideFile.id,
                    filename: res.data.createSlideFile.file.filename,
                    url: res.data.createSlideFile.file.url,
                },
            ];

            slideComponentHandler({ ...item }, true);
        } catch (err) {
            enqueueSnackbar(t('error.UPLOAD_ERROR'), {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
    };

    const deleteAudio = async (id: string, item: SlideComponent) => {
        let itemContent: SlideComponentAudio = item.content as SlideComponentAudio;
        const fileID = itemContent.audio.filter((audio: SlideComponentFileModel) => audio.id === id)[0].id;
        if (fileID) {
            try {
                // todo jerry: delete unused file later
                // await deleteFile({
                //   variables: {
                //     slideFileID: fileID,
                //   },
                // });

                //done
                // console.log(res);
                itemContent.audio = itemContent.audio.filter((audio: SlideComponentFileModel) => audio.id !== id);
                slideComponentHandler({ ...item }, true);
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    enqueueSnackbar(errorMsg, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }
            }
        }
    };

    return {
        slideComponentHandler,
        uploadFileOnStopCallback,
        deleteAudio,
    };
}
