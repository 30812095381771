import React from 'react';
import { useEffect, useState, useContext } from 'react';

import { Stack, Box, Tab, Tabs, AppBar, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PaymentPlanButtonType, PaymentPlans, PlanName } from './PaymentPlans';
import { i18n } from '../../../src/index';
import { SubscribeButtonProps } from '../payment/PaymentPlans';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { InvoicePreview } from '../payment/components/InvoicePreview';
import { NotPaymentUserDialog } from '../profile/components/NotPaymentUserDialog';

interface PaymentPanelProps {
    language?: string; // `language` is optional and is of type string
    onSubscribeClick?: (props: SubscribeButtonProps) => void;
}

export const PaymentPanel: React.FC<PaymentPanelProps> = ({ language, onSubscribeClick }) => {
    const { t } = useTranslation();
    const auth = useContext(UserAuthContext);

    const url = new URL(window.location.href);
    const params = url.searchParams;
    const planToSubscribe = params.get('plan_to_subscribe');

    useEffect(() => {
        if (planToSubscribe && planToSubscribe !== '' && auth.selectedSchool) {
            handleSubscribeClick({
                buttonType: PaymentPlanButtonType.Subscribe,
                internalPlanName: planToSubscribe as PlanName,
            });
        }
    }, [planToSubscribe, auth.selectedSchool]);

    const [selectedPlanName, setSelectedPlanName] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    // The following is for when PaymentPanel is embedded inside the Turtlelearn.com website,
    // so that the language changes with the domain name
    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language]);
    const [showContactPaymentUserDialog, setShowContactPaymentUserDialog] = useState(false);
    const [individualOrSchool, setIndividualOrSchool] = React.useState(1);
    const [period, setPeriod] = React.useState(0);
    const [openInvoice, setOpenInvoice] = useState(false);

    const handleSubscribeClick = async (props: SubscribeButtonProps) => {
        const currentLanguage = i18n.language;
        setSelectedPlanName(props.internalPlanName || '');

        // Check if the current user is the PaymentUser
        const isPaymentUser = auth.selectedSchool?.paymentUser.id === auth.userData?.id;

        if (props.buttonType === 'Contact') {
            // Temporarily make all buttons lead to contact form
            let targetLocale = '';
            if (currentLanguage === 'zh-CN') {
                targetLocale = 'zh.';
            } else if (currentLanguage === 'en-US') {
                targetLocale = 'en.';
            }
            window.open('https://' + targetLocale + 'turtlelearn.com/school-order', '_blank');
        } else if (props.buttonType === 'Subscribe') {
            if (!isPaymentUser) {
                setShowContactPaymentUserDialog(true);
                return;
            }

            setOpenInvoice(true);
        }
    };
    const handleCloseInvoice = () => {
        setOpenInvoice(false);
        // window.location.reload();
    };
    const handleIndividualSchoolChange = (event: React.SyntheticEvent, newValue: number) => {
        // console.log(newValue);
        setIndividualOrSchool(newValue);
    };

    const handlePeriodChange = (event: React.SyntheticEvent, newValue: number) => {
        // console.log(newValue);
        setPeriod(newValue);
    };

    return (
        <Stack direction='column' width='1152px' alignItems='center'>
            {/* Dialog for Invoice Preview */}
            <Dialog open={openInvoice} onClose={handleCloseInvoice} maxWidth='sm' fullWidth>
                <DialogTitle>{t('invoice.invoice-preview-title')}</DialogTitle>
                <DialogContent>
                    <InvoicePreview upgradePlanName={selectedPlanName} />
                </DialogContent>
            </Dialog>
            <Box
                sx={{
                    width: '100%',
                    marginBottom: '30px',
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #e0e0e0',
                }}>
                <Tabs variant='fullWidth' value={individualOrSchool} onChange={handleIndividualSchoolChange} aria-label='individual school'>
                    <Tab label={t('pricing.individual')} />
                    <Tab label={t('pricing.school')} />
                </Tabs>
            </Box>
            <Box
                sx={{
                    // boxSizing: 'border-box',
                    bgcolor: 'background.paper',
                    width: '30%',
                    marginTop: '30px',
                    marginBottom: '30px',
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #e0e0e0',
                }}>
                <AppBar position='static' color='inherit'>
                    <Tabs variant='fullWidth' indicatorColor='secondary' textColor='inherit' value={period} onChange={handlePeriodChange} aria-label='renewal period'>
                        <Tab label={t('pricing.annual')} />
                        <Tab label={t('pricing.monthly')} />
                    </Tabs>
                </AppBar>
            </Box>
            {individualOrSchool === 0 ? (
                <PaymentPlans
                    onSubscribeButtonClick={onSubscribeClick == undefined || onSubscribeClick == null ? handleSubscribeClick : onSubscribeClick} // if onSubscribeClick is not provided, use handleSubscribeClick here
                    category='individual'
                    period={period}
                />
            ) : (
                <PaymentPlans
                    onSubscribeButtonClick={onSubscribeClick == undefined || onSubscribeClick == null ? handleSubscribeClick : onSubscribeClick} // if onSubscribeClick is not provided, use handleSubscribeClick here          category='school'
                    category='school'
                    period={period}
                />
            )}
            <Box
                sx={{
                    // boxSizing: 'border-box',
                    bgcolor: 'background.paper',
                    width: '30%',
                    marginTop: '30px',
                    marginBottom: '30px',
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #e0e0e0',
                }}>
                <AppBar position='static' color='inherit'>
                    <Tabs variant='fullWidth' indicatorColor='secondary' textColor='inherit' value={period} onChange={handlePeriodChange} aria-label='renewal period'>
                        <Tab label={t('pricing.annual')} />
                        <Tab label={t('pricing.monthly')} />
                    </Tabs>
                </AppBar>
            </Box>
            {individualOrSchool === 0 ? (
                <PaymentPlans
                    onSubscribeButtonClick={onSubscribeClick == undefined || onSubscribeClick == null ? handleSubscribeClick : onSubscribeClick} // if onSubscribeClick is not provided, use handleSubscribeClick here
                    isComparisonView={true}
                    category='individual'
                    period={period}
                />
            ) : (
                <PaymentPlans
                    onSubscribeButtonClick={onSubscribeClick == undefined || onSubscribeClick == null ? handleSubscribeClick : onSubscribeClick} // if onSubscribeClick is not provided, use handleSubscribeClick here
                    isComparisonView={true}
                    category='school'
                    period={period}
                />
            )}
            <NotPaymentUserDialog t={t} open={showContactPaymentUserDialog} setOpen={setShowContactPaymentUserDialog} />
        </Stack>
    );
};
