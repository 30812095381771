import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { LessonsNavBar } from './LessonsNavBar';
import AddIcon from '@mui/icons-material/Add';

import { SlidePreviewContextProvider } from '../contexts/SlidePreviewContext';
import { SlidePreview } from 'pages/testroom/views/SlidePreview';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import IosShareIcon from '@mui/icons-material/IosShare';
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { UserPermissionContext } from '../../../contexts/UserPermissionContext';
import { useNavigate } from 'react-router-dom';
import { UpgradePlanPopup } from '../../../routes/UpgradePlanPopup';
import { useContext, useState } from 'react';
import { LessonModel } from 'models/lesson/LessonModel';
import { ratio } from '../hooks/useLessonSlide';

type LessonsListProps = {
    loading: boolean;
    lessonData: LessonModel[];
};
export const LessonsList = (props: LessonsListProps) => {
    const { t, i18n } = useTranslation();
    const currentLocale = require(`date-fns/locale/${i18n.language}`).default;
    const { userPermissions } = useContext(UserPermissionContext);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const navigate = useNavigate();

    const lessonLimitReached = () => {
        return props.lessonData?.length >= userPermissions?.maxNumLessons;
    };
    const handleButtonClick = () => {
        if (props.lessonData?.length + 1 <= userPermissions?.maxNumLessons) {
            navigate('/lessons/+');
        } else {
            setShowUpgradePopup(true);
        }
    };
    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <PopupCircularProgress isOpened={props.loading} />
            <Grid container sx={{ pb: 3 }}>
                <Grid item xs={6}>
                    <LessonsNavBar />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6}>
                    <Button variant='contained' color='secondary' sx={{ textTransform: 'none', borderRadius: '8px' }} startIcon={<AddIcon />} onClick={handleButtonClick}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <Typography component='span'>{t('add-lesson')}</Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    component='span'
                                    sx={{
                                        color: lessonLimitReached() ? 'red' : 'inherit',
                                    }}>
                                    {props.lessonData?.length}
                                </Typography>
                                <Typography component='span' sx={{ fontSize: '0.75rem' }}>
                                    {' '}
                                    {/* Same smaller font size */}
                                    {' / '}
                                    {userPermissions?.maxNumLessons} {t('lesson-limit')}
                                </Typography>
                            </Box>
                        </Box>
                    </Button>
                    {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='lessonLimitExceeded' />}
                </Grid>
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} justifyContent='flex-start' alignItems='flex-start'>
                {props.lessonData?.map((data: LessonModel) => (
                    <Grid item xl={'auto'} key={data.id}>
                        <Card
                            sx={{
                                borderRadius: '16px',
                                border: 1,
                                borderColor: '#5A4522',
                                bgcolor: '#FFFBF6',
                            }}
                            elevation={0}>
                            <CardActionArea href={'/lessons/' + data.id}>
                                <CardMedia
                                    component='div'
                                    sx={{
                                        alignContent: 'center',
                                    }}>
                                    <Box
                                        sx={{
                                            width: 345,
                                            aspectRatio: `${ratio}`,
                                            overflow: 'auto',
                                            overflowX: 'hidden',
                                            overflowY: 'hidden',
                                        }}>
                                        {data.latestLessonDraft?.slides && data.latestLessonDraft?.slides.length > 0 && (
                                            <SlidePreviewContextProvider>
                                                <SlidePreview data={data.latestLessonDraft?.slides[0].data} hasBorderColor={false} borderRadius='0px' />
                                            </SlidePreviewContextProvider>
                                        )}
                                    </Box>
                                </CardMedia>
                                <CardContent
                                    sx={{
                                        position: 'relative',
                                    }}>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontSize: '1rem',
                                            color: '#5A4522',
                                            fontWeight: 'medium',
                                            lineHeight: '21px',
                                        }}>
                                        {data.latestLessonDraft?.lessonName}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 'regular',
                                            // lineHeight: '16px',
                                            color: '#BFAC90',
                                            // height: '10px',
                                        }}>
                                        {data?.latestLessonDraft?.dateUpdated &&
                                            format(
                                                t('last-update'),
                                                formatDistanceToNow(new Date(data?.latestLessonDraft?.dateUpdated), {
                                                    addSuffix: true,
                                                    locale: currentLocale,
                                                }),
                                            )}
                                    </Typography>
                                    {data && (data?.shareWithPublic || data?.sharesCount > 0) ? (
                                        <IosShareIcon
                                            sx={{
                                                position: 'absolute',
                                                fontSize: 35,
                                                fill: '#F06E3C',
                                                border: 1,
                                                borderColor: '#F06E3C',
                                                borderRadius: 2,
                                                padding: 0.5,
                                                bottom: 10,
                                                right: 10,
                                            }}
                                        />
                                    ) : null}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};
