import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { ResultTableProps } from 'components/ResultTable';
import React, { useEffect, useState } from 'react';
import QuestionTypeCorrectPercentageChart from './QuestionTypeCorrectPercentageChart';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import { useTranslation } from 'react-i18next';

interface QuestionType {
    [key: string]: number;
}

function convertGameTypeToStr(gameType: string) {
    if (gameType === 'Card') {
        return '字卡';
    }
    if (gameType === 'Writing') {
        return '寫字';
    }
    return '';
}

const data: QuestionType = {
    Writing: 50,
    Card: 50,
};

const OverallQuestionTypePerformance = () => {
    const { questionTypeResult } = useTestroomResult();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Card
                sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flex: '1 0 0',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                <CardHeader
                    title={
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontStyle: 'normal',
                            }}>
                            {t('resultPage.question-type-answer-performance')}
                        </Typography>
                    }
                />
                <Divider orientation='horizontal' flexItem />
                <CardContent
                    sx={{
                        display: 'flex',
                        padding: '16px 132px',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        gap: '60px',
                    }}>
                    {' '}
                    {questionTypeResult &&
                        Object.keys(questionTypeResult).map((key) => (
                            <QuestionTypeCorrectPercentageChart correct={questionTypeResult[key].totalCorrect ?? 0} question={questionTypeResult[key].totalQuestions ?? 0} gameType={key} />
                        ))}
                </CardContent>
            </Card>
        </Box>
    );
};

export default OverallQuestionTypePerformance;
