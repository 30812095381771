import { useMutation, gql } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { useContext, useEffect, useMemo } from 'react';

// Define the GraphQL mutation
const CREATE_GOOGLE_DOC = gql`
    mutation CreateGoogleDoc($userId: String!, $docTitle: String!) {
        createGoogleDoc(userId: $userId, docTitle: $docTitle) {
            success
            docId
            docUrl
        }
    }
`;

const APPEND_GOOGLE_DOC = gql`
    mutation AppendGoogleDoc($userId: String!, $docId: String!, $text: String!) {
        appendGoogleDoc(userId: $userId, docId: $docId, text: $text)
    }
`;

export function useAppendGoogleDoc() {
    const [appendGoogleDoc, { data }] = useMutation(APPEND_GOOGLE_DOC);
    const { userData, selectedSchool } = useContext(UserAuthContext);
    const handleAppendGoogleDoc = async (docId: string, text: string) => {
        // console.log('handleAppendGoogleDoc, userData: ', userData);
        if (userData) {
            try {
                const success = await appendGoogleDoc({
                    variables: {
                        userId: userData.id,
                        docId: docId,
                        text: text,
                    },
                });
                // Handle the successful creation of the doc...
                if (success) {
                    // console.log('Document appended with text: ', text);
                    return true;
                }
            } catch (error) {
                // Handle an error response...
                console.error(error);
            }
        } else {
            console.error('userData is null');
            throw new Error('userData is null');
        }
    };
    return handleAppendGoogleDoc;
}

export function useCreateGoogleDoc() {
    const [createGoogleDoc, { data }] = useMutation(CREATE_GOOGLE_DOC);
    const { userData, selectedSchool } = useContext(UserAuthContext);

    const handleCreateGoogleDoc = async (docTitle: string) => {
        // console.log('handleCreateGoogleDoc, userData: ', userData);

        if (userData) {
            try {
                const response = await createGoogleDoc({
                    variables: {
                        userId: userData.id,
                        docTitle: docTitle,
                    },
                });

                // Handle the successful creation of the doc...
                if (response.data.createGoogleDoc.success) {
                    // console.log(
                    //   'Document created with id: ',
                    //   response.data.createGoogleDoc.docId,
                    // );
                    return response.data.createGoogleDoc.docId;
                }
            } catch (error) {
                // Handle an error response...
                console.error(error);
            }
        } else {
            console.error('userData is null');
            throw new Error('userData is null');
        }
    };

    return handleCreateGoogleDoc;
}
