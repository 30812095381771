import { gql } from '@apollo/client';

export const SIGN_IN = gql`
    mutation SignIn($email: String!, $password: String!) {
        authenticateUserWithPassword(email: $email, password: $password) {
            ... on UserAuthenticationWithPasswordSuccess {
                sessionToken
                item {
                    id
                    email
                    name
                    role
                    googleId
                    googleEmail
                    googleProfilePicture
                }
            }
            ... on UserAuthenticationWithPasswordFailure {
                message
            }
        }
    }
`;

export const SIGN_UP = gql`
    mutation RequestSignUp($data: VerifyAndCreateUserInput!) {
        requestSignUp(data: $data) {
            ... on RequestSignUpFailure {
                message
            }
            ... on RequestSignUpSuccess {
                success
            }
        }
    }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
    mutation ResendVerificationEmail($data: VerifyAndCreateUserInput!) {
        resendVerificationEmail(data: $data)
    }
`;

export const VERIFY_AND_CREATE_USER = gql`
    mutation VerifyAndCreateUser($token: String!) {
        verifyAndCreateUser(token: $token) {
            ... on VerifyAndCreateUserSuccess {
                user {
                    id
                    email
                    name
                    role
                    teacherProfile {
                        id
                    }
                }
                fallback
            }
            ... on VerifyAndCreateUserFailure {
                message
            }
        }
    }
`;

export const AUTHENTICATE_WITH_GOOGLE = gql`
    mutation AuthenticateWithGoogle($token: String!) {
        authenticateWithGoogle(token: $token) {
            ... on AuthenticateWithGoogleSuccess {
                sessionToken
                item {
                    id
                    email
                    name
                    role
                    googleId
                    googleEmail
                    googleProfilePicture
                }
            }
            ... on SignUpWithGoogleSuccess {
                sessionToken
                item {
                    id
                    email
                    name
                    role
                    googleId
                    googleEmail
                    googleProfilePicture
                }
            }
            ... on AuthenticateWithGoogleFailure {
                message
            }
        }
    }
`;
