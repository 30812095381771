import { gql, useLazyQuery } from '@apollo/client';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext, useState } from 'react';
import { LessonShareModel } from './LessonShareModel';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { enqueueSnackbar } from 'notistack';

const QUERY_LESSON_SHARE_GQL = gql`
    query LessonShares($where: LessonShareWhereInput!) {
        lessonShares(where: $where) {
            id
            email
            accepted
        }
    }
`;

type LessonShareModelQueryVariableType = {
    where: {
        lesson: { id: { equals: string } };
    };
};

type LessonShareModelQueryType = {
    lessonShares: LessonShareModel[];
};

export const useLessonShareModelQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData] = useLazyQuery<LessonShareModelQueryType>(QUERY_LESSON_SHARE_GQL);
    const [data, setData] = useState<LessonShareModel[]>([]);

    const getData = async (variables: LessonShareModelQueryVariableType): Promise<LessonShareModel[]> => {
        try {
            const result = await queryData({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            setData(result?.data?.lessonShares);
            return result?.data?.lessonShares;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return [];
    };

    return { getData, data };
};
