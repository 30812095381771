import styled from '@emotion/styled';
import { forwardRef, useLayoutEffect, useMemo, useRef } from 'react';

interface WritingAnswerRef {
    replayStrokeDraw: () => void;
}

type Strokes = {
    x: number;
    y: number;
    pressure?: number | undefined;
};

type WritingAnswerProps = {
    strokes: Strokes[][];
    writingVersion: number | null;
};
const WRITING_CANVAS_HKCS = {
    width: 72,
    height: 72,
};
const STROKE_WIDTH_VERSION_1 = 3;
const STROKE_WIDTH_VERSION_2 = 3;
const UNITY_WRITING_CANVAS = 400;
const INTERPOLATION_FACTOR = 10;
export const HkcsWritingAnswer = forwardRef((props: WritingAnswerProps, ref) => {
    const { strokes, writingVersion } = props;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const intervalRef = useRef<any>(null);
    let t = 1;

    const replayStrokeDraw = () => {
        if (canvasRef?.current) {
            clearInterval(intervalRef.current);
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            context?.clearRect(0, 0, canvas?.width, canvas?.height);
            if (outlinePoint.length > 0) {
                const calPoints = calcWaypoints(outlinePoint[0]);
                animate(calPoints, outlinePoint.length, 0);
                t = 1;
            }
        }
    };

    const createOutlinedPoint = (strokes: Strokes[][]) => {
        const scaleFactor = writingVersion ? WRITING_CANVAS_HKCS.width / UNITY_WRITING_CANVAS : 0.1;
        const newStrokes = strokes as Strokes[][];
        return newStrokes.map((stroke) =>
            stroke.map((value) => ({
                x: value.x * scaleFactor,
                y: value.y * scaleFactor,
            })),
        );
    };

    const outlinePoint = useMemo(() => createOutlinedPoint(strokes), [strokes]);

    const calcWaypoints = (
        vertices: {
            x: number;
            y: number;
        }[],
    ) => {
        var waypoints = [];
        const tempVertices = vertices as Strokes[];
        for (var i = 1; i < tempVertices.length; i++) {
            var pt0 = tempVertices[i - 1];
            var pt1 = tempVertices[i];
            var dx = pt1.x - pt0.x;
            var dy = pt1.y - pt0.y;
            for (var j = 0; j < 10; j++) {
                var x = pt0.x + (dx * j) / INTERPOLATION_FACTOR;
                var y = pt0.y + (dy * j) / INTERPOLATION_FACTOR;
                waypoints.push({
                    x: x,
                    y: y,
                });
            }
        }
        return waypoints;
    };

    const animate = (
        points: {
            x: number;
            y: number;
        }[],
        strokeLength: number,
        index: number,
    ) => {
        if (canvasRef?.current) {
            const canvas = canvasRef.current;
            let context = canvas?.getContext('2d');
            intervalRef.current = setInterval(() => {
                if (t < points?.length - 1) {
                    context?.beginPath();
                    context?.moveTo(points[t - 1].x, points[t - 1].y);
                    context?.lineTo(points[t].x, points[t].y);
                    context?.stroke();
                    t++;
                } else {
                    clearInterval(intervalRef.current);
                    t = 1;
                    if (index + 1 < strokeLength) {
                        const calPoints = calcWaypoints(outlinePoint[index + 1]);
                        animate(calPoints, outlinePoint.length, index + 1);
                    }
                }
            }, 1 / 100);
        }
    };

    const writeStrokes = (
        points: {
            x: number;
            y: number;
        }[],
        context: CanvasRenderingContext2D,
    ) => {
        for (var i = 1; i < points.length; i++) {
            if (points[i + 1] && points[i + 1].y) {
                context?.beginPath();
                context?.moveTo(points[i].x, points[i].y);
                context?.lineTo(points[i + 1].x, points[i + 1].y);
                context?.stroke();
            }
        }
    };

    useLayoutEffect(() => {
        if (canvasRef?.current) {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if (writingVersion) {
                // Version 2 does not require flip Y Axis
                context?.transform(1, 0, 0, 1, 0, 0);
            } else {
                context?.transform(1, 0, 0, -1, 0, canvas.height);
            }
            if (context) {
                context.lineWidth = writingVersion ? STROKE_WIDTH_VERSION_2 : STROKE_WIDTH_VERSION_1;
            }
        }
    }, [canvasRef]);

    useLayoutEffect(() => {
        if (canvasRef?.current) {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if (outlinePoint.length > 0 && context) {
                for (const points of outlinePoint) {
                    const calPoints = calcWaypoints(points);
                    writeStrokes(calPoints, context);
                }
            }
        }
    }, [outlinePoint, canvasRef]);

    return (
        <Container>
            <Canvas width={WRITING_CANVAS_HKCS.width} height={WRITING_CANVAS_HKCS.height} ref={canvasRef}>
                Canvas
            </Canvas>
        </Container>
    );
});

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 71px;
    background: transparent;
    position: relative;
`;

const Canvas = styled.canvas``;
