import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { TeacherProfileModel } from 'models/teacher/TeacherProfileModel';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const QUERY_GQL = gql`
    query GetRows($userID: ID!) {
        user(where: { id: $userID }) {
            teacherProfile {
                id
                schools {
                    id
                    schoolName
                    paymentUser {
                        id
                        name
                        email
                    }
                    teachers {
                        id
                    }
                    subscriptionHistories(take: 1, orderBy: { dateCreated: desc }) {
                        id
                        expiryDate
                        stripeSubscriptionId
                        stripeCustomerId
                        autoRenew
                        product {
                            name
                            internalName
                            isFreePlan
                            price
                            currency
                            interval
                            stripeProductId
                            stripePriceId
                            planConfig {
                                id
                                planName
                                enableCantonesePronunciation
                                enableFillInTheBlanks
                                enableMandarinPronunciation
                                enablePunctuation
                                enableReadingComprehension
                                enableMultipleChoice
                                enableHkcsQ1
                                enableHkcsQ2
                                enableHkcsQ3
                                enableHkcsEnQ1
                                enableHkcsEnQ2
                                enableSasAudio
                                enableSasFillInTheBlanks
                                enableSasFillInTheBlanksWriting
                                enableSasRadicalMatching
                                enableRearrangingSentence
                                enableSharing
                                enableUploadImage
                                enableWorksheetFunction
                                enableWriting
                                maxNumClasses
                                maxNumLessons
                                maxStudentID
                                permittedHongKongLexCharacters
                            }
                        }
                    }
                }
            }
        }
    }
`;

type QueryVariableType = {
    userID: string;
};

type QueryType = {
    user: { teacherProfile: TeacherProfileModel };
};

export const useSchoolQuery = () => {
    const auth = useContext(UserAuthContext);
    const [getForm] = useLazyQuery<QueryType>(QUERY_GQL, {
        fetchPolicy: 'network-only',
    });
    const fetchSchool = async (variables: QueryVariableType) => {
        try {
            const result = await getForm({
                variables,
            });

            if (!result || !result.data?.user?.teacherProfile) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            if (auth?.userData) {
                auth.setUserData({
                    ...auth.userData,
                    schools: result.data.user.teacherProfile?.schools,
                    teacherProfileId: result.data.user.teacherProfile?.id,
                });
            }

            const schoolIndex = result.data.user.teacherProfile?.schools.findIndex((school: any) => school.id === auth.selectedSchool?.id);

            auth.setSelectedSchool(result.data.user.teacherProfile?.schools?.[schoolIndex >= 0 ? schoolIndex : 0]);
        } catch (err) {
            console.log(err);
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { fetchSchool };
};
