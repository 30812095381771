import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { SlideComponent } from 'models/lesson/LessonModel';

export const GET_CHARACTER_JSON = gql`
    query Characters($character: String!) {
        characters(where: { character: { equals: $character }, locale: { equals: "zh-HK" } }, take: 1) {
            id
            code
            locale
            character
            strokeArray
        }
    }
`;

export const get_charater = (character: string) => {
    return {
        character: character,
    };
};

export type ModelQueryType = {
    characters?: any[];
};

export type Slide = {
    data: SlideComponent;
};
export type LessonVersion = {
    slides: Slide[];
};

export type Testroom = {
    id: string;
    lessonVersion: LessonVersion;
};

type QueryVariableType = {
    character: string;
};

export const useGetCharacterJson = () => {
    const auth = useContext(UserAuthContext);
    const [getChar, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_CHARACTER_JSON);
    const getCharacterJson = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getChar({
                variables: get_charater(variables.character),
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            console.log(result?.data);
            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getCharacterJson };
};
