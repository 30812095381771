import { useContext } from 'react';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SlideComponent, SlideComponentAudio } from 'models/lesson/LessonModel';
import { Button } from '@mui/material';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';

let playingAudio: HTMLAudioElement | null = null;

export const SlideAudio = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentAudio = item.content as SlideComponentAudio;

    return (
        <Button
            sx={{
                position: 'absolute',
                zoom: zoom,
                width: `${item?.size?.width ?? 0}px`,
                height: `${item?.size?.width ?? 0}px`, // sqaure
                borderRadius: '50%',
                left: item.pos.x,
                top: item.pos.y,
                zIndex: 500,
                m: 'inherit',
                p: 'inherit',
                color: 'black',
                bgcolor: 'white',
                '&:hover': {
                    bgcolor: '#e3e3e3',
                },
                minWidth: `${item?.size?.width ?? 0}px`,
                minHeight: `${item?.size?.width ?? 0}px`,
            }}
            onClick={() => {
                if (itemContent.audio.length > 0) {
                    if (playingAudio) {
                        playingAudio.pause();
                    }
                    playingAudio = new Audio(itemContent.audio[0].url);
                    playingAudio.play();
                }
            }}>
            <VolumeUpIcon
                sx={{
                    width: `${item?.size?.width ?? 0}px`,
                    height: `${item?.size?.width ?? 0}px`, // sqaure
                    m: 'inherit',
                    p: 'inherit',
                }}
            />
        </Button>
    );
};
