import { REQUEST_TEACHER_CONFIRM_ASSIGN } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';

export const requestConfirmAssign = (props: { socket: Socket | null }) => {
    if (props.socket == null) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';
    props.socket?.emit(REQUEST_TEACHER_CONFIRM_ASSIGN, { token: token });
};
