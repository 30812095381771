import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
interface SelectClassProp {
    filteredClassId: string;
    classes: ClassProp[];
    selectedClassChange: (e: string) => void;
}

export interface ClassProp {
    className: string;
    id: string;
}

const StudentSelectFromClassMenuButton = ({ filteredClassId, classes, selectedClassChange }: SelectClassProp) => {
    const { t } = useTranslation();

    const getClassName = (classId: string) => {
        if (classId === '') {
            return '';
        }
        const result = classes.find((classObj: ClassProp) => classObj.id === classId);
        setMenuBtnText(result !== undefined ? result.className : '');
        return result !== undefined ? result.className : '';
    };
    useEffect(() => {
        getClassName(filteredClassId);
    }, [classes]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuBtnText, setMenuBtnText] = useState('');
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClick = (className: ClassProp) => {
        selectedClassChange(className.id);
        setMenuBtnText(className.className);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Button
                variant='contained'
                color='secondary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                    marginLeft: '2rem',
                }}
                onClick={handleClick}>
                {menuBtnText === '' ? t('studentPage.class-filter') : menuBtnText}
            </Button>
            <Menu
                sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {classes.map((classObj) => (
                    <MenuItem key={classObj.id} onClick={() => handleMenuClick(classObj)}>
                        {classObj.className === '' ? t('all') : classObj.className}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default StudentSelectFromClassMenuButton;
