import { t } from 'i18next';
import { Results } from '../models';

export type SortDirection = 'ascending' | 'descending' | 'unsorted';
export function getStringSortDirection(array: any[], targetProps: string): SortDirection {
    const c = [];
    for (let i = 1; i < array.length; i++) {
        c.push(array[i - 1][targetProps].localeCompare(array[i][targetProps]));
    }

    if (c.every((n) => n <= 0)) return 'ascending';
    if (c.every((n) => n >= 0)) return 'descending';

    return 'unsorted';
}

export function getStringDotSplitSortDirection(array: any[], targetProps: string): SortDirection {
    const regex = /^(\d+)\./;
    const differences = [];

    for (let i = 1; i < array.length; i++) {
        const aMatch = array[i - 1][targetProps].match(regex);
        const bMatch = array[i][targetProps].match(regex);

        if (!aMatch || !bMatch) {
            console.error('Error: Invalid data format');
            return 'unsorted';
        }

        const aNum = Number(aMatch[1]);
        const bNum = Number(bMatch[1]);

        if (isNaN(aNum) || isNaN(bNum)) {
            console.error('Error: Invalid number format');
            return 'unsorted';
        }

        differences.push(aNum - bNum);
    }

    if (differences.every((n) => n <= 0)) return 'ascending';
    if (differences.every((n) => n >= 0)) return 'descending';
    return 'unsorted';
}

export function getDateSortDirection(array: any[], targetProps: string): SortDirection {
    const c = [];
    for (let i = 1; i < array.length; i++) {
        c.push(new Date(array[i - 1][targetProps]).valueOf() - new Date(array[i][targetProps]).valueOf());
    }

    if (c.every((n) => n <= 0)) return 'ascending';
    if (c.every((n) => n >= 0)) return 'descending';

    return 'unsorted';
}
export type GameType =
    | 'Writing'
    | 'OneCard'
    | 'TwoCards'
    | 'Audio'
    | 'multipleChoice'
    | 'hkcsQ1'
    | 'hkcsQ2'
    | 'hkcsQ3'
    | 'hkcsEnQ1'
    | 'hkcsEnQ2'
    | 'sasAudio'
    | 'sasFillInTheBlanks'
    | 'sasFillInTheBlanksWriting'
    | 'sasRadicalMatching';
export const gameTypeStr = (type: GameType) => {
    switch (type) {
        case 'Writing':
            return t('writing');
        case 'OneCard':
        case 'TwoCards':
            return t('card');
        case 'hkcsQ1':
            return t('hkcs.q1.title');
        case 'hkcsQ2':
            return t('hkcs.q2.title');
        case 'hkcsQ3':
            return t('hkcs.q3.title');
        case 'hkcsEnQ1':
            return t('hkcs.en.q1.title');
        case 'hkcsEnQ2':
            return t('hkcs.en.q2.title');
        case 'multipleChoice':
            return t('hksas.mctitle');
        case 'sasAudio':
            return t('hksas.audio.title');
        case 'sasFillInTheBlanks':
            return t('hksas.fillInTheBlanks.title');
        case 'Audio':
            return t('audio-box');
        case 'sasFillInTheBlanksWriting':
            return t('hksas.fillInTheBlanksWriting.title');
        case 'sasRadicalMatching':
            return t('hksas.radicalMatching.title');
    }
};

export type GradingType = 'ungraded' | 'correct' | 'incorrect' | 'notGiven' | 'n/a';

export const grade = (answers: Results[]): GradingType => {
    if (answers.length > 0) {
        const grade = answers?.some((answer) => answer.grading === 'correct' || (answer.grading !== 'incorrect' && answer.correct))
            ? 'correct'
            : answers?.some((answer) => answer.grading === 'incorrect' || (answer.grading !== 'correct' && !answer.correct))
            ? 'incorrect'
            : 'ungraded';
        return grade;
    } else {
        return 'n/a';
    }
};
export const gradingTypeStr = (type: GradingType) => {
    switch (type) {
        case 'ungraded':
            return t('ungraded');
        case 'correct':
            return t('correct');
        case 'incorrect':
            return t('incorrect');
        case 'notGiven':
            return t('not-given');
        case 'n/a':
            return t('resultPage.not-attempted');
    }
};
export const gradeVariants = (type: GradingType) => {
    switch (type) {
        case 'ungraded':
        case 'notGiven':
            return '#F0F0F0';
        case 'correct':
            return '#23D680';
        case 'incorrect':
            return '#F97066';
        case 'n/a':
            return '#FFCA28';
    }
};
