import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';

export const GET_RESULT_TYPE_COUNT = gql`
    query GetGroupedQuestionTypeResult($classId: String!, $studentId: String!) {
        getGroupedQuestionTypeResult(classId: $classId, studentId: $studentId) {
            correctResultTypeCount {
                count
                studentId
                gameType
                testroomId
            }
        }
    }
`;

export const get_overall_testroom_anwer_status = (grading: string, testroomId: string) => {
    return {
        where: {
            AND: [
                {
                    testroom: {
                        id: {
                            equals: testroomId,
                        },
                    },
                    grading: {
                        equals: grading,
                    },
                },
            ],
        },
    };
};

export type ModelQueryType = {
    getGroupedQuestionTypeResult?: CorrectResultTypeCount;
};
export type CorrectResultTypeCount = {
    correctResultTypeCount?: ResultTypeCount[];
};
export type ResultTypeCount = {
    count: number;
    studentId: string;
    gameType: string;
    testroomId: string;
};
type QueryVariableType = {
    classId: string;
    studentId: string;
};

export const useTestroomAnswerStatusByType = () => {
    const auth = useContext(UserAuthContext);
    const [getTestRoomAnswerStatus, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_RESULT_TYPE_COUNT);
    const getCorrectAttemptsByType = async (_variable: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getTestRoomAnswerStatus({
                variables: {
                    studentId: _variable.studentId,
                    classId: _variable.classId,
                },
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            return result?.data;
        } catch (err) {
            console.log(err);
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };
    return { getCorrectAttemptsByType };
};
