import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Box, FormControl, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Constants } from 'Constants';
import { CharacterModel } from 'models/character/CharacterModel';
import { CharacterPhraseModel } from 'models/character/CharacterPhraseModel';
import { CharacterSoundGroupModel, CharacterSoundModel } from 'models/lesson/LessonModel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWordPicker } from './PropsWordPicker';

export const forceChangeGameType = (character: CharacterModel, gameType: string) => {
    // check hasCard
    if (isWritingOnlyType(character) && (gameType === 'OneCard' || gameType === 'TwoCards')) {
        return 'Writing';
    } else {
        return gameType as string;
    }
};

export const isWritingOnlyType = (character: CharacterModel) => {
    if (character?.serial1?.length <= 0 && character?.serial2?.length <= 0) {
        return true;
    } else {
        return false;
    }
};

type SelectWordsBoxProps =
    | {
          // sx?: SxProps<Theme>;
          enabled?: boolean;
          textLabel?: string;
          singleWord: true;
          canSelectQuestion: boolean;
          questionLabel: string;
          characterList: CharacterModel[];
          phrase: string;
          selectedCharacterIndex: number;
          phraseSound?: CharacterSoundModel;
          phraseSoundGroup?: CharacterSoundGroupModel;
          onPhraseSoundGroupChanged?: (phraseSoundGroup: CharacterSoundGroupModel) => void;
          onCharacterPicked: (characterPhrase: CharacterPhraseModel) => void;
          onQuestionCharacterSelected: (index: number, character: CharacterModel) => void;
      }
    | {
          // sx?: SxProps<Theme>;
          enabled?: boolean;
          textLabel?: string;
          singleWord: false;
          canSelectQuestion: boolean;
          questionLabel: string;
          characterList: CharacterModel[];
          phrase: string;
          selectedCharacterIndex: number;
          phraseSound: CharacterSoundModel;
          phraseSoundGroup: CharacterSoundGroupModel;
          onPhraseSoundGroupChanged: (phraseSoundGroup: CharacterSoundGroupModel) => void;
          onCharacterPicked: (characterPhrase: CharacterPhraseModel) => void;
          onQuestionCharacterSelected: (index: number, character: CharacterModel) => void;
      };

export const SelectWordsBox = (props: SelectWordsBoxProps) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const calculateRatio = (phraseSound: string, phrase: string) => {
        const wordCount = phraseSound.split(' ').length;
        const cleanedPhraseLength = phrase.replace(/[（）\(\)]/g, '').length;
        return Math.floor(wordCount / cleanedPhraseLength);
    };

    return (
        <Stack direction='column' spacing={1}>
            <PropsWordPicker
                enabled={props.enabled}
                singleWord={props.singleWord}
                textLabel={props.textLabel ? props.textLabel : t('selectCharacter')}
                onClickHandler={(characterPhrase) => {
                    props.onCharacterPicked(characterPhrase);
                }}
            />
            <Box
                sx={{
                    backgroundColor: '#F8F0E0',
                    width: '100%',
                    height: 150,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {props.characterList?.map((v, index) => {
                        if (props.phrase && props.phrase.includes(props.characterList[index].character)) {
                            return (
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.onQuestionCharacterSelected(index, v);
                                    }}>
                                    {v.character}
                                </MenuItem>
                            );
                        }
                    })}
                </Menu>
                <Typography sx={{ fontSize: 35 }}>{props.phrase}</Typography>
                {props.characterList && props.characterList.length > 0 && (
                    <Box sx={{ position: 'absolute', right: -5, top: -5 }}>
                        <Typography
                            sx={{
                                fontSize: 12,
                                ml: 1,
                                borderRadius: 2,
                                backgroundColor: '#ab7e2f',
                                color: '#ffffff',
                                pl: 1,
                                pr: 1,
                            }}>
                            {t(`lesson.${props.characterList?.[props.selectedCharacterIndex]?.locale ?? Constants.lesson.defaultLocale}`)}
                        </Typography>
                    </Box>
                )}
            </Box>

            {props.characterList.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Stack sx={{ flex: '1 1 50px' }}>
                        {props.phraseSoundGroup && props.phraseSound?.jyutping && <Typography sx={{ m: 0.5 }}>{t('cantonese')}</Typography>}
                        {props.phraseSoundGroup &&
                            props.phraseSound?.jyutping?.map((phraseSound, index) => (
                                <FormControl fullWidth key={index}>
                                    <InputLabel id='choosePronunciation-label'>{t('choosePronunciation')}</InputLabel>
                                    <Select
                                        labelId='choosePronunciation-label'
                                        id='choosePronunciation'
                                        value={props.phraseSoundGroup?.jyutping && props.phraseSoundGroup.jyutping[index]?.startIndex.toString()}
                                        label={t('cardOrWriting')}
                                        onChange={(event: SelectChangeEvent) => {
                                            if (props.phraseSoundGroup) {
                                                const phraseSoundGroup = {
                                                    ...props.phraseSoundGroup,
                                                    ...(props.phraseSoundGroup?.jyutping && {
                                                        jyutping: props.phraseSoundGroup.jyutping.map((item, idx) => {
                                                            if (idx === index) {
                                                                return {
                                                                    ...item,
                                                                    startIndex: parseInt(event.target.value), // Ensure the radix is specified for parseInt
                                                                };
                                                            }
                                                            return item;
                                                        }),
                                                    }),
                                                };
                                                if (props.onPhraseSoundGroupChanged) {
                                                    props.onPhraseSoundGroupChanged(phraseSoundGroup);
                                                }
                                            }
                                        }}>
                                        {[...Array(calculateRatio(phraseSound.sound, props.phrase))].map((_, idx) => (
                                            <MenuItem value={idx.toString()}>
                                                <Stack direction='row' spacing={1}>
                                                    <Typography>
                                                        {t('phraseSoundGroup', {
                                                            i: idx + 1,
                                                        })}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ))}
                    </Stack>

                    <Stack sx={{ flex: '1 1 50px' }}>
                        {props.phraseSoundGroup && props.phraseSound?.pinyin && <Typography sx={{ m: 0.5 }}>{t('putonghua')}</Typography>}
                        {props.phraseSoundGroup &&
                            props.phraseSound?.pinyin?.map((phraseSound, index) => (
                                <FormControl fullWidth key={index}>
                                    <InputLabel id='choosePronunciation-label'>{t('choosePronunciation')}</InputLabel>
                                    <Select
                                        labelId='choosePronunciation-label'
                                        id='choosePronunciation'
                                        value={props.phraseSoundGroup?.pinyin && props.phraseSoundGroup.pinyin[index]?.startIndex.toString()}
                                        label={t('cardOrWriting')}
                                        onChange={(event: SelectChangeEvent) => {
                                            if (props.phraseSoundGroup) {
                                                const phraseSoundGroup = {
                                                    ...props.phraseSoundGroup,
                                                    ...(props.phraseSoundGroup?.pinyin && {
                                                        pinyin: props.phraseSoundGroup.pinyin.map((item, idx) => {
                                                            if (idx === index) {
                                                                return {
                                                                    ...item,
                                                                    startIndex: parseInt(event.target.value), // Ensure the radix is specified for parseInt
                                                                };
                                                            }
                                                            return item;
                                                        }),
                                                    }),
                                                };
                                                if (props.onPhraseSoundGroupChanged) {
                                                    props.onPhraseSoundGroupChanged(phraseSoundGroup);
                                                }
                                            }
                                        }}>
                                        {[...Array(calculateRatio(phraseSound.sound, props.phrase))].map((_, idx) => (
                                            <MenuItem value={idx.toString()}>
                                                <Stack direction='row' spacing={1}>
                                                    <Typography>
                                                        {t('phraseSoundGroup', {
                                                            i: idx + 1,
                                                        })}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ))}
                    </Stack>
                </Box>
            )}
            {props.canSelectQuestion && props.selectedCharacterIndex >= 0 ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Typography>{props.questionLabel}:</Typography>
                    <Box
                        onClick={handleClick}
                        sx={{
                            backgroundColor: '#533D18',
                            color: 'white',
                            borderRadius: '12px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            pl: 1,
                            pr: 1,
                            mr: 1,
                            ml: 1,
                        }}>
                        <Typography>{props.characterList?.[props.selectedCharacterIndex]?.character}</Typography>
                        <ExpandCircleDownIcon fontSize='small' sx={{ ml: 1 }} />
                    </Box>
                </Box>
            ) : null}
        </Stack>
    );
};
