import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import zhHK from './i18n/zh-HK.json';
import zhCN from './i18n/zh-CN.json';
import { Constants } from './Constants';
import TagManager from 'react-gtm-module';

const heapAppId = process.env.REACT_APP_HEAP_ID;
const heapScript = document.createElement('script');
heapScript.text = `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
    heap.load("${heapAppId}");
`;
document.head.appendChild(heapScript);

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID?.toString() ?? '',
};
TagManager.initialize(tagManagerArgs);

const resources = {
    'en-US': en,
    'zh-HK': zhHK,
    'zh-CN': zhCN,
};

i18n.use(initReactI18next).init({
    resources,
    lng: (() => {
        try {
            return JSON.parse(localStorage.getItem(Constants.localStorageKeys.language) ?? '');
        } catch {
            return Constants.main.defaultLanguage;
        }
    })(),
    fallbackLng: Constants.main.defaultLanguage,
    interpolation: {
        escapeValue: false,
    },
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { i18n };
