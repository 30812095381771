import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';
import { ClassModel } from './ClassModel';

const SAVE_GQL = gql`
    mutation save($className: String!, $schoolId: ID!) {
        createClass(data: { className: $className, school: { connect: { id: $schoolId } } }) {
            className
        }
    }
`;

type MutationVariableType = {
    className: string;
    schoolId: string;
};

type MutationType = {
    createClass: ClassModel;
};

export const useClassModelCreateMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation<MutationType>(SAVE_GQL);

    const create = async (variables: MutationVariableType) => {
        try {
            const result = await saveForm({
                variables,
            });

            if (!result || !result.data?.createClass) {
                throw new TTError(ERROR_TYPE.UPDATE_FAILED);
            }
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { create };
};
