import { useCallback, useContext, useEffect, useState } from 'react';
import { SlideListContext } from '../../contexts/LessonsSlideContext';
import { DotBorder } from './styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DraggableLeftDot } from './DraggableLeftDot';
import { DraggableRightDot } from './DraggableRightDot';
import { SlideComponent } from 'models/lesson/LessonModel';
import useLessonSlide from '../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../contexts/SlidePreviewContext';
import { useTranslation } from 'react-i18next';

type DraggableDotBorderProps = {
    item: SlideComponent;
    index: number;
    icon: JSX.Element;
    title: string;
    resize?: boolean;
    deleteExtension?: () => void;
    canDelete?: boolean;
    disabled?: boolean;
};

export const DraggableDotBorder = ({ item, index, icon, title, resize, deleteExtension, canDelete = true, disabled }: DraggableDotBorderProps) => {
    const { selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { deleteSlideItem } = useLessonSlide();

    const { t } = useTranslation();

    const [isDeleteConfirmOpened, setIsDeleteConfirmOpened] = useState(false);

    const handleDeleteKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (canDelete && event.key === 'Delete') {
                // console.log('Delete key pressed', index);
                // Add your logic for the Delete key press here
                setIsDeleteConfirmOpened(true);
            }
        },
        [setIsDeleteConfirmOpened, canDelete],
    );

    const handleDeleteConfirmClose = useCallback(() => {
        setIsDeleteConfirmOpened(false);
    }, [setIsDeleteConfirmOpened]);

    useEffect(() => {
        // Add the event listener for the "keydown" event
        if (selectedComponentIndex === index) {
            window.addEventListener('keydown', handleDeleteKeyPress);
            // Clean up the event listener when the component is unmounted
            return () => {
                window.removeEventListener('keydown', handleDeleteKeyPress);
            };
        }
    }, [handleDeleteKeyPress, selectedComponentIndex, index]);

    const handleDeleteItem = useCallback(() => {
        if (deleteExtension) {
            deleteExtension();
        }
        deleteSlideItem(index);
        setIsDeleteConfirmOpened(false);
    }, [deleteExtension, deleteSlideItem, index, setIsDeleteConfirmOpened]);

    return (
        <>
            <DotBorder zoom={zoom} disabled={disabled || selectedComponentIndex !== index}>
                <Stack direction='row' justifyContent='space-between' sx={{ zoom: zoom, mt: -4 }}>
                    <Stack
                        direction='row'
                        sx={{
                            backgroundColor: '#FFA400',
                            borderRadius: 2,
                            p: 0.5,
                            pr: 1,
                            ml: -4,
                            color: 'white',
                        }}>
                        {icon} <Typography noWrap>{title}</Typography>
                    </Stack>
                    <IconButton
                        size='large'
                        sx={{
                            p: 0,
                            mr: -4,
                            width: '30px',
                            height: '30px',
                            bgcolor: 'white',
                            '&:hover': {
                                backgroundColor: 'red',
                                color: 'white',
                            },
                        }}
                        onClick={() => setIsDeleteConfirmOpened(true)}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                </Stack>
                {resize ? (
                    <>
                        <DraggableLeftDot item={item} />
                        <DraggableRightDot item={item} />
                    </>
                ) : (
                    ''
                )}
            </DotBorder>

            <Dialog open={isDeleteConfirmOpened} onClose={handleDeleteConfirmClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirmClose}>{t('cancel')}</Button>
                    <Button onClick={handleDeleteItem} autoFocus>
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
