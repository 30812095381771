import { useEffect } from 'react';
import { SlideComponentH5P } from 'models/lesson/LessonModel';
import { H5PStandalone } from '../../../../../vendor/h5p-standalone/src/h5p-standalone';
import '../../../../../vendor/h5p-standalone/vendor/h5p/js/h5p-event-dispatcher';
import { XAPIEvent } from '../../../../../types/xapiTypes';

interface H5PContainerProps {
    containerId: string;
    itemContent: SlideComponentH5P;
    showThumbnail?: boolean;
}

export const H5PContainer = ({ containerId, itemContent, showThumbnail = false }: H5PContainerProps) => {
    useEffect(() => {
        const parentEl = document.getElementById('h5p-container');
        console.log('containerId: ', containerId);
        console.log('parentEl: ', parentEl);
        if (parentEl) {
            console.log('Found parentEL: ', parentEl);
            // Find all child elements with the 'data-content-id' attribute
            const childEls = parentEl.querySelectorAll(`iframe[data-content-id]`);
            childEls.forEach((childEl) => {
                // Remove each child element
                childEl.remove();
            });
        }

        const loadH5P = async () => {
            const el = document.getElementById('h5p-container');
            const options = {
                h5pJsonPath: itemContent.h5pPath,
                frame: true,
                frameJs: '/workspace/assets/dist/frame.bundle.js',
                frameCss: '/workspace/assets/dist/styles/h5p.css',
            };
            if (el) {
                const myH5P = await new H5PStandalone(el, options);
                console.log('H5P instance: ', myH5P);
                console.log('Kendall myH5P ', myH5P);
                console.log('Kendall H5P ', H5P);
                console.log('Kendall H5P.externalDispatcher ', H5P.externalDispatcher);
                console.log('Kendall H5P.externalDispatcher.on ', H5P.externalDispatcher.on);
                H5P.externalDispatcher.on('xAPI', (event: any) => {
                    const xAPIEvent: XAPIEvent = event;

                    // If:
                    // xAPIEventdata.statement.verb.display['en-US'] == 'answered'
                    // Look for:
                    // xAPIEventdata.result.completion == true
                    // xAPIEventdata.result.success == true
                    // xAPIEventdata.result.score.raw (number)
                    // console.log('xAPI event: ', xAPIEvent);
                    console.log('xAPI event: ', JSON.stringify(xAPIEvent, null, 2));
                });
            }
        };
        loadH5P();
    }, [containerId, itemContent.h5pPath]);

    console.log('Kendall haha:', itemContent.thumbnail);
    return (
        // <div id={'h5p-container'}>
        //   <img src={`/asset/images/${itemContent.thumbnail}`} style={{ width: '100%' }} />
        // </div>
        <>
            <div
                id={'h5p-container'}
                style={{
                    // position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 2,
                }}></div>
            {showThumbnail && (
                <img
                    src={`/asset/images/${itemContent.thumbnail}`}
                    style={{
                        // position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 1,
                    }}
                />
            )}
        </>
    );
};

// export default H5PContainer;
