import { REQUEST_USER_ROOM_STATE } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';
import { TT_EVENT_ASSIGN_END, TT_EVENT_ASSIGN_START } from '../customEvent';

export enum RoomState {
    ASSIGNING = 'assigning',
    LESSONING = 'lessoning',
}

export const requestRoomState = (socket: Socket | null) => {
    if (socket == null) {
        return;
    }

    socket?.emit(REQUEST_USER_ROOM_STATE, {}, (response: any) => {
        console.log('classroom:', REQUEST_USER_ROOM_STATE, 'Acknowledgements', response);

        if (response === RoomState.ASSIGNING) {
            const eventMessage = new CustomEvent(TT_EVENT_ASSIGN_START, {
                detail: response,
            });
            window.dispatchEvent(eventMessage);
        } else if (response === RoomState.LESSONING) {
            const eventMessage = new CustomEvent(TT_EVENT_ASSIGN_END, {
                detail: response,
            });
            window.dispatchEvent(eventMessage);
        }
    });
};
