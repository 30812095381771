import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const DELETE_GQL = gql`
    mutation DeleteLessonShare($where: LessonShareWhereUniqueInput!) {
        deleteLessonShare(where: $where) {
            id
        }
    }
`;

type MutationVariableType = {
    where: { id: string };
};

type MutationType = {
    deleteLessonShare: MutationVariableType;
};

export const useLessonShareModelDeleteMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [deleteForm] = useMutation<MutationType>(DELETE_GQL);

    const deleteLessonShare = async (variables: MutationVariableType) => {
        try {
            const result = await deleteForm({
                variables,
            });

            if (!result || !result.data?.deleteLessonShare) {
                throw new TTError(ERROR_TYPE.UPDATE_FAILED);
            }
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { deleteLessonShare };
};
