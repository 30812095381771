import { UserAuthContext } from 'contexts/UserAuthContext';
import { UserPermissionContext } from 'contexts/UserPermissionContext';
import { useClassModelCreateMutation } from 'models/class/useClassModelCreateMutation';
import { useContext, useState } from 'react';

export const useClassesAddHook = (currentNumClasses: number, refresh: () => void) => {
    const auth = useContext(UserAuthContext);
    const { create } = useClassModelCreateMutation();
    const { userPermissions } = useContext(UserPermissionContext);

    const [open, setOpen] = useState(false);
    const [initValues, setInitValues] = useState({ className: '' });
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const handleClickOpen = () => {
        if (currentNumClasses + 1 <= userPermissions?.maxNumClasses) {
            setOpen(true);
            setInitValues((prev) => {
                return { ...prev };
            });
        } else {
            setShowUpgradePopup(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpgradePopupClose = () => {
        setShowUpgradePopup(false);
    };

    const classLimitReached = () => {
        return currentNumClasses >= userPermissions?.maxNumClasses;
    };

    const createClass = async (values: any) => {
        try {
            await create({
                ...values,
                schoolId: auth.selectedSchool?.id ?? '',
            });
            handleClose();
            refresh();
        } catch (err) {}
    };

    return {
        open,
        showUpgradePopup,
        maxNumClasses: userPermissions?.maxNumClasses,
        classLimitReached,
        handleClickOpen,
        handleUpgradePopupClose,
        createClass,
        initValues,
        handleClose,
    };
};
