import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { SlideComponent } from 'models/lesson/LessonModel';

export const GET_QUESTION_TYPE_COUNT = gql`
    query Testrooms($where: TestroomWhereInput!) {
        testrooms(where: $where) {
            id
            lessonVersion {
                slides {
                    data
                }
            }
        }
    }
`;

export const get_testroom_question_count = (classId: string) => {
    return {
        where: {
            class: {
                id: {
                    equals: classId,
                },
            },
        },
    };
};

export type ModelQueryType = {
    testrooms?: Testroom[];
};

export type Slide = {
    data: SlideComponent;
};
export type LessonVersion = {
    slides: Slide[];
};

export type Testroom = {
    id: string;
    lessonVersion: LessonVersion;
};

type QueryVariableType = {
    classId: string;
};

export const useGetQuestionTypeData = () => {
    const auth = useContext(UserAuthContext);
    const [getQuestionCount, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_QUESTION_TYPE_COUNT);
    const getQuestionTypeCountByTestroomId = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getQuestionCount({
                variables: get_testroom_question_count(variables.classId),
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getQuestionTypeCountByTestroomId };
};
