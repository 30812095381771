/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { StudentsAdd } from './StudentsAdd';
import { StudentQueryModel } from '../../queryModels/StudentQueryModel';
import { StudentCreateForm } from './components/StudentCreateForm';
import { useStudentModelUpdateMutation } from 'models/student/useStudentModelUpdateMutation';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { StudentsAddFromCSV } from './StudentAddFromCSV';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { useParams } from 'react-router-dom';
import { ClassProp } from './StudentSelectFromClassMenuButton';
import StudentSelectFromClassMenuButton from './StudentSelectFromClassMenuButton';
import { QrCodeBtn } from './QrCodeBtn';
import { enqueueSnackbar } from 'notistack';
export type Pagination = {
    pageSize: number;
    page: number;
    sortingOrder: string;
    sortingField: string;
    classId: string;
};
export const CLASS_QUERY = gql`
    query GetRows($schoolId: ID!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        classes(orderBy: { className: asc }, where: { school: { id: { equals: $schoolId } } }) {
            id
            name
            className
        }
    }
`;
export const STUDENT_QUERY_BY_ORDER_TYPE = gql`
    query classes($sortingOrder: String!, $orderType: String!, $schoolId: String!, $className: String!, $skip: Int!, $take: Int!, $where: UserWhereInput!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        usersCount(where: $where)
        getSortedResult(sortingOrder: $sortingOrder, orderType: $orderType, schoolId: $schoolId, className: $className, skip: $skip, take: $take) {
            users {
                id
                name
                email
                role
                passwordAES
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                    class {
                        id
                        className
                        school {
                            schoolName
                        }
                    }
                }
            }
        }
    }
`;

export const STUDENT_QUERY_BY_ORDER_NAME = gql`
    query GetRows($skip: Int!, $take: Int!, $orderBy: [UserOrderByInput!]!, $where: UserWhereInput!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        users(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
            id
            name
            email
            passwordAES
            studentProfile {
                id
                studentNumber
                familyName
                givenName
                dob
                class {
                    id
                    className
                    school {
                        schoolName
                    }
                }
            }
        }
        usersCount(where: $where)
    }
`;
export const StudentsPage = () => {
    const { update } = useStudentModelUpdateMutation();
    const { t } = useTranslation();
    const auth = useContext(UserAuthContext);

    const [editTargetValue, setEditTargetValue] = useState<any>(null);
    const [isEmailExists, setIsEmailExists] = useState(false);
    const { classId } = useParams();
    const [pagination, setPagination] = useState<Pagination>({
        pageSize: 0,
        page: 0,
        sortingOrder: '',
        sortingField: '',
        classId: classId ? classId : '',
    });

    const [getClasses] = useLazyQuery(CLASS_QUERY);

    const [getStudentsByNameOrder] = useLazyQuery(STUDENT_QUERY_BY_ORDER_NAME);
    const [getStudentsByOtherAttribute] = useLazyQuery(STUDENT_QUERY_BY_ORDER_TYPE);
    const [data, setData] = useState<StudentQueryModel | null>(null);
    const [loading, setLoading] = useState(false);
    const [accessedClasses, setAccessedClasses] = useState<ClassProp[]>([]);
    const { authCheck } = useAuthChecking();

    const flattenData = (data: any) => {
        return data.flatMap((item: any) => {
            return [
                {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    studentNumber: item.studentProfile.studentNumber,
                    familyName: item.studentProfile.familyName,
                    givenName: item.studentProfile.givenName,
                    className: item.studentProfile?.class?.className ?? '',
                    schoolName: item.studentProfile.class?.school?.schoolName ?? '',
                    classId: item.studentProfile?.class?.id,
                    studentProfileId: item.studentProfile?.id,
                    dob: item.studentProfile?.dob,
                },
            ];
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('name'),
            width: 200,
            sortable: true,
            filterable: false,
        },
        {
            field: 'schoolName',
            headerName: t('school-name'),
            width: 150,
            sortable: false,
            filterable: false,
        },
        {
            field: 'className',
            headerName: t('class-name'),
            width: 150,
            sortable: true,
            filterable: false,
        },
        {
            field: 'studentNumber',
            headerName: t('student-id'),
            width: 150,
            sortable: true,
            filterable: false,
        },
        {
            field: 'familyName',
            headerName: t('family-name'),
            width: 150,
            sortable: true,
            filterable: false,
        },
        {
            field: 'givenName',
            headerName: t('given-name'),
            width: 150,
            sortable: false,
            filterable: false,
        },
        {
            field: 'email',
            headerName: t('email'),
            width: 350,
            sortable: false,
            filterable: false,
        },
    ];

    const refresh = async () => {
        if (pagination) {
            setLoading(true);
            try {
                const result = await getClasses({
                    variables: {
                        skip: pagination.page * pagination.pageSize,
                        take: pagination.pageSize,
                        schoolId: auth.selectedSchool?.id,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new Error('no data');
                }
                let classNameArray: ClassProp[] = [];
                let classArray: any[] = result?.data.classes;
                classNameArray.push({
                    id: '',
                    className: '',
                });
                classArray.forEach((classObj: any) => {
                    let classProp: ClassProp = {
                        id: classObj.id,
                        className: classObj.className,
                    };
                    classNameArray.push(classProp);
                });
                setAccessedClasses(classNameArray);
            } catch (err) {
                console.log('get data error:', err);
            }
            try {
                let searchingJson: any[] = [];
                if (pagination.sortingOrder != '') {
                    let searchingJsonObj: any = {};
                    searchingJsonObj[pagination.sortingField.toString()] = pagination.sortingOrder;
                    searchingJson.push(searchingJsonObj);
                }
                let result: any = {};
                const filterByClass =
                    pagination.classId === ''
                        ? {}
                        : {
                              studentProfile: {
                                  class: {
                                      id: { equals: pagination.classId },
                                  },
                              },
                          };
                if (pagination.sortingField === 'name') {
                    result = await getStudentsByNameOrder({
                        variables: {
                            skip: pagination.page * pagination.pageSize,
                            take: pagination.pageSize,
                            schoolId: auth.selectedSchool?.id,
                            orderBy: searchingJson,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                } else if (pagination.sortingField !== '') {
                    result = await getStudentsByOtherAttribute({
                        variables: {
                            skip: pagination.page * pagination.pageSize,
                            take: pagination.pageSize,
                            schoolId: auth.selectedSchool?.id,
                            className: pagination.classId,
                            sortingOrder: pagination.sortingOrder,
                            orderType: pagination.sortingField,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                    result.data.users = result.data.getSortedResult.users;
                } else {
                    result = await getStudentsByNameOrder({
                        variables: {
                            skip: pagination.page * pagination.pageSize,
                            take: pagination.pageSize,
                            schoolId: auth.selectedSchool?.id,
                            orderBy: searchingJson,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                }
                if (!result?.data) {
                    throw new Error('no data');
                }
                setData({ ...result.data });
            } catch (err) {
                console.log('get data error:', err);
            }
            setLoading(false);
        }
    };

    const onSortModelChange = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            const sortingOrderFromSortModel: string = sortModel[0].sort === undefined || sortModel[0].sort === null ? '' : sortModel[0].sort;
            //there is a sort model
            setPagination({
                ...pagination,
                sortingField: sortModel[0].field,
                sortingOrder: sortingOrderFromSortModel,
            });
        } else {
            setPagination({
                ...pagination,
                sortingField: '',
                sortingOrder: '',
            });
        }
    };

    const handleClose = () => {
        setEditTargetValue(null);
    };

    const filterStudent = (classsNameRecived: string) => {
        setPagination({
            ...pagination,
            page: 0,
            classId: classsNameRecived,
        });
    };

    useEffect(() => {
        if (pagination.pageSize > 0) {
            refresh();
        }
    }, [pagination, auth.selectedSchool]);

    // check session expire
    useEffect(() => {
        if (data) {
            try {
                authCheck(data?.authenticatedItem);
            } catch (err) {
                auth.logout();
            }
        }
    }, [data?.authenticatedItem]);

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <Grid container sx={{ pb: 3 }}>
                <Grid item container justifyContent='flex-start' xs={6}>
                    <Typography variant='h4'>{t('menu-students')}</Typography>
                    <StudentSelectFromClassMenuButton filteredClassId={pagination.classId} classes={accessedClasses} selectedClassChange={filterStudent} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6}>
                    <StudentsAddFromCSV refresh={refresh} />
                    <StudentsAdd
                        refresh={refresh}
                        currentNumStudents={data?.usersCount ?? 0} // Providing a default value of 0
                    />
                    <QrCodeBtn pagination={pagination} />
                </Grid>
            </Grid>
            <DataGrid
                columns={columns}
                paginationMode='server'
                sortingMode='server'
                rows={flattenData(data?.users || [])}
                loading={loading}
                page={pagination.page}
                rowCount={data?.usersCount || 0}
                onSortModelChange={onSortModelChange}
                onPageChange={(page) => {
                    setPagination((prev) => ({ ...prev, page: page }));
                }}
                autoPageSize
                onPageSizeChange={(pz) => {
                    setPagination((prev) => ({ ...prev, pageSize: pz }));
                }}
                onCellClick={(e) => {
                    const { row } = e;
                    setEditTargetValue({
                        id: row.id,
                        studentProfileId: row.studentProfileId,
                        name: row.name,
                        email: row.email,
                        password: '',
                        passwordConfirmation: '',
                        studentNumber: row.studentNumber,
                        familyName: row.familyName,
                        givenName: row.givenName,
                        class: row.classId,
                        dob: row.dob,
                    });
                }}
                sx={{
                    border: 2,
                    borderColor: '#DCCDB5',
                    borderRadius: '16px',
                    backgroundColor: '#fff',
                }}
            />

            <StudentCreateForm
                isUpdate
                isOpened={editTargetValue !== null}
                onSubmit={async (values) => {
                    try {
                        await update({
                            ...values,
                            id: editTargetValue.id,
                            studentProfileId: editTargetValue.studentProfileId,
                            name: `${values.familyName} ${values.givenName}`,
                        });
                        handleClose();
                        refresh();
                    } catch (err) {
                        const errorMsg = handleException(err);
                        if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                            auth.accessDenied(errorMsg);
                        } else if (errorMsg === ERROR_TYPE.FIELD_DUPLICATE) {
                            setIsEmailExists(true);
                        } else {
                            enqueueSnackbar(errorMsg, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            });
                        }
                    }
                }}
                initValues={editTargetValue}
                handleClose={handleClose}
                isEmailExists={isEmailExists}
                resetEmailExists={() => setIsEmailExists(false)}
            />
        </Stack>
    );
};
