import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SignInPath } from 'routes/utils';

export const RegisterSuccessPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useLocation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                minWidth: 360,
            }}>
            <Box
                sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#858585',
                }}>
                <Typography>{`${t('successRegisteredDesc')}.`}</Typography>
                <Typography>{`${t('clickBelowToLogIn')}.`}</Typography>
            </Box>
            <Box>
                <LoadingButton
                    variant='contained'
                    disableElevation
                    fullWidth
                    size='large'
                    color='primary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '12px',
                        background: '#F06E3C',
                        boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                        fontWeight: '500',
                        lineHeight: '28px',
                        fontSize: '18px',
                        color: '#FDFCFC',
                    }}
                    // TODO: [TTEACH-193] clicking should automatically log in the user, probably by sending in JWT to server that contains username and password

                    onClick={() => navigate(`../${SignInPath}?fallback=${encodeURIComponent(state.fallback)}`)}>
                    {t('continue')}
                </LoadingButton>
            </Box>
        </Box>
    );
};
