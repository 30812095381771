import { REQUEST_USER_JOIN_ROOM } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';

export const requestJoinRoom = (socket: Socket | null, roomName: string) => {
    if (socket == null) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';
    const roomData = {
        room: roomName,
        token: token,
    };
    socket?.emit(REQUEST_USER_JOIN_ROOM, roomData);
};
