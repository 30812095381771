import { useLazyQuery } from '@apollo/client';
import { StudentQueryModel } from 'queryModels/StudentQueryModel';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { Pagination } from 'pages/students/StudentsPage';
import { STUDENT_QUERY_BY_ORDER_TYPE, STUDENT_QUERY_BY_ORDER_NAME } from 'pages/students/StudentsPage';

const NUMBER_OF_QR_CODE_PER_TIME = 27;
export function usePrintQRCode(pagination: Pagination) {
    const [getStudentData, { loading: orderTypeLoading }] = useLazyQuery(STUDENT_QUERY_BY_ORDER_TYPE);
    const [getStudentsByNameOrder, { loading: getStudentsByNameOrderLoading }] = useLazyQuery(STUDENT_QUERY_BY_ORDER_NAME);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [loadingCount, setLoadingCount] = useState(0);
    const [studentData, setStudentsData] = useState<StudentQueryModel | null>(null);
    const auth = useContext(UserAuthContext);
    useEffect(() => {
        getQrCode();
    }, [page]);

    const increaseLoadingCount = () => {
        setLoadingCount((prev) => ++prev);
    };
    const HandlePreviousPage = () => {
        if (page > 0) {
            setLoadingCount(0);
            setPage((prev) => --prev);
        }
    };

    const handleNextPage = () => {
        if (page < maxPage) {
            setLoadingCount(0);
            setPage((prev) => ++prev);
        }
    };
    const returnToFirstPage = () => {
        setLoadingCount(0);
    };
    const getQrCode = async () => {
        if (page >= 0) {
            try {
                let searchingJson: any[] = [];
                if (pagination.sortingOrder != '') {
                    let searchingJsonObj: any = {};
                    searchingJsonObj[pagination.sortingField.toString()] = pagination.sortingOrder;
                    searchingJson.push(searchingJsonObj);
                }
                let result: any = {};
                const filterByClass =
                    pagination.classId === ''
                        ? {}
                        : {
                              studentProfile: {
                                  class: {
                                      id: { equals: pagination.classId },
                                  },
                              },
                          };
                if (pagination.sortingField === 'name') {
                    result = await getStudentsByNameOrder({
                        variables: {
                            skip: page * NUMBER_OF_QR_CODE_PER_TIME,
                            take: NUMBER_OF_QR_CODE_PER_TIME,
                            schoolId: auth.selectedSchool?.id,
                            orderBy: searchingJson,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                } else if (pagination.sortingField !== '') {
                    result = await getStudentData({
                        variables: {
                            skip: page * NUMBER_OF_QR_CODE_PER_TIME,
                            take: NUMBER_OF_QR_CODE_PER_TIME,
                            schoolId: auth.selectedSchool?.id,
                            className: pagination.classId,
                            sortingOrder: pagination.sortingOrder,
                            orderType: pagination.sortingField,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                    result.data.users = result.data.getSortedResult.users;
                } else {
                    result = await getStudentsByNameOrder({
                        variables: {
                            skip: page * NUMBER_OF_QR_CODE_PER_TIME,
                            take: NUMBER_OF_QR_CODE_PER_TIME,
                            schoolId: auth.selectedSchool?.id,
                            orderBy: searchingJson,
                            where: {
                                AND: [
                                    { role: { equals: 'student' } },
                                    {
                                        studentProfile: {
                                            class: {
                                                school: {
                                                    id: {
                                                        equals: auth.selectedSchool?.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    filterByClass,
                                ],
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                }

                if (!result?.data) {
                    throw new Error('no data');
                }
                setMaxPage(Math.floor(result.data.usersCount / NUMBER_OF_QR_CODE_PER_TIME));
                setStudentsData({ ...result.data });
            } catch (err) {}
        }
    };
    return {
        studentData,
        getQrCode,
        page,
        handleNextPage,
        HandlePreviousPage,
        returnToFirstPage,
        maxPage,
        orderTypeLoading,
        getStudentsByNameOrderLoading,
        finishLoadingImgCount: loadingCount < (studentData?.users?.length ?? 0),
        increaseLoadingCount,
        setPage,
    };
}
