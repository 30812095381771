import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteLessonHook } from './hooks/useDeleteLessonHook';

export const LessonsDelete = ({ lessonID, disabled }: { lessonID: string; disabled?: boolean }) => {
    const { t } = useTranslation();

    const { handleDelete, handleClickOpen, handleClose, open } = useDeleteLessonHook(lessonID);

    return (
        <Box>
            <Button
                disabled={disabled || !lessonID}
                variant='outlined'
                color='error'
                sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                }}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}>
                {t('delete')}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleDelete} autoFocus>
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
