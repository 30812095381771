import { Autocomplete, Box, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlipIcon from '@mui/icons-material/Flip';
import { SlideComponent, SlideComponentImage } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

const sceneOptions = [
    { path: 'scene1_2.png', label: 'scene1_2' },
    { path: 'scene1_3.png', label: 'scene1_3' },
    { path: 'scene2_2.png', label: 'scene2_2' },
    { path: 'scene2_3.png', label: 'scene2_3' },
    { path: 'scene3_2.png', label: 'scene3_2' },
    { path: 'scene3_3.png', label: 'scene3_3' },
    { path: 'scene4_2.png', label: 'scene4_2' },
    { path: 'scene4_3.png', label: 'scene4_3' },
    { path: 'scene5_2.png', label: 'scene5_2' },
    { path: 'scene5_3.png', label: 'scene5_3' },
    { path: 'scene6_2.png', label: 'scene6_2' },
    { path: 'scene6_3.png', label: 'scene6_3' },
];

export const LessonsSlidePropsImage = ({ item, index, handleCreate }: { item: SlideComponent; index: number; handleCreate?: (item: SlideComponent) => void }) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentImage = item.content as SlideComponentImage;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Autocomplete
                fullWidth
                disablePortal
                id='image-select-box'
                options={sceneOptions}
                value={sceneOptions.filter((v) => v.path === itemContent.filename)[0]}
                onChange={(event, newValue, reason) => {
                    itemContent.filename = newValue?.path ?? '';
                    updateUI(true);
                }}
                renderInput={(params) => <TextField {...params} label='Image' />}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Box component='img' src={`/asset/images/${option.path}`} sx={{ width: '80px' }} />
                            <Typography>{option.label}</Typography>
                        </Stack>
                    </li>
                )}
            />

            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography>{t('flip')}:</Typography>
                <ToggleButton
                    value='1'
                    selected={itemContent.flip === -1}
                    onChange={() => {
                        if (itemContent.flip === -1) {
                            itemContent.flip = 1;
                        } else {
                            itemContent.flip = -1;
                        }
                        updateUI(true);
                    }}
                    aria-label='bold'>
                    <FlipIcon />
                </ToggleButton>
            </Stack>

            <TextField
                label='Layer'
                type='number'
                sx={{ width: '100%' }}
                value={itemContent.zIndex}
                onChange={(e) => {
                    let v: number = parseInt(e.target.value === '' ? '0' : e.target.value);
                    if (v < 0) {
                        v = 0;
                    }
                    // console.log(v);
                    itemContent.zIndex = v;
                    updateUI(false);
                    debouncedSaveHandler(() => {
                        updateUI(true);
                    });
                }}
                // onBlur={() => {
                //   updateUI(true);
                // }}
            />
        </Stack>
    );
};
