import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, List, ListItem, Typography } from '@mui/material';
type ResultCardProps = {
    header: string;
    students: string[];
    numberOfStudent: number;
};
const ResultPageCard = (props: ResultCardProps) => {
    return (
        <Card
            sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'flex-start',
                flexDirection: 'column',
                alignSelf: 'stretch',
                padding: '16px',
                minHeight: '400px',
            }}>
            <CardHeader
                title={
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>
                        {props.header + ' (' + props.numberOfStudent + ')'}
                    </Typography>
                }
            />
            <Divider orientation='horizontal' flexItem />
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    padding: '0px',
                }}>
                <List
                    sx={{
                        display: 'flex',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                    {props.students?.map((student) => (
                        <ListItem
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'stretch',
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignSelf: 'stretch',
                                    width: '100%',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}>
                                    <Typography
                                        fontSize='16px'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            alignSelf: 'stretch',
                                        }}>
                                        {student}
                                    </Typography>
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default ResultPageCard;
