import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const SubmitButton = (props: LoadingButtonProps) => {
    return (
        <LoadingButton
            {...props}
            sx={{
                px: '16px',
                py: '8px',
                borderRadius: '12px',

                fontWeight: '700',
                fontSize: '18px',
                lineHeight: '150%',
                backgroundColor: '#F06E3C',
                color: '#FDFCFC',
                '&:hover': {
                    backgroundColor: '#C84522',
                },
                '&:disabled': {
                    color: '#FDFCFC',
                    backgroundColor: '#747C78',
                },
                ...props.sx,
            }}>
            {props.children}
        </LoadingButton>
    );
};
