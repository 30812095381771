import { UserAuthContext } from 'contexts/UserAuthContext';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';
import { useContext, useEffect } from 'react';

export const useAuthChecking = (data?: any) => {
    const auth = useContext(UserAuthContext);

    // check session expire
    useEffect(() => {
        if (data) {
            try {
                authCheck(data?.authenticatedItem);
            } catch (err) {
                auth.logout();
            }
        }
    }, [data]);

    const authCheck = (authenticatedItem?: AuthenticatedItemModel) => {
        if (authenticatedItem == null) {
            throw new Error("Don't have permission to view this page");
        }
        if (authenticatedItem && authenticatedItem.role !== 'teacher') {
            throw new Error('This user is not a teacher');
        }

        return true;
    };

    return { authCheck };
};
