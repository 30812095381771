import { Card, Box, Typography } from '@mui/material';
import React from 'react';
type QuestionHeaderType = {
    questionName: string;
};
const QuestionTabHeader = (questionHeader: QuestionHeaderType) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                alignSelf: 'stretch',
                backgroundColor: 'white',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35' fill='none'>
                        <path
                            d='M16.1438 18.7396C17.2668 16.7125 19.4251 15.5167 20.6793 13.7229C22.0063 11.8417 21.2626 8.32708 17.5001 8.32708C15.0355 8.32708 13.8251 10.1937 13.3147 11.7396L9.5376 10.15C10.573 7.04375 13.3876 4.375 17.4855 4.375C20.9126 4.375 23.2605 5.93542 24.4563 7.88958C25.4772 9.56667 26.0751 12.7021 24.5001 15.0354C22.7501 17.6167 21.073 18.4042 20.1688 20.0667C19.8043 20.7375 19.6584 21.175 19.6584 23.3333H15.4438C15.4293 22.1958 15.2543 20.3438 16.1438 18.7396ZM20.4168 29.1667C20.4168 30.7708 19.1043 32.0833 17.5001 32.0833C15.8959 32.0833 14.5834 30.7708 14.5834 29.1667C14.5834 27.5625 15.8959 26.25 17.5001 26.25C19.1043 26.25 20.4168 27.5625 20.4168 29.1667Z'
                            fill='#F05023'
                        />
                    </svg>
                </Box>
                <Typography fontSize={20}>{questionHeader.questionName}</Typography>
            </Box>
        </Box>
    );
};

export default QuestionTabHeader;
