import { Stack, TextField, FormControl, Select, MenuItem, Box, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { SlideComponent, SlideComponentMultipleChoice, SlideComponentMultipleChoiceQuestionModel } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { useLessonsSlidePropsPunctuationMarkHook } from './hooks/useLessonsSlidePropsPunctuationMarkHook';
import { PunctuationMarkComponent } from './components/PunctuationMarkComponent';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

import { MultipleChoiceQuestionComponent } from './components/MultipleChoiceQuestionComponent';
import { useLessonsSlidePropsMultipleChoiceHook } from './hooks/useLessonsSlidePropsMultipleChoiceHook';
import { SetStateAction, useEffect } from 'react';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

// Define the type for the item you are passing to the component
export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsMultipleChoice = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentMultipleChoice = item.content as SlideComponentMultipleChoice;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const { getTextFromServerGeneric, deleteQuestionArrayInfo, questionFieldChange, choicesFieldChange, answerIndexChange, loading, hasGenerated, addQuestion, addChoice, deleteChoice } =
        useLessonsSlidePropsMultipleChoiceHook(itemContent);

    useEffect(() => {
        setCanSave?.(itemContent.questions.length > 0 && !hasEmptyFieldInArray(itemContent.questions));
    }, [item]);

    const hasEmptyFieldInArray = (questions: SlideComponentMultipleChoiceQuestionModel[]) => {
        for (const question of questions) {
            if (question.questionText.trim() === '' || question.choices.length === 0) {
                return true;
            }
            for (const choice of question.choices) {
                if (choice.trim() === '') {
                    return true;
                }
            }
        }
    };

    return (
        <>
            <Stack spacing={2} sx={{ mt: 2, m: 1 }}>
                <Box sx={{ flex: 2, width: '100%' }}>
                    {/* <Stack direction='row' spacing={8}> */}
                    {/* <Stack direction='column' spacing={10} width={'50%'}> */}
                    <Stack spacing={1} sx={{ overflow: 'auto', marginTop: '50px' }}>
                        {itemContent && itemContent.questions ? (
                            <>
                                {itemContent.questions.map((question: SlideComponentMultipleChoiceQuestionModel, index: number) => (
                                    <MultipleChoiceQuestionComponent
                                        id={question.id}
                                        key={'MultipleChoiceQuestionComponent' + index.toString()}
                                        index={index}
                                        questionText={question.questionText}
                                        answerIndex={question.answerIndex}
                                        choices={question.choices || []}
                                        onTextQuestionFieldChange={(idx: number, textContent: string) => {
                                            questionFieldChange(idx, textContent);
                                            updateUI(false);
                                            debouncedSaveHandler(() => {
                                                updateUI(true);
                                            });
                                        }}
                                        onChoicesFieldChange={(idx: number, choiceIndex: number, choiceContent: string) => {
                                            choicesFieldChange(idx, choiceIndex, choiceContent);
                                            updateUI(false);
                                            debouncedSaveHandler(() => {
                                                updateUI(true);
                                            });
                                        }}
                                        onAnswerIndexChange={(idx: number, myAnswerIndex: number) => {
                                            answerIndexChange(idx, myAnswerIndex);
                                            updateUI(true);
                                        }}
                                        deleteItemChange={(idx: number) => {
                                            deleteQuestionArrayInfo(idx);
                                            updateUI(true);
                                        }}
                                        onAddChoice={(questionIndex: number) => {
                                            addChoice(questionIndex);
                                            updateUI(true);
                                        }}
                                        onDeleteChoice={(questionIndex: number, choiceIndex: number) => {
                                            deleteChoice(questionIndex, choiceIndex);
                                            updateUI(true);
                                        }}
                                    />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </Stack>
                    {itemContent.questions.length < 10 && (
                        <IconButton // add an icon button for delete
                            sx={{
                                width: '120px',
                                height: '18px',
                            }}
                            onClick={() => {
                                addQuestion();
                                updateUI(true);
                            }}>
                            <AddIcon />
                            <Typography>{t('manual-add-question')}</Typography>
                        </IconButton>
                    )}
                </Box>

                <Stack direction='row' justifyContent='space-evenly' spacing={2}></Stack>
            </Stack>

            <PopupCircularProgress isOpened={loading} />
        </>
    );
};
