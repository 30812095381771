import { Card, CardContent, List, ListItem, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export type QuestionOverallView = {
    data: any;
    questionName: string;
};

const QuestionResultOverview = (props: QuestionOverallView) => {
    const [questionStat, setQuestionStat] = useState<any>({
        correct: 0,
        incorrect: 0,
        notAttempted: 0,
    });
    const { t } = useTranslation();
    useEffect(() => {
        if (props?.data?.data) {
            if (Array.isArray(props?.data?.data)) {
                const studentSpecificQuestionResults = props?.data?.data;
                let questionStat = {
                    correct: 0,
                    incorrect: 0,
                    notAttempted: 0,
                };
                studentSpecificQuestionResults.forEach((element: any) => {
                    if (element?.score) {
                        questionStat.correct = element?.score === 1 ? questionStat.correct + 1 : questionStat.correct;
                    } else {
                        if (element?.attempt === 0) {
                            questionStat.notAttempted += 1;
                        } else {
                            questionStat.incorrect += 1;
                        }
                    }
                });
                setQuestionStat(questionStat);
            }
        }
    }, [props]);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row',
                alignSelf: 'stretch',
                padding: '16px',
                width: '100%',
                backgroundColor: 'white',
            }}>
            <Card
                elevation={0}
                sx={{
                    display: 'flex',
                    width: 256,
                    height: 192,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 64,
                    }}>
                    {props.questionName}
                </Typography>
            </Card>
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '16px',
                }}>
                <List
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        padding: '8px ',
                        gap: '16px',
                    }}>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                                <path
                                    d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z'
                                    fill='#4CAF50'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.correct')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {questionStat.correct}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                <path
                                    d='M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z'
                                    fill='#EF5350'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.wrong')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {questionStat.incorrect}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                <path
                                    d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z'
                                    fill='#533D18'
                                    fill-opacity='0.56'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.not-attempted')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {questionStat.notAttempted}
                        </Typography>
                    </ListItem>
                </List>
            </CardContent>
        </Box>
    );
};

export default QuestionResultOverview;
