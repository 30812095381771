import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';

export const GET_QUESTION_COUNT = gql`
    query QuestionCount($where: ClassWhereUniqueInput!) {
        class(where: $where) {
            testrooms {
                id
                lessonVersion {
                    slides {
                        questionCount
                    }
                }
            }
        }
    }
`;

export const get_testroom_question_count = (classId: string) => {
    return {
        where: {
            id: classId,
        },
    };
};

export type ModelQueryType = {
    class?: ClassQuestionCountModel;
};
export type ClassQuestionCountModel = {
    testrooms: Testroom[];
};

type QuestionCount = {
    questionCount: number;
};

type Slides = {
    slides: QuestionCount[];
};
type LessonVersion = {
    lessonVersion: Slides;
};

type Testroom = {
    id: string;
    lessonVersion: Slides;
};

type QueryVariableType = {
    classId: string;
};

export const useGetTestroomQuestionCount = () => {
    const auth = useContext(UserAuthContext);
    const [getQuestionCount, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_QUESTION_COUNT);
    const getQuestionCountByClass = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getQuestionCount({
                variables: get_testroom_question_count(variables.classId),
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getQuestionCountByClass };
};
