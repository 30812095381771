import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentText } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { jyutpingFunction } from './functions/textCommonFunction';
import { JyutpingDisplay } from './components/JyutpingDisplay';
import { SetStateAction, useEffect } from 'react';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export const LessonsSlidePropsText = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentText = item.content as SlideComponentText;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    useEffect(() => {
        setCanSave?.(itemContent.text !== '');
    }, [item]);

    return (
        <Stack spacing={2} sx={{ mt: 2, m: 1 }}>
            <TextField
                label={t('text-box')}
                placeholder={t('text-box-placeholder')}
                sx={{ width: '100%', lineHeight: 1 }}
                value={itemContent.text}
                onChange={(e) => {
                    itemContent.text = e.target.value;
                    updateUI(false);
                    debouncedSaveHandler(() => {
                        // TTEACH-352 Hide Jyutping function from TT
                        // itemContent.jyutping = jyutpingFunction(itemContent.text);
                        updateUI(true);
                    });
                }}
                multiline
            />
            {/* TTEACH-352 Hide Jyutping function from TT */}
            {/* <Typography>
        {t('jyutping')}: <JyutpingDisplay data={itemContent.jyutping} />
      </Typography> */}
            <TextField
                label={t('text-font-size')}
                type='number'
                sx={{ width: '100%' }}
                value={itemContent.fontSize}
                onChange={(e) => {
                    itemContent.fontSize = parseInt(e.target.value === '' ? '0' : e.target.value);
                    updateUI(false);
                    debouncedSaveHandler(() => {
                        updateUI(true);
                    });
                }}
                // onBlur={(e) => {
                //   updateUI(true);
                // }}
            />
            <TextField
                label={t('layer')}
                type='number'
                sx={{ width: '100%' }}
                value={itemContent.zIndex}
                onChange={(e) => {
                    let v: number = parseInt(e.target.value === '' ? '0' : e.target.value);
                    if (v < 0) {
                        v = 0;
                    }
                    // console.log(v);
                    itemContent.zIndex = v;
                    updateUI(false);
                    debouncedSaveHandler(() => {
                        updateUI(true);
                    });
                }}
                // onBlur={(e) => {
                //   updateUI(true);
                // }}
            />
        </Stack>
    );
};
