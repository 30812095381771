import { Box, Button, Grid, Dialog, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QrCodeComponent } from './components/QrCodeComponent';
import { usePrintQRCode } from 'hooks/usePrintQRCode';
import 'react-datepicker/dist/react-datepicker.css';
import { Pagination } from './StudentsPage';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

export const QrCodeBtn = ({ pagination }: { pagination: Pagination }) => {
    const { t } = useTranslation();
    const printStyles = `
  @media print {
    body * {
      visibility: hidden; /* Hide everything on the page */
    }
    .print-area, .print-area * {
      visibility: visible; /* Only show the QR codes and their children */
    }
    .print-area {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

    const [openQrCode, setSpenQrCode] = useState(false);
    const { studentData, getQrCode, page, handleNextPage, HandlePreviousPage, returnToFirstPage, orderTypeLoading, getStudentsByNameOrderLoading, finishLoadingImgCount, increaseLoadingCount } =
        usePrintQRCode(pagination);
    const handleOpenQrCode = async () => {
        await getQrCode();
        setSpenQrCode(true);
    };

    const handleCloseQrCode = () => {
        returnToFirstPage();
        setSpenQrCode(false);
    };

    return (
        <>
            <style>{printStyles}</style>
            <Box style={{ marginLeft: 20 }}>
                <Button
                    sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        marginRight: '2rem',
                    }}
                    variant='contained'
                    color='secondary'
                    onClick={handleOpenQrCode}>
                    {t('studentPage.print-student-qrcode')}
                </Button>
            </Box>
            <Dialog open={openQrCode} fullScreen onClose={handleCloseQrCode}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Box display='flex' justifyContent='center'>
                            <Button style={{ margin: 10, width: '50%' }} variant='contained' color='secondary' onClick={handleCloseQrCode}>
                                {t('return-last-page-button-title')}
                            </Button>{' '}
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display='flex' justifyContent='center'>
                            <Button style={{ margin: 10, width: '50%' }} variant='contained' color='secondary' onClick={HandlePreviousPage}>
                                Previous
                            </Button>{' '}
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box style={{ marginTop: 20 }} display='flex' justifyContent='center' alignItems='center' textAlign='center'>
                            <Typography>{page + 1}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display='flex' justifyContent='center'>
                            <Button style={{ margin: 10, width: '50%' }} variant='contained' color='secondary' onClick={handleNextPage}>
                                Next
                            </Button>{' '}
                        </Box>
                    </Grid>

                    <Grid item xs={3}>
                        <Box display='flex' justifyContent='center'>
                            <Button style={{ margin: 10, width: '50%' }} variant='contained' color='primary' onClick={() => window.print()}>
                                {t('studentPage.print')}
                            </Button>
                        </Box>
                    </Grid>
                    <PopupCircularProgress isOpened={finishLoadingImgCount && openQrCode} />
                </Grid>

                <Grid container spacing={3} className='print-area'>
                    {(studentData?.users || []).length > 0 &&
                        (studentData?.users || []).map((item: any, index: number) => (
                            <Grid item xs={6} key={'QRCODE' + index}>
                                <QrCodeComponent initValues={item} increaseLoadingCount={increaseLoadingCount} />
                            </Grid>
                        ))}
                </Grid>
            </Dialog>
            <PopupCircularProgress isOpened={orderTypeLoading || getStudentsByNameOrderLoading} />
        </>
    );
};
