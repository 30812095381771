import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PetsIcon from '@mui/icons-material/Pets';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentCharacter } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';
import { DoSound } from '../props/components/DoSound';

const HoverOverlay = ({ selectedComponentIndex, index, itemContent }: { selectedComponentIndex: number; index: number; itemContent: SlideComponentCharacter }) => {
    return (
        <Stack
            display={selectedComponentIndex === index ? 'none' : ''}
            justifyContent='center'
            alignItems='center'
            spacing={1}
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 2,
            }}>
            <Stack
                justifyContent='center'
                alignItems='center'
                spacing={1}
                sx={{
                    position: 'absolute',
                    top: -16,
                    left: -16,
                }}>
                <Box className='hidden-button' component='img' src={itemContent.gameType === 'OneCard' || itemContent.gameType === 'TwoCards' ? '/icons/icon-card.png' : '/icons/icon-writing.png'} />
                <Box
                    className='hidden-button'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        width: '32px',
                        height: '32px',
                        borderRadius: 45,
                        bgcolor: 'white',
                        color: '#29C0E7',
                    }}>
                    {itemContent?.characterList?.[itemContent?.character]?.character}
                </Box>
            </Stack>
        </Stack>
    );
};

const AudioButtonOverlay = ({ itemContent }: { itemContent: SlideComponentCharacter }) => {
    const { t } = useTranslation();
    return (
        <Stack
            spacing={2}
            justifyContent='center'
            alignItems='center'
            sx={{
                position: 'absolute',
                zIndex: 10,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
            }}>
            <DoSound
                soundString={itemContent?.phraseSound?.jyutping?.[0]?.sound}
                phrase={itemContent?.phrase}
                groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                folder='jyutping'
                sx={{
                    p: 0,
                    bgcolor: '#23B1D3',
                    color: '#FAFAFA',
                    opacity: '0.7',
                    '&:hover': {
                        bgcolor: '#23B1D3',
                        color: '#FAFAFA',
                        opacity: '1',
                    },
                    pointerEvents: 'auto',
                }}>
                {t('cantoneseIcon')}
            </DoSound>

            <DoSound
                soundString={itemContent?.phraseSound?.pinyin?.[0]?.sound}
                phrase={itemContent?.phrase}
                groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                folder='pinyin'
                sx={{
                    p: 0,
                    bgcolor: '#FFFFFF',
                    color: '#29C0E7',
                    opacity: '0.7',
                    '&:hover': {
                        bgcolor: '#FFFFFF',
                        color: '#29C0E7',
                        opacity: '1',
                    },
                    pointerEvents: 'auto',
                }}>
                {t('putonghuaIcon')}
            </DoSound>
        </Stack>
    );
};

export const LessonsSlideDataCharacter = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex, selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentCharacter = item.content as SlideComponentCharacter;

    const compRef = useRef<HTMLImageElement>(null);

    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (index === selectedComponentIndex) {
            const compRefCurrent = compRef.current;

            if (canvasObserverRef.current) {
                canvasObserverRef.current.disconnect();
            }
            canvasObserverRef.current = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element to be watched
                const { width, height } = entries[0].contentRect;

                if (item.size.height !== height) {
                    item.size.height = height;
                    updateItemProperties({ ...item }, false);
                }
            });

            if (compRefCurrent) {
                canvasObserverRef.current.observe(compRefCurrent);
            }

            return () => {
                if (compRefCurrent) {
                    canvasObserverRef?.current?.unobserve(compRefCurrent);
                }
                canvasObserverRef?.current?.disconnect();
            };
        }
    }, [item, item.size.width, itemContent.image, selectedComponentIndex, index]);

    useEffect(() => {
        if (item.size.width === 0 && item.size.height === 0) {
            const compWidth = compRef.current?.naturalWidth!;
            const compHeight = compRef.current?.naturalHeight!;
            const defaultSize = 300;

            if (compWidth && compHeight) {
                const aspectRatio = compWidth / compHeight;

                if (compWidth > compHeight && compWidth > defaultSize) {
                    item.size.width = defaultSize;
                    item.size.height = defaultSize / aspectRatio;
                } else if (compHeight > compWidth && compHeight > defaultSize) {
                    item.size.width = defaultSize * aspectRatio;
                    item.size.height = defaultSize;
                } else {
                    item.size.width = compWidth;
                    item.size.height = compHeight;
                }
            }
            updateItemProperties({ ...item }, true);
        }
    }, [compRef.current, compRef.current?.naturalWidth, compRef.current?.naturalHeight]);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <Box
                sx={{
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                    '& .hidden-button': {
                        display: 'none',
                    },
                    ...(selectedComponentIndex !== index && {
                        '&:hover': {
                            backgroundColor: 'rgba(134, 255, 248, 0.75)',
                        },
                        '&:hover .hidden-button': {
                            display: 'flex',
                        },
                    }),
                }}>
                <strong
                    style={{
                        cursor: 'move',
                    }}>
                    <HoverOverlay selectedComponentIndex={selectedComponentIndex} index={index} itemContent={itemContent} />
                    <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<PetsIcon />} title={t('character-box')} resize={true} />

                    <Box
                        ref={compRef}
                        component='img'
                        src={itemContent.imageURL && itemContent.imageURL !== '' ? itemContent.imageURL : `/characters/${itemContent.image !== '' ? itemContent.image + '/image.png' : 'default.png'}`}
                        alt='bg'
                        sx={{
                            zoom: zoom,
                            aspectRatio: 'auto',
                            transform: `scaleX(${itemContent.flip ?? 1})`,
                            pointerEvents: 'none',
                        }}
                        width={item.size.width}
                    />
                </strong>
                <AudioButtonOverlay itemContent={itemContent} />
            </Box>
        </Draggable>
    );
};
