import { gql, useQuery } from '@apollo/client';
import { EventInput } from '@fullcalendar/react';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { TestroomsQueryModel } from '../../../queryModels/TestroomsQueryModel';
import { useAuthChecking } from 'hooks/useAuthChecking';

const TESTROOMS_QUERY = gql`
    query GetRows {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        testrooms {
            id
            testroomName
            lessonVersion {
                id
                lessonName
            }
            startTime
            endTime
        }
    }
`;

export default function useTimeSlot() {
    const { logout } = useContext(UserAuthContext);
    const { refetch: queryTestroom, data: testroomsQueryData } = useQuery<TestroomsQueryModel>(TESTROOMS_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const [initData, setInitData] = useState<EventInput[]>([]);

    const { authCheck } = useAuthChecking();

    useEffect(() => {
        if (testroomsQueryData?.testrooms) {
            setInitData(
                testroomsQueryData?.testrooms.map((data) => {
                    return {
                        id: data.id,
                        title: data.testroomName,
                        start: data.startTime,
                        end: data.endTime,
                    };
                }),
            );
        }
    }, [testroomsQueryData?.testrooms]);

    // check session expire
    useEffect(() => {
        if (testroomsQueryData) {
            try {
                authCheck(testroomsQueryData?.authenticatedItem);
            } catch (err) {
                logout();
            }
        }
    }, [logout, testroomsQueryData, testroomsQueryData?.authenticatedItem]);

    return { queryTestroom, initData };
}
