import { useTranslation } from 'react-i18next';
import { TestroomModel } from '../models/TestroomModel';
import { TopButton, TopButtonTypography, ButtonTypography } from '../TestroomTemplate';

export const TestroomPasslock = ({ testroomData }: { testroomData: TestroomModel }) => {
    const { t } = useTranslation();

    if (testroomData.passlock !== '') {
        return (
            <TopButton>
                <TopButtonTypography>{t('passlock')}</TopButtonTypography>
                <ButtonTypography>{testroomData.passlock}</ButtonTypography>
            </TopButton>
        );
    } else {
        return <></>;
    }
};
