import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { TestroomsWithQuestionsQueryModel } from 'queryModels/TestroomsQueryModel';
import { useNavigate, useParams } from 'react-router-dom';
import { OverallResultPath, SelectClassPath, SelectTestroomPath } from 'routes/utils';
import { Refresh } from '@mui/icons-material';

export interface TestRoomSelectorProp {
    testRooms: TestroomsWithQuestionsQueryModel | undefined;
}
const TestRoomSelector = (props: TestRoomSelectorProp) => {
    const navigator = useNavigate();
    const [selectedTestRoomName, setSelectedTestRoomNae] = useState('');
    const { schoolId, classId, testroomId } = useParams();
    useEffect(() => {
        if (props?.testRooms?.testrooms && testroomId) {
            const selectedTestRoom = props?.testRooms?.testrooms.filter((testRoom) => testRoom.id == testroomId);
            if (selectedTestRoom.length > 0) {
                setSelectedTestRoomNae(selectedTestRoom[0].testroomName);
            }
        }
    }, [props]);
    return (
        <FormControl
            variant='standard'
            sx={{
                gap: '4px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                alignSelf: 'stretch',
                minWidth: '360px',
            }}>
            <InputLabel id='demo-simple-select-standard-label'>{selectedTestRoomName}</InputLabel>
            <Select
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    alignSelf: 'center',
                    minWidth: '360px',
                }}
                onChange={(e) => {
                    navigator(`./${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${OverallResultPath}/${e.target.value}/`);
                    window.location.reload();
                }}
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                label='Age'>
                <MenuItem value=''>
                    <em>請選擇</em>
                </MenuItem>
                {props?.testRooms?.testrooms.map((testRoom: TestroomWithQuestions) => (
                    <MenuItem value={testRoom.id}>{testRoom.testroomName}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TestRoomSelector;
