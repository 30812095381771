export const replaceWithJsx = (valueToUpdate: string, replacement: Record<string, JSX.Element>) => {
    const result: Array<string | JSX.Element> = [];

    const keys = Object.keys(replacement);
    const regExp = new RegExp(keys.join('|'));

    valueToUpdate.split(regExp).forEach((item, index) => {
        result.push(item);

        const key = keys[index];
        if (valueToUpdate.includes(key)) {
            result.push(replacement[key]);
        }
    });

    return result;
};

export function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}
