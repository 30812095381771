import { gql, useLazyQuery } from '@apollo/client';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';

import { enqueueSnackbar } from 'notistack';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';
import { LessonVersionModel } from './LessonModel';

const QUERY_GQL = gql`
    query GetSlideByLessonVersionQuery($lessonVersionID: ID!) {
        lessonVersion(where: { id: $lessonVersionID }) {
            id
            lessonName
            slides(orderBy: { sort: asc }) {
                id
                sort
                data
                questionCount
            }
            published
            parent {
                id
                shareWithPublic
                teacher {
                    user {
                        id
                    }
                }
            }
        }
    }
`;

type QueryVariableType = {
    lessonVersionID: string;
};

type ModelQueryType = {
    authenticatedItem: AuthenticatedItemModel;
    lessonVersion: LessonVersionModel;
};

export const useLessonVersionQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const getLessonVersion = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await queryData({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getLessonVersion, data, loading };
};
