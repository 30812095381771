import React, { useContext, useState } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
interface NotPaymentUserDialogProps {
    t: (key: string) => string;
    open: boolean;
    setOpen: (open: boolean) => void;
}
export const NotPaymentUserDialog: React.FC<NotPaymentUserDialogProps> = ({ t, open, setOpen }) => {
    const auth = useContext(UserAuthContext);

    const handleCloseContactPaymentUserDialog = () => {
        // setShowContactPaymentUserDialog(false);
        setOpen(false);
    };

    return (
        <>
            {open && (
                <Dialog open={open} onClose={handleCloseContactPaymentUserDialog}>
                    <DialogTitle>{t('pricing.payment-user-popup.contact-payment-user-title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('pricing.payment-user-popup.contact-payment-user-text')}
                            {'\n'}
                            {'(' + auth.selectedSchool?.paymentUser.email + ')'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseContactPaymentUserDialog}>{t('pricing.payment-user-popup.close')}</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
