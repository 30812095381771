import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

type Props = {
    id: string;
    index?: number;
    summaryChildren?: ReactNode;
    children?: ReactNode;
    isExpand?: boolean;
};

export const AnswerAccordion = forwardRef(({ id, index, summaryChildren, children, isExpand }: Props, ref) => {
    const [isExpanded, setIsExpanded] = useState(true);

    useImperativeHandle(ref, () => ({
        setExpanded(boolean: boolean) {
            setIsExpanded(boolean);
        },
    }));

    useEffect(() => {
        if (typeof isExpand !== 'undefined' && !isExpand) {
            setIsExpanded(isExpand);
        }
    }, [isExpand]);

    return (
        <Accordion id={id} sx={{ padding: '0px', boxShadow: 'none' }} defaultExpanded={true} expanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                id={`answer-header-${index}`}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    background: '#FFFFFF',
                }}
                onClick={() => setIsExpanded((prevState) => !prevState)}>
                {summaryChildren}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    background: '#FFFFFF',
                    padding: '10px 10px',
                }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
});
