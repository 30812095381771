import React, { useState, useEffect, useContext } from 'react';
import { ListItemButton, ListItemIcon, Button, Typography, Box } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { UserPrefContext } from 'contexts/UserPrefContext';

// Define the props type
interface LanguageMenuItemProps {
    isMenuOpened: boolean;
    hoverBackgroundColor?: string;
    textColor?: string;
    iconColor?: string;
    alwaysHovered?: boolean;
}

type LanguageKey = 'zh-HK' | 'zh-CN' | 'en-US';

const LanguageMenuItem: React.FC<LanguageMenuItemProps> = ({
    isMenuOpened,
    hoverBackgroundColor = '#00000080',
    textColor = '#fff',
    iconColor = '#533D18',
    alwaysHovered = false, // Default value
}) => {
    const { language, changeLanguage } = useContext(UserPrefContext);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageKey | null>(null);

    useEffect(() => {
        setSelectedLanguage(language as LanguageKey);
    }, [language]);

    const hoverStyles = {
        backgroundColor: hoverBackgroundColor,
        '& .MuiListItemText-primary': {
            color: textColor,
        },
        '& .MuiListItemIcon-root .languageBtn': {
            color: textColor,
        },
        '& .MuiListItemIcon-root button': {
            visibility: 'visible',
        },
    };

    return (
        <UserPrefContext.Consumer>
            {({ language, changeLanguage }) => (
                <ListItemButton
                    key='language'
                    sx={{
                        minHeight: 48,
                        justifyContent: isMenuOpened ? 'initial' : 'center',
                        px: 1,
                        borderRadius: '8px',
                        // borderTopLeftRadius: '48px',
                        // borderBottomLeftRadius: '48px',
                        // borderTopRightRadius: '48px',
                        // borderBottomRightRadius: '48px',

                        mx: '10px',
                        backgroundColor: alwaysHovered ? hoverBackgroundColor : 'inherit',
                        // '& .MuiListItemText-primary': {
                        //   color: alwaysHovered ? textColor : 'inherit',
                        // },
                        // '& .MuiListItemIcon-root .languageBtn': {
                        //   color: alwaysHovered ? textColor : iconColor,
                        // },
                        '& .MuiListItemIcon-root .languageBtn': {
                            visibility: alwaysHovered ? 'visible' : 'visible',
                        },
                        '& .MuiListItemIcon-root .localeBtn': {
                            visibility: alwaysHovered ? 'visible' : 'hidden',
                        },
                        '&:hover': {
                            backgroundColor: hoverBackgroundColor,
                            '& .MuiListItemText-primary': {
                                color: textColor,
                            },
                            '& .MuiListItemIcon-root .languageBtn': {
                                color: textColor,
                            },
                            '& .MuiListItemIcon-root button': {
                                visibility: 'visible',
                            },
                        },
                    }}
                    onClick={() => {}}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                        }}>
                        <Box>
                            <Button
                                className='languageBtn'
                                sx={{
                                    m: 0,
                                    p: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    color: iconColor,
                                }}>
                                <LanguageIcon />
                            </Button>

                            <Button
                                className='localeBtn'
                                sx={{
                                    m: 0,
                                    ml: 1,
                                    p: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    color: 'white',
                                    visibility: 'hidden',
                                }}
                                onClick={() => {
                                    changeLanguage('zh-HK' as LanguageKey);
                                    setSelectedLanguage('zh-HK');
                                }}>
                                <Typography
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        borderColor: 'white',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        display: 'flex',
                                        backgroundColor: selectedLanguage === 'zh-HK' ? '#F06E3C' : 'inherit',
                                    }}>
                                    繁
                                </Typography>
                            </Button>
                            <Button
                                className='localeBtn'
                                sx={{
                                    m: 0,
                                    ml: 1,
                                    p: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    color: 'white',
                                    visibility: 'hidden',
                                }}
                                onClick={() => {
                                    changeLanguage('zh-CN' as LanguageKey);
                                    setSelectedLanguage('zh-CN');
                                }}>
                                <Typography
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        borderColor: 'white',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        display: 'flex',
                                        backgroundColor: selectedLanguage === 'zh-CN' ? '#F06E3C' : 'inherit',
                                    }}>
                                    简
                                </Typography>
                            </Button>
                            <Button
                                className='localeBtn'
                                sx={{
                                    m: 0,
                                    ml: 1,
                                    mr: 1,
                                    p: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    color: 'white',
                                    visibility: 'hidden',
                                }}
                                onClick={() => {
                                    changeLanguage('en-US' as LanguageKey);
                                    setSelectedLanguage('en-US');
                                }}>
                                <Typography
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        borderColor: 'white',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        display: 'flex',
                                        backgroundColor: selectedLanguage === 'en-US' ? '#F06E3C' : 'inherit',
                                    }}>
                                    EN
                                </Typography>
                            </Button>
                        </Box>
                    </ListItemIcon>
                </ListItemButton>
            )}
        </UserPrefContext.Consumer>
    );
};

export default LanguageMenuItem;
