import { Stack, FormControl, Select, MenuItem, Typography, Grid, TextField, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentMatching, SlideComponentMatchingQuestionModel } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { useEffect, useState } from 'react';
import { MatchingAnswerBubble } from './components/MatchingAnswerBubble';
import RefreshIcon from '@mui/icons-material/Refresh';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';

const debouncedSaveHandler = createDebouncedSaveHandler();
const { v4: uuidv4 } = require('uuid');

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsMatching = ({ item, index, handleCreate }: { item: SlideComponent; index: number; handleCreate?: (item: SlideComponent) => void }) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentMatching = item.content as SlideComponentMatching;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const randomDistribution = () => {
        assignSortValue(shuffleArray([...itemContent.questions]));
    };

    const onDragHandler = (textContent: SlideComponentMatchingQuestionModel[], isDrag: boolean) => {
        assignSortValue(textContent);
    };

    const addRemoveQuestion = () => {
        if (itemContent.questions.length !== itemContent.questionCount) {
            if (itemContent.questions.length < itemContent.questionCount) {
                const diff = itemContent.questionCount - itemContent.questions.length;
                for (let i = 0; i < diff; i++) {
                    itemContent.questions.push({
                        id: uuidv4(),
                        image: '',
                        imageURL: '',
                        flip: 1,
                        answer: '',
                        sort: 0,
                    });
                }
            } else {
                const diff = itemContent.questions.length - itemContent.questionCount;
                itemContent.questions.splice(itemContent.questionCount, diff);
            }
        }

        randomDistribution();
    };

    const saveUploadedImage = (imageURL: string, index: number) => {
        itemContent.questions[index].imageURL = imageURL;
    };
    const imageOnClick = (option: OptionsType, index: number) => {
        itemContent.questions[index].image = option?.value ?? '';
    };
    const deleteUploadedImage = (index: number) => {
        itemContent.questions[index].imageURL = '';
        itemContent.questions[index].image = '';
    };

    return (
        <>
            <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack direction='row' justifyContent='space-evenly' spacing={2}>
                    <FormControl sx={{ flex: 2, borderRadius: '12px' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('reorder-sentence-number-of-questions')}
                        </Typography>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={itemContent.questionCount.toString()}
                            // label={t('cardOrWriting')}
                            onChange={(event: SelectChangeEvent) => {
                                itemContent.questionCount = parseInt(event.target.value);
                                addRemoveQuestion();
                                updateUI(true);
                            }}>
                            {[...Array(4)].map((_, index) => {
                                const value = index + 1;
                                if (value % 2 === 0) {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                }
                                return null;
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        height: gridHeight,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}>
                    {itemContent?.questions?.map((question, index) => (
                        <Grid item xs={6} sx={{ mb: 2 }} key={index}>
                            <Stack spacing={1}>
                                <Typography>
                                    {t('matching.question', {
                                        count: index + 1,
                                    })}
                                </Typography>
                                <SelectImageBox
                                    keepMargin={false}
                                    imageURL={question.imageURL ?? ''}
                                    image={question.image ?? ''}
                                    flip={question.flip ?? 1}
                                    onSelected={(imageURL: string) => {
                                        saveUploadedImage(imageURL, index);
                                        updateUI(true);
                                    }}
                                    handleDelete={() => {
                                        deleteUploadedImage(index);
                                        updateUI(true);
                                    }}
                                    onClickHandler={(option: OptionsType) => {
                                        imageOnClick(option, index);
                                        updateUI(true);
                                    }}
                                />
                                <TextField
                                    sx={{
                                        height: '48px',
                                        borderRadius: '12px',
                                    }}
                                    placeholder={t('matching.answerPlaceholder')}
                                    type='string'
                                    value={question.answer}
                                    onChange={(e) => {
                                        itemContent.questions[index].answer = e.target.value === '' ? '' : e.target.value;
                                        updateUI(false);
                                        debouncedSaveHandler(() => {
                                            updateUI(true);
                                        });
                                    }}
                                    // onBlur={(e) => {
                                    //   updateUI(true);
                                    // }}
                                />
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction='column'>
                    <Typography>{t('matching.questionBubbles')}</Typography>
                    <MatchingAnswerBubble
                        questionBubbles={itemContent.questions}
                        onDragChange={(textContent: SlideComponentMatchingQuestionModel[], isDrag: boolean) => {
                            onDragHandler(textContent, isDrag);
                            updateUI(true);
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            randomDistribution();
                            updateUI(true);
                        }}
                        sx={{
                            height: '18px',
                            marginLeft: 'auto',
                            color: '#B07E27',
                        }}>
                        <RefreshIcon />
                        <Typography sx={{ color: '#B07E27', ml: 1 }}>{t('matching.randomSort')}</Typography>
                    </IconButton>
                </Stack>
            </Stack>

            {/* <PopupCircularProgress isOpened={loading} /> */}
        </>
    );
};

const shuffleArray = (array: SlideComponentMatchingQuestionModel[]) => {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
};

const assignSortValue = (textContent: SlideComponentMatchingQuestionModel[]) => {
    for (let index in textContent) {
        let text = textContent[index];
        text.sort = parseInt(index);
    }
};
