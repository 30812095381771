import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { colors } from 'styles/Colors';
import FeedbackIcon from '../CorrectTopRightIcon/FeedbackIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SasRadicalCardComponent from './SasRadicalCardComponent';
type SasRadicalMatchingAnswerProp = {
    answers: number[];
    characterImg1: string;
    characterImg2: string;
    sasCardChoices: SasCardAnswer[];
    isColumn: boolean;
};

export type SasCardAnswer = {
    correctAnswerPosition: number;
    text: string;
    urlPath: string;
};
const SasRadicalMatchingAnswer = (prop: SasRadicalMatchingAnswerProp) => {
    console.log(prop);
    const [isCorrect, setIsCorrect] = useState(true);
    useEffect(() => {
        prop.answers.forEach((ansIdx, idx) => {
            if (ansIdx !== -1) {
                if (prop.sasCardChoices[ansIdx].correctAnswerPosition != idx + 1) {
                    setIsCorrect(false);
                }
            }
        });
    }, [isCorrect]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    padding: '24px 32px 24px 32px',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: '8px',
                        position: 'relative', // Add this
                        flexDirection: prop.isColumn ? 'column' : 'row',
                        padding: '0px',
                    }}>
                    <FeedbackIcon isCorrect={isCorrect} top={-15} right={-45} />
                    <SasRadicalCardComponent character='' isRadical={true} url={prop.answers[0] === -1 ? prop.characterImg1 : prop.sasCardChoices[prop.answers[0]]?.urlPath} isSimplified={false} />
                    <SasRadicalCardComponent character='' isRadical={false} url={prop.answers[1] === -1 ? prop.characterImg2 : prop.sasCardChoices[prop.answers[1]]?.urlPath} isSimplified={false} />
                </Box>
            </Box>
        </Box>
    );
};

export default SasRadicalMatchingAnswer;
