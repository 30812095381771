import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DateRangeIcon from '@mui/icons-material/DateRange';
import ClassIcon from '@mui/icons-material/Class';
import Description from '@mui/icons-material/Description';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
// import LightModeIcon from '@mui/icons-material/LightMode';
// import DarkModeIcon from '@mui/icons-material/DarkMode';
import SchoolIcon from '@mui/icons-material/School';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';

import { DrawerHeader, Drawer, collapseBtn, BootstrapTooltip } from './theme';
import { ReactComponent as LogoIcon } from '../../assets/logo/logo.svg';
import { ReactComponent as TurtleTeachIcon } from '../../assets/images/turtleteach.svg';
import { useNavigate } from 'react-router-dom';

import Workspace from './Workspace';

import { UserAuthContext } from '../../contexts/UserAuthContext';
import { Button, Typography } from '@mui/material';
import { LogoutButton } from '../../pages/authentication/LogoutButton';
import { LanguageKey, UserPrefContext } from 'contexts/UserPrefContext';
import LanguageMenuItem from './LanguageMenuItem'; // Adjust the import path

import { RecoverTestroomDialog } from './RecoverTestroomDialog';

interface MenuObject {
    icon: any;
    name: string;
    onClick: () => void;
}

interface Props {
    children: any;
}

// const LanguageMenuItem = ({ isMenuOpened }: { isMenuOpened: boolean }) => {
//   return (
//     <UserPrefContext.Consumer>
//       {({ language, changeLanguage }) => {
//         return (
//           <ListItemButton
//             key='language'
//             sx={{
//               minHeight: 48,
//               justifyContent: isMenuOpened ? 'initial' : 'center',
//               px: 1,
//               borderRadius: '8px',
//               borderTopRightRadius: '48px',
//               borderBottomRightRadius: '48px',
//               '&:hover': {
//                 backgroundColor: '#00000080',
//                 '& .MuiListItemText-primary': {
//                   color: '#fff',
//                 },
//                 '& .MuiListItemIcon-root .languageBtn': {
//                   color: '#fff',
//                 },
//                 '& .MuiListItemIcon-root button': {
//                   visibility: 'visible',
//                 },
//               },
//               mx: '10px',
//             }}
//             onClick={() => {}}>
//             <ListItemIcon
//               sx={{
//                 minWidth: 0,
//                 mr: 'auto',
//                 justifyContent: 'center',
//               }}>
//               <Box>
//                 <Button
//                   className='languageBtn'
//                   sx={{
//                     m: 0,
//                     p: 0,
//                     minWidth: 0,
//                     minHeight: 0,
//                     color: '#533D18',
//                   }}>
//                   <LanguageIcon />
//                 </Button>
//                 <Button
//                   sx={{
//                     m: 0,
//                     ml: 1,
//                     p: 0,
//                     minWidth: 0,
//                     minHeight: 0,
//                     color: 'white',
//                     visibility: 'hidden',
//                   }}
//                   onClick={() => {
//                     changeLanguage('zh-HK' as LanguageKey);
//                   }}>
//                   <Typography
//                     sx={{
//                       width: 40,
//                       height: 40,
//                       borderRadius: '50%',
//                       borderColor: 'white',
//                       borderWidth: 1,
//                       borderStyle: 'solid',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       textAlign: 'center',
//                       alignSelf: 'center',
//                       display: 'flex',
//                     }}>
//                     繁
//                   </Typography>
//                 </Button>
//                 <Button
//                   sx={{
//                     m: 0,
//                     ml: 1,
//                     p: 0,
//                     minWidth: 0,
//                     minHeight: 0,
//                     color: 'white',
//                     visibility: 'hidden',
//                   }}
//                   onClick={() => {
//                     changeLanguage('zh-CN' as LanguageKey);
//                   }}>
//                   <Typography
//                     sx={{
//                       width: 40,
//                       height: 40,
//                       borderRadius: '50%',
//                       borderColor: 'white',
//                       borderWidth: 1,
//                       borderStyle: 'solid',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       textAlign: 'center',
//                       alignSelf: 'center',
//                       display: 'flex',
//                     }}>
//                     简
//                   </Typography>
//                 </Button>
//                 <Button
//                   sx={{
//                     m: 0,
//                     ml: 1,
//                     mr: 1,
//                     p: 0,
//                     minWidth: 0,
//                     minHeight: 0,
//                     color: 'white',
//                     visibility: 'hidden',
//                   }}
//                   onClick={() => {
//                     changeLanguage('en-US' as LanguageKey);
//                   }}>
//                   <Typography
//                     sx={{
//                       width: 40,
//                       height: 40,
//                       borderRadius: '50%',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       textAlign: 'center',
//                       alignSelf: 'center',
//                       display: 'flex',
//                       backgroundColor: '#F06E3C',
//                     }}>
//                     En
//                   </Typography>
//                 </Button>
//               </Box>
//             </ListItemIcon>
//           </ListItemButton>
//         );
//       }}
//     </UserPrefContext.Consumer>
//   );
// };

export default function SideMenu(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const { theme, toggleTheme } = useContext(UserPrefContext);
    const { isUserLoggedIn } = useContext(UserAuthContext);
    const { isMenuOpened, setIsMenuOpened } = useContext(UserPrefContext);
    const page = useLocation();
    const handleDrawerOpen = () => {
        setIsMenuOpened(!isMenuOpened);
    };

    const mainMenuItem = (textObj: MenuObject, index: number) => {
        return (
            <BootstrapTooltip title={textObj.name} key={index}>
                <ListItemButton
                    key={index}
                    sx={{
                        minHeight: 48,
                        justifyContent: isMenuOpened ? 'initial' : 'center',
                        px: 2.5,
                        '&:hover': {
                            backgroundColor: '#00000080',
                            '& .MuiListItemIcon-root': {
                                color: '#fff',
                            },
                            '& .MuiListItemText-primary': {
                                color: '#fff',
                            },
                        },
                        borderRadius: '8px',
                        mx: '10px',
                    }}
                    onClick={textObj.onClick}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isMenuOpened ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                        <textObj.icon />
                    </ListItemIcon>
                    <ListItemText
                        primary={textObj.name}
                        sx={{
                            opacity: isMenuOpened ? 1 : 0,
                            fontFamily: 'Sofia Pro Soft',
                            fontWeight: 'medium',
                            color: '#533D18',
                        }}
                    />
                </ListItemButton>
            </BootstrapTooltip>
        );
    };

    const bottomMenuItem = (textObj: MenuObject, index: number) => {
        return (
            <ListItemButton
                key={index}
                sx={{
                    minHeight: 40,
                    width: 40,
                    justifyContent: isMenuOpened ? 'initial' : 'center',
                    px: 1,
                    '&:hover': {
                        backgroundColor: '#00000080',
                        '& > .MuiListItemIcon-root': {
                            color: '#fff', // Change icon color on hover
                        },
                    },
                    borderRadius: '8px',
                    mx: '10px',
                }}
                onClick={textObj.onClick}>
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: 'auto',
                        justifyContent: 'center',
                    }}>
                    <textObj.icon />
                </ListItemIcon>
            </ListItemButton>
        );
    };

    return isUserLoggedIn ? (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Drawer
                variant='permanent'
                open={isMenuOpened}
                PaperProps={{
                    sx: {
                        padding: '16px',
                        borderWidth: 0,
                        backgroundColor: 'inherit',
                    },
                }}>
                <Box
                    sx={{
                        backgroundColor: '#FFA400',
                        color: '#FAFAFA',
                        borderRadius: '24px',
                        height: '100%',
                    }}>
                    {collapseBtn(isMenuOpened, handleDrawerOpen)}

                    <DrawerHeader>
                        <Button onClick={() => navigate('/')}>
                            <Box
                                sx={{
                                    padding: '1px',
                                    marginTop: '28px',
                                    marginBottom: '48px',
                                }}>
                                <LogoIcon />
                                <TurtleTeachIcon
                                    style={{
                                        marginLeft: '10px',
                                        marginBottom: '5px',
                                        display: isMenuOpened ? '' : 'none',
                                    }}
                                />
                            </Box>
                        </Button>
                    </DrawerHeader>
                    <Box sx={{ px: '20px' }}>
                        <Workspace isParentOpen={isMenuOpened}></Workspace>
                    </Box>

                    <List>
                        {[
                            {
                                icon: ClassIcon,
                                name: t('menu-lesson'),
                                onClick: () => navigate('/lessons'),
                            },
                            {
                                icon: Description,
                                name: t('menu-worksheet'),
                                onClick: () => navigate('/worksheets'),
                            },
                            {
                                icon: SupervisorAccountIcon,
                                name: t('menu-classes'),
                                onClick: () => navigate('/classes'),
                            },
                            {
                                icon: SchoolIcon,
                                name: t('menu-students'),
                                onClick: () => navigate('/students'),
                            },
                            {
                                icon: ArticleIcon,
                                name: t('menu-results'),
                                onClick: () => navigate('/results'),
                            },
                            // {
                            //   icon: TranslateIcon,
                            //   name: t('menu-resource'),
                            //   onClick: () => navigate('/resources'),
                            // },
                        ].map((textObj: MenuObject, index: number) => mainMenuItem(textObj, index))}
                    </List>

                    <List
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            marginBottom: '20px',
                            marginLeft: '5.77px',
                        }}>
                        <LanguageMenuItem isMenuOpened={isMenuOpened} />

                        {[
                            // {
                            //   icon:
                            //     theme.palette.mode === 'dark' ? LightModeIcon : DarkModeIcon,
                            //   name: '',
                            //   onClick: toggleTheme,
                            // },
                            {
                                icon: HelpIcon,
                                name: t('menu-support'),
                                onClick: () => navigate(`support`),
                            },
                            {
                                icon: PersonIcon,
                                name: t('menu-profile'),
                                onClick: () => navigate(`profile`),
                            },
                            { icon: LogoutButton, name: '', onClick: () => {} },
                        ].map((textObj: MenuObject, index: number) => bottomMenuItem(textObj, index))}
                    </List>
                </Box>
            </Drawer>

            <Box component='main' sx={{ flexGrow: 1, p: 3, minWidth: 900 }}>
                {props.children}
            </Box>

            <RecoverTestroomDialog />
        </Box>
    ) : (
        props.children
    );
}
