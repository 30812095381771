import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useGetCharacterJson } from 'models/resultPage/useGetCharacterJson';

type WordProps = {
    character: string;
    selectedStrokeIndex: any;
    setSelectedStrokeIndex: React.Dispatch<React.SetStateAction<number>>;
    characterJson: any;
    setCharacterJson: React.Dispatch<React.SetStateAction<any>>;
};
export const Word = (props: WordProps) => {
    const navigate = useNavigate();
    const [updateCanvas, setUpdateCanvas] = useState<number>(0);
    const { getCharacterJson } = useGetCharacterJson();
    useEffect(() => {
        return () => {
            console.log('new');
            setUpdateCanvas(0);
        };
    }, [props.character]);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const getCroppedImage = (image: HTMLImageElement) => {
        const canvas = document.createElement('canvas');
        const context = canvas?.getContext('2d')!;

        // Set the canvas dimensions to match the image dimensions
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        context.drawImage(image, 0, 0);

        // Get the image data
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        const data = imageData.data;

        // Find the non-transparent pixels
        let minX = canvas.width;
        let minY = canvas.height;
        let maxX = 0;
        let maxY = 0;

        for (let i = 0; i < data.length; i += 4) {
            const alpha = data[i + 3];
            if (alpha !== 0) {
                const x = (i / 4) % canvas.width;
                const y = Math.floor(i / (4 * canvas.width));

                minX = Math.min(minX, x);
                minY = Math.min(minY, y);
                maxX = Math.max(maxX, x);
                maxY = Math.max(maxY, y);
                data[i] = 128; // Red channel
                data[i + 1] = 128; // Green channel
                data[i + 2] = 128; // Blue channel
            }
        }
        context.putImageData(imageData, 0, 0);

        // Calculate the cropped dimensions
        const croppedWidth = maxX - minX + 1;
        const croppedHeight = maxY - minY + 1;

        return { canvas, minX, minY, croppedWidth, croppedHeight };
    };

    const getOuputImage = ({ srcImg, minX, minY, croppedWidth, croppedHeight }: { srcImg: any; minX: number; minY: number; croppedWidth: number; croppedHeight: number }) => {
        const canvas = document.createElement('canvas');
        const context = canvas?.getContext('2d')!;

        // Set the canvas dimensions to match the image dimensions
        canvas.width = croppedWidth;
        canvas.height = croppedHeight;

        context.drawImage(srcImg, minX, minY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);

        return canvas.toDataURL();
    };

    useEffect(() => {
        if (canvasRef.current) {
            (async () => {
                try {
                    let data;

                    if (updateCanvas === 0) {
                        const res = await getCharacterJson({
                            character: props.character,
                        });
                        const characterArr: any[] = res?.characters ?? [];
                        data = characterArr.length > 0 ? characterArr[0] : {};
                        props.setCharacterJson(data);
                    } else {
                        data = props.characterJson;
                    }
                    const canvas = canvasRef.current;
                    if (!canvas) return;

                    const context = canvas.getContext('2d');
                    if (!context) return;

                    context.clearRect(0, 0, canvas.width, canvas.height);

                    for (const stroke of data.strokeArray) {
                        console.log(props.character);
                        console.log(stroke.img);
                        const image = new Image();
                        image.src = `/asset/svg/${data.code}_${props.character}/${stroke.img}.svg`;

                        image.onload = () => {
                            // console.log(image.naturalWidth, image.naturalHeight);

                            const croppedImage = getCroppedImage(image);

                            // context.fillRect(
                            //     stroke.x,
                            //     stroke.y,
                            //     croppedImage.croppedWidth,
                            //     croppedImage.croppedHeight
                            // );
                            // console.log(croppedImage);
                            // Draw the cropped image on the new canvas
                            context.drawImage(
                                croppedImage.canvas,
                                croppedImage.minX,
                                croppedImage.minY,
                                croppedImage.croppedWidth,
                                croppedImage.croppedHeight,
                                stroke.x,
                                stroke.y,
                                croppedImage.croppedWidth,
                                croppedImage.croppedHeight,
                            );

                            const imageData = getOuputImage({
                                srcImg: croppedImage.canvas,
                                minX: croppedImage.minX,
                                minY: croppedImage.minY,
                                croppedWidth: croppedImage.croppedWidth,
                                croppedHeight: croppedImage.croppedHeight,
                            });

                            console.log(imageData);

                            props.setCharacterJson((prev: any) => {
                                const updatedStrokeArray = prev.strokeArray.map((item: any, index: number) => {
                                    if (item.img === stroke.img) {
                                        // Update the boundry property of the first item in the strokeArray
                                        return {
                                            ...item,
                                            boundry: {
                                                x: stroke.x,
                                                y: stroke.y,
                                                minX: croppedImage.minX,
                                                minY: croppedImage.minY,
                                                croppedWidth: croppedImage.croppedWidth,
                                                croppedHeight: croppedImage.croppedHeight,
                                            },
                                            imageData: imageData,
                                        };
                                    }
                                    return item;
                                });

                                return {
                                    ...prev,
                                    strokeArray: updatedStrokeArray,
                                };
                            });
                        };
                        // break;
                    }
                    // console.log(data.strokeUIBoundryList);
                } catch (err) {
                    console.log('err', err);
                }
            })();
        }
    }, [canvasRef, props.character, updateCanvas]);

    return (
        <Box style={{ zoom: 72 / 400 }}>
            <canvas
                ref={canvasRef}
                width={400}
                height={400}
                style={{
                    backgroundColor: 'transparent',
                    pointerEvents: 'none',
                }}
            />

            {props.characterJson?.strokeArray &&
                props.characterJson?.strokeArray.map((stroke: any, index: number) => {
                    // console.log(stroke);
                    return (
                        <div
                            key={`${index}${stroke?.boundry?.y}${stroke?.boundry?.x}`}
                            style={{
                                width: stroke?.boundry?.croppedWidth,
                                height: stroke?.boundry?.croppedHeight,
                                position: 'absolute',
                                top: stroke?.boundry?.y,
                                left: stroke?.boundry?.x,
                                // border: '1px solid #000',
                                pointerEvents: props.selectedStrokeIndex === index ? 'auto' : 'none',
                            }}></div>
                    );
                })}
        </Box>
    );
};
