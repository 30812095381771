import { UserPermissionContext } from 'contexts/UserPermissionContext';
import { useLessonShareModelQuery } from 'models/lessonShare/useLessonShareModelQuery';
import { useLessonShareModelResendInvitationMutation } from 'models/lessonShare/useLessonShareModelResendInvitationMutation';
import { useLessonShareModelUpdateMutation } from 'models/lessonShare/useLessonShareModelUpdateMutation';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useShareHook = (lessonID: string) => {
    const { userPermissions } = useContext(UserPermissionContext);
    const { t } = useTranslation();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const [isShareWithPublic, setIsShareWithPublic] = useState<boolean | null>(null);

    const { update } = useLessonShareModelUpdateMutation();
    const { getData, data } = useLessonShareModelQuery();

    const { resendInvitation } = useLessonShareModelResendInvitationMutation();

    const [refreshCount, setRefreshCount] = useState(0);

    useEffect(() => {
        if (refreshCount > 0) {
            try {
                getData({
                    where: { lesson: { id: { equals: lessonID } } },
                });
            } catch (err) {
                console.log('err: ', err);
            }
        }
    }, [refreshCount]);

    const refreshList = () => {
        setRefreshCount((prev) => ++prev);
    };

    const handleOpen = () => {
        if (userPermissions?.enableSharing) {
            setIsPopupOpen(true);
            refreshList();
        } else {
            setShowUpgradePopup(true);
        }
    };
    const handleClose = () => {
        setIsPopupOpen(false);
        setShowUpgradePopup(false); // Close the UpgradePlanPopup if open
    };

    const updateShareWithPublic = async (shareWithPublic: boolean) => {
        try {
            const result = await update({
                shareWithPublic: shareWithPublic,
                lessonID: lessonID,
            });

            setIsShareWithPublic(result?.shareWithPublic ?? false);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    const resendLessonShare = async (lessonShareId: string) => {
        try {
            const result = await resendInvitation({
                lessonShareId,
            });

            if (result) {
                enqueueSnackbar(t('lessonShare.sent'), {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
            }
        } catch (err) {
            console.log('err: ', err);
        }
    };

    return {
        updateShareWithPublic,
        isShareWithPublic,
        isPopupOpen,
        showUpgradePopup,
        handleOpen,
        handleClose,
        emailList: data,
        refreshList,
        resendLessonShare,
    };
};
