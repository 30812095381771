import { REQUEST_TEACHER_UPDATE_ROOM_SETTINGS } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';

export type RoomSettings = {
    page: number;
    screenSharing: boolean;
    writingHints: boolean;
    freeStyleMode: boolean;
    screenSync: boolean;
};

export const requestUpdateRoomSettings = (socket: Socket | null, roomId: string, roomSettings: Partial<RoomSettings>, forceUpdate: boolean, callback: (response: RoomSettings) => void) => {
    if (!socket) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';

    socket?.emit(REQUEST_TEACHER_UPDATE_ROOM_SETTINGS, { token: token, roomId: roomId, roomSettings: roomSettings, forceUpdate: forceUpdate }, callback);
};
