import { UserAuthContext } from 'contexts/UserAuthContext';
import { useClassModelQuery } from 'models/class/useClassModelQuery';
import { useClassModelUpdateMutation } from 'models/class/useClassModelUpdateMutation';
import { useContext, useEffect, useState } from 'react';

type Pagination = {
    pageSize: number;
    page: number;
};

export const useClassesHook = () => {
    const { getClasses, data, loading } = useClassModelQuery();

    const { update } = useClassModelUpdateMutation();
    const auth = useContext(UserAuthContext);

    const [pagination, setPagination] = useState<Pagination>({
        pageSize: 10,
        page: 0,
    });

    const [editTargetValue, setEditTargetValue] = useState<any>(null);

    useEffect(() => {
        refresh();
    }, [pagination, auth.selectedSchool]);

    const handleClose = () => {
        setEditTargetValue(null);
    };

    const refresh = async () => {
        if (pagination) {
            try {
                await getClasses({
                    skip: pagination.page * pagination.pageSize,
                    take: pagination.pageSize,
                    schoolId: auth.selectedSchool?.id ?? '',
                });
            } catch (err) {}
        }
    };

    const updateClass = async (values: any) => {
        try {
            await update({
                ...values,
                id: editTargetValue.id,
            });
            handleClose();
            refresh();
        } catch (err) {}
    };

    return {
        data,
        loading,
        refresh,
        pagination,
        setPagination,
        updateClass,
        editTargetValue,
        setEditTargetValue,
        handleClose,
    };
};
