import { gql, useMutation } from '@apollo/client';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UserAuthContext } from '../../../contexts/UserAuthContext';

const UPDATE_WORKSHEET_NAME_GQL = gql`
    mutation save($worksheetName: String!, $worksheetID: ID!) {
        updateWorksheet(data: { worksheetName: $worksheetName }, where: { id: $worksheetID }) {
            worksheetName
        }
    }
`;

export default function useWorksheetUpdate(worksheetID: string, value: string) {
    const { accessDenied } = useContext(UserAuthContext);
    const { t } = useTranslation();
    const [worksheetName, setWorksheetName] = useState('');
    const [worksheetNameError, setWorksheetNameError] = useState('');

    useEffect(() => {
        setWorksheetName(value);
    }, [value]);

    const [updateWorksheetNameMutation] = useMutation(UPDATE_WORKSHEET_NAME_GQL, {
        variables: {
            worksheetName: '',
            worksheetID: '',
        },
    });
    const updateWorksheetNameMutationValidation = Yup.object().shape({
        worksheetName: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
    });

    const onChangeHandler = async (newValue: string) => {
        setWorksheetName(newValue);
        try {
            await validateWorksheetField(newValue);
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            }
        }
    };

    const onBlurHandler = async (newValue: string) => {
        // console.log(event.target.value);
        if (worksheetID) {
            try {
                await validateWorksheetField(newValue);
                await updateWorksheetNameMutation({
                    variables: {
                        worksheetName: newValue,
                        worksheetID: worksheetID ?? '',
                    },
                });
                // console.log('done');
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                }
            }
        }
    };

    const validateWorksheetField = (name: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                await updateWorksheetNameMutationValidation.validate({
                    worksheetName: name,
                });

                setWorksheetNameError('');
                resolve('');
            } catch (err: any) {
                setWorksheetNameError(err.message);
                reject(err.message);
            }
        });
    };

    return {
        worksheetName,
        worksheetNameError,
        onChangeHandler,
        onBlurHandler,
    };
}
