import { Box } from '@mui/material';

import { RefObject, useContext, useEffect, useRef } from 'react';
import { SlideListContext } from '../contexts/LessonsSlideContext';
import { LessonsSlideDataText } from './dataComponents/views/LessonsSlideDataText';
import { LessonsSlideDataImage } from './dataComponents/views/LessonsSlideDataImage';
import { LessonsSlideDataCharacter } from './dataComponents/views/LessonsSlideDataCharacter';
import { LessonsSlideDataAudio } from './dataComponents/views/LessonsSlideDataAudio';
import { SlideComponent } from 'models/lesson/LessonModel';
import useLessonSlide from '../hooks/useLessonSlide';
import { LessonsSlideDataBackground } from './dataComponents/views/LessonsSlideDataBackground';
import { SlidePreviewContext } from '../contexts/SlidePreviewContext';
import { LessonsSlideDataReorderSentence } from './dataComponents/views/LessonsSlideDataReorderSentence';
import { LessonsSlideDataFillInTheBlank } from './dataComponents/views/LessonsSlideDataFillInTheBlank';
import { LessonsSlideDataPunctuationMark } from './dataComponents/views/LessonsSlideDataPunctuationMark';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { LessonsSlideDataReadingComprehension } from './dataComponents/views/LessonsSlideDataReadingComprehension';
import { LessonsSlideDataH5P } from './dataComponents/views/LessonsSlideDataH5P';
import { LessonsSlideDataMatching } from './dataComponents/views/LessonsSlideDataMatching';
import { LessonsSlideDataHkcsQ1 } from './dataComponents/views/LessonsSlideDataHkcsQ1';
import { LessonsSlideDataHkcsQ2 } from './dataComponents/views/LessonsSlideDataHkcsQ2';
import { LessonsSlideDataHkcsQ3 } from './dataComponents/views/LessonsSlideDataHkcsQ3';
import { LessonsSlideDataMultipleChoice } from './dataComponents/views/LessonsSlideDataMultipleChoice';
import { LessonsSlideDataSasAudio } from './dataComponents/views/LessonsSlideDataSasAudio';
import { LessonsSlideDataSasFillInTheBlanks } from './dataComponents/views/LessonsSlideDataSasFillInTheBlanks';
import { LessonsSlideDataSasFillInTheBlanksWriting } from './dataComponents/views/LessonsSlideDataSasFillInTheBlanksWriting';
import { LessonsSlideDataSasRadicalMatching } from './dataComponents/views/LessonsSlideDataSasRadicalMatching';
import { LessonsSlideDataHkcsEnQ1 } from './dataComponents/views/LessonsSlideDataHkcsEnQ1';
import { LessonsSlideDataHkcsEnQ2 } from './dataComponents/views/LessonsSlideDataHkcsEnQ2';

export const LessonsSlide = ({ observeTarget }: { observeTarget: RefObject<HTMLDivElement> }) => {
    const { userData } = useContext(UserAuthContext);
    const { selectedSlideIndex, lessonData } = useContext(SlideListContext);
    const canvasRef = useRef<HTMLDivElement>(null);

    const { refreshSlide, ratio, setSlideRoot } = useLessonSlide();

    const { setParentCanvas, setCanvas, targetCanvasWidth, targetCanvasHeight } = useContext(SlidePreviewContext);

    useEffect(() => {
        if (lessonData?.latestLessonDraft?.slides && lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.id) {
            refreshSlide(lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id);
        }
    }, [lessonData?.latestLessonDraft?.slides?.length, selectedSlideIndex]);

    const parentObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (parentObserverRef.current) {
            parentObserverRef.current.disconnect();
        }
        parentObserverRef.current = new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element ot be watched
            const { width, height } = entries[0].contentRect;
            // console.log('width', width, 'height', height);
            setParentCanvas(width, height);
        });

        const observeTargetCurrent = observeTarget.current;
        if (observeTargetCurrent) {
            parentObserverRef.current.observe(observeTargetCurrent);
        }

        return () => {
            if (observeTargetCurrent) {
                parentObserverRef?.current?.unobserve(observeTargetCurrent);
            }
            parentObserverRef.current?.disconnect();
        };
    }, [observeTarget, setParentCanvas]);

    const canvasObserverRef = useRef<ResizeObserver | null>(null);
    useEffect(() => {
        const canvasRefCurrent = canvasRef.current;

        if (canvasObserverRef.current) {
            canvasObserverRef.current.disconnect();
        }
        canvasObserverRef.current = new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element ot be watched
            const { width, height } = entries[0].contentRect;
            setCanvas(width, height);
        });

        if (canvasRefCurrent) {
            canvasObserverRef.current.observe(canvasRefCurrent);
        }

        return () => {
            if (canvasRefCurrent) {
                canvasObserverRef?.current?.unobserve(canvasRefCurrent);
            }
            canvasObserverRef?.current?.disconnect();
        };
    }, [canvasRef, setCanvas]);

    useEffect(() => {
        if (canvasRef) {
            setSlideRoot(canvasRef);
        }
    }, [canvasRef]);

    return (
        <Box
            ref={canvasRef}
            sx={{
                width: `${targetCanvasWidth}`,
                height: `${targetCanvasHeight}`,
                aspectRatio: `${ratio}`,
                bgcolor: 'white',
                boxShadow: 4,
                top: `calc(50% - ${targetCanvasHeight} / 2)`,
                position: 'absolute',
            }}>
            {lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data &&
                lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data.map((item: SlideComponent, index: number) => {
                    const canEdit = !lessonData?.latestLessonDraft?.published && userData?.id === lessonData?.teacher?.user?.id;
                    switch (item.type) {
                        case 'readingComprehension':
                            return <LessonsSlideDataReadingComprehension item={item} index={index} key={index} />;
                        case 'punctuationMark':
                            return <LessonsSlideDataPunctuationMark item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'fillInTheBlank':
                            return <LessonsSlideDataFillInTheBlank item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'reorderSentence':
                            return <LessonsSlideDataReorderSentence item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'matching':
                            return <LessonsSlideDataMatching item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'text':
                            return <LessonsSlideDataText item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'image':
                            return <LessonsSlideDataImage item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'background':
                            return <LessonsSlideDataBackground item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'character':
                            return <LessonsSlideDataCharacter item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'audio':
                            return <LessonsSlideDataAudio item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'h5p':
                            return <LessonsSlideDataH5P item={item} index={index} key={index} containerId={'h5p_' + selectedSlideIndex} disabled={!canEdit} />;
                        case 'hkcsQ1':
                            return <LessonsSlideDataHkcsQ1 item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'hkcsQ2':
                            return <LessonsSlideDataHkcsQ2 item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'hkcsQ3':
                            return <LessonsSlideDataHkcsQ3 item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'hkcsEnQ1':
                            return <LessonsSlideDataHkcsEnQ1 item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'hkcsEnQ2':
                            return <LessonsSlideDataHkcsEnQ2 item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'multipleChoice':
                            return <LessonsSlideDataMultipleChoice item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'sasAudio':
                            return <LessonsSlideDataSasAudio item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'sasFillInTheBlanks':
                            return <LessonsSlideDataSasFillInTheBlanks item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'sasFillInTheBlanksWriting':
                            return <LessonsSlideDataSasFillInTheBlanksWriting item={item} index={index} key={index} disabled={!canEdit} />;
                        case 'sasRadicalMatching':
                            return <LessonsSlideDataSasRadicalMatching item={item} index={index} key={index} disabled={!canEdit} />;
                        default:
                            return '';
                    }
                })}
        </Box>
    );
};
