import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ResultTable, ResultTableProps } from 'components/ResultTable';
import { GET_CLASSES } from 'pages/results/queries';
import { SelectClassPath, SelectTestroomPath } from 'routes/utils';
import { ClassesQueryModel, ResultsPageContext } from 'pages/results/models';
import noResultImage from 'assets/images/no-result.png';

export const SelectClassPage = () => {
    const navigator = useNavigate();
    const { schoolId } = useParams();
    const { userData, setSelectedClass } = useOutletContext<ResultsPageContext>();
    const { t } = useTranslation();
    //TODO error handle
    const { data, loading } = useQuery<ClassesQueryModel>(GET_CLASSES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            teacher: { equals: userData?.teacherProfileId },
            school: { equals: schoolId },
        },
        skip: !userData,
    });
    const { data: SchoolNameData, loading: LoadingSchoolNameData } = useQuery<ClassesQueryModel>(GET_CLASSES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            where: {
                id: schoolId,
            },
        },
        skip: !userData,
    });
    const [classesTableData, setClassesTableData] = useState<ResultTableProps>();

    useEffect(() => {
        const classesRow = data?.classes.map((item) => {
            return {
                className: item.className,
                id: item.id,
                onClick: async () => {
                    setSelectedClass(item);
                    navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${item.id}`);
                },
            };
        });
        setClassesTableData({
            data: classesRow,
            columns: [
                {
                    name: 'className',
                    nameStr: t('class'),
                    sortCallback: () => {
                        setClassesTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                return {
                                    ...prevState,
                                    data: cloneState.reverse(),
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
            ],
        });
    }, [data]);

    return (
        <>
            <ResultTable columns={classesTableData?.columns} data={classesTableData?.data} isLoading={loading} showSearchTab={true} />
            {(data?.classes && data?.classes?.length == 0) || loading ? (
                <img
                    src={noResultImage}
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '50px', // adjust as needed
                    }}
                />
            ) : (
                <div></div>
            )}
        </>
    );
};
