import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, InputLabel, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { BootstrapStyleInput } from 'components/BootstrapStyleInput';
import { EmailRules } from 'pages/onBoarding/utils';
import { OnBoardingPageContext } from 'pages/onBoarding/models';
import { ResetVerificationPath } from 'routes/utils';
import { useMutation, gql } from '@apollo/client';

// Use the new mutation
const SEND_PASSWORD_RESET_LINK = gql`
    mutation SendUserPasswordResetLink($email: String!) {
        sendUserPasswordResetLink(email: $email)
    }
`;

export const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setErrorMessage, setIsLoading } = useOutletContext<OnBoardingPageContext>();
    const [sendUserPasswordResetLink, { loading, error }] = useMutation(SEND_PASSWORD_RESET_LINK);

    const handleSubmit = async ({ email }: { email: string }) => {
        try {
            const { data } = await sendUserPasswordResetLink({
                variables: { email },
            });
            if (data.sendUserPasswordResetLink) {
                navigate(`../${ResetVerificationPath}`, {
                    state: { forgotPasswordEmail: email },
                });
            } else {
                setErrorMessage(t('resetPasswordError'));
            }
        } catch (error: any) {
            setErrorMessage(error?.message);
        }
    };

    // const handleSubmit = async ({ email }: { email: string }) => {
    //   try {
    //     navigate(`../${ResetVerificationPath}`, {
    //       state: { forgotPasswordEmail: email },
    //     });
    //   } catch (error: any) {
    //     setErrorMessage(error?.message);
    //   }
    // };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setErrorMessage(error?.message ? error?.message : '');
    }, [error]);

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
            validationSchema={EmailRules}>
            {({ errors, handleChange }) => (
                <Form>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            minWidth: 360,
                        }}>
                        <Box>
                            <FormControl variant='standard' fullWidth>
                                <InputLabel
                                    shrink
                                    htmlFor='email'
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#533D18',
                                    }}>
                                    <Typography>{`${t('email')}*`}</Typography>
                                </InputLabel>
                                <BootstrapStyleInput id='email' name='email' onChange={handleChange} fullWidth placeholder={t('enterEmail')} />
                                {errors?.email && <Box sx={{ color: 'red' }}>{errors.email ? <div>{errors.email}</div> : null}</Box>}
                            </FormControl>
                        </Box>

                        <Box>
                            <LoadingButton
                                variant='contained'
                                disableElevation
                                fullWidth
                                size='large'
                                color='primary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '12px',
                                    background: '#F06E3C',
                                    boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    fontSize: '18px',
                                    color: '#FDFCFC',
                                }}
                                type='submit'>
                                {t('resetPassword')}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
