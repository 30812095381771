export const halfCharactersSerials = {
    data: [
        { serial: 'CCL0000040', scene: [1, 1] },
        { serial: 'CCL0000080', scene: [1, 2] },
        { serial: 'CCL0000140', scene: [1, 3] },
        { serial: 'CCL0000180', scene: [1, 4] },
        { serial: 'CCL00002C0', scene: [1, 5] },
        { serial: 'CCL0000300', scene: [1, 6] },
        { serial: 'CCL0000500', scene: [1, 7] },
        { serial: 'CCL00005C0', scene: [1, 8] },
        { serial: 'CCL0000640', scene: [1, 9] },
        { serial: 'CCL0000900', scene: [1, 10] },
        { serial: 'CCL00009C0', scene: [1, 11] },
        { serial: 'CCL0000A80', scene: [1, 12] },
        { serial: 'CCL0000B80', scene: [1, 13] },
        { serial: 'CCL0000C40', scene: [1, 14] },
        { serial: 'CCL0000C80', scene: [1, 15] },
        { serial: 'CCL0000D40', scene: [1, 16] },
        { serial: 'CCL0000D80', scene: [1, 17] },
        { serial: 'CCL0000E00', scene: [1, 18] },
        { serial: 'CCL00010C0', scene: [1, 19] },
        { serial: 'CCL0001100', scene: [1, 20] },
        { serial: 'CCL0001240', scene: [1, 21] },
        { serial: 'CCL0001280', scene: [1, 22] },
        { serial: 'CCL0001340', scene: [1, 23] },
        { serial: 'CCL0001380', scene: [1, 24] },
        { serial: 'CCL0001440', scene: [1, 25] },
        { serial: 'CCL0001540', scene: [1, 26] },
        { serial: 'CCL0001700', scene: [1, 27] },
        { serial: 'CCL0001880', scene: [1, 28] },
        { serial: 'CCL0001B00', scene: [1, 29] },
        { serial: 'CCL0001C00', scene: [1, 30] },
        { serial: 'CCL0002040', scene: [1, 31] },
        { serial: 'CCL0002080', scene: [1, 32] },
        { serial: 'CCL0002140', scene: [1, 33] },
        { serial: 'CCL0002180', scene: [1, 34] },
        { serial: 'CCL0002200', scene: [1, 35] },
        { serial: 'CCL0002500', scene: [1, 36] },
        { serial: 'CCL0002680', scene: [1, 37] },
        { serial: 'CCL00028C0', scene: [1, 38] },
        { serial: 'CCL0002A80', scene: [1, 39] },
        { serial: 'CCL0002E00', scene: [1, 40] },
        { serial: 'CCL00030C0', scene: [1, 41] },
        { serial: 'CCL0003100', scene: [1, 42] },
        { serial: 'CCL0003440', scene: [1, 43] },
        { serial: 'CCL0003600', scene: [1, 44] },
        { serial: 'CCL0003840', scene: [1, 45] },
        { serial: 'CCL0004000', scene: [1, 46] },
        { serial: 'CCL00040C0', scene: [1, 47] },
        { serial: 'CCL0004100', scene: [2, 1] },
        { serial: 'CCL0004280', scene: [2, 2] },
        { serial: 'CCL0004340', scene: [2, 3] },
        { serial: 'CCL0004440', scene: [2, 4] },
        { serial: 'CCL0004480', scene: [2, 5] },
        { serial: 'CCL0004840', scene: [2, 6] },
        { serial: 'CCL0004880', scene: [2, 7] },
        { serial: 'CCL0004A00', scene: [2, 8] },
        { serial: 'CCL0004B00', scene: [2, 9] },
        { serial: 'CCL0004C00', scene: [2, 10] },
        { serial: 'CCL0004CC0', scene: [2, 11] },
        { serial: 'CCL0004D00', scene: [2, 12] },
        { serial: 'CCL0005040', scene: [2, 13] },
        { serial: 'CCL0005180', scene: [2, 14] },
        { serial: 'CCL0005400', scene: [2, 15] },
        { serial: 'CCL0005500', scene: [2, 16] },
        { serial: 'CCL0006840', scene: [2, 17] },
        { serial: 'CCL0006880', scene: [2, 18] },
        { serial: 'CCL0006C00', scene: [2, 19] },
        { serial: 'CCL0007080', scene: [2, 20] },
        { serial: 'CCL0007140', scene: [2, 21] },
        { serial: 'CCL0008140', scene: [2, 22] },
        { serial: 'CCL0008180', scene: [2, 23] },
        { serial: 'CCL0008200', scene: [2, 24] },
        { serial: 'CCL0008300', scene: [2, 25] },
        { serial: 'CCL0008400', scene: [2, 26] },
        { serial: 'CCL00084C0', scene: [2, 27] },
        { serial: 'CCL0008500', scene: [2, 28] },
        { serial: 'CCL0008680', scene: [2, 29] },
        { serial: 'CCL0008740', scene: [2, 30] },
        { serial: 'CCL0008800', scene: [2, 31] },
        { serial: 'CCL00088C0', scene: [2, 32] },
        { serial: 'CCL0008900', scene: [2, 33] },
        { serial: 'CCL0009000', scene: [2, 34] },
        { serial: 'CCL0009100', scene: [2, 35] },
        { serial: 'CCL0009600', scene: [2, 36] },
        { serial: 'CCL0009840', scene: [2, 37] },
        { serial: 'CCL0009980', scene: [2, 38] },
        { serial: 'CCL0009A00', scene: [2, 39] },
        { serial: 'CCL000A040', scene: [2, 40] },
        { serial: 'CCL000A080', scene: [2, 41] },
        { serial: 'CCL000A200', scene: [2, 42] },
        { serial: 'CCL000A300', scene: [2, 43] },
        { serial: 'CCL000A800', scene: [2, 44] },
        { serial: 'CCL000C100', scene: [3, 1] },
        { serial: 'CCL000C1C0', scene: [3, 2] },
        { serial: 'CCL000C240', scene: [3, 3] },
        { serial: 'CCL000C600', scene: [3, 4] },
        { serial: 'CCL000D080', scene: [3, 5] },
        { serial: 'CCL000D800', scene: [3, 6] },
        { serial: 'CCL000E100', scene: [3, 7] },
        { serial: 'CCL0010000', scene: [3, 8] },
        { serial: 'CCL00100C0', scene: [3, 9] },
        { serial: 'CCL0010600', scene: [3, 10] },
        { serial: 'CCL0010840', scene: [3, 11] },
        { serial: 'CCL0010880', scene: [3, 12] },
        { serial: 'CCL0010940', scene: [3, 13] },
        { serial: 'CCL0010980', scene: [3, 14] },
        { serial: 'CCL0010A00', scene: [3, 15] },
        { serial: 'CCL0010D00', scene: [3, 16] },
        { serial: 'CCL0010E80', scene: [3, 17] },
        { serial: 'CCL0011080', scene: [3, 18] },
        { serial: 'CCL0011180', scene: [3, 19] },
        { serial: 'CCL0011200', scene: [3, 20] },
        { serial: 'CCL0011400', scene: [3, 21] },
        { serial: 'CCL0011800', scene: [3, 22] },
        { serial: 'CCL0012000', scene: [3, 23] },
        { serial: 'CCL0012240', scene: [3, 24] },
        { serial: 'CCL0012A00', scene: [3, 25] },
        { serial: 'CCL0012C00', scene: [3, 26] },
        { serial: 'CCL0013080', scene: [3, 27] },
        { serial: 'CCL0013300', scene: [3, 28] },
        { serial: 'CCL0013400', scene: [3, 29] },
        { serial: 'CCL0014040', scene: [3, 30] },
        { serial: 'CCL0014080', scene: [3, 31] },
        { serial: 'CCL0014400', scene: [3, 32] },
        { serial: 'CCL0015000', scene: [3, 33] },
        { serial: 'CCL0015100', scene: [3, 34] },
        { serial: 'CCL0018240', scene: [3, 35] },
        { serial: 'CCL0018380', scene: [3, 36] },
        { serial: 'CCL0018440', scene: [3, 37] },
        { serial: 'CCL0018480', scene: [3, 38] },
        { serial: 'CCL0018C00', scene: [3, 39] },
        { serial: 'CCL001A000', scene: [3, 40] },
        { serial: 'CCL001A0C0', scene: [3, 41] },
        { serial: 'CCL001A100', scene: [3, 42] },
        { serial: 'CCL001C200', scene: [3, 43] },
        { serial: 'CCL001C500', scene: [3, 44] },
        { serial: 'CCL0020080', scene: [3, 45] },
        { serial: 'CCL0020180', scene: [3, 46] },
        { serial: 'CCL0020300', scene: [3, 47] },
        { serial: 'CCL00203C0', scene: [3, 48] },
        { serial: 'CCL00204C0', scene: [3, 49] },
        { serial: 'CCL0020800', scene: [3, 50] },
        { serial: 'CCL0020C40', scene: [3, 51] },
        { serial: 'CCL0021100', scene: [3, 52] },
        { serial: 'CCL0021280', scene: [3, 53] },
        { serial: 'CCL0021A00', scene: [3, 54] },
        { serial: 'CCL0021D00', scene: [3, 55] },
        { serial: 'CCL0022040', scene: [3, 56] },
        { serial: 'CCL0022140', scene: [3, 57] },
        { serial: 'CCL0022300', scene: [3, 58] },
        { serial: 'CCL0022400', scene: [3, 59] },
        { serial: 'CCL0022800', scene: [4, 1] },
        { serial: 'CCL0023000', scene: [4, 2] },
        { serial: 'CCL0024000', scene: [4, 3] },
        { serial: 'CCL0024100', scene: [4, 4] },
        { serial: 'CCL0024240', scene: [4, 5] },
        { serial: 'CCL0024280', scene: [4, 6] },
        { serial: 'CCL0024480', scene: [4, 7] },
        { serial: 'CCL0024540', scene: [4, 8] },
        { serial: 'CCL0025400', scene: [4, 9] },
        { serial: 'CCL0025800', scene: [4, 10] },
        { serial: 'CCL0026000', scene: [4, 11] },
        { serial: 'CCL0026100', scene: [4, 12] },
        { serial: 'CCL0026600', scene: [4, 13] },
        { serial: 'CCL0028080', scene: [4, 14] },
        { serial: 'CCL0028140', scene: [4, 15] },
        { serial: 'CCL0028800', scene: [4, 16] },
        { serial: 'CCL0028900', scene: [4, 17] },
        { serial: 'CCL002A200', scene: [4, 18] },
        { serial: 'CCL002C000', scene: [4, 19] },
        { serial: 'CCL002C840', scene: [4, 20] },
        { serial: 'CCL00300C0', scene: [4, 21] },
        { serial: 'CCL0030100', scene: [4, 22] },
        { serial: 'CCL0030480', scene: [4, 23] },
        { serial: 'CCL0030700', scene: [4, 24] },
        { serial: 'CCL0030880', scene: [4, 25] },
        { serial: 'CCL0031800', scene: [4, 26] },
        { serial: 'CCL0032000', scene: [4, 27] },
        { serial: 'CCL0032440', scene: [4, 28] },
        { serial: 'CCL0034180', scene: [4, 29] },
        { serial: 'CCL0034200', scene: [4, 30] },
        { serial: 'CCL0038A00', scene: [4, 31] },
        { serial: 'CCL0040140', scene: [4, 32] },
        { serial: 'CCL0040180', scene: [4, 33] },
        { serial: 'CCL0040200', scene: [4, 34] },
        { serial: 'CCL0040300', scene: [4, 35] },
        { serial: 'CCL0040640', scene: [4, 36] },
        { serial: 'CCL0040780', scene: [4, 37] },
        { serial: 'CCL0041000', scene: [4, 38] },
        { serial: 'CCL0041100', scene: [4, 39] },
        { serial: 'CCL0041880', scene: [4, 40] },
        { serial: 'CCL0041940', scene: [4, 41] },
        { serial: 'CCL0042040', scene: [4, 42] },
        { serial: 'CCL0042080', scene: [4, 43] },
        { serial: 'CCL0042200', scene: [4, 44] },
        { serial: 'CCL0042300', scene: [4, 45] },
        { serial: 'CCL0042400', scene: [4, 46] },
        { serial: 'CCL00424C0', scene: [4, 47] },
        { serial: 'CCL0042500', scene: [4, 48] },
        { serial: 'CCL0043A00', scene: [4, 49] },
        { serial: 'CCL0044000', scene: [5, 1] },
        { serial: 'CCL00440C0', scene: [5, 2] },
        { serial: 'CCL0044280', scene: [5, 3] },
        { serial: 'CCL0044600', scene: [5, 4] },
        { serial: 'CCL0045040', scene: [5, 5] },
        { serial: 'CCL0045800', scene: [5, 6] },
        { serial: 'CCL0046000', scene: [5, 7] },
        { serial: 'CCL0046240', scene: [5, 8] },
        { serial: 'CCL0048200', scene: [5, 9] },
        { serial: 'CCL0048500', scene: [5, 10] },
        { serial: 'CCL0048900', scene: [5, 11] },
        { serial: 'CCL0048A80', scene: [5, 12] },
        { serial: 'CCL0049000', scene: [5, 13] },
        { serial: 'CCL0049440', scene: [5, 14] },
        { serial: 'CCL004A800', scene: [5, 15] },
        { serial: 'CCL004C000', scene: [5, 16] },
        { serial: 'CCL004CC00', scene: [5, 17] },
        { serial: 'CCL0050000', scene: [5, 18] },
        { serial: 'CCL00500C0', scene: [5, 19] },
        { serial: 'CCL0050100', scene: [5, 20] },
        { serial: 'CCL0050280', scene: [5, 21] },
        { serial: 'CCL0050340', scene: [5, 22] },
        { serial: 'CCL0050440', scene: [5, 23] },
        { serial: 'CCL0050480', scene: [5, 24] },
        { serial: 'CCL0051200', scene: [5, 25] },
        { serial: 'CCL0054200', scene: [5, 26] },
        { serial: 'CCL0054400', scene: [5, 27] },
        { serial: 'CCL0058000', scene: [5, 28] },
        { serial: 'CCL0059080', scene: [5, 29] },
        { serial: 'CCL0060040', scene: [5, 30] },
        { serial: 'CCL0060180', scene: [5, 31] },
        { serial: 'CCL0060200', scene: [5, 32] },
        { serial: 'CCL0060800', scene: [5, 33] },
        { serial: 'CCL0060900', scene: [5, 34] },
        { serial: 'CCL0060E00', scene: [5, 35] },
        { serial: 'CCL0061000', scene: [5, 36] },
        { serial: 'CCL00610C0', scene: [5, 37] },
        { serial: 'CCL0061100', scene: [5, 38] },
        { serial: 'CCL0063000', scene: [5, 39] },
        { serial: 'CCL0064000', scene: [5, 40] },
        { serial: 'CCL0064880', scene: [5, 41] },
        { serial: 'CCL0068080', scene: [5, 42] },
        { serial: 'CCL0068400', scene: [5, 43] },
        { serial: 'CCL006A040', scene: [5, 44] },
        { serial: 'CCL0071400', scene: [5, 45] },
        { serial: 'CCL0078000', scene: [5, 46] },
        { serial: 'CCL0080040', scene: [5, 47] },
        { serial: 'CCL0080140', scene: [5, 48] },
        { serial: 'CCL0080300', scene: [5, 49] },
        { serial: 'CCL0080400', scene: [6, 1] },
        { serial: 'CCL00808C0', scene: [6, 2] },
        { serial: 'CCL0080900', scene: [6, 3] },
        { serial: 'CCL0080C80', scene: [6, 4] },
        { serial: 'CCL0080F00', scene: [6, 5] },
        { serial: 'CCL0081100', scene: [6, 6] },
        { serial: 'CCL00811C0', scene: [6, 7] },
        { serial: 'CCL0081240', scene: [6, 8] },
        { serial: 'CCL0081600', scene: [6, 9] },
        { serial: 'CCL0082040', scene: [6, 10] },
        { serial: 'CCL0082200', scene: [6, 11] },
        { serial: 'CCL0082400', scene: [6, 12] },
        { serial: 'CCL0082800', scene: [6, 13] },
        { serial: 'CCL0082C40', scene: [6, 14] },
        { serial: 'CCL0083100', scene: [6, 15] },
        { serial: 'CCL0083280', scene: [6, 16] },
        { serial: 'CCL0084000', scene: [6, 17] },
        { serial: 'CCL0084100', scene: [6, 18] },
        { serial: 'CCL0084600', scene: [6, 19] },
        { serial: 'CCL0084840', scene: [6, 20] },
        { serial: 'CCL0084980', scene: [6, 21] },
        { serial: 'CCL0084A00', scene: [6, 22] },
        { serial: 'CCL0085080', scene: [6, 23] },
        { serial: 'CCL0087400', scene: [6, 24] },
        { serial: 'CCL0088040', scene: [6, 25] },
        { serial: 'CCL0088180', scene: [6, 26] },
        { serial: 'CCL0088400', scene: [6, 27] },
        { serial: 'CCL0088500', scene: [6, 28] },
        { serial: 'CCL008A080', scene: [6, 29] },
        { serial: 'CCL008A140', scene: [6, 30] },
        { serial: 'CCL008B000', scene: [6, 31] },
        { serial: 'CCL008C000', scene: [6, 32] },
        { serial: 'CCL008C480', scene: [6, 33] },
        { serial: 'CCL008E000', scene: [6, 34] },
        { serial: 'CCL0090000', scene: [6, 35] },
        { serial: 'CCL0090440', scene: [6, 36] },
        { serial: 'CCL0090840', scene: [6, 37] },
        { serial: 'CCL0090A00', scene: [6, 38] },
        { serial: 'CCL0090C00', scene: [6, 39] },
        { serial: 'CCL0091200', scene: [6, 40] },
        { serial: 'CCL0091500', scene: [6, 41] },
        { serial: 'CCL0092000', scene: [6, 42] },
        { serial: 'CCL0092880', scene: [6, 43] },
        { serial: 'CCL0095000', scene: [6, 44] },
        { serial: 'CCL0098000', scene: [6, 45] },
        { serial: 'CCL0098100', scene: [6, 46] },
        { serial: 'CCL0099800', scene: [6, 47] },
        { serial: 'CCL009C040', scene: [6, 48] },
        { serial: 'CCL00A0040', scene: [6, 49] },
        { serial: 'CCL00A0080', scene: [6, 50] },
        { serial: 'CCL00A0180', scene: [6, 51] },
        { serial: 'CCL00A0200', scene: [6, 52] },
    ],
};
