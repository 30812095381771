import { Stack, Box, styled } from '@mui/material';
import { SlideComponent } from 'models/lesson/LessonModel';
import useLessonSlide from '../../lessons/hooks/useLessonSlide';
import { useContext, useEffect, useRef } from 'react';
import { SlideText } from './SlideText';
import { SlideCharacter } from './SlideCharacter';
import { SlideAudio } from './SlideAudio';
import { SlideImage } from './SlideImage';
import { SlideBackground } from './SlideBackground';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideReorderSentence } from './SlideReorderSentence';
import { SlideFillInTheBlank } from './SlideFillInTheBlank';
import { SlidePunctuationMark } from './SlidePunctuationMark';
import { SlideReadingComprehension } from './SlideReadingComprehension';
import { SlideH5P } from './SlideH5P';
import { SlideMatching } from './SlideMatching';
import { SlideHkcsQ1 } from './SlideHkcsQ1';
import { SlideHkcsQ2 } from './SlideHkcsQ2';
import { SlideHkcsQ3 } from './SlideHkcsQ3';
import { SlideMultipleChoice } from './SlideMultipleChoice';
import { SlideSasAudio } from './SlideSasAudio';
import { SlideSasFillInTheBlanks } from './SlideSasFillInTheBlanks';
import { SlideSasFillInTheBlanksWriting } from './SlideSasFillInTheBlanksWriting';
import { SlideSasRadicalMatching } from './SlideSasRadicalMatching';
import { SlideHkcsEnQ1 } from './SlideHkcsEnQ1';
import { SlideHkcsEnQ2 } from './SlideHkcsEnQ2';

const GridBox = styled(Box)({
    border: '9px solid #FFF3E0',
    borderRadius: '32px',
    marginTop: '9px',
});

export const SlidePreview = ({ data, borderRadius, hasBorderColor }: { data?: SlideComponent[]; borderRadius: string; hasBorderColor: boolean }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);

    const { ratio } = useLessonSlide();

    const { setParentCanvas, setCanvas } = useContext(SlidePreviewContext);

    const parentObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        let containerCurrent = containerRef.current;

        if (parentObserverRef.current) {
            parentObserverRef.current.disconnect();
        }
        parentObserverRef.current = new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element ot be watched
            const { width, height } = entries[0].contentRect;

            setParentCanvas(width, height);
        });

        if (containerCurrent) {
            parentObserverRef.current.observe(containerCurrent);
        }

        return () => {
            if (containerCurrent) {
                parentObserverRef?.current?.unobserve(containerCurrent);
            }
            parentObserverRef?.current?.disconnect();
        };
    }, [containerRef, setParentCanvas]);

    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        let canvasCurrent = canvasRef.current;

        if (canvasObserverRef.current) {
            canvasObserverRef.current.disconnect();
        }
        canvasObserverRef.current = new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element ot be watched
            const { width, height } = entries[0].contentRect;
            setCanvas(width, height);
        });

        if (canvasCurrent) {
            canvasObserverRef.current.observe(canvasCurrent);
        }

        return () => {
            if (canvasCurrent) {
                canvasObserverRef?.current?.unobserve(canvasCurrent);
            }
            canvasObserverRef?.current?.disconnect();
        };
    }, [canvasRef, setCanvas]);

    // useEffect(() => {
    //   if (canvasRef) {
    //     setSlideRoot(canvasRef);
    //   }
    // }, [canvasRef]);

    return (
        <Stack ref={containerRef} justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
            <Box
                ref={canvasRef}
                data-testid='slide-preview'
                sx={{
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    aspectRatio: `${ratio}`,
                    bgcolor: 'white',
                    position: 'relative',
                    border: 0,
                    borderRadius: `${borderRadius}`,
                    overflow: 'hidden',
                }}>
                {data &&
                    data.length > 0 &&
                    data.map((item: SlideComponent, index: number) => {
                        switch (item.type) {
                            case 'punctuationMark':
                                return <SlidePunctuationMark item={item} index={index} key={index} />;
                            case 'fillInTheBlank':
                                return <SlideFillInTheBlank item={item} index={index} key={index} />;
                            case 'reorderSentence':
                                return <SlideReorderSentence item={item} index={index} key={index} />;
                            case 'matching':
                                return <SlideMatching item={item} index={index} key={index} />;
                            case 'readingComprehension':
                                return <SlideReadingComprehension item={item} index={index} key={index} />;
                            case 'text':
                                return <SlideText item={item} index={index} key={index} />;
                            case 'image':
                                return <SlideImage item={item} index={index} key={index} />;
                            case 'background':
                                return <SlideBackground item={item} index={index} key={index} />;
                            case 'character':
                                return <SlideCharacter item={item} index={index} key={index} />;
                            case 'audio':
                                return <SlideAudio item={item} index={index} key={index} />;
                            case 'h5p':
                                return <SlideH5P item={item} index={index} key={index} />;
                            case 'hkcsQ1':
                                return <SlideHkcsQ1 item={item} index={index} key={index} />;
                            case 'hkcsQ2':
                                return <SlideHkcsQ2 item={item} index={index} key={index} />;
                            case 'hkcsQ3':
                                return <SlideHkcsQ3 item={item} index={index} key={index} />;
                            case 'hkcsEnQ1':
                                return <SlideHkcsEnQ1 item={item} index={index} key={index} />;
                            case 'hkcsEnQ2':
                                return <SlideHkcsEnQ2 item={item} index={index} key={index} />;
                            case 'multipleChoice':
                                return <SlideMultipleChoice item={item} index={index} key={index} />;
                            case 'sasAudio':
                                return <SlideSasAudio item={item} index={index} key={index} />;
                            case 'sasFillInTheBlanks':
                                return <SlideSasFillInTheBlanks item={item} index={index} key={index} />;
                            case 'sasFillInTheBlanksWriting':
                                return <SlideSasFillInTheBlanksWriting item={item} index={index} key={index} />;
                            case 'sasRadicalMatching':
                                return <SlideSasRadicalMatching item={item} index={index} key={index} />;
                            // return (
                            //   <SlideBackground item={item} index={index} key={index} />
                            // );
                            default:
                                return '';
                        }
                    })}
                {hasBorderColor && (
                    <GridBox
                        ref={canvasRef}
                        sx={{
                            height: '100%',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            aspectRatio: `${ratio}`,
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: 999,
                            m: 'inherit',
                        }}
                    />
                )}
            </Box>
        </Stack>
    );
};
