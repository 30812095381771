import React, { useState } from 'react';
import { turtleTeachHtml } from './LandingPageWebFlow_TurtleTeach';
import { cssString } from './CSSCombinedWebFlow_TurtleTeach';
import { turtleTeachHtmlFooter } from './LandingPageWebFlow_TurtleTeachFooter';
import { Button, ButtonGroup } from '@mui/material';

// import './normalize.css';
// import './webflow.css';
// import './turtlelearn.webflow.css';
import { useNavigate, Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { PaymentPanel } from 'pages/payment/PaymentPanel';
import { LandingPageWebFlowEmbed } from './LandingPageWebFlowEmbed';
import { Constants } from 'Constants';

export const LandingPageWebFlow = () => {
    const [selectedLocale, setSelectedLocale] = useState(Constants.lesson.defaultLocale);

    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const paymentPanelStyle = {
        background: '#FFFBF7',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '50px',
        paddingBottom: '50px',
    };

    let htmlString = `
    <style>
      ${cssString}
    </style>
    ${turtleTeachHtml}
  `;

    return (
        <>
            <header
                className='top-menu-bar'
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backgroundColor: '#FFA92E',
                    minHeight: '100px',
                    padding: isSmallScreen ? '10px 0px' : '10px 110px',
                }}>
                <div style={{ marginRight: '150px' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <Stack direction='row' spacing={2}>
                            <LoadingButton
                                fullWidth={isSmallScreen}
                                variant='contained'
                                disableElevation
                                size='large'
                                color='primary'
                                sx={{
                                    minWidth: '100px',
                                    textTransform: 'none',
                                    borderRadius: '12px',
                                    background: '#F06E3C',
                                    boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    fontSize: '18px',
                                    color: '#FDFCFC',
                                }}
                                onClick={() => {
                                    navigate('/on-boarding/sign-in');
                                }}>
                                登入<br></br>Login
                            </LoadingButton>

                            {process.env.REACT_APP_SIGN_UP_ENABLED == 'false' ? null : (
                                <LoadingButton
                                    variant='contained'
                                    disableElevation
                                    fullWidth={isSmallScreen}
                                    size='large'
                                    color='primary'
                                    sx={{
                                        minWidth: '100px',
                                        textTransform: 'none',
                                        borderRadius: '12px',
                                        background: '#F06E3C',
                                        boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                        fontWeight: '500',
                                        lineHeight: '28px',
                                        fontSize: '18px',
                                        color: '#FDFCFC',
                                    }}
                                    onClick={() => {
                                        navigate('/on-boarding/sign-up');
                                    }}>
                                    註冊<br></br>Sign Up
                                </LoadingButton>
                            )}
                        </Stack>
                    </Box>
                </div>
            </header>
            {/* <div dangerouslySetInnerHTML={{ __html: htmlString }} /> */}
            <div dangerouslySetInnerHTML={{ __html: turtleTeachHtml }} />
            {/* <div style={paymentPanelStyle}>
        <Stack direction='column' alignItems='center' spacing={4}>
          <Typography variant='h3'>計劃一覽</Typography>
          <PaymentPanel />
        </Stack>
      </div> */}
            <div style={{ textAlign: 'center', margin: '0px 0' }}>
                <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
                    <Button onClick={() => setSelectedLocale('en-US')} variant={selectedLocale === 'en-US' ? 'contained' : 'outlined'}>
                        English
                    </Button>
                    <Button onClick={() => setSelectedLocale('zh-HK')} variant={selectedLocale === 'zh-HK' ? 'contained' : 'outlined'}>
                        繁體
                    </Button>
                    <Button onClick={() => setSelectedLocale('zh-CN')} variant={selectedLocale === 'zh-CN' ? 'contained' : 'outlined'}>
                        简体
                    </Button>
                </ButtonGroup>
            </div>

            <LandingPageWebFlowEmbed locale={selectedLocale}></LandingPageWebFlowEmbed>
            <div dangerouslySetInnerHTML={{ __html: turtleTeachHtmlFooter }} />
        </>
    );
};

// export default MyWebflowPage;
