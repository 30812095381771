export const AuthPath = 'auth';
export const LandingPath = 'landing';
export const LandingPathEmbed = 'landingEmbed';
export const OnBoardingPath = 'on-boarding';
export const SignInPath = 'sign-in';
export const SignUpPath = 'sign-up';
export const EmailVerificationPath = 'email-verification';
export const RegisterSuccessPath = 'register-success';
export const ForgotPasswordPath = 'forgot-password';
export const ResetVerificationPath = 'reset-verification';
export const SetNewPasswordPath = 'set-new-password';
export const ResetSuccessPath = 'reset-success';
export const RegistrationProcessPath = 'verify';

export const CalendarPath = 'calendar';
export const LessonsPath = 'lessons';
export const WorksheetsPath = 'worksheets';
export const LessonsShareWithPublicPath = 'lessons-share-with-public';
export const ClassesPath = 'classes';
export const StudentsPath = 'students';
export const SupportPath = 'support';
// export const SignUpPath = 'signup';
export const PreviewPath = 'preview';
export const TestroomPath = 'testroom';
export const RedirectPlaylistPath = 'app/playlist';
export const ProfilePath = 'profile';
export const UpgradePath = 'profile'; // Set to profile for now. Can change later when we have a proper upgrade flow

export const ResultPath = 'results';
export const SelectClassPath = 'select-class';
export const SelectTestroomPath = 'select-testroom';
export const SelectStudentPath = 'select-student';
export const SelectQuestionId = 'select-question-id';
export const OverallResultPath = 'overall-result';
export const ClassOverallResultPath = 'class-overall-result';
export const SelectQuestionPath = 'select-question';
export const TermsConditionsPath = 'terms-conditions';
export const PrivacyPolicyPath = 'privacy-policy';
export const GradingPath = 'select-grading';

export const ConfirmLessonSharePath = 'confirmLessonShare';
