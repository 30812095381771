import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Results } from './ResultPageModel';

export const GET_RESULTS_BY_TESTROOM = gql`
    query GetResultsByTestroom($testroomId: ID!) {
        results(where: { testroom: { id: { equals: $testroomId } } }) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            startTime
            endTime
            componentId
            correct
            grading
            gameType
        }
    }
`;

export type ModelQueryType = {
    results?: Results[];
};

type QueryVariableType = {
    testroomId: string;
};

export const useTestRoomResultQuery = () => {
    const auth = useContext(UserAuthContext);

    const [getTestRoomResultQuery, { data, loading: resultsLoading }] = useLazyQuery(GET_RESULTS_BY_TESTROOM);

    const getTestRoomsResultData = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getTestRoomResultQuery({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            console.log(data);
            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };
    return { getTestRoomsResultData, data, resultsLoading };
};
