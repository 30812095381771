import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export type ResultStep = {
    schoolName: string;
    className: string;
    testroomName: string;
    studentName: string;
    score: string;
};

export interface ResultStepperProps {
    steps: ResultStep;
}

export const initStep = {
    schoolName: '',
    className: '',
    testroomName: '',
    studentName: '',
    score: '',
};

export const ResultStepper = (props: ResultStepperProps) => {
    const {
        steps: { schoolName, className, testroomName, studentName, score },
    } = props;

    return (
        <Stack
            direction='row'
            spacing={5}
            sx={{
                background: '#FCC86C',
                padding: '8px 24px',
                borderRadius: '12px',
                width: 'fit-content',
            }}>
            {schoolName && <Text>{schoolName}</Text>}
            {className && <Text>{className}</Text>}
            {testroomName && <Text>{testroomName}</Text>}
            {studentName && <Text>{studentName}</Text>}
            {score && <Text>{score}</Text>}
        </Stack>
    );
};

const Text = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #533d18;
`;
