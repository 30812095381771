import styled from '@emotion/styled';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
export interface AudioAnswerProp {
    index: number;
    audioUrl: string;
    isPlaying: boolean;
    playSoundOnClick: (audioUrl: string, idx: number) => void;
}

export const AudioAnswer = (props: AudioAnswerProp) => {
    return (
        <Button
            sx={{
                borderRadius: 60,
                backgroundColor: '#F06E3C',
                width: '120px',
                height: '120px',
                '&:hover': {
                    backgroundColor: '#FCB525',
                },
            }}
            variant='contained'
            onClick={() => {
                props.playSoundOnClick(props.audioUrl, props.index);
            }}>
            {props.isPlaying ? (
                <PauseIcon
                    sx={{
                        width: '80px',
                        height: '80px',
                    }}
                />
            ) : (
                <PlayArrowIcon
                    sx={{
                        width: '80px',
                        height: '80px',
                    }}
                />
            )}
        </Button>
    );
};
