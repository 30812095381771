import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const SAVE_GQL = gql`
    mutation UpdateTestroom($where: TestroomWhereUniqueInput!, $data: TestroomUpdateInput!) {
        updateTestroom(where: $where, data: $data) {
            id
        }
    }
`;

type MutationVariableType = {
    where: { id: string };
};

type MutationType = {
    updateTestroom: { id: string };
};

export const useEndLessonMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation<MutationType>(SAVE_GQL);

    const endLesson = async (variables: MutationVariableType) => {
        try {
            const result = await saveForm({
                variables: { ...variables, data: {} },
            });

            if (!result || !result.data?.updateTestroom) {
                throw new TTError(ERROR_TYPE.UPDATE_FAILED);
            }
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { endLesson };
};
