import { Stack, Box, Divider, ToggleButton, FormControl, InputLabel, Select, MenuItem, Grid, TextField, SelectChangeEvent, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentSasRadicalMatching, SlideComponentSasRadicalMatchingAnswerModels } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useEffect, useState } from 'react';
import { SelectWordsBox } from './components/SelectWordsBox';
import { DoSound } from './components/DoSound';
import FlipIcon from '@mui/icons-material/Flip';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export const LessonsSlidePropsSasRadicalMatching = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentSasRadicalMatching = item.content as SlideComponentSasRadicalMatching;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.(
            (itemContent.image !== '' || itemContent.imageURL !== '') &&
                itemContent.characterList.length > 0 &&
                itemContent.answers.length > 0 &&
                !hasEmptyFieldInArray(itemContent.answers, itemContent.answerPosition),
        );
    }, [item]);

    const hasEmptyFieldInArray = (answers: SlideComponentSasRadicalMatchingAnswerModels[], answerPosition: number) => {
        let hasChoice1 = false;
        let hasChoice2 = false;
        for (const answer of answers) {
            if (answer.urlPath.trim() === '') {
                return true;
            }
            if ((answerPosition === 1 || answerPosition === 3) && answer.correctAnswerPosition === 1) {
                hasChoice1 = true;
            } else if ((answerPosition === 2 || answerPosition === 3) && answer.correctAnswerPosition === 2) {
                hasChoice2 = true;
            }
        }
        if (!hasChoice1 && (answerPosition === 1 || answerPosition === 3)) {
            return true;
        }

        if (!hasChoice2 && (answerPosition === 2 || answerPosition === 3)) {
            return true;
        }

        return false;
    };

    const resetAnswer = (answerPosition: number) => {
        for (const answer of itemContent.answers) {
            if (answer.correctAnswerPosition === answerPosition) {
                answer.correctAnswerPosition = 0;
            }
        }
    };

    const updateRadicalURL = () => {
        const tmpCharacter = itemContent.characterList[itemContent.character];
        itemContent.questionImageURL1 = `${window.location.origin}/parts/${tmpCharacter.code}_${tmpCharacter.character}/R${tmpCharacter.character}.png`;
        itemContent.questionImageURL2 = `${window.location.origin}/parts/${tmpCharacter.code}_${tmpCharacter.character}/P${tmpCharacter.character}.png`;
    };

    return (
        <Stack sx={{ flex: 1, pt: 1 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <Box sx={{ flex: '1 1 400px', m: 1 }}>
                    <Stack direction={'row'} spacing={1} justifyContent='space-evenly'>
                        <Box sx={{ width: '50%' }}>
                            <FormControl fullWidth sx={{ mb: 1 }}>
                                <InputLabel id='layout'>{t('hksas.radicalMatching.layout')}</InputLabel>
                                <Select
                                    labelId='layoutx'
                                    id='layoutxx'
                                    value={String(itemContent.layout)}
                                    label={t('hksas.radicalMatching.layout')}
                                    onChange={(event: SelectChangeEvent) => {
                                        itemContent.layout = parseInt(event.target.value);
                                        updateUI(true);
                                    }}>
                                    <MenuItem value={'1'}>{t('hksas.radicalMatching.layout1')}</MenuItem>
                                    <MenuItem value={'2'}>{t('hksas.radicalMatching.layout2')}</MenuItem>
                                    <MenuItem value={'3'}>{t('hksas.radicalMatching.layout3')}</MenuItem>
                                </Select>
                            </FormControl>

                            <SelectImageBox
                                keepMargin={true}
                                imageURL={itemContent.imageURL ?? ''}
                                image={itemContent.image ?? ''}
                                flip={itemContent.flip}
                                onSelected={(imageURL: string) => {
                                    itemContent.imageURL = imageURL;
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    itemContent.imageURL = '';
                                    itemContent.image = '';
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    itemContent.image = option?.value ?? '';
                                    updateUI(true);
                                }}
                            />
                            <ToggleButton
                                value='1'
                                selected={itemContent.flip === -1}
                                onChange={() => {
                                    if (itemContent.flip === -1) {
                                        itemContent.flip = 1;
                                    } else {
                                        itemContent.flip = -1;
                                    }
                                    updateUI(true);
                                }}
                                aria-label='bold'
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    borderRadius: '12px',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '150%',
                                    color: '#FDFCFC',
                                    backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                    '&:hover': {
                                        backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                    },
                                }}>
                                <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                            </ToggleButton>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <FormControl fullWidth sx={{ mb: 1 }}>
                                <InputLabel id='answerPosition'>{t('hksas.radicalMatching.answerPosition')}</InputLabel>
                                <Select
                                    labelId='answerPosition'
                                    id='answerPosition'
                                    value={String(itemContent.answerPosition)}
                                    label={t('hksas.radicalMatching.answerPosition')}
                                    onChange={(event: SelectChangeEvent) => {
                                        itemContent.answerPosition = parseInt(event.target.value);
                                        updateUI(true);
                                    }}>
                                    <MenuItem value={'1'}>{t('hksas.radicalMatching.layout' + itemContent.layout)[0]}</MenuItem>
                                    <MenuItem value={'2'}>{t('hksas.radicalMatching.layout' + itemContent.layout)[1]}</MenuItem>
                                    <MenuItem value={'3'}>{t('hksas.radicalMatching.layout' + itemContent.layout)}</MenuItem>
                                </Select>
                            </FormControl>

                            <SelectWordsBox
                                singleWord={false}
                                canSelectQuestion={true}
                                questionLabel={t('hksas.radicalMatching.questionLabel')}
                                characterList={itemContent?.characterList}
                                phrase={itemContent.phrase}
                                phraseSound={itemContent.phraseSound}
                                phraseSoundGroup={itemContent.phraseSoundGroup}
                                onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                                    itemContent.phraseSoundGroup = phraseSoundGroup;
                                    updateUI(true);
                                }}
                                selectedCharacterIndex={itemContent.character}
                                onCharacterPicked={(characterPhrase) => {
                                    itemContent.characterList = characterPhrase?.character;
                                    itemContent.character = 0;
                                    updateRadicalURL();
                                    itemContent.characterSound = {
                                        jyutping: characterPhrase?.character?.[itemContent.character]?.jyutping,
                                        pinyin: characterPhrase?.character?.[itemContent.character]?.pinyin,
                                    };
                                    itemContent.phrase = characterPhrase?.phrase;
                                    itemContent.phraseSound = {
                                        jyutping: characterPhrase?.jyutping,
                                        pinyin: characterPhrase?.pinyin,
                                    };
                                    updateUI(true);
                                }}
                                onQuestionCharacterSelected={(index, character) => {
                                    itemContent.character = index;
                                    updateRadicalURL();

                                    itemContent.characterSound = {
                                        jyutping: character.jyutping,
                                        pinyin: character.pinyin,
                                    };

                                    updateUI(true);
                                }}
                            />
                        </Box>
                    </Stack>

                    <Divider sx={{ p: 2 }} />
                    <Stack direction={'row'} spacing={1} sx={{ mt: 2 }}>
                        <DoSound
                            soundString={itemContent?.phraseSound?.jyutping?.[0]?.sound}
                            phrase={itemContent?.phrase}
                            groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                            folder='jyutping'
                            sx={{
                                bgcolor: '#23B1D3',
                                color: '#FAFAFA',
                                '&:hover': {
                                    bgcolor: '#23B1D3',
                                    color: '#FAFAFA',
                                    opacity: '0.7',
                                },
                                pointerEvents: 'auto',
                            }}>
                            {t('cantonese')}
                        </DoSound>

                        <DoSound
                            soundString={itemContent?.phraseSound?.pinyin?.[0]?.sound}
                            phrase={itemContent?.phrase}
                            groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                            folder='pinyin'
                            sx={{
                                bgcolor: '#FFFFFF',
                                color: '#29C0E7',
                                '&:hover': {
                                    bgcolor: '#FFFFFF',
                                    color: '#29C0E7',
                                    opacity: '0.7',
                                },
                                pointerEvents: 'auto',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: '#29C0E7',
                            }}>
                            {t('putonghua')}
                        </DoSound>
                    </Stack>
                </Box>

                <Box
                    sx={{
                        flex: '1 1 200px',
                        border: 1,
                        borderRadius: 1,
                        m: 1,
                    }}>
                    <Grid container>
                        {itemContent.answerPosition === 1 || itemContent.answerPosition === 3 ? (
                            <Grid item xs={1} sx={{ p: 1 }}>
                                {t('hksas.radicalMatching.layout' + itemContent.layout)[0]}
                            </Grid>
                        ) : null}
                        {itemContent.answerPosition === 2 || itemContent.answerPosition === 3 ? (
                            <Grid item xs={1} sx={{ p: 1 }}>
                                {t('hksas.radicalMatching.layout' + itemContent.layout)[1]}
                            </Grid>
                        ) : null}
                        <Grid item xs={10} sx={{ p: 1 }}>
                            {t('hksas.radicalMatching.answerInstruction')}
                        </Grid>
                    </Grid>

                    {itemContent.answers.map((v, idx) => (
                        <Grid container key={idx}>
                            {itemContent.answerPosition === 1 || itemContent.answerPosition === 3 ? (
                                <Grid item xs={1} sx={{ p: 1 }}>
                                    <FormControlLabel
                                        value={idx}
                                        control={<Radio size='small' />}
                                        label=''
                                        onChange={(e) => {
                                            resetAnswer(1);
                                            v.correctAnswerPosition = 1;
                                            updateUI(true);
                                        }}
                                        checked={v.correctAnswerPosition === 1}
                                    />
                                </Grid>
                            ) : null}
                            {itemContent.answerPosition === 2 || itemContent.answerPosition === 3 ? (
                                <Grid item xs={1} sx={{ p: 1 }}>
                                    <FormControlLabel
                                        value={idx}
                                        control={<Radio size='small' />}
                                        label=''
                                        onChange={(e) => {
                                            resetAnswer(2);
                                            v.correctAnswerPosition = 2;
                                            updateUI(true);
                                        }}
                                        checked={v.correctAnswerPosition === 2}
                                    />
                                </Grid>
                            ) : null}
                            <Grid item xs={10} sx={{ p: 1 }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#c0c0c0',
                                        width: 50,
                                        height: 50,
                                        borderRadius: 2,
                                        mb: 1,
                                    }}>
                                    {v.urlPath && v.urlPath !== '' ? <img src={v.urlPath} alt='' width='50' height='50' /> : null}
                                </Box>
                                <TextField
                                    label={t('hksas.radicalMatching.answer')}
                                    type='text'
                                    sx={{ width: '100%' }}
                                    value={v.text}
                                    placeholder='cn,R什 or cn,P什'
                                    onChange={(e) => {
                                        v.text = e.target.value;
                                        const textSplit = v.text?.split(',');
                                        v.urlPath = `${window.location.origin}/asset/parts/${textSplit?.[0]}${textSplit?.[1]?.[1]?.charCodeAt(0)}_${textSplit?.[1]?.[1]}/${textSplit?.[1]}.png`;
                                        updateUI(false);
                                        debouncedSaveHandler(() => {
                                            updateUI(true);
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
        </Stack>
    );
};
