import { CssBaseline } from '@mui/material';
import './App.css';
import { UserAuthContext, UserAuthContextProvider } from './contexts/UserAuthContext';
import { ThemeProvider } from '@mui/material';
import { UserPrefContext, UserPrefContextProvider } from './contexts/UserPrefContext';
import { UserPermissionContextProvider } from './contexts/UserPermissionContext';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { MainRoutes } from 'routes';

const link = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_API_URL}`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        },
    };
});

const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            'apollo-require-preflight': 'true',
        },
    });
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: middlewareLink.concat(authLink.concat(link)),
});

const App = () => {
    return (
        <UserAuthContextProvider>
            <ApolloProvider client={client}>
                <UserAuthContext.Consumer>
                    {({ isUserLoggedIn }) => {
                        return (
                            <UserPrefContextProvider>
                                <UserPrefContext.Consumer>
                                    {({ theme }) => {
                                        return (
                                            <UserPermissionContextProvider>
                                                <ThemeProvider theme={theme}>
                                                    <CssBaseline />
                                                    <MainRoutes isUserLoggedIn={isUserLoggedIn} />
                                                </ThemeProvider>
                                            </UserPermissionContextProvider>
                                        );
                                    }}
                                </UserPrefContext.Consumer>
                            </UserPrefContextProvider>
                        );
                    }}
                </UserAuthContext.Consumer>
            </ApolloProvider>
        </UserAuthContextProvider>
    );
};

export default App;
