import { ResultTableProps } from 'components/ResultTable';
import moment from 'moment';
import { useEffect, useState } from 'react';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;
export interface HkcsQ1QuestionImages {
    id: string;
    image: string;
    imageURL: string;
    flip: number;
}
export const useGetHkcsImagesHook = () => {
    const [hkcsImage, setHkcsImage] = useState<HkcsQ1QuestionImages[]>([]);

    return {
        setHkcsImage,
        hkcsImage,
    };
};
