import { useCallback, useState } from 'react';
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as LogoIcon } from 'assets/logo/logomark.svg';
import { ArrowBack } from '@mui/icons-material';
import { EmailVerificationPath, ForgotPasswordPath, SignInPath, SignUpPath } from 'routes/utils';
import { isDisplayFooter, onBoardingFooterHighLightedText, onBoardingFooterText, onBoardingTitle } from './utils';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import TermsConditions from './subPage/SignUp/TermsConditions';
import PrivacyPolicy from './subPage/SignUp/PrivacyPolicy';
import { useMutation } from '@apollo/client';
import { RESEND_VERIFICATION_EMAIL } from 'pages/onBoarding/queries';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import LanguageMenuItem from '../../components/sideMenu/LanguageMenuItem';
import { enqueueSnackbar } from 'notistack';

export const OnBoardingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [resendVerificationEmail, { loading, error }] = useMutation(RESEND_VERIFICATION_EMAIL);

    const url = new URL(window.location.href);
    const params = url.searchParams;
    const fallbackParam = params.get('fallback');
    const fallbackPath = encodeURIComponent(fallbackParam || '');

    const setErrorMessage = useCallback((msg: string) => {
        if (msg !== '') {
            enqueueSnackbar(msg, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
    }, []);

    const landingPageURL = process.env.REACT_APP_LANDING_PAGE_URL ? process.env.REACT_APP_LANDING_PAGE_URL : window.location.origin + '/on-boarding/sign-in';

    return (
        // TODO: [TTEACH-196] Add language switcher at the sign in and sign up pages
        <Stack direction='column' justifyContent='center' alignItems='center' spacing={2} sx={{ m: 1 }}>
            <PopupCircularProgress isOpened={isLoading} />

            <Container>
                <FormContainer>
                    <TitleContainer>
                        {/* <Link to='/landing' style={{ textDecoration: 'none' }}> */}
                        <Link to={landingPageURL} style={{ textDecoration: 'none' }}>
                            <LogoIcon />
                        </Link>
                        <Title onClick={() => navigate(landingPageURL)}>{t('turtleTeach')}</Title>
                    </TitleContainer>
                    <LanguageMenuItem isMenuOpened={true} hoverBackgroundColor='#FFA425' textColor='#987654' iconColor='#987654' alwaysHovered={true} />
                    <Header>{onBoardingTitle(location.pathname)}</Header>
                    <Outlet
                        context={{
                            setErrorMessage,
                            setIsLoading,
                        }}
                    />
                    {isDisplayFooter(location.pathname) && (
                        <FooterContainer>
                            <Box padding={0} paddingBottom={3} textAlign='center'>
                                {process.env.REACT_APP_SIGN_UP_ENABLED == 'false' ? [] : <Footer>{onBoardingFooterText(location.pathname) + ' '}</Footer>}

                                <FooterHighLightedText
                                    onClick={() => {
                                        if (location.pathname.includes(SignInPath)) {
                                            navigate(`./${SignUpPath}?fallback=${fallbackPath}`);
                                        } else if (location.pathname.includes(SignUpPath)) {
                                            navigate(`./${SignInPath}?fallback=${fallbackPath}`);
                                        } else if (location.pathname.includes(EmailVerificationPath)) {
                                            let data;
                                            if (location.state) {
                                                data = location.state.data;
                                            }
                                            setIsLoading(true);
                                            resendVerificationEmail({
                                                variables: {
                                                    data: data,
                                                },
                                            })
                                                .then((response) => {
                                                    if (response.data) {
                                                        setIsLoading(false);
                                                        // console.log('Resent!');
                                                        // console.log(response.data);
                                                        // handle success, maybe show a success message
                                                        setErrorMessage('Email Re-sent. Please check your mailbox.');
                                                    }
                                                })
                                                .catch((err: any) => {
                                                    setErrorMessage(err.message);
                                                })
                                                .finally(() => setIsLoading(false));
                                        }
                                    }}>
                                    {process.env.REACT_APP_SIGN_UP_ENABLED == 'false' ? [] : onBoardingFooterHighLightedText(location.pathname)}
                                </FooterHighLightedText>
                            </Box>

                            {location.pathname.includes(SignUpPath) || location.pathname.includes(SignInPath) ? (
                                <Footer
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                    <Typography
                                        style={{
                                            maxWidth: '450px',
                                            wordWrap: 'break-word',
                                        }}>
                                        <TermsConditions />
                                        <br></br>
                                        <PrivacyPolicy />
                                    </Typography>
                                </Footer>
                            ) : (
                                <div></div>
                            )}
                        </FooterContainer>
                    )}

                    {(location.pathname.includes(ForgotPasswordPath) || location.pathname.includes(EmailVerificationPath)) && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '4px',
                                alignItems: 'center',
                                color: '#858585',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '150%',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(`./${SignInPath}?fallback=${fallbackPath}`)}>
                            <ArrowBack />
                            {t('backToLogIn')}
                        </Box>
                    )}
                </FormContainer>
            </Container>
        </Stack>
    );
};

const Container = styled.div`
    background-color: #ffffff;

    box-sizing: border-box;
    border: 2px solid #533d18;
    border-radius: 24px;
    border-color: #dccdb5;
    padding: 32px 156px;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: fit-content;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

const Title = styled.span`
    color: #ffa425;
    font-weight: 400;
    font-size: 32px;
    line-height: 125%;
`;

const Header = styled.span`
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    color: #533d18;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const Footer = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #858585;
`;

const FooterHighLightedText = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #f06e3c;

    cursor: pointer;
`;
