import { gql, useLazyQuery, useQuery } from '@apollo/client';

import { SlideComponentFillInTheBlank, SlideComponentFillInTheBlankQuestionModel } from 'models/lesson/LessonModel';

const { v4: uuidv4 } = require('uuid');

type SentenceModel = {
    answerText: string;
    questionText: string;
};

type GenerateTextQueryModel = {
    generateTextFillInTheBlank: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionLength: String!) {
        generateTextFillInTheBlank(questionTopic: $questionTopic, questionLength: $questionLength) {
            answerText
            questionText
        }
    }
`;

const GENERATE_TEXT_GENERIC_QUERY = gql`
    query GenTextGeneric($questionPrompt: String!) {
        generateTextGeneric(questionPrompt: $questionPrompt) {
            replyText
        }
    }
`;

export const useLessonsSlidePropsFillInTheBlankHook = (itemContent: SlideComponentFillInTheBlank) => {
    const [genText, { loading: genTextLoading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const [genTextGeneric, { loading }] = useLazyQuery(GENERATE_TEXT_GENERIC_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const getTextFromServer = async (questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genText({
                variables: { questionLength, questionTopic },
            });
            UpdateQuestionArrayInfo(result.data?.generateTextFillInTheBlank ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const parseResultsFillInTheBlank = (results: string, questionCountLimit?: number) => {
        console.log('results:', results);
        console.log('questionCountLimit:', questionCountLimit);
        const regex = /(.*\s\|\s.*)[\r\n]?/g;

        const sentences: { questionText: string; answerText: string }[] = [];

        const match = results.match(regex);
        console.log(match);

        match?.forEach((sentence) => {
            const [answerText, questionText] = sentence.split(' | ');
            sentences.push({
                answerText: answerText.trim(),
                questionText: questionText.trim().replace(answerText.trim(), '__'),
            });
        });

        const result = questionCountLimit ? sentences.slice(questionCountLimit) : sentences;

        console.log(result);

        return result;
    };

    const parseResultsMultipleChoice = (results: string) => {
        const blocks = results.split(/\n\s*\n/); // Split the text into blocks using blank lines as separators

        const parsedBlocks = blocks.map((block: string) => {
            const questionMatch = block.match(/^.+/);
            const questionText = questionMatch ? questionMatch[0].trim() : '';

            const choicesRegex = /[A-D]\)\s(.*)(?=(?:\n(?!答案：)[A-D])|\n答案：)/g;
            const choicesMatch = block.match(choicesRegex);
            const choices = choicesMatch ? choicesMatch.map((choice) => choice.replace(/[A-D]\)\s/, '').trim()) : [];

            const answerRegex = /答案：([A-D])\)/;
            const answerMatch = block.match(answerRegex);
            const answerIndex = answerMatch ? answerMatch[1].charCodeAt(0) - 'A'.charCodeAt(0) : -1;

            return { questionText, choices, answerIndex };
        });

        // console.log(parsedBlocks);
        return parsedBlocks;
    };

    const getTextFromServerGeneric = async (questionType: string, questionPrompt: string, isRegenerate: boolean, questionCountLimit?: number) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genTextGeneric({
                variables: { questionPrompt },
            });

            let sentences = undefined;
            if (questionType == 'fillInTheBlank') {
                if (questionCountLimit) {
                    sentences = parseResultsFillInTheBlank(result.data.generateTextGeneric.replyText, questionCountLimit);
                } else {
                    sentences = parseResultsFillInTheBlank(result.data.generateTextGeneric.replyText);
                }

                // console.log('Finished Parsing');
                //"1. 智能筆盒也被稱為__筆盒。\n聰明\n2. 我的聰明筆盒外觀像__，卻只有它的十分之一大小。\n黑色鞋盒\n3. 聰明筆盒的功能不止__和關蓋，還能為你削鉛筆，用螢幕操作計算機及看串流影片等。\n開蓋和關蓋"

                UpdateQuestionArrayInfoFillInTheBlank(sentences ?? []);
            } else if (questionType == 'multipleChoice') {
                let blocks = parseResultsMultipleChoice(result.data.generateTextGeneric.replyText);
                // 1. 這個筆盒的尺寸是多少？
                // A) 十厘米長、五厘米闊、三厘米高
                // B) 十厘米長、十厘米闊、五厘米高
                // C) 二十厘米長、十厘米闊、五厘米高
                // D) 二十厘米長、五厘米闊、十厘米高
                // 答案：C) 二十厘米長、十厘米闊、五厘米高
                // UpdateQuestionArrayInfoMultipleChoice(blocks ?? []);
            }
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const UpdateQuestionArrayInfoFillInTheBlank = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentFillInTheBlankQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                answerText: question.answerText,
                questionText: question.questionText,
                sort: 0,
            });
            i++;
        }
        itemContent.questions = newQuestion;
    };

    const UpdateQuestionArrayInfo = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentFillInTheBlankQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                answerText: question.answerText,
                questionText: question.questionText,
                sort: 0,
            });
            i++;
        }
        itemContent.questions = newQuestion;
    };
    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);
        itemContent.questions = newQuestion;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            answerText: '',
            questionText: '',
            sort: 0,
        });
    };
    const questionFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].questionText = textContent;
    };
    const answerFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].answerText = textContent;
    };
    return {
        getTextFromServer,
        getTextFromServerGeneric,
        deleteQuestionArrayInfo,
        questionFieldChange,
        answerFieldChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
    };
};
