import { REQUEST_TEACHER_ASSIGN_MULTIPLE_LABEL } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';
import { requestClientList } from './requestClientList';

export type AssignAction = '' | 'change' | 'delete';

export type AssignLabelRequest = {
    studentName: string;
    socketId: string;
    studentProfileId: string;
    studentNumber: string;
    profilePicURL: string;
    action: AssignAction;
};

export const requestAssignMultipleLabel = (props: { socket: Socket | null; labels: AssignLabelRequest[] }) => {
    if (props.socket == null) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';
    const assignData: { token: string; labels: AssignLabelRequest[] } = {
        token: token,
        labels: props.labels,
    };
    props.socket?.emit(REQUEST_TEACHER_ASSIGN_MULTIPLE_LABEL, assignData, () => {
        // update client list after assigning labels
        requestClientList(props.socket);
    });
};
