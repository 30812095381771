import { PDFDocument } from 'pdf-lib';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import Blob from 'blob';

export const useLessonAddExportSlidesToPDF = () => {
    const handleExportSlidesToPDF = async () => {
        const slideElements = Array.from(document.querySelectorAll("[data-testid='slide-preview']"));

        if (slideElements.length === 0) {
            console.error('No slides found');
            return;
        }

        const mergedPdf = await PDFDocument.create();
        const scale = 8;

        // Store the original transform values for each slide
        const originalTransformValues = slideElements.map((slideElement) => {
            return (slideElement as HTMLElement).style.transform;
        });

        for (let i = 0; i < slideElements.length; i++) {
            const slideElement = slideElements[i] as HTMLElement;

            const slideWidth = slideElement.clientWidth * scale;
            const slideHeight = slideElement.clientHeight * scale;
            const slideWidthPt = (slideWidth * 72) / 96;
            const slideHeightPt = (slideHeight * 72) / 96;

            slideElement.style.transform = `scale(8)`;
            slideElement.style.transformOrigin = 'top left';
            slideElement.style.width = `${slideElement.clientWidth}px`;
            slideElement.style.height = `${slideElement.clientHeight}px`;

            // Clone the slideElement
            const pdf = new jsPDF({
                orientation: 'l',
                unit: 'pt',
                format: [slideWidthPt, slideHeightPt],
            });

            const img = await domtoimage.toPng(slideElement, {
                width: slideWidth,
                height: slideHeight,
                cacheBust: true,
                cacheDisable: true,
            });

            pdf.addImage(img, 'PNG', 0, 0, slideWidthPt, slideHeightPt);

            const pdfBytes = pdf.output('arraybuffer');
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const [page] = await mergedPdf.copyPages(pdfDoc, [0]);
            mergedPdf.addPage(page);
        }

        const mergedPdfBytes = await mergedPdf.save();
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'slides.pdf';
        link.click();

        // Reset the original transform values for all slides
        for (let i = 0; i < slideElements.length; i++) {
            const slideElement = slideElements[i] as HTMLElement;
            slideElement.style.transform = originalTransformValues[i];
            slideElement.style.transformOrigin = 'top left';
        }
    };

    // Utility function to convert a stream to a Blob
    async function streamToBlob(stream: ReadableStream<Uint8Array>): Promise<Blob> {
        const reader = stream.getReader();
        const chunks: Uint8Array[] = [];

        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            if (value) {
                chunks.push(value);
            }
        }

        return new Blob(chunks);
    }

    return { handleExportSlidesToPDF };
};
