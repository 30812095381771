import { REQUEST_TEACHER_END_LESSON } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';

export const requestEndLesson = (socket: Socket | null, callback: (response: boolean) => void) => {
    if (!socket) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';

    socket?.emit(REQUEST_TEACHER_END_LESSON, { token }, callback);
};
