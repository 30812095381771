import { Outlet } from 'react-router-dom';
import SideMenu from 'components/sideMenu/SideMenu';

export const RootPage = () => {
    return (
        <>
            <SideMenu>
                <Outlet />
            </SideMenu>
        </>
    );
};
