import { gql, useMutation } from '@apollo/client';
import useLessonRefresh from './common/useLessonRefresh';

const SAVE_SLIDE_GQL = gql`
    mutation save($data: [SlideUpdateArgs!]!) {
        updateSlides(data: $data) {
            id
        }
    }
`;

const ADD_SLIDE_GQL = gql`
    mutation CreateSlide($lessonVersionID: ID!) {
        createSlide(data: { lessonVersion: { connect: { id: $lessonVersionID } } }) {
            id
        }
    }
`;

const UPDATE_ONE_SLIDE_GQL = gql`
    mutation save($data: JSON!, $slideID: ID!) {
        updateSlide(data: { data: $data }, where: { id: $slideID }) {
            id
        }
    }
`;
// const UPDATE_ONE_SLIDE_GQL = gql`
//   mutation save($data: JSON!, $slideID: ID!, $file: Upload!) {
//     updateSlide(
//       data: { data: $data, thumbnail: { upload: $file } }
//       where: { id: $slideID }
//     ) {
//       id
//     }
//   }
// `;

const DELETE_GQL = gql`
    mutation delete($slideID: ID!) {
        deleteSlide(where: { id: $slideID }) {
            id
        }
    }
`;

export default function useLessonSlideMutation() {
    const { refreshLesson } = useLessonRefresh();

    const [doUpdateSorting] = useMutation(SAVE_SLIDE_GQL);
    const [addSlideMutation] = useMutation(ADD_SLIDE_GQL);
    const [updateOneSlide] = useMutation(UPDATE_ONE_SLIDE_GQL);
    const [deleteForm] = useMutation(DELETE_GQL);

    const deleteSlide = async (lessonID: string, slideID: string) =>
        new Promise(async (resolve, reject) => {
            try {
                await deleteForm({
                    variables: {
                        slideID: slideID ?? '',
                    },
                });

                refreshLesson(lessonID);
                resolve('');
            } catch (err) {
                console.log(err);
                reject(err);
            }
        });

    return {
        doUpdateSorting,
        addSlideMutation,
        updateOneSlide,
        deleteSlide,
    };
}
