import Draggable from 'react-draggable';
import { useContext } from 'react';
import { RightDot } from './styles';
import { SlideComponent } from 'models/lesson/LessonModel';
import useLessonSlide, { baseWidth } from '../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../contexts/SlidePreviewContext';

let tempItemWidth = 0;
export const DraggableRightDot = ({ item }: { item: SlideComponent }) => {
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    return (
        <>
            <RightDot zoom={zoom} sx={{ cursor: 'nwse-resize' }} />
            <Draggable
                axis='x'
                onStart={(e) => {
                    e.stopPropagation();
                    tempItemWidth = item.size.width;
                }}
                onDrag={(e, position) => {
                    let newX = position.x / zoom;
                    let finalWidth = tempItemWidth + newX;
                    const minWidth = 50;
                    if (finalWidth < minWidth) {
                        finalWidth = minWidth;
                    }

                    if (newX !== 0) {
                        item.size.width = finalWidth;

                        if (item.size.width > baseWidth) {
                            item.pos.x = 0;
                            item.pos.y = 0;
                            item.size.width = baseWidth;
                        }
                        updateItemProperties(
                            {
                                ...item,
                            },
                            false,
                        );
                    }
                }}
                onStop={(e) => {
                    updateItemProperties(
                        {
                            ...item,
                        },
                        true,
                    );
                }}
                position={{ x: 0, y: 0 }}>
                <RightDot
                    zoom={zoom}
                    sx={{
                        cursor: 'nwse-resize',
                        backgroundColor: 'transparent',
                    }}
                />
            </Draggable>
        </>
    );
};
