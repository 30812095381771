import { Box, Grid, Stack, Typography } from '@mui/material';

import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentMatching } from 'models/lesson/LessonModel';
import { useTranslation } from 'react-i18next';

export const SlideMatching = ({ item }: { item: SlideComponent; index: number }) => {
    const { t } = useTranslation();
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentMatching = item.content as SlideComponentMatching;

    return (
        <Box
            sx={{
                position: 'absolute',
                zoom: zoom,
                aspectRatio: 'auto',
                pointerEvents: 'none',
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
                m: 'inherit',
                p: 'inherit',
            }}>
            <Typography sx={{ color: '#533D18DE', textAlign: 'left' }}>{t('matching.instruction')}</Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {itemContent.questions.map((question, qIdx) => {
                    return (
                        <Grid item sx={{ width: 400 }} key={qIdx}>
                            <Stack>
                                <Typography
                                    sx={{
                                        width: 'fit-content',
                                        borderRadius: 2,
                                        display: 'inline-block',
                                        m: 1,
                                        backgroundColor: 'red',
                                        p: 1,
                                        pl: 2,
                                        pr: 2,
                                        color: 'white',
                                    }}>
                                    {qIdx}
                                </Typography>
                                <Box
                                    component='img'
                                    src={question.imageURL && question.imageURL !== '' ? question.imageURL : `/characters/${question.image !== '' ? question.image + '/image.png' : 'default.png'}`}
                                    alt='bg'
                                    sx={{
                                        alignSelf: 'center',
                                        height: 150,
                                        maxWidth: 300,
                                        objectFit: 'contain',
                                        transform: `scaleX(${question.flip ?? 1})`,
                                        pointerEvents: 'none',
                                    }}
                                />
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
            <Stack direction='row' sx={{ mt: 6 }}>
                {itemContent.questions &&
                    [...itemContent.questions]
                        .sort((a, b) => a.sort - b.sort)
                        .map((question, qIdx) => {
                            return (
                                <Stack direction='row' key={qIdx}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: 1,
                                            borderColor: '#533D18DE',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 1,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            color: '#533D18DE',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 2,
                                        }}>
                                        {question.answer}
                                    </Box>
                                </Stack>
                            );
                        })}
            </Stack>
        </Box>
    );
};
