/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Grid, Box, Divider, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useWorksheetHook from './hooks/useWorksheetHook';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import Blob from 'blob';
import { useCreateGoogleDoc, useAppendGoogleDoc } from './utils/GoogleDocBridge';

import { WorksheetAddPopup } from './components/dataComponents/adds/WorksheetAddPopup';
import { WorksheetsTitle } from './components/WorksheetsTitle';
import { WorksheetDelete } from './WorksheetDelete';
import { LessonsAddProps } from './components/LessonsAddProps';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import ShareIcon from '@mui/icons-material/Share';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { pinyinFunction, jyutpingFunction, appendJyutpingFunction } from './components/dataComponents/props/functions/textCommonFunction';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { enqueueSnackbar } from 'notistack';

export const WorksheetsAddPage = () => {
    const [googleDocId, setGoogleDocId] = useState<string | null>(null);

    const { t } = useTranslation();
    let { id: worksheetID } = useParams();
    const navigate = useNavigate();
    const { refreshOrCreateWorksheet, loading, worksheetData } = useWorksheetHook();
    const { accessDenied } = useContext(UserAuthContext);

    const containerRef = useRef<HTMLDivElement>(null);
    const handleCreateGoogleDoc = useCreateGoogleDoc();
    const handleAppendGoogleDoc = useAppendGoogleDoc();

    const handleSaveWorksheet = (saveData: any) => {
        // Call the mutation function here
        // console.log('handleSaveWorksheet called: ', saveData);
        let htmlContent = '';
        let isAppendJyutping = saveData.isAppendJyutping ? saveData.isAppendJyutping : false;
        //'<!DOCTYPE html><html><head><style>h1 { text-align: center; }</style></head><body>';
        switch (saveData.type) {
            case 'reorderSentence':
                htmlContent += `重組句子\n\n`;
                //htmlContent += '<ol>';

                if ('questions' in saveData.content) {
                    let count = 1;
                    saveData.content.questions.forEach((q: any) => {
                        if ('questionBubbles' in q) {
                            htmlContent += `${count}.\t${q.questionBubbles.join(' / ')}\n\n`;
                            htmlContent += '\t____________________________________________\n\n\n'; // Add a line of underscores
                        }
                        count++;
                    });
                }

                // htmlContent += '</ol>';
                // htmlContent += `<br>`;
                break;
            case 'fillInTheBlank':
                htmlContent += `填充題\n\n`;

                let answerTexts: string[] = [];

                // Collect answerText values

                if ('questions' in saveData.content) {
                    saveData.content.questions.forEach((q: any) => {
                        if ('answerText' in q) {
                            answerTexts.push(q.answerText);
                        }
                    });
                }

                // Shuffle the answerTexts array
                answerTexts.sort(() => Math.random() - 0.5);

                // Append answerText values in a table
                const columns = 4;
                const cellWidth = 100 / columns;
                // htmlContent += `<table style="width: 100%; table-layout: fixed;">`;
                for (let i = 0; i < answerTexts.length; i++) {
                    htmlContent += answerTexts[i] + '\t\t';

                    //   if (i % columns === 0) {
                    //     htmlContent += `<tr>`;
                    //   }
                    //   htmlContent += `<td style="width: ${cellWidth}%; padding: 5px; text-align: center;">${answerTexts[i]}</td>`;
                    //   if (i % columns === columns - 1 || i === answerTexts.length - 1) {
                    //     htmlContent += `</tr>`;
                    //   }
                }
                // htmlContent += `</table>`;

                // Append questionText values
                // htmlContent += `<br>`;
                // htmlContent += '<ol>';
                htmlContent += '\n\n';
                if ('questions' in saveData.content) {
                    let count = 1;
                    saveData.content.questions.forEach((q: any) => {
                        if ('questionText' in q) {
                            htmlContent += `${count}.\t${q.questionText}\n\n`;
                        }
                        count++;
                    });
                }

                // htmlContent += '</ol>';
                // htmlContent += `<br>`;
                break;
            case 'punctuationMark':
                htmlContent += `標點符號\n\n`;
                // htmlContent += '<ol>';

                if ('questions' in saveData.content) {
                    let count = 1;
                    saveData.content.questions.forEach((q: any) => {
                        if ('question' in q) {
                            htmlContent += `${count}.\t${q.question}\n\n`;
                        }
                        count++;
                    });
                }

                // htmlContent += '</ol>';
                // htmlContent += `<br>`;
                break;
            case 'readingComprehension':
                htmlContent += `閱讀理解\n\n`;
                // htmlContent += '<ol>';
                if ('articleText' in saveData.content) {
                    htmlContent += saveData.content.articleText + '\n\n';
                }
                if ('itemContent_fillInTheBlank' in saveData.content) {
                    htmlContent += `填充題\n\n`;
                    let answerTexts: string[] = [];
                    let curItemContent = saveData.content.itemContent_fillInTheBlank;

                    if ('questions' in curItemContent) {
                        curItemContent.questions.forEach((q: any) => {
                            if ('answerText' in q) {
                                answerTexts.push(q.answerText);
                            }
                        });
                    }

                    // Shuffle the answerTexts array
                    answerTexts.sort(() => Math.random() - 0.5);

                    // Append answerText values in a table
                    const columns = 4;
                    const cellWidth = 100 / columns;
                    for (let i = 0; i < answerTexts.length; i++) {
                        htmlContent += answerTexts[i] + '\t\t';
                    }

                    htmlContent += '\n\n';
                    if ('questions' in curItemContent) {
                        let count = 1;
                        curItemContent.questions.forEach((q: any) => {
                            if ('questionText' in q) {
                                htmlContent += `${count}.\t${q.questionText}\n\n`;
                            }
                            count++;
                        });
                    }
                }
                // if ('questions' in saveData.content) {
                //   let count = 1;
                //   saveData.content.questions.forEach((q: any) => {
                //     if ('question' in q) {
                //       htmlContent += `${count}.\t${q.question}\n\n`;
                //     }
                //     count++;
                //   });
                // }

                if ('itemContent_fillInTheBlank' in saveData.content) {
                    htmlContent += `選擇題\n\n`;
                    let curItemContent = saveData.content.itemContent_multipleChoice;

                    if ('questions' in curItemContent) {
                        curItemContent.questions.forEach((q: any) => {
                            if ('questionText' in q) {
                                htmlContent += `${q.questionText}\n`;
                            }
                            let letter = 'A';
                            if ('choices' in q) {
                                q.choices.forEach((c: any) => {
                                    htmlContent += `(${letter}) ${c}\n`;
                                    letter = String.fromCharCode(letter.charCodeAt(0) + 1);
                                });
                            }

                            htmlContent += `\n`;
                        });
                    }
                }
                break;
        }
        // console.log('Text to send to Google Doc: ', htmlContent);
        // console.log('Jyutping: ', JSON.stringify(jp));

        if (googleDocId) {
            handleAppendGoogleDoc(googleDocId, isAppendJyutping ? appendJyutpingFunction(jyutpingFunction(htmlContent)) : htmlContent);
        }
        // mutateGoogleDoc(saveData);
    };

    useEffect(() => {
        if (worksheetID) {
            // console.log(
            //   'inside useEffect of WorksheetsAddPage, worksheetID: ',
            //   worksheetID,
            //   worksheetData,
            // );
            refresh();
        }
    }, [worksheetID]);

    const refresh = async () => {
        if (worksheetID) {
            try {
                const { worksheetId, googleDocId } = await refreshOrCreateWorksheet(
                    //        const id = await refreshOrCreateWorksheet(
                    worksheetID,
                    handleCreateGoogleDoc,
                );
                if (googleDocId) {
                    setGoogleDocId(googleDocId);
                }
                if (worksheetId) {
                    // navigate('/worksheets');
                    navigate('/worksheets/' + worksheetId, { replace: true });
                } else {
                    navigate('/worksheets');
                }
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    enqueueSnackbar(errorMsg, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }

                // if (!accessDenied(String(err))) {
                //   navigate('/worksheets');
                // }
            }
        }
    };

    // Utility function to convert a stream to a Blob
    async function streamToBlob(stream: ReadableStream<Uint8Array>): Promise<Blob> {
        const reader = stream.getReader();
        const chunks: Uint8Array[] = [];

        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            if (value) {
                chunks.push(value);
            }
        }

        return new Blob(chunks);
    }

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <PopupCircularProgress isOpened={loading} />
            <Box
                sx={{
                    bgcolor: '#FFFBF6',
                    boxShadow: 4,
                    borderRadius: '24px',
                    display: 'flex',
                    height: '100vh',
                    flexDirection: 'column',
                }}>
                <Box>
                    <Grid container>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Box justifyContent='center' textAlign='center'>
                                <WorksheetsTitle worksheetID={worksheetID ?? ''} value={worksheetData?.worksheetName ?? ''} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ p: 2, mt: -1 }}>
                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                }}
                                startIcon={<ArrowBackIosIcon />}
                                href='/worksheets'>
                                {t('overview-of-worksheets')}
                            </Button>
                        </Grid>
                        <Grid item container justifyContent='flex-end' xs={6}>
                            {worksheetID ? <WorksheetDelete worksheetID={worksheetID} /> : ''}

                            {/* Google Doc integration */}
                            {/* <Button
                onClick={handleCreateGoogleDoc}
                variant='outlined'
                color='inherit'
                sx={{ textTransform: 'none', borderRadius: '0px', ml: 1 }}
                startIcon={<FileDownloadIcon />}>
                {t('export-google-doc')}
              </Button> */}
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='center' xs={12} sx={{ mt: -1, pb: 1 }}>
                        <Box>
                            <WorksheetAddPopup onSave={handleSaveWorksheet} type='reorderSentence' text={t('reorder-sentence')} iconPath='../../icons/icon-ai.png' />
                            <WorksheetAddPopup onSave={handleSaveWorksheet} type='fillInTheBlank' text={t('fillInTheBlank.title')} iconPath='../../icons/icon-ai.png' />
                            <WorksheetAddPopup onSave={handleSaveWorksheet} type='punctuationMark' text={t('punctuationMark')} iconPath='../../icons/icon-ai.png' />
                            <WorksheetAddPopup
                                onSave={handleSaveWorksheet}
                                type='readingComprehension'
                                text={t('readingComprehension')}
                                iconPath='../../icons/icon-ai.png'
                                fullWidth={true}
                                maxWidth={'xl'}
                            />
                        </Box>
                    </Grid>
                </Box>
                <Divider sx={{ border: 1, borderColor: '#68522B' }} />

                <Grid
                    container
                    sx={{
                        height: '100%',
                    }}>
                    <Grid ref={containerRef} item xs={8} sm={8} md={8} lg={8} xl={8} sx={{ pl: 1, position: 'relative' }}>
                        <iframe src={`https://docs.google.com/document/d/${googleDocId}`} width='1000' height='700' title='Embedded Google Doc' />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <LessonsAddProps />
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};
