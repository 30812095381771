import { ApolloError } from '@apollo/client';
import { t } from 'i18next';

export const ERROR_TYPE = {
    NO_DATA: 'NO_DATA',
    UPDATE_FAILED: 'UPDATE_FAILED',
    FIELD_DUPLICATE: 'FIELD_DUPLICATE',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    KS_ACCESS_DENIED: 'KS_ACCESS_DENIED',
    NETWORK_ERROR: 'NETWORK_ERROR',
    KS_VALIDATION_FAILURE: 'KS_VALIDATION_FAILURE',
    TOKEN_EXPIRED_ERROR: 'TokenExpiredError',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export class TTError extends Error {
    constructor(message?: string) {
        super(message);
    }
}

export const handleException = (error: any): string => {
    if (error instanceof ApolloError) {
        if (error.graphQLErrors[0]?.extensions?.code === 'KS_PRISMA_ERROR') {
            //@ts-ignore
            if (error.graphQLErrors[0]?.extensions?.prisma?.code === 'P2002') {
                return ERROR_TYPE.FIELD_DUPLICATE;
            }
            return ERROR_TYPE.INTERNAL_SERVER_ERROR;
        } else if (error.graphQLErrors[0]?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
            if (error.graphQLErrors[0].message !== '') {
                return t(`error.${error.graphQLErrors[0].message}`);
            } else {
                return t(`error.${ERROR_TYPE.UNKNOWN_ERROR}`);
            }
        } else if (error.graphQLErrors[0]?.extensions?.code === 'KS_ACCESS_DENIED') {
            return ERROR_TYPE.KS_ACCESS_DENIED;
        } else if (error.networkError?.message !== '') {
            return ERROR_TYPE.NETWORK_ERROR;
        } else if (error.graphQLErrors[0].extensions.code === 'KS_VALIDATION_FAILURE') {
            return ERROR_TYPE.KS_VALIDATION_FAILURE;
        }
    } else if (error instanceof TTError) {
        return t(`error.${error.message}`);
    } else {
        return t(error);
    }

    return ERROR_TYPE.UNKNOWN_ERROR;
};
