import { Box, IconButton, TextField } from '@mui/material';
import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type FillInTheBlankProps = {
    id: string;
    index: number;
    questionText: string;
    answerText: string;
    onTextQuestionFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    onTextAnswerFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    deleteItemChange: (idx: number) => void;
};
export const FillInTheBlankQuestionComponent: React.FC<FillInTheBlankProps> = (props) => {
    const { t } = useTranslation();

    const [deleteBubble, setDeleteBubble] = useState(false);

    return (
        <Box
            sx={{
                backgroundColor: '#F7EEDC',
                padding: 2,
                borderRadius: '12px',
                position: 'relative',
            }}>
            <Box sx={{ color: '#533D18', fontSize: 14 }}>{t('ui.question-num').replace('{num}', `${props.index + 1}`)}</Box>
            <Box sx={{ color: '#533D18', fontSize: 9 }}>id:{props.id}</Box>
            {!deleteBubble && (
                <IconButton // add an icon button for delete
                    sx={{
                        position: 'absolute', // make the button position absolute
                        top: '20px',
                        right: '10px',
                        width: '14px',
                        height: '18px',
                    }}
                    onClick={() => {
                        setDeleteBubble(true);
                    }}>
                    <DeleteIcon />
                </IconButton>
            )}
            {deleteBubble && (
                <>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '10px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            setDeleteBubble(false);
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '40px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            props.deleteItemChange(props.index);
                        }}>
                        <DoneIcon />
                    </IconButton>
                </>
            )}

            <Box sx={{ color: '#533D18', fontSize: 12 }}>{t('ui.question')}：</Box>

            <TextField
                type='string'
                placeholder='____可以觀察遠處的景象。'
                sx={{ width: '100%', marginBottom: 1, color: '#533D18' }}
                value={props.questionText}
                onChange={(e) => {
                    props.onTextQuestionFieldChange(props.index, e.target.value);
                }}
            />
            <Box sx={{ color: '#533D18', fontSize: 12 }}>答案：</Box>

            <TextField
                type='string'
                placeholder='望遠鏡'
                sx={{ width: '100%', marginBottom: 1, color: '#533D18' }}
                value={props.answerText}
                onChange={(e) => {
                    props.onTextAnswerFieldChange(props.index, e.target.value);
                }}
            />
        </Box>
    );
};

export function shuffleArray(array: string[]) {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
}
