import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Draggable from 'react-draggable';
import { useContext } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentMultipleChoice } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataMultipleChoice = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentMultipleChoice = item.content as SlideComponentMultipleChoice;

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <strong
                style={{
                    cursor: 'move',
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                }}>
                <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<LibraryAddCheckIcon />} title={t('multipleChoice')} resize={true} />
                <Box
                    sx={{
                        borderRadius: 3,
                        backgroundColor: '#fff',
                        p: '18px',
                        color: '#533D18',
                        boxShadow: 2,
                        zoom: zoom,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}>
                    {itemContent.questions.map((question, qIdx) => (
                        <span key={qIdx}>
                            {t('ui.question-num').replace('{num}', `${qIdx + 1}`)}
                            {':\t'} {question.questionText}
                        </span>
                    ))}
                </Box>
            </strong>
        </Draggable>
    );
};
