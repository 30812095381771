import { UserAuthContext } from 'contexts/UserAuthContext';
import { SchoolModel } from 'models/school/SchoolModel';
import { useSchoolQuery } from 'models/school/useSchoolQuery';
import { useContext, useEffect, useState } from 'react';

export const useSchoolHook = () => {
    const auth = useContext(UserAuthContext);
    const { fetchSchool } = useSchoolQuery();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        try {
            fetchSchool({ userID: auth.userData?.id ?? '' });
        } catch (err) {}
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (value: SchoolModel) => {
        handleClose();
        auth.setSelectedSchool(value);
        window.location.reload();
    };

    return {
        open,
        handleClickOpen,
        handleClose,
        handleSelect,
    };
};
