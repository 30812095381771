import { Box, IconButton, Stack, TextField } from '@mui/material';
import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

type ReorderSentenceProps = {
    id: string;
    index: number;
    questionText: string;
    questionBubbles: string[];
    onTextFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    onDragChange: (idx: number, textContent: string[], isDrag: boolean) => void;
    deleteItemChange: (idx: number) => void;
};
export const ReorderQuestionComponent: React.FC<ReorderSentenceProps> = (props) => {
    const [deleteBubble, setDeleteBubble] = useState(false);
    const { t } = useTranslation();

    const handleOnDragEnd1 = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(props.questionBubbles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        props.onDragChange(props.index, items, true);
    };

    const randomDistribution = () => {
        const items = Array.from(props.questionBubbles);
        shuffleArray(items);
        props.onDragChange(props.index, items, false);
    };

    return (
        <Box
            sx={{
                backgroundColor: '#F7EEDC',
                padding: 2,
                borderRadius: '12px',
                position: 'relative',
            }}>
            <Box sx={{ color: '#533D18', fontSize: 14 }}>{t('ui.question-num').replace('{num}', `${props.index + 1}`)}</Box>
            <Box sx={{ color: '#533D18', fontSize: 9 }}>id:{props.id}</Box>
            {!deleteBubble && (
                <IconButton // add an icon button for delete
                    sx={{
                        position: 'absolute', // make the button position absolute
                        top: '20px',
                        right: '10px',
                        width: '14px',
                        height: '18px',
                    }}
                    onClick={() => {
                        setDeleteBubble(true);
                    }}>
                    <DeleteIcon />
                </IconButton>
            )}
            {deleteBubble && (
                <>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '10px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            setDeleteBubble(false);
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '40px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            props.deleteItemChange(props.index);
                        }}>
                        <DoneIcon />
                    </IconButton>
                </>
            )}
            <Box sx={{ color: '#533D18', fontSize: 12 }}>答案：</Box>

            <TextField
                // label='Questions'
                type='string'
                placeholder='小明/上/廣州'
                sx={{ width: '100%', marginBottom: 1, color: '#533D18' }}
                value={props.questionText}
                onChange={(e) => {
                    props.onTextFieldChange(props.index, e.target.value);
                    // if (handleCreate) {
                    //   handleCreate(item);
                    // } else {
                    //   slideComponentHandler(item, false);
                    // }
                }}
                // onBlur={(e) => {
                //   if (handleCreate) {
                //     handleCreate(item);
                //   } else {
                //     slideComponentHandler(item, true);
                //   }
                // }}
            />
            <Box sx={{ color: '#533D18', fontSize: 12 }}>{t('ui.question')}：</Box>

            <DragDropContext onDragEnd={handleOnDragEnd1}>
                <Droppable droppableId={props.id} direction='horizontal' type='QUESTION' isCombineEnabled={true}>
                    {(provided) => (
                        <Stack
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            direction='row'
                            flexWrap='wrap'
                            justifyContent='flex-start' // align bubbles to the left
                            spacing={0.5}>
                            {props.questionBubbles.length > 1 &&
                                props.questionBubbles.map((text, index) => (
                                    <Draggable key={index + 'question12'} draggableId={index + 'question'} index={index}>
                                        {(provided) => (
                                            <Box
                                                key={index + 'question1112'}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    color: '#533D18',
                                                    backgroundColor: 'white',
                                                    borderRadius: '5px',
                                                    border: '1px solid grey',
                                                    width: 'auto',
                                                    px: 1,
                                                    py: 0.5,
                                                    height: '48px', // set a fixed height
                                                    marginBottom: 1,
                                                    textAlign: 'center', // add this line
                                                    display: 'flex', // enable flexbox layout
                                                    alignItems: 'center', // vertically center the text
                                                }}>
                                                {text}
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={randomDistribution}
                    sx={{
                        width: '14px',
                        height: '18px',
                    }}>
                    <RefreshIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export function shuffleArray(array: string[]) {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
}
