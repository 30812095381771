import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LessonsShareWithPublicPath } from 'routes/utils';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const LessonsNavBar = () => {
    const { t } = useTranslation();
    const [lessonType, setLessonType] = useState(0);
    // const [value, setValue] = React.useState(0);

    const handleLessonTypeChange = (event: React.SyntheticEvent, newValue: number) => {
        console.log('handleLessonTypeChange: ', newValue);
        setLessonType(newValue);
    };

    const NavBarLink = (props: { path: string; label: string; index: number }) => {
        // console.log('NavBarLink: ', props.index);
        // setLessonType(props.index);

        const location = useLocation();
        if (location.pathname === props.path) {
            setLessonType(props.index);
        }
        return location.pathname === props.path ? (
            <Tab label={props.label} {...a11yProps(props.index)} sx={{ fontWeight: 'bold', fontSize: '1.5rem' }} />
        ) : (
            <Link to={props.path} style={{ display: 'inline-block', textDecoration: 'none' }}>
                <Tab
                    label={props.label}
                    {...a11yProps(props.index)}
                    sx={{
                        color: '#CF9528',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                    }}
                />
            </Link>
        );
    };

    return (
        <>
            <nav>
                <Tabs variant='fullWidth' value={lessonType} onChange={handleLessonTypeChange} aria-label='public private lessons'>
                    <NavBarLink path={'/' + LessonsShareWithPublicPath} label={t('menu-lesson-public')} index={0} />
                    <NavBarLink path='/lessons' label={t('menu-lesson-private')} index={1} />
                </Tabs>
            </nav>
        </>
    );
};
