import { useContext } from 'react';
import { WorksheetContext } from '../contexts/WorksheetContext';
import useWorksheetMutation from './useWorksheetMutation';

export default function useWorksheetHook() {
    const { loading, worksheetData } = useContext(WorksheetContext);

    //const { refreshOrCreateWorksheet, refreshWorksheet } = useWorksheetMutation();
    const { refreshOrCreateWorksheet } = useWorksheetMutation();

    return {
        refreshOrCreateWorksheet,
        // refreshWorksheet,
        worksheetData,
        loading,
    };
}
