import { useMutation, useLazyQuery } from '@apollo/client';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { AnswerCard, AnswerCardDataProps } from 'components/AnswerCard';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import moment from 'moment';
import { AnswerAccordion } from 'pages/results/component/AnswerAccordion';
import OverallStudentGradingView from 'pages/results/component/GradingPage/OverallStudentGradingView';
import StudentHeader from 'pages/results/component/GradingPage/StudentHeader';
import { GradingStatus } from 'pages/results/component/GradingStatus';
import { PrintResultAnswerCard } from 'pages/results/component/PrintResultAnswerCard/PrintResultAnswerCard';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import { ResultsPageContext, Results } from 'pages/results/models';
import { UPDATE_RESULTS, GET_RESULTS_BY_TESTROOM_AND_STUDENT } from 'pages/results/queries';
import { GradingType, GameType, gradeVariants, gradingTypeStr } from 'pages/results/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SelectClassPath, SelectTestroomPath, SelectStudentPath, SelectQuestionPath, GradingPath } from 'routes/utils';
const handlePrint = () => {
    // const printWindow = window.open('', '_blank');

    window.print();
};

const PrintResultPage = () => {
    const navigator = useNavigate();
    const { selectedTestRoom, selectedStudent, selectedQuestion, gradedItems, setGradedItems } = useOutletContext<ResultsPageContext>();
    const { sortedStudentData } = useTestroomResult();
    const onSaveGrading = async (item: any[]) => {
        const data = item
            ?.map((item: any) => {
                return item.items.map((result: any) => {
                    return {
                        where: { id: result.id },
                        data: { grading: item.grade },
                    };
                });
            })
            .flat();
        try {
            setUpdateResultLoading(true);
            const result = await updateResults({
                variables: {
                    data: data,
                },
            });
            setUpdateResultLoading(false);

            if (result.data) {
                // handleClickOpen();
            }
        } catch (error) {
            console.log('error:', error);
        }
    };
    const [loadGradingPage, setLoadGradingPage] = useState(false);
    const { schoolId, classId, testroomId, studentId, questionIndex } = useParams();

    const [updateResults, { loading, error }] = useMutation(UPDATE_RESULTS);
    const [updateResultLoading, setUpdateResultLoading] = useState(false);
    const [answersData, setAnswersData] = useState<AnswerCardDataProps[]>([]);
    const [expandedControl, setExpandedControl] = useState<{ accordionId: string; isExpanded: boolean }[]>([]);
    const [score, setScore] = useState('');
    const [rank, setRank] = useState('');
    const [time, setTime] = useState('');
    const [unattempted, setUnattempted] = useState('');
    const [getStudentResults, { loading: resultsLoading }] = useLazyQuery(GET_RESULTS_BY_TESTROOM_AND_STUDENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            testroomId: { equals: testroomId },
            studentId: { equals: studentId },
        },
    });
    const { t } = useTranslation();

    const onGrading = (items: any[], grade: GradingType) => {
        setGradedItems((prevState: any[]) => [{ componentID: items[0].componentId, items: items, grade: grade }]);
        onSaveGrading([{ componentID: items[0].componentId, items: items, grade: grade }]);
        setExpandedControl((prevState) => {
            let newArr = [...prevState];
            const newState = newArr.map((controlObj) => {
                if (controlObj.accordionId === items[0].componentId) {
                    return {
                        accordionId: items[0].componentId,
                        isExpanded: false,
                    };
                } else {
                    return controlObj;
                }
            });
            return newState;
        });
    };
    const prepareStudentResults = async () => {
        try {
            if (selectedTestRoom.id && selectedStudent.id) {
                setLoadGradingPage(true);
                const { data: studentResults } = await getStudentResults();
                prepareAnswers(studentResults?.results ?? []);
                setLoadGradingPage(false);
            }
        } catch (error) {
            console.log('prepareStudentResults', error);
        }
    };
    useEffect(() => {
        if (answersData.length > 0) {
            setExpandedControl(
                answersData.map((item) => {
                    return { accordionId: item.questionID, isExpanded: true };
                }),
            );
            if (selectedQuestion || questionIndex) {
                const accordion = document?.getElementById(`answerAccordion-${selectedQuestion ?? questionIndex}`);
                accordion?.scrollIntoView();
            }
        }
    }, [answersData, selectedQuestion]);

    const prepareAnswers = (results: Results[]) => {
        const answersTimeSec = results.reduce((prevValue, answer) => prevValue + moment(answer?.endTime).diff(moment(answer?.startTime)), 0);
        const answers = selectedTestRoom?.lessonVersion?.lessonQuestions?.map((question, index) => {
            let questionName;
            if (isNullOrEmpty(question?.content?.phrase)) {
                questionName =
                    typeof question?.content?.character === 'number'
                        ? question?.content?.characterList[question?.content?.character].character
                        : question?.content?.character?.split('/')[1].replace(/[0-9]/g, '');
            } else {
                questionName = question?.content?.phrase;
            }
            const filterItem = results.filter((element: any) => {
                if (element?.componentId === question?.id) {
                    return true;
                } else {
                    return false;
                }
            });
            let grading: boolean = false;
            if (filterItem.length > 0) {
                grading = filterItem?.some((item: any) => item.grading !== 'ungraded');
            }
            const isCorrect = filterItem?.some((item: any) => item.correct) || filterItem?.some((item: any) => item.grading === 'correct');
            let questionType = (question?.content?.gameType as GameType) ?? (question.type as GameType);
            return {
                index: index,
                question: questionName,
                questionID: question.id,
                questionType,
                correct: isCorrect,
                data: filterItem,
                grading: grading,
            };
        });
        setUnattempted(answers?.reduce((prevValue, answer) => prevValue + (answer.data.length === 0 ? 1 : 0), 0).toString());
        // const answersTimeSec = answers?.reduce(
        //   (prevValue, answer) =>
        //     prevValue + moment(answer?.endTime).diff(moment(answer?.startTime)),
        //   0,
        // );
        setAnswersData(answers);
    };
    useEffect(() => {
        if (sortedStudentData) {
            const student = sortedStudentData.find((stud) => stud.id === selectedStudent.id);
            const studentIdx = sortedStudentData.findIndex((stud) => stud.id === selectedStudent.id);
            setScore(student?.totalScore ? student?.totalScore.toString() : '0');
            setTime(student?.totalTestDuration ? student?.totalTestDuration.toString() : '00:00');
            setRank(studentIdx !== -1 ? (studentIdx + 1).toString() + '/' + sortedStudentData.length.toString() : '0');
        }
    }, [sortedStudentData]);
    useEffect(() => {
        if (selectedTestRoom?.lessonVersion?.lessonQuestions?.length > 0 && selectedStudent?.results && selectedStudent?.results?.length > 0) {
            prepareStudentResults();
        }
    }, [selectedStudent, selectedQuestion]);

    return (
        <Dialog fullScreen open={true}>
            <Box
                sx={{
                    '@media print': {
                        width: '210mm',
                        height: '297mm',
                        margin: 0,
                    },
                    border: 1,
                    width: '100%', // Add this
                    height: '100%', // Add this
                    pageBreakAfter: 'always',
                    printColorAdjust: 'exact',
                    WebkitPrintColorAdjust: 'exact',
                }}>
                <Box sx={{ backgroundColor: 'white' }}>
                    {answersData.map((answer, index) => {
                        const gradedItem = gradedItems.find((item: any) => item?.componentID === answer.questionID);
                        let gradeType: GradingType = 'n/a';
                        if (Array.isArray(answer.data)) {
                            if (answer.data.length > 0) {
                                gradeType = 'ungraded';
                                if (answer.questionType === 'Writing') {
                                    if (JSON.parse(answer.data[0].data).writing?.normalMode) {
                                        gradeType = answer.correct ? 'correct' : 'incorrect';
                                    } else {
                                        if (answer.grading) {
                                            gradeType = answer.correct ? 'correct' : 'incorrect';
                                        }
                                    }
                                } else if (answer.questionType === 'Audio') {
                                    if (answer.grading) {
                                        gradeType = answer.correct ? 'correct' : 'incorrect';
                                    }
                                } else if (answer.questionType === 'multipleChoice') {
                                    if (answer.grading) {
                                        gradeType = answer.correct ? 'correct' : 'incorrect';
                                    }
                                } else {
                                    gradeType = answer.correct ? 'correct' : 'incorrect';
                                }
                            }
                        }
                        if (gradedItem) {
                            gradeType = gradedItem?.grade;
                            answer.grading = gradedItem?.grade;
                            answer.correct = gradedItem?.grade === 'correct' ? true : false;
                        }
                        return <Box>{answer.data.length > 0 ? <PrintResultAnswerCard data={answer} onGrading={onGrading} /> : <></>}</Box>;
                    })}
                </Box>

                <Button sx={{ position: 'absolute', top: -10 }} variant='contained' onClick={handlePrint} className='no-print'>
                    Print
                </Button>
            </Box>
        </Dialog>
    );
};

export default PrintResultPage;
function isNullOrEmpty(str: string | undefined): boolean {
    if (str === undefined) {
        return true;
    }
    if (str.trim() === '') {
        return true;
    }
    return false;
}
