import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { ResultTableProps } from 'components/ResultTable';
import React, { useEffect, useState } from 'react';

type StudentNumberProp = {
    student?: number;
};
const StudentNumber = (props: StudentNumberProp) => {
    const [AverageScore, setAverageScore] = useState('');

    return (
        <Card
            sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                height: '100%',
            }}>
            <CardHeader
                title={
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>
                        學生人數
                    </Typography>
                }
            />
            <Divider orientation='horizontal' flexItem />
            <CardContent
                sx={{
                    display: 'flex',
                    padding: '16px 132px',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    gap: '16px',
                }}>
                <StudentIcon />
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                    }}>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '48px',
                        }}>
                        {props.student}
                    </Typography>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                        }}>
                        人
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

const StudentIcon: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: 70,
                height: 70,
                borderRadius: '50%',
            }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='70' height='70' viewBox='0 0 70 70' fill='none'>
                <path
                    d='M35.0001 34.9998C41.4459 34.9998 46.6667 29.779 46.6667 23.3332C46.6667 16.8873 41.4459 11.6665 35.0001 11.6665C28.5542 11.6665 23.3334 16.8873 23.3334 23.3332C23.3334 29.779 28.5542 34.9998 35.0001 34.9998ZM35.0001 40.8332C27.2126 40.8332 11.6667 44.7415 11.6667 52.4998V58.3332H58.3334V52.4998C58.3334 44.7415 42.7876 40.8332 35.0001 40.8332Z'
                    fill='#F05023'
                />
            </svg>
        </Box>
    );
};
export default StudentNumber;
