import { gql } from '@apollo/client';
import { EncryptionAES } from 'utils/function/encryption';
import { Constants } from 'Constants';
import { useState, useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { useQuery, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { UserPermissionContext } from '../contexts/UserPermissionContext';
import { UpgradePlanPopup } from '../routes/UpgradePlanPopup';

const CREATE_USERS_MUTATION = gql`
    mutation CreateUsers($data: [UserCreateInput!]!) {
        createUsers(data: $data) {
            id
            name
        }
    }
`;

function formatDate(date: string): string {
    const parts = date.split('-');
    const day = parts[0].padStart(2, '0');
    const month = parts[1].padStart(2, '0');
    const year = parts[2];
    return `${year}-${month}-${day}`;
}
export const parseCSV = (csvText: string, delimiter: string = ','): { data?: StudentData[]; error?: number } => {
    const lines = csvText.split('\n').filter((line) => line.trim() !== '');
    const headers = lines[0].split(delimiter).map((header) => header.trim().replace(/\r/g, ''));
    const data = [];
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const values = line.split(delimiter);
        const student: any = headers.reduce((obj, header, index) => {
            (obj as any)[header] = values[index];
            return obj;
        }, {} as StudentData);

        // Check if all required fields are present
        if (!student.name || !student.email || !student.password || !student.studentNumber) {
            const missingFields = ['name', 'email', 'password', 'studentNumber'].filter((field) => !student[field]);
            return {
                error: i + 1,
            };
        }

        data.push(student);
    }

    return { data };
};

export const GET_ALL_CLASS_ID = gql`
    query GET_ALL_CLASS_ID {
        classes {
            id
            className
        }
    }
`;
const passwordSecretKey = (Constants.localStorageKeys.password_AES_SecretKey || '').toString();
export interface ClassByTeacher {
    id: string;
    className: string;
}

export interface Classes {
    classes: ClassByTeacher[];
}

export interface StudentData {
    name: string;
    email: string;
    password: string;
    studentNumber: string;
    familyName: string;
    givenName: string;
}

interface UseStudentAddFromCsvParams {
    refresh: () => void;
    onStudentLimitExceeded: () => void;
}

export function useStudentAddFromCsv({ refresh, onStudentLimitExceeded }: UseStudentAddFromCsvParams) {
    const { userPermissions } = useContext(UserPermissionContext);

    const [studentsData, setStudentsData] = useState<StudentData[]>([]);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const [uploadingFile, setUploadingFile] = useState<File | null>(null);
    const [createUsers] = useMutation(CREATE_USERS_MUTATION);
    const [errorMsg, setErrorMsg] = useState('');
    const [classID, setClassID] = useState('');
    const auth = useContext(UserAuthContext);
    const handleChosenIDChange = (id: string) => {
        setClassID(id);
    };
    const handleSetOpen = (isOpen: boolean) => {
        setOpen(isOpen);
    };
    const [isEmailExists, setIsEmailExists] = useState(false);
    const [finishUploading, setFinishLoading] = useState(false);
    const {
        loading,
        data: allClassIDs,
        refetch,
    } = useQuery<Classes>(GET_ALL_CLASS_ID, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });
    const SetSubmittedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        setErrorMsg('');
        setUploadingFile(file);
    };

    const handleReset = () => {
        setUploadingFile(null);
        setStudentsData([]);
    };

    const handleFileUpload = async () => {
        const file = uploadingFile;
        if (!file) {
            return;
        }

        try {
            const csvText = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target?.result as string);
                reader.onerror = reject;
                reader.readAsText(file);
            });
            const validateCSVHeaders = (csvContent: any) => {
                const lines = csvContent.split('\n');
                if (lines.length === 0) {
                    return false;
                }

                const headers = lines[0].split(',');
                const correctHeaders = ['name', 'email', 'password', 'studentNumber', 'familyName', 'givenName'];

                if (headers.length !== correctHeaders.length) {
                    return false;
                }

                for (let i = 0; i < headers.length; i++) {
                    if (headers[i].trim() !== correctHeaders[i]) {
                        return false;
                    }
                }

                return true;
            };
            const csvWrongFormatMsg = t('studentPage.wrong-csv-format');

            if (!validateCSVHeaders(csvText)) {
                setErrorMsg(csvWrongFormatMsg);
                return;
            }

            const jsonData = parseCSV(csvText as string);
            if (jsonData.error) {
                setErrorMsg(t('studentPage.missed-field').replace('{num}', '' + jsonData.error));
                return;
            }

            if (jsonData.data && jsonData.data.length > userPermissions?.maxStudentID) {
                onStudentLimitExceeded(); // Trigger the callback when limit is exceeded

                handleReset();
                return;
            }

            setStudentsData(jsonData.data ? jsonData.data : []);
            if (jsonData.data) {
                if (jsonData.data.length > 0) {
                    await handleSubmit(jsonData.data);
                } else {
                    setErrorMsg(csvWrongFormatMsg);
                    return;
                }
            }

            handleReset();
        } catch (error) {}
    };
    const handleSubmit = async (studentsData: StudentData[]) => {
        const validationSchema = Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(t('required')),
                email: Yup.string().email(t('invalid-email')).required(t('required')),
                password: Yup.string().min(8, t('password-requirement')).required(t('required')),
                role: Yup.string().required(t('required')),
                studentProfile: Yup.object()
                    .shape({
                        create: Yup.object()
                            .shape({
                                studentNumber: Yup.string().required(t('required')),
                                familyName: Yup.string().required(t('required')),
                                givenName: Yup.string().required(t('required')),
                                class: Yup.object()
                                    .shape({
                                        connect: Yup.object().shape({
                                            id: Yup.string().required(t('required')),
                                        }),
                                    })
                                    .required(t('required')),
                            })
                            .required(t('required')),
                    })
                    .required(t('required')),
            }),
        );
        const validateData = async (data: any) => {
            const errors = [];
            for (let i = 0; i < data.length; i++) {
                try {
                    await validationSchema.validate([data[i]]);
                } catch (error) {
                    if (error instanceof Yup.ValidationError) {
                        errors.push({ index: i + 1, errors: error.errors });
                        break;
                    }
                }
            }
            return errors;
        };
        try {
            if (classID == '') {
                alert('The Class does not exist!');
                return;
            }
            const userData = studentsData.map((student) => {
                return {
                    name: student.name,
                    role: 'student',
                    email: student.email,
                    password: student.password,
                    passwordAES: EncryptionAES(student.password, passwordSecretKey),
                    studentProfile: {
                        create: {
                            studentNumber: student.studentNumber,
                            familyName: student.familyName,
                            givenName: student.givenName,
                            class: {
                                connect: { id: classID },
                            },
                        },
                    },
                };
            });

            const errorFromValidation = await validateData(userData);
            if (errorFromValidation.length > 0) {
                setErrorMsg(t('studentPage.n-row').replace('{num}', '' + errorFromValidation[0].index) + errorFromValidation[0].errors.join(', '));
                return;
            }

            const { data } = await createUsers({
                variables: { data: userData },
            });
            setFinishLoading(true);
        } catch (error: any) {
            if (error instanceof Yup.ValidationError) {
            }
            const errorMsgParse = handleException(error);
            if (errorMsgParse === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsgParse);
            } else if (errorMsgParse === ERROR_TYPE.FIELD_DUPLICATE) {
                let repeatEmail = '';
                error?.graphQLErrors.forEach((element: any) => {
                    repeatEmail += studentsData[element?.path[1]].email + ', ';
                });
                setIsEmailExists(true);
                const repeatEmailMsg = t('email-exist');
                const errorRedundantMsg = repeatEmailMsg + ' : ' + repeatEmail;
                setErrorMsg(errorRedundantMsg);
            } else {
                const errorMessage = errorMsgParse.replace('ValidationError: ', '');
                setErrorMsg(errorMessage);
            }
        } finally {
            refresh();
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setErrorMsg('');
        setClassID('');
        setUploadingFile(null);
        setOpen(false);
    };
    return {
        studentsData,
        open,
        handleFileUpload,
        handleSubmit,
        handleClickOpen,
        handleClose,
        handleChosenIDChange,
        handleSetOpen,
        SetSubmittedFile,
        uploadingFile,
        allClassIDs,
        classID,
        errorMsg,
        finishUploading,
        setFinishLoading,
    };
}
