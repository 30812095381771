import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, ToggleButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonTypography, TopButton, TopButtonTypography } from '../TestroomTemplate';

type Props = { freeStyleMode: boolean; onToggle: () => void };

export const TestroomFreeStyleMode = (props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const enable = !props.freeStyleMode;
    const isFreeStyleMode = props.freeStyleMode;

    return (
        <TopButton>
            <TopButtonTypography>{t('freeStyleMode')}</TopButtonTypography>
            <ButtonTypography
                sx={{
                    padding: '0px 8px',
                }}>
                <Stack direction='row' justifyContent='center' alignItems='center'>
                    <ToggleButton
                        value='check'
                        selected={isFreeStyleMode}
                        onChange={async () => {
                            setOpen(true);
                        }}
                        size='small'
                        sx={{ p: 0 }}>
                        {!isFreeStyleMode ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </ToggleButton>
                    <Typography>{t('enable')}</Typography>
                </Stack>
            </ButtonTypography>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>
                    {enable ? t('enable') : t('disable')} {t('freeStyleMode')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {enable ? t('enable') : t('disable')} {t('freeStyleMode')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
                    <Button
                        onClick={() => {
                            props.onToggle();
                            setOpen(false);
                        }}
                        autoFocus>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </TopButton>
    );
};
