import React, { SetStateAction } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

import { SlideComponent, SlideComponentFileModel } from 'models/lesson/LessonModel';

type ComponentProps = {
    item: SlideComponent;
    index: number;
    audio: SlideComponentFileModel[];
    deleteAudio: (id: string, item: SlideComponent) => Promise<void>;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    deleteSlideItem: (index: number) => void;
    isCreate?: boolean;
};
export const RecordingsList = (props: ComponentProps) => {
    const { t } = useTranslation();

    if (props.audio.length > 0) {
        return (
            <Stack spacing={1} style={{ width: '100%' }}>
                {props.audio.map((audio: SlideComponentFileModel, idx) => (
                    <Stack direction='row' key={audio.id}>
                        <audio controls src={audio.url} style={{ width: '100%' }} />
                        <IconButton
                            onClick={async () => {
                                props.deleteAudio(audio.id, props.item);
                                props.setCanSave?.(false);
                                if (!props.isCreate) {
                                    props.deleteSlideItem(props.index);
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
        );
    } else {
        return !props.isCreate ? (
            <Stack direction='row' spacing={1} alignItems='center'>
                <WarningIcon />
                <Typography>{t('noAudioRecords')}</Typography>
            </Stack>
        ) : null;
    }
};
