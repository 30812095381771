import { Stack } from '@mui/material';
import { ResultTable, ResultTableProps } from 'components/ResultTable';
import styled from '@emotion/styled';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import CsvGenerator from 'pages/results/component/CsvGenerator';

export const QuestionResultPage = () => {
    //   const navigator = useNavigate();
    //   const { schoolId, classId, testroomId, studentId } = useParams();
    //   const { selectedTestRoom, selectedStudent, setSelectedQuestion } =
    //     useOutletContext<ResultsPageContext>();
    //   const { t } = useTranslation();

    //   const [isLoading, setIsLoading] = useState(true);
    const { questionTableData, loadingOverallResultPage } = useTestroomResult();

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <PopupCircularProgress isOpened={loadingOverallResultPage} />

            <ResultTable columns={questionTableData?.columns} data={questionTableData?.data} isLoading={false} showSearchTab={true} showGenerateCsv={true} />
        </Stack>
    );
};

export const DotContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;
