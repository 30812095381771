import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { PaymentPanel } from 'pages/payment/PaymentPanel';
import { useParams } from 'react-router-dom';
import { PaymentPlanButtonType } from '../payment/PaymentPlans';
import { SubscribeButtonProps } from '../payment/PaymentPlans';

interface LandingPageWebFlowEmbedProps {
    locale?: string;
}

export const LandingPageWebFlowEmbed: React.FC<LandingPageWebFlowEmbedProps> = ({ locale: propLocale }) => {
    // let { locale } = useParams();
    const { locale: routeLocale } = useParams<{ locale: string }>();
    const initialLocale = propLocale || routeLocale || 'zh-HK';

    const [panelLanguage, setPanelLanguage] = useState(initialLocale);
    const handleSubscribeClick = (props: SubscribeButtonProps) => {
        // window.parent.postMessage(
        //   { type: 'subscribeClick', data: '/school-order' },
        //   '*', // or the specific origin
        // );

        // console.log(
        //   window.location.origin +
        //     '/profile?plan_to_subscribe=' +
        //     props.internalPlanName,
        // );
        window.parent.postMessage(
            {
                type: 'signUp',
                data: window.location.origin + '/profile?plan_to_subscribe=' + props.internalPlanName + '&signup=1',
            },
            '*', // or the specific origin
        );
    };

    useEffect(() => {
        function sendHeight() {
            const height = document.documentElement.scrollHeight || document.body.scrollHeight;
            window.parent.postMessage(
                {
                    type: 'iframeHeight',
                    height: height,
                },
                '*',
            );
        }

        // Send initial height
        sendHeight();

        // Optionally: if the iframe's content can change dynamically, consider
        // using a MutationObserver, resize event, or other means to detect such
        // changes and call sendHeight again. For now, we're sending the initial height.

        // Cleanup function if needed
        return () => {
            // Cleanup code here (e.g., disconnecting a MutationObserver)
        };
    }, []);

    useEffect(() => {
        console.log('propLocale:' + propLocale);
        console.log('routeLocale:' + routeLocale);
        console.log('initialLocale:' + initialLocale);

        if (routeLocale && routeLocale !== '') {
            setPanelLanguage(routeLocale);
        } else {
            setPanelLanguage(initialLocale);
        }
    }, [initialLocale]);

    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const paymentPanelStyle = {
        background: '#FFFBF7',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '50px',
        paddingBottom: '50px',
    };

    return (
        <>
            <div style={paymentPanelStyle}>
                <Stack direction='column' alignItems='center' spacing={4}>
                    <PaymentPanel language={panelLanguage} onSubscribeClick={handleSubscribeClick} />
                </Stack>
            </div>
        </>
    );
};
