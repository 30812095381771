import { alpha, InputBase, styled, InputBaseProps } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { useState } from 'react';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 14px 10px 14px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Sofia Pro Soft'].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const BootstrapStyleInput = (props: InputBaseProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const { type, ...otherProps } = props;
    const isPassword = type === 'password';

    return (
        <StyledInputBase
            {...otherProps}
            type={isPassword && showPassword ? 'text' : type}
            sx={{
                width: isPassword ? 'calc(100% + 36px)' : '100%',
            }}
            endAdornment={
                isPassword && (
                    <InputAdornment position='end'>
                        <IconButton onClick={handleTogglePassword} edge='end'>
                            {showPassword ? <RemoveRedEyeOutlined /> : <RemoveRedEye />}
                        </IconButton>
                    </InputAdornment>
                )
            }
            inputProps={{
                ...(otherProps.inputProps || {}),
                'aria-label': isPassword ? 'Password' : undefined,
            }}
        />
    );
};
