import { gql, useLazyQuery } from '@apollo/client';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';

import { enqueueSnackbar } from 'notistack';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';
import { LessonModel } from './LessonModel';

const QUERY_GQL = gql`
    query GetLessonPageRows($teacherID: ID!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        lessons(
            where: { AND: [{ teacher: { user: { id: { equals: $teacherID } } } }, { lessonVersions: { some: { published: { equals: false } } } }, { archived: { equals: false } }] }
            orderBy: [{ dateUpdated: desc }]
        ) {
            id
            latestLessonDraft {
                lessonName
                slides(take: 1, orderBy: { sort: asc }) {
                    id
                    data
                }
                dateUpdated
            }
            shareWithPublic
            sharesCount
        }
    }
`;

type QueryVariableType = {
    teacherID: string;
};

type ModelQueryType = {
    authenticatedItem: AuthenticatedItemModel;
    lessons: LessonModel[];
};

export const useLessonsQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const getMyLessons = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await queryData({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getMyLessons, data, loading };
};
