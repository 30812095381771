/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Typography, Stack, Grid, TextField, Button } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

interface PasswordChangeFormProps {
    userData: any;
    formikPassword: any; // Replace with your Formik instance type
    t: (key: string) => string;
    setNormalMessage: (message: string) => void;
    setErrorMessage: (message: string) => void;
}

const UPDATE_PASSWORD = gql`
    mutation updateUserPassword($currentPassword: String!, $newPassword: String!) {
        updateUserPassword(currentPassword: $currentPassword, newPassword: $newPassword)
    }
`;

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ userData, formikPassword, t, setNormalMessage, setErrorMessage }) => {
    const [updatePassword, { loading: updatePasswordLoading, error: updatePasswordError, data: updatePasswordData }] = useMutation(UPDATE_PASSWORD);
    const handleChangePassword = async ({ currentPassword, password }: { currentPassword: string; password: string }) => {
        try {
            // console.log('Kendall :', password);

            const { data: updatePasswordData } = await updatePassword({
                variables: {
                    currentPassword: currentPassword,
                    newPassword: password,
                },
                fetchPolicy: 'no-cache',
            });
            // console.log('Kendall result: ', updatePasswordData);

            // Handle the response from the mutation
            if (updatePasswordData) {
                // Password update was successful
                formikPassword.resetForm();

                enqueueSnackbar(t('profile.passwordUpdateSuccess'), {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });

                // Show a success message or perform any other actions
            } else {
                // Password update failed
                // Handle the error message returned from the backend
                // const errorMessage =
                //   data?.updatePassword?.message || 'Password update failed.';
                // Display the error message or perform any other error handling

                enqueueSnackbar(t('profile.passwordUpdateFailure'), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        } catch (error) {
            // console.log('Kendall error: ', error);

            enqueueSnackbar(t('profile.passwordUpdateFailure'), {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });

            // Handle the GraphQL or network error
            // Display an error message or perform any other error handling
        }
    };
    // Component logic and JSX
    return (
        <Stack spacing={2} minWidth={480} alignItems={'center'}>
            <Grid container alignItems='flex-start'>
                <Typography variant='h5'>{t('profile.password')}</Typography>
            </Grid>
            {/* <TextField
      id='currentPassword'
      name='currentPassword'
      type='password'
      autoComplete='new-password'
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.currentPassword}
      label={t('profile.currentPassword')}
      fullWidth
      variant='outlined'
      error={!!formik.errors.currentPassword}
      helperText={formik.touched.password && formik.errors.password}
    /> */}

            <TextField
                id='currentPassword'
                name='currentPassword'
                type='password'
                autoComplete='currentPassword'
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                value={formikPassword.values.currentPassword}
                label={t('profile.currentPassword')}
                fullWidth
                variant='outlined'
                error={formikPassword.touched.currentPassword && !!formikPassword.errors.currentPassword}
                helperText={formikPassword.touched.currentPassword && formikPassword.errors.currentPassword}
            />

            <TextField
                id='password'
                name='password'
                type='password'
                autoComplete='new-password'
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                value={formikPassword.values.password}
                label={t('profile.newPassword')}
                fullWidth
                variant='outlined'
                error={formikPassword.touched.password && !!formikPassword.errors.password}
                helperText={formikPassword.touched.password && formikPassword.errors.password}
            />

            <TextField
                id='passwordConfirmation'
                name='passwordConfirmation'
                type='password'
                autoComplete='new-password'
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                value={formikPassword.values.passwordConfirmation}
                label={t('profile.confirmPassword')}
                fullWidth
                variant='outlined'
                error={formikPassword.touched.passwordConfirmation && !!formikPassword.errors.passwordConfirmation}
                helperText={formikPassword.touched.passwordConfirmation && formikPassword.errors.passwordConfirmation}
            />

            <Button
                variant='contained'
                color='primary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                    width: '100%',
                }}
                // onClick={() =>
                //   userData &&
                //   userData.email &&
                //   handleChangePassword({ email: userData.email })
                // }
                // onClick={handleChangePassword(formik.values.password)}
                onClick={() =>
                    handleChangePassword({
                        currentPassword: formikPassword.values.currentPassword,
                        password: formikPassword.values.password,
                    })
                }
                disabled={!formikPassword.isValid || !formikPassword.touched.passwordConfirmation || !formikPassword.touched.password}
                // disabled={formik.isSubmitting}
            >
                {t('profile.changePassword')}
            </Button>
            {/* {normalMessage && (
        <Alert
          variant='filled'
          severity='success'
          sx={{
            width: '100%',
          }}>
          {normalMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert
          variant='filled'
          severity='error'
          sx={{
            width: '100%',
          }}>
          {errorMessage}
        </Alert>
      )} */}
        </Stack>
    );
};
