import { useMediaQuery, Theme, createTheme, PaletteMode } from '@mui/material';
import { createContext, useMemo } from 'react';
import { useLocalStorage } from '../hooks/UseLocalStorage';

import { enUS, Localization, zhHK, zhCN } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import { Constants } from '../Constants';
import { SnackbarProvider } from 'notistack';

import SofiaProSoftRegular from '../assets/fonts/SofiaProSoft/SofiaProSoftRegular.ttf';

export type LanguageKey = 'en-US' | 'zh-HK' | 'zh-CN';

export type UserPrefContextType = {
    theme: Theme;
    toggleTheme: () => void;
    language: LanguageKey;
    changeLanguage: (language: LanguageKey) => void;
    isMenuOpened: boolean;
    setIsMenuOpened: (value: boolean | ((val: boolean) => boolean)) => void;
};

export const languageMapping: { [language: string]: Localization } = {
    'en-US': enUS,
    'zh-HK': zhHK,
    'zh-CN': zhCN,
};

const contextDefaultValues = {
    theme: createTheme(),
    toggleTheme: () => {},
    language: Constants.main.defaultLanguage as LanguageKey,
    changeLanguage: () => {},
    isMenuOpened: false,
    setIsMenuOpened: () => {},
};

export const UserPrefContext = createContext<UserPrefContextType>(contextDefaultValues);

export const UserPrefContextProvider = ({ children }: { children: React.ReactNode }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { i18n } = useTranslation();
    const [langauge, setLangauge] = useLocalStorage<LanguageKey>(Constants.localStorageKeys.language, Constants.main.defaultLanguage as LanguageKey);

    const [colorMode, setColorMode] = useLocalStorage<PaletteMode>(Constants.localStorageKeys.theme, prefersDarkMode ? 'dark' : 'light');

    const [isMenuOpen, setIsMenuOpen] = useLocalStorage<boolean>(Constants.localStorageKeys.isMenuOpened, false);

    const theme = useMemo(
        () =>
            createTheme(
                {
                    typography: {
                        fontFamily: 'Sofia Pro Soft',
                        h4: {
                            fontFamily: 'Sofia Pro Soft',
                            fontWeight: 700,
                            fontSize: '2rem', // 32px
                        },
                        h5: {
                            fontFamily: 'Sofia Pro Soft',
                            fontWeight: 700,
                            fontSize: '1.875rem', // 30px
                        },
                        caption: {
                            fontFamily: 'Sofia Pro Soft',
                            fontWeight: 400,
                            fontSize: '1rem',
                        },
                        body2: {
                            fontWeight: 500,
                        },
                    },
                    components: {
                        MuiButtonBase: {
                            defaultProps: {
                                disableRipple: true,
                            },
                        },
                        MuiCssBaseline: {
                            styleOverrides: `
                @font-face {
                  font-family: 'Sofia Pro Soft';
                  font-style: normal;
                  src: local(''), url(${SofiaProSoftRegular}) format('truetype');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
              `,
                        },
                    },
                    palette: {
                        mode: colorMode,
                        primary: {
                            main: '#FFA425',
                            light: '#ffb650',
                            dark: '#b27219',
                            contrastText: '#fff',
                        },
                        secondary: {
                            main: '#29C0E7',
                            light: '#53cceb',
                            dark: '#1c86a1',
                            contrastText: '#fff',
                        },
                        background: {
                            default: '#FAEFC4',
                            paper: '#FFFBF6',
                        },
                    },
                },
                languageMapping[langauge],
            ),
        [colorMode, langauge],
    );

    return (
        <SnackbarProvider maxSnack={5}>
            <UserPrefContext.Provider
                value={{
                    theme: theme,
                    toggleTheme: () => {
                        if (colorMode === 'light') {
                            setColorMode('dark');
                        } else {
                            setColorMode('light');
                        }
                    },
                    language: langauge,
                    changeLanguage: (langauge: LanguageKey) => {
                        setLangauge(langauge);
                        i18n.changeLanguage(langauge);
                    },
                    isMenuOpened: isMenuOpen,
                    setIsMenuOpened: setIsMenuOpen,
                }}>
                {children}
            </UserPrefContext.Provider>
        </SnackbarProvider>
    );
};
