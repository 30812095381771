import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldsIcon from '@mui/icons-material/TextFields';

import Draggable from 'react-draggable';
import { useContext } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentMatching } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataMatching = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentMatching = item.content as SlideComponentMatching;

    return (
        <>
            <Draggable
                disabled={disabled}
                bounds='parent'
                handle='strong'
                onStart={() => {
                    setSelectedComponentIndex(index);
                }}
                onStop={(e, position) => {
                    const newX = position.x / zoom;
                    const newY = position.y / zoom;
                    if (item.pos.x !== newX || item.pos.y !== newY) {
                        item.pos.x = newX;
                        item.pos.y = newY;
                        updateItemProperties({ ...item }, true);
                    }
                }}
                position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
                <strong
                    style={{
                        cursor: 'move',
                        width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                        height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                        position: 'absolute',
                        zIndex: itemContent.zIndex ?? 0,
                    }}>
                    <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<TextFieldsIcon />} title={t('matching.title')} resize={false} />
                    <Typography sx={{ color: '#533D18DE', zoom: zoom, fontSize: 30 }}>{t('matching.instruction')}</Typography>
                    <Grid container spacing={2} sx={{ zoom: zoom, mt: 3 }}>
                        {itemContent.questions.map((question, qIdx) => {
                            return (
                                <Grid item sx={{ width: 400 }} key={qIdx}>
                                    <Stack>
                                        <Typography
                                            sx={{
                                                width: 'fit-content',
                                                borderRadius: 2,
                                                display: 'inline-block',
                                                m: 1,
                                                backgroundColor: 'red',
                                                p: 1,
                                                pl: 2,
                                                pr: 2,
                                                color: 'white',
                                            }}>
                                            {qIdx}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={
                                                question.imageURL && question.imageURL !== ''
                                                    ? question.imageURL
                                                    : `/characters/${question.image !== '' ? question.image + '/image.png' : 'default.png'}`
                                            }
                                            alt='bg'
                                            sx={{
                                                alignSelf: 'center',
                                                height: 150,
                                                maxWidth: 300,
                                                objectFit: 'contain',
                                                transform: `scaleX(${question.flip ?? 1})`,
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Stack direction='row' sx={{ zoom: zoom, mt: 6 }}>
                        {itemContent.questions &&
                            [...itemContent.questions]
                                .sort((a, b) => a.sort - b.sort)
                                .map((question, qIdx) => {
                                    return (
                                        <Stack direction='row' key={qIdx}>
                                            <Box
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                    border: 1,
                                                    borderColor: '#533D18DE',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mr: 1,
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    color: '#533D18DE',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mr: 2,
                                                    fontSize: 30,
                                                }}>
                                                {question.answer}
                                            </Box>
                                        </Stack>
                                    );
                                })}
                    </Stack>
                </strong>
            </Draggable>
        </>
    );
};
