import { Dialog, TextField, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, FormHelperText, Stack, IconButton, Typography, Divider, Box } from '@mui/material';
import { forwardRef, useEffect, useContext, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, getMonth, getYear } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import range from 'lodash/range';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
// import QRCode from 'qrcode.react';
import { QRCode } from 'react-qrcode-logo';
import { Constants } from '../../../Constants';
import { EncryptionAES } from 'utils/function/encryption';
import * as htmlToImage from 'html-to-image';

const CLASS_QUERY = gql`
    query GetRows($schoolId: ID!) {
        classes(orderBy: { className: asc }, where: { school: { id: { equals: $schoolId } } }) {
            id
            name
        }
    }
`;

const GET_USER_PASSWORDAES = gql`
    query GetUserPasswordAES($email: String!) {
        user(where: { email: $email }) {
            passwordAES
        }
    }
`;

type ClassData = {
    id: string;
    name: string;
};

const years = range(getYear(new Date()) - 100, getYear(new Date()) + 1, 1);
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const StudentCreateForm = ({
    onSubmit,
    isOpened,
    initValues,
    handleClose,
    isUpdate,
    isEmailExists,
    resetEmailExists,
}: {
    onSubmit: (values: any, createAnother: boolean, createAnotherCb: () => void) => void;
    isOpened: boolean;
    initValues: any;
    handleClose: () => void;
    isUpdate?: boolean;
    isEmailExists: boolean;
    resetEmailExists: () => void;
}) => {
    const auth = useContext(UserAuthContext);
    const { t } = useTranslation();
    const [fetchClasses, { data: classData }] = useLazyQuery(CLASS_QUERY);
    const [GetUserPasswordAES, { data: userData }] = useLazyQuery(GET_USER_PASSWORDAES);
    const [qrData, setQrData] = useState('');
    const [qrPic, setQrPic] = useState('');
    const passwordSecretKey = (Constants.localStorageKeys.password_AES_SecretKey || '').toString();
    const qrContainerRef = useRef(null);
    const createAnother = useRef<boolean>(false);

    const validationSchema: any = Yup.object().shape({
        isUpdate: Yup.boolean(),
        email: Yup.string().email(t('invalid-email')).required(t('required')),
        password: isUpdate ? Yup.string().nullable().min(8, t('password-requirement')) : Yup.string().min(8, t('password-requirement')).required(t('required')),
        passwordConfirmation: isUpdate
            ? Yup.string()
                  .nullable()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .when('password', (password, schema) => {
                      return !password || (password && password.length <= 0) ? schema : schema.required(t('required'));
                  })
            : Yup.string()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .required(t('required')),
        class: Yup.string().required(t('required')),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            passwordAES: '',
            studentNumber: '',
            familyName: '',
            givenName: '',
            class: '',
            dob: new Date(),
        },
        validationSchema,
        onSubmit: async (values) => {
            onSubmit(values, createAnother.current, () => {
                formik.resetForm();
            });
        },
    });

    useEffect(() => {
        formik.setFieldValue('name', initValues?.name ?? '');
        formik.setFieldTouched('name', false);

        formik.setFieldValue('email', initValues?.email ?? '');
        formik.setFieldTouched('email', false);

        formik.setFieldValue('password', '');
        formik.setFieldTouched('password', false);

        getPasswordAES(initValues?.email ?? '');

        formik.setFieldValue('passwordAES', '');
        formik.setFieldTouched('passwordAES', false);
        setQrData('');

        formik.setFieldValue('passwordConfirmation', initValues?.passwordConfirmation ?? '');
        formik.setFieldTouched('passwordConfirmation', false);

        formik.setFieldValue('studentNumber', initValues?.studentNumber ?? '');
        formik.setFieldTouched('studentNumber', false);

        formik.setFieldValue('familyName', initValues?.familyName ?? '');
        formik.setFieldTouched('familyName', false);

        formik.setFieldValue('givenName', initValues?.givenName ?? '');
        formik.setFieldTouched('givenName', false);

        formik.setFieldValue('dob', initValues?.dob ? new Date(initValues?.dob) : new Date());
        formik.setFieldTouched('dob', false);

        formik.setFieldValue('class', initValues?.class ?? '');
        formik.setFieldTouched('class', false);
    }, [initValues]);

    const getPasswordAES = useCallback(
        async (email: string) => {
            if (email) {
                await GetUserPasswordAES({
                    variables: { email: email },
                    fetchPolicy: 'no-cache',
                });
            }
        },
        [GetUserPasswordAES],
    );

    useEffect(() => {
        let qrData;
        let pw;

        if (userData && formik.values.password === '') {
            formik.setFieldValue('passwordAES', userData?.user?.passwordAES);
            formik.setFieldTouched('passwordAES', false);
            qrData = JSON.stringify({
                email: formik.values.email,
                password: userData?.user?.passwordAES,
            });
        } else if (formik.values.password !== '') {
            pw = EncryptionAES(formik.values.password, passwordSecretKey);
            formik.setFieldValue('passwordAES', pw);
            formik.setFieldTouched('passwordAES', false);

            qrData = JSON.stringify({
                email: formik.values.email,
                password: pw,
            });
        }
        if (qrData) {
            setQrData(qrData);
        }
    }, [formik.values.email, formik.values.password, userData, getPasswordAES]);

    useEffect(() => {
        const ToImage = async () => {
            setTimeout(async () => {
                if (qrContainerRef.current) {
                    const dataUrl = await htmlToImage.toPng(qrContainerRef.current);
                    setQrPic(dataUrl);
                }
            }, 800);
        };
        ToImage();
    }, [formik.values.familyName, formik.values.givenName]);

    useEffect(() => {}, []);

    const IconButtonInput = forwardRef(({ value, onClick }: { value?: any; onClick?: any }, ref: any) => (
        <IconButton onClick={onClick} ref={ref}>
            <DateRangeIcon />
        </IconButton>
    ));

    useEffect(() => {
        fetchClasses({
            variables: { schoolId: auth.selectedSchool?.id },
            fetchPolicy: 'no-cache',
        });
    }, []);

    return (
        <Dialog open={isOpened}>
            <DialogTitle>
                <PopupTitle>{isUpdate ? t('update-student') : t('add-student')}</PopupTitle>
            </DialogTitle>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    createAnother.current = false;
                    formik.handleSubmit();
                }}>
                <DialogContent>
                    <Stack spacing={2} minWidth={480}>
                        <Stack direction='row' spacing={2}>
                            <TextField
                                id='familyName'
                                name='familyName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.familyName}
                                label={t('family-name')}
                                fullWidth
                                variant='outlined'
                                error={formik.touched.familyName && !!formik.errors.familyName}
                                helperText={formik.touched.familyName && formik.errors.familyName}
                            />
                            <TextField
                                id='givenName'
                                name='givenName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.givenName}
                                label={t('given-name')}
                                fullWidth
                                variant='outlined'
                                error={formik.touched.givenName && !!formik.errors.givenName}
                                helperText={formik.touched.givenName && formik.errors.givenName}
                            />
                        </Stack>

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id='className'>{t('class-name')}</InputLabel>
                            <Select
                                labelId='className'
                                id='class'
                                name='class'
                                value={formik.values.class}
                                label={t('class-name')}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.class && !!formik.errors.class}>
                                {classData?.classes?.map((option: ClassData, key: number) => (
                                    <MenuItem value={option.id} key={key}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.class && formik.errors && <FormHelperText>{formik.errors.class}</FormHelperText>}
                        </FormControl>

                        <TextField
                            id='studentNumber'
                            name='studentNumber'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.studentNumber}
                            label={t('student-id')}
                            fullWidth
                            variant='outlined'
                            error={formik.touched.studentNumber && !!formik.errors.studentNumber}
                            helperText={formik.touched.studentNumber && formik.errors.studentNumber}
                        />

                        <TextField
                            id='email'
                            name='email'
                            autoComplete='new-password'
                            onChange={(e) => {
                                resetEmailExists();
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            label={t('email')}
                            fullWidth
                            variant='outlined'
                            error={formik.touched.email && (!!formik.errors.email || isEmailExists)}
                            helperText={formik.touched.email && (formik.errors.email ? formik.errors.email : isEmailExists ? t('email-exist') : '')}
                        />
                        <TextField
                            id='password'
                            name='password'
                            type='password'
                            autoComplete='new-password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            label={t('password')}
                            fullWidth
                            variant='outlined'
                            error={formik.touched.password && !!formik.errors.password}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            id='passwordConfirmation'
                            name='passwordConfirmation'
                            type='password'
                            autoComplete='new-password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passwordConfirmation}
                            label={t('password-confirm')}
                            fullWidth
                            variant='outlined'
                            error={formik.touched.password && !!formik.errors.passwordConfirmation}
                            helperText={formik.touched.password && formik.errors.passwordConfirmation}
                        />

                        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                            <Typography>{t('dob')}</Typography>
                            <Typography>{format(formik.values.dob, 'dd-MM-yyyy')}</Typography>
                            <DatePicker
                                selected={formik.values.dob}
                                onChange={(date: Date) => formik.setFieldValue('dob', date)}
                                customInput={<IconButtonInput />}
                                withPortal
                                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                        <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                            {'<'}
                                        </button>
                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
                                            {years.map((option: any) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                            {'>'}
                                        </button>
                                    </div>
                                )}
                            />
                        </Stack>
                    </Stack>
                    <div
                        ref={qrContainerRef}
                        style={{
                            position: 'absolute',
                            top: '1px',
                            left: '-1px',
                            width: 200,
                            height: 200,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid black',
                            borderRadius: '4px',
                            fontSize: '2rem',
                            padding: '2px',
                            margin: '0',
                            textAlign: 'center',
                            zIndex: '-1',
                            backgroundColor: 'white',
                        }}>
                        <span
                            style={{
                                width: '150px',
                                wordWrap: 'break-word',
                                textAlign: 'center',
                                color: 'black',
                            }}>
                            {formik.values.familyName + ' ' + formik.values.givenName}
                        </span>
                    </div>

                    <QRCode ecLevel='H' value={qrData} logoImage={qrPic} logoHeight={80} logoWidth={80} size={300} fgColor={'#000000'} />

                    <Divider
                        sx={{
                            width: '90%',
                            backgroundColor: '#858585',
                            margin: '20px auto',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <CancelButton onClick={handleClose} disabled={formik.isSubmitting} sx={{ border: '1px solid #F06E3C', ml: 1, mr: 1 }}>
                            {t('cancel')}
                        </CancelButton>
                    </Box>
                    <Box>
                        {!isUpdate && (
                            <CancelButton
                                onClick={() => {
                                    createAnother.current = true;
                                    formik.handleSubmit();
                                }}
                                disabled={formik.isSubmitting}
                                sx={{
                                    border: '1px solid #F06E3C',
                                    ml: 1,
                                    mr: 1,
                                }}>
                                {t('saveAndAddAnother')}
                            </CancelButton>
                        )}
                        <SubmitButton type='submit' loading={formik.isSubmitting} sx={{ ml: 1, mr: 1 }}>
                            {t('save')}
                        </SubmitButton>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};
