import { Box, Stack, StackProps, SxProps, Theme } from '@mui/material';
import { ImageUpload } from './ImageUpload';
import { ImageUploadDeleteButton } from './ImageUploadDeleteButton';
import { OptionsType, PropsImagePicker } from './PropsImagePicker';
import { useTranslation } from 'react-i18next';
import { imageOptions } from '../options/imageOptions';

type SelectImageBoxProps = {
    imageURL: string;
    image: string;
    flip: number;
    onSelected: (imageURL: string) => void;
    handleDelete: () => void;
    onClickHandler: (option: OptionsType) => void;
    sx?: SxProps<Theme>;
    keepMargin: boolean;
};

export const SelectImageBox = (props: SelectImageBoxProps) => {
    const { t } = useTranslation();

    const isNewForm = !props.image && props.image === '' && !props.imageURL && props.imageURL === '';

    return (
        <Stack direction='column' spacing={1} sx={{ ...props.sx }}>
            {isNewForm ? <PropsImagePicker textLabel={t('selectImage')} imageOptions={imageOptions} onClickHandler={props.onClickHandler} /> : props.keepMargin ? <Box sx={{ height: 40 }} /> : null}
            <Box
                sx={{
                    backgroundColor: '#F8F0E0',
                    // width: 150,
                    height: 150,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                {props.imageURL !== '' || props.image !== '' ? (
                    <>
                        <Box
                            component='img'
                            src={props.imageURL && props.imageURL !== '' ? props.imageURL : `/characters/${props.image !== '' ? props.image + '/image.png' : 'default.png'}`}
                            sx={{
                                width: 140,
                                height: 140,
                                // visibility: itemContent.image ? 'visible' : 'hidden',
                                transform: props.flip === -1 ? 'scaleX(-1)' : 'none',
                                objectFit: 'contain',
                            }}
                            loading='lazy'
                        />
                        <ImageUploadDeleteButton handleDelete={props.handleDelete} />
                    </>
                ) : (
                    <ImageUpload onSelected={(imageURL) => props.onSelected(imageURL)} uploadedImageURL={props.imageURL} />
                )}
            </Box>
        </Stack>
    );
};
