import { SelectChangeEvent, Select, OutlinedInput, MenuItem, FormControl } from '@mui/material';
import { useLessonShareModelDeleteMutation } from 'models/lessonShare/useLessonShareModelDeleteMutation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type PermissionDropdownMenuProps = {
    canDelete?: boolean;
    id?: string;
    refreshList: () => void;
};

export const PermissionDropdownMenu = (props: PermissionDropdownMenuProps) => {
    const { t } = useTranslation();

    const { deleteLessonShare } = useLessonShareModelDeleteMutation();

    const permissionOptionLabels = [
        { value: 'canView', label: t('canView'), disabled: false },
        { value: 'canEdit', label: t('canEdit'), disabled: true },
    ];

    const [dropdownValue, setDropdownValue] = useState<string>('canView');

    const handleDropdownChange = async (event: SelectChangeEvent<string>) => {
        if (event.target.value === 'delete') {
            try {
                await deleteLessonShare({ where: { id: props.id! } });
                props.refreshList();
            } catch (err) {}
        } else {
            setDropdownValue(event.target.value);
        }
    };

    return (
        <FormControl sx={{ width: 120 }}>
            <Select
                value={dropdownValue}
                onChange={handleDropdownChange}
                displayEmpty
                renderValue={(selected) => {
                    const option = permissionOptionLabels.find((opt) => opt.value === selected);
                    return option ? option.label : <em>--</em>;
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                input={
                    <OutlinedInput
                        label=''
                        notched={false}
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                    />
                }>
                {permissionOptionLabels.map((option) => (
                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                        {option.label}
                    </MenuItem>
                ))}
                {props.canDelete && (
                    <MenuItem key='delete' value='delete'>
                        {t('lessonShare.delete')}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};
