import { gql, useLazyQuery } from '@apollo/client';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';

import { enqueueSnackbar } from 'notistack';
import { CharacterPhraseModel } from './CharacterPhraseModel';

const QUERY_GQL = gql`
    query CharacterPhrases($where: CharacterPhraseWhereInput!, $characterWhere: CharacterWhereInput!) {
        characterPhrases(where: $where) {
            id
            character(where: $characterWhere) {
                id
                code
                locale
                character
                jyutping
                pinyin
                serial1
                serial2
            }
            phrase
            question
            questionEN
            jyutping
            pinyin
        }
    }
`;

type QueryVariableType = {
    where: {
        phrase: {
            contains: string;
        };
        character: {
            some: {
                locale: {
                    equals: string;
                };
            };
        };
    };
    characterWhere: {
        locale: {
            equals: string;
        };
    };
};

type ModelQueryType = {
    characterPhrases: CharacterPhraseModel[];
};

export const useCharacterPhrasesQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const searchPhrases = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await queryData({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { searchPhrases, data, loading };
};
