import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import HkcsQ3PrintImage from './HkcsQ3PrintImage';
import HkcsQ3WritingGrid from './HkcsQ3WritingGrid';
type HkcsQ3InGameLayoutProp = {
    wordElement: string;
    vocab: string;
    item: any;
    image: HkcsQuestionImages;
};
export interface HkcsQuestionImages {
    id: string;
    image: string;
    imageURL: string;
    flip: number;
}
const HkcsQ3InGameLayout = (prop: HkcsQ3InGameLayoutProp) => {
    return (
        <Box
            sx={{
                padding: '10mm',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '150px',
            }}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        padding: '0px 48px 0px 16px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Kaiti TC"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '143%', // equivalent to 22.88px
                            letterSpacing: '0.17px',
                        }}>
                        學校：香港基督教服務處幼兒教育服務
                    </Typography>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Kaiti TC"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '143%', // equivalent to 22.88px
                            letterSpacing: '0.17px',
                        }}>
                        日期：15/9/2023{' '}
                    </Typography>
                </Box>{' '}
                <Box
                    sx={{
                        display: 'flex',
                        padding: '0px 48px 0px 16px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Kaiti TC"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '143%', // equivalent to 22.88px
                            letterSpacing: '0.17px',
                        }}>
                        姓名：徐朗傑{' '}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Kaiti TC"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '143%', // equivalent to 22.88px
                            letterSpacing: '0.17px',
                        }}>
                        班別：4-5歲{' '}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Kaiti TC"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '143%', // equivalent to 22.88px
                            letterSpacing: '0.17px',
                        }}>
                        學號：6{' '}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    zoom: 1.2,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}>
                <Typography
                    align='center'
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: '"Kaiti TC"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '116.7%',
                    }}>
                    運用以下字詞創作並寫上句子，及繪畫出相配的圖畫。
                </Typography>
                <Box
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                    }}>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Typography
                            align='center'
                            sx={{
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: '"Kaiti TC"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '116.7%', // equivalent to 37.344px
                            }}>
                            固定語素：
                        </Typography>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                            }}>
                            <Typography
                                sx={{
                                    color: '#000',
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                    fontFamily: '"Kaiti TC"',
                                    fontSize: '48px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '100%', // equivalent to 96px
                                    letterSpacing: '-1.5px',
                                }}>
                                {prop.wordElement}
                            </Typography>
                            <HkcsQ3PrintImage width='60px' height='60px' image={prop.image.image} imageURL={prop.image.imageURL} />
                        </Box>
                        <Typography
                            sx={{
                                color: '#000',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: '"Kaiti TC"',
                                fontSize: '54px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '100%', // equivalent to 96px
                                letterSpacing: '-1.5px',
                            }}>
                            {prop.vocab}
                        </Typography>
                    </Box>
                    <Box
                        component='img'
                        src={prop.item.files.length > 0 ? prop.item.files[0].file.url : ''}
                        alt='Example Image'
                        sx={{
                            width: '278px', // width in pixels
                            height: '172px', // height in pixels
                            flexShrink: 0,
                            borderRadius: 12.5 / 2, // border radius in pixels
                            border: '1.5px solid #000', // border style
                            background: '#FFF',
                        }}
                    />
                </Box>
                <HkcsQ3WritingGrid textContent={JSON.parse(prop.item.data).hkcsQ3.textContent ?? ''} item={prop.item} />
            </Box>
        </Box>
    );
};

export default HkcsQ3InGameLayout;
