import { Box, Typography } from '@mui/material';
import React from 'react';
type StudentName = {
    studentName: string;
};
const StudentHeader = (props: StudentName) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                alignSelf: 'stretch',
                backgroundColor: 'white',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='36' viewBox='0 0 35 36' fill='none'>
                        <path
                            d='M17.5002 18C20.7231 18 23.3335 15.3896 23.3335 12.1666C23.3335 8.94373 20.7231 6.33331 17.5002 6.33331C14.2772 6.33331 11.6668 8.94373 11.6668 12.1666C11.6668 15.3896 14.2772 18 17.5002 18ZM17.5002 20.9166C13.6064 20.9166 5.8335 22.8708 5.8335 26.75V29.6666H29.1668V26.75C29.1668 22.8708 21.3939 20.9166 17.5002 20.9166Z'
                            fill='#F05023'
                        />
                    </svg>
                </Box>
                <Typography fontSize={20}>{props.studentName}</Typography>
            </Box>
        </Box>
    );
};

export default StudentHeader;
