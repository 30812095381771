import { imageOptionsLexdb } from './imageOptionsLexdb';

export const imageOptions = [
    { path: 's1/動1/image.png', value: 's1/動1', hasCard: true, label: '動' },
    { path: 's1/唱/image.png', value: 's1/唱', hasCard: true, label: '唱' },
    { path: 's1/園/image.png', value: 's1/園', hasCard: true, label: '園' },
    { path: 's1/打/image.png', value: 's1/打', hasCard: true, label: '打' },
    { path: 's1/朋1/image.png', value: 's1/朋1', hasCard: true, label: '朋' },
    { path: 's1/朵/image.png', value: 's1/朵', hasCard: true, label: '朵' },
    { path: 's1/松/image.png', value: 's1/松', hasCard: true, label: '松' },
    { path: 's1/林/image.png', value: 's1/林', hasCard: true, label: '林' },
    { path: 's1/果/image.png', value: 's1/果', hasCard: true, label: '果' },
    { path: 's1/樹/image.png', value: 's1/樹', hasCard: true, label: '樹' },
    { path: 's1/步/image.png', value: 's1/步', hasCard: true, label: '步' },
    { path: 's1/泉/image.png', value: 's1/泉', hasCard: true, label: '泉' },
    { path: 's1/流/image.png', value: 's1/流', hasCard: true, label: '流' },
    { path: 's1/玩1/image.png', value: 's1/玩1', hasCard: true, label: '玩' },
    { path: 's1/秋/image.png', value: 's1/秋', hasCard: true, label: '秋' },
    { path: 's1/穿/image.png', value: 's1/穿', hasCard: true, label: '穿' },
    { path: 's1/綠/image.png', value: 's1/綠', hasCard: true, label: '綠' },
    { path: 's1/羽/image.png', value: 's1/羽', hasCard: true, label: '羽' },
    { path: 's1/花/image.png', value: 's1/花', hasCard: true, label: '花' },
    { path: 's1/草/image.png', value: 's1/草', hasCard: true, label: '草' },
    { path: 's1/葉/image.png', value: 's1/葉', hasCard: true, label: '葉' },
    { path: 's1/蝶/image.png', value: 's1/蝶', hasCard: true, label: '蝶' },
    { path: 's1/蝸/image.png', value: 's1/蝸', hasCard: true, label: '蝸' },
    { path: 's1/跑1/image.png', value: 's1/跑1', hasCard: true, label: '跑' },
    { path: 's1/跳/image.png', value: 's1/跳', hasCard: true, label: '跳' },
    { path: 's1/青/image.png', value: 's1/青', hasCard: true, label: '青' },
    { path: 's1/鴨/image.png', value: 's1/鴨', hasCard: true, label: '鴨' },
    { path: 's1/休/image.png', value: 's1/休', hasCard: false, label: '休' },
    { path: 's1/坡/image.png', value: 's1/坡', hasCard: false, label: '坡' },
    { path: 's1/尾/image.png', value: 's1/尾', hasCard: false, label: '尾' },
    { path: 's1/枝/image.png', value: 's1/枝', hasCard: false, label: '枝' },
    { path: 's1/棵/image.png', value: 's1/棵', hasCard: false, label: '棵' },
    { path: 's1/清/image.png', value: 's1/清', hasCard: false, label: '清' },
    { path: 's1/耍/image.png', value: 's1/耍', hasCard: false, label: '耍' },
    { path: 's1/行1/image.png', value: 's1/行1', hasCard: false, label: '行' },
    { path: 's1/衣/image.png', value: 's1/衣', hasCard: false, label: '衣' },
    { path: 's1/間/image.png', value: 's1/間', hasCard: false, label: '間' },
    { path: 's1/隻/image.png', value: 's1/隻', hasCard: false, label: '隻' },
    { path: 's1/鵝/image.png', value: 's1/鵝', hasCard: false, label: '鵝' },

    { path: 's2/員/image.png', value: 's2/員', hasCard: true, label: '員' },
    { path: 's2/喇/image.png', value: 's2/喇', hasCard: true, label: '喇' },
    { path: 's2/垃/image.png', value: 's2/垃', hasCard: true, label: '垃' },
    { path: 's2/夏/image.png', value: 's2/夏', hasCard: true, label: '夏' },
    { path: 's2/帽2/image.png', value: 's2/帽2', hasCard: true, label: '帽' },
    { path: 's2/彩/image.png', value: 's2/彩', hasCard: true, label: '彩' },
    { path: 's2/排2/image.png', value: 's2/排2', hasCard: true, label: '排' },
    { path: 's2/星/image.png', value: 's2/星', hasCard: true, label: '星' },
    { path: 's2/曬/image.png', value: 's2/曬', hasCard: true, label: '曬' },
    { path: 's2/書2/image.png', value: 's2/書2', hasCard: true, label: '書' },
    { path: 's2/朋2/image.png', value: 's2/朋2', hasCard: true, label: '朋' },
    { path: 's2/殼/image.png', value: 's2/殼', hasCard: true, label: '殼' },
    { path: 's2/汗/image.png', value: 's2/汗', hasCard: true, label: '汗' },
    { path: 's2/沙/image.png', value: 's2/沙', hasCard: true, label: '沙' },
    { path: 's2/油/image.png', value: 's2/油', hasCard: true, label: '油' },
    { path: 's2/波2/image.png', value: 's2/波2', hasCard: true, label: '波' },
    { path: 's2/泳/image.png', value: 's2/泳', hasCard: true, label: '泳' },
    { path: 's2/浪/image.png', value: 's2/浪', hasCard: true, label: '浪' },
    { path: 's2/海/image.png', value: 's2/海', hasCard: true, label: '海' },
    { path: 's2/游/image.png', value: 's2/游', hasCard: true, label: '游' },
    { path: 's2/滑/image.png', value: 's2/滑', hasCard: true, label: '滑' },
    { path: 's2/玻/image.png', value: 's2/玻', hasCard: true, label: '玻' },
    { path: 's2/珠/image.png', value: 's2/珠', hasCard: true, label: '珠' },
    { path: 's2/薯/image.png', value: 's2/薯', hasCard: true, label: '薯' },
    { path: 's2/藍/image.png', value: 's2/藍', hasCard: true, label: '藍' },
    { path: 's2/蟹/image.png', value: 's2/蟹', hasCard: true, label: '蟹' },
    { path: 's2/褲/image.png', value: 's2/褲', hasCard: true, label: '褲' },
    { path: 's2/鏡/image.png', value: 's2/鏡', hasCard: true, label: '鏡' },
    { path: 's2/燒2/image.png', value: 's2/燒2', hasCard: false, label: '燒' },
    { path: 's2/聊2/image.png', value: 's2/聊2', hasCard: false, label: '聊' },
    { path: 's2/邊/image.png', value: 's2/邊', hasCard: false, label: '邊' },
    { path: 's2/風/image.png', value: 's2/風', hasCard: false, label: '風' },
    { path: 's2/魚/image.png', value: 's2/魚', hasCard: false, label: '魚' },

    { path: 's3/冬/image.png', value: 's3/冬', hasCard: true, label: '冬' },
    { path: 's3/哭/image.png', value: 's3/哭', hasCard: true, label: '哭' },
    { path: 's3/喝/image.png', value: 's3/喝', hasCard: true, label: '喝' },
    { path: 's3/地/image.png', value: 's3/地', hasCard: true, label: '地' },
    { path: 's3/套/image.png', value: 's3/套', hasCard: true, label: '套' },
    { path: 's3/寺/image.png', value: 's3/寺', hasCard: true, label: '寺' },
    { path: 's3/屋/image.png', value: 's3/屋', hasCard: true, label: '屋' },
    { path: 's3/店/image.png', value: 's3/店', hasCard: true, label: '店' },
    { path: 's3/怕/image.png', value: 's3/怕', hasCard: true, label: '怕' },
    { path: 's3/怪/image.png', value: 's3/怪', hasCard: true, label: '怪' },
    { path: 's3/拍/image.png', value: 's3/拍', hasCard: true, label: '拍' },
    { path: 's3/晚/image.png', value: 's3/晚', hasCard: true, label: '晚' },
    { path: 's3/機3/image.png', value: 's3/機3', hasCard: true, label: '機' },
    { path: 's3/歌/image.png', value: 's3/歌', hasCard: true, label: '歌' },
    { path: 's3/氣/image.png', value: 's3/氣', hasCard: true, label: '氣' },
    { path: 's3/河/image.png', value: 's3/河', hasCard: true, label: '河' },
    { path: 's3/物/image.png', value: 's3/物', hasCard: true, label: '物' },
    { path: 's3/男/image.png', value: 's3/男', hasCard: true, label: '男' },
    { path: 's3/相/image.png', value: 's3/相', hasCard: true, label: '相' },
    { path: 's3/空/image.png', value: 's3/空', hasCard: true, label: '空' },
    { path: 's3/站3/image.png', value: 's3/站3', hasCard: true, label: '站' },
    { path: 's3/童/image.png', value: 's3/童', hasCard: true, label: '童' },
    { path: 's3/紅/image.png', value: 's3/紅', hasCard: true, label: '紅' },
    { path: 's3/行3/image.png', value: 's3/行3', hasCard: true, label: '行' },
    { path: 's3/貓/image.png', value: 's3/貓', hasCard: true, label: '貓' },
    { path: 's3/跑3/image.png', value: 's3/跑3', hasCard: true, label: '跑' },
    { path: 's3/路/image.png', value: 's3/路', hasCard: true, label: '路' },
    { path: 's3/道/image.png', value: 's3/道', hasCard: true, label: '道' },
    { path: 's3/陽/image.png', value: 's3/陽', hasCard: true, label: '陽' },
    { path: 's3/雪3/image.png', value: 's3/雪3', hasCard: true, label: '雪' },
    { path: 's3/雲/image.png', value: 's3/雲', hasCard: true, label: '雲' },
    { path: 's3/餐/image.png', value: 's3/餐', hasCard: true, label: '餐' },
    { path: 's3/停/image.png', value: 's3/停', hasCard: false, label: '停' },
    { path: 's3/像/image.png', value: 's3/像', hasCard: false, label: '像' },
    { path: 's3/帽3/image.png', value: 's3/帽3', hasCard: false, label: '帽' },
    { path: 's3/樓/image.png', value: 's3/樓', hasCard: false, label: '樓' },
    { path: 's3/歡/image.png', value: 's3/歡', hasCard: false, label: '歡' },
    { path: 's3/背3/image.png', value: 's3/背3', hasCard: false, label: '背' },
    { path: 's3/糕/image.png', value: 's3/糕', hasCard: false, label: '糕' },

    { path: 's4/信/image.png', value: 's4/信', hasCard: true, label: '信' },
    { path: 's4/借/image.png', value: 's4/借', hasCard: true, label: '借' },
    { path: 's4/冷/image.png', value: 's4/冷', hasCard: true, label: '冷' },
    { path: 's4/問/image.png', value: 's4/問', hasCard: true, label: '問' },
    { path: 's4/回/image.png', value: 's4/回', hasCard: true, label: '回' },
    { path: 's4/圖/image.png', value: 's4/圖', hasCard: true, label: '圖' },
    { path: 's4/壁/image.png', value: 's4/壁', hasCard: true, label: '壁' },
    { path: 's4/奶4/image.png', value: 's4/奶4', hasCard: true, label: '奶' },
    { path: 's4/字/image.png', value: 's4/字', hasCard: true, label: '字' },
    { path: 's4/學/image.png', value: 's4/學', hasCard: true, label: '學' },
    { path: 's4/家/image.png', value: 's4/家', hasCard: true, label: '家' },
    { path: 's4/寫/image.png', value: 's4/寫', hasCard: true, label: '寫' },
    { path: 's4/師/image.png', value: 's4/師', hasCard: true, label: '師' },
    { path: 's4/幼/image.png', value: 's4/幼', hasCard: true, label: '幼' },
    { path: 's4/排4/image.png', value: 's4/排4', hasCard: true, label: '排' },
    { path: 's4/教/image.png', value: 's4/教', hasCard: true, label: '教' },
    { path: 's4/書4/image.png', value: 's4/書4', hasCard: true, label: '書' },
    { path: 's4/校41/image.png', value: 's4/校41', hasCard: true, label: '校' },
    { path: 's4/校42/image.png', value: 's4/校42', hasCard: true, label: '校' },
    { path: 's4/椅4/image.png', value: 's4/椅4', hasCard: true, label: '椅' },
    { path: 's4/機4/image.png', value: 's4/機4', hasCard: true, label: '機' },
    { path: 's4/窗/image.png', value: 's4/窗', hasCard: true, label: '窗' },
    { path: 's4/站4/image.png', value: 's4/站4', hasCard: true, label: '站' },
    { path: 's4/筆4/image.png', value: 's4/筆4', hasCard: true, label: '筆' },
    { path: 's4/背4/image.png', value: 's4/背4', hasCard: true, label: '背' },
    { path: 's4/英/image.png', value: 's4/英', hasCard: true, label: '英' },
    { path: 's4/茶4/image.png', value: 's4/茶4', hasCard: true, label: '茶' },
    { path: 's4/詞/image.png', value: 's4/詞', hasCard: true, label: '詞' },
    { path: 's4/課/image.png', value: 's4/課', hasCard: true, label: '課' },
    { path: 's4/讀/image.png', value: 's4/讀', hasCard: true, label: '讀' },
    { path: 's4/電4/image.png', value: 's4/電4', hasCard: true, label: '電' },
    { path: 's4/交/image.png', value: 's4/交', hasCard: false, label: '交' },
    { path: 's4/叫/image.png', value: 's4/叫', hasCard: false, label: '叫' },
    { path: 's4/封4/image.png', value: 's4/封4', hasCard: false, label: '封' },
    { path: 's4/收/image.png', value: 's4/收', hasCard: false, label: '收' },
    { path: 's4/服4/image.png', value: 's4/服4', hasCard: false, label: '服' },
    { path: 's4/植/image.png', value: 's4/植', hasCard: false, label: '植' },
    { path: 's4/粉/image.png', value: 's4/粉', hasCard: false, label: '粉' },
    { path: 's4/視4/image.png', value: 's4/視4', hasCard: false, label: '視' },
    { path: 's4/記/image.png', value: 's4/記', hasCard: false, label: '記' },

    { path: 's5/做/image.png', value: 's5/做', hasCard: true, label: '做' },
    { path: 's5/冰/image.png', value: 's5/冰', hasCard: true, label: '冰' },
    { path: 's5/吃/image.png', value: 's5/吃', hasCard: true, label: '吃' },
    { path: 's5/和/image.png', value: 's5/和', hasCard: true, label: '和' },
    { path: 's5/單/image.png', value: 's5/單', hasCard: true, label: '單' },
    { path: 's5/姐/image.png', value: 's5/姐', hasCard: true, label: '姐' },
    { path: 's5/婆/image.png', value: 's5/婆', hasCard: true, label: '婆' },
    { path: 's5/媽/image.png', value: 's5/媽', hasCard: true, label: '媽' },
    { path: 's5/房51/image.png', value: 's5/房51', hasCard: true, label: '房' },
    { path: 's5/房52/image.png', value: 's5/房52', hasCard: true, label: '房' },
    { path: 's5/扇/image.png', value: 's5/扇', hasCard: true, label: '扇' },
    { path: 's5/抄/image.png', value: 's5/抄', hasCard: true, label: '抄' },
    { path: 's5/故/image.png', value: 's5/故', hasCard: true, label: '故' },
    { path: 's5/服5/image.png', value: 's5/服5', hasCard: true, label: '服' },
    { path: 's5/杯/image.png', value: 's5/杯', hasCard: true, label: '杯' },
    { path: 's5/架/image.png', value: 's5/架', hasCard: true, label: '架' },
    { path: 's5/桌/image.png', value: 's5/桌', hasCard: true, label: '桌' },
    { path: 's5/椅5/image.png', value: 's5/椅5', hasCard: true, label: '椅' },
    { path: 's5/汁5/image.png', value: 's5/汁5', hasCard: true, label: '汁' },
    { path: 's5/燈5/image.png', value: 's5/燈5', hasCard: true, label: '燈' },
    { path: 's5/玩5/image.png', value: 's5/玩5', hasCard: true, label: '玩' },
    { path: 's5/看/image.png', value: 's5/看', hasCard: true, label: '看' },
    { path: 's5/眼/image.png', value: 's5/眼', hasCard: true, label: '眼' },
    { path: 's5/祖/image.png', value: 's5/祖', hasCard: true, label: '祖' },
    { path: 's5/笑/image.png', value: 's5/笑', hasCard: true, label: '笑' },
    { path: 's5/筆5/image.png', value: 's5/筆5', hasCard: true, label: '筆' },
    { path: 's5/視5/image.png', value: 's5/視5', hasCard: true, label: '視' },
    { path: 's5/雪5/image.png', value: 's5/雪5', hasCard: true, label: '雪' },
    { path: 's5/電5/image.png', value: 's5/電5', hasCard: true, label: '電' },
    { path: 's5/鞋5/image.png', value: 's5/鞋5', hasCard: true, label: '鞋' },
    { path: 's5/公/image.png', value: 's5/公', hasCard: false, label: '公' },
    { path: 's5/刷/image.png', value: 's5/刷', hasCard: false, label: '刷' },
    { path: 's5/功/image.png', value: 's5/功', hasCard: false, label: '功' },
    { path: 's5/奶5/image.png', value: 's5/奶5', hasCard: false, label: '奶' },
    { path: 's5/封5/image.png', value: 's5/封5', hasCard: false, label: '封' },
    { path: 's5/扒/image.png', value: 's5/扒', hasCard: false, label: '扒' },
    { path: 's5/掃/image.png', value: 's5/掃', hasCard: false, label: '掃' },
    { path: 's5/澡/image.png', value: 's5/澡', hasCard: false, label: '澡' },
    { path: 's5/開/image.png', value: 's5/開', hasCard: false, label: '開' },
    { path: 's5/音/image.png', value: 's5/音', hasCard: false, label: '音' },
    { path: 's5/食/image.png', value: 's5/食', hasCard: false, label: '食' },

    { path: 's6/圓/image.png', value: 's6/圓', hasCard: true, label: '圓' },
    { path: 's6/外/image.png', value: 's6/外', hasCard: true, label: '外' },
    { path: 's6/妹/image.png', value: 's6/妹', hasCard: true, label: '妹' },
    { path: 's6/拖/image.png', value: 's6/拖', hasCard: true, label: '拖' },
    { path: 's6/椅6/image.png', value: 's6/椅6', hasCard: true, label: '椅' },
    { path: 's6/機6/image.png', value: 's6/機6', hasCard: true, label: '機' },
    { path: 's6/汁6/image.png', value: 's6/汁6', hasCard: true, label: '汁' },
    { path: 's6/波6/image.png', value: 's6/波6', hasCard: true, label: '波' },
    { path: 's6/涼/image.png', value: 's6/涼', hasCard: true, label: '涼' },
    { path: 's6/燈6/image.png', value: 's6/燈6', hasCard: true, label: '燈' },
    { path: 's6/燒6/image.png', value: 's6/燒6', hasCard: true, label: '燒' },
    { path: 's6/牌/image.png', value: 's6/牌', hasCard: true, label: '牌' },
    { path: 's6/甜/image.png', value: 's6/甜', hasCard: true, label: '甜' },
    { path: 's6/碗/image.png', value: 's6/碗', hasCard: true, label: '碗' },
    { path: 's6/等/image.png', value: 's6/等', hasCard: true, label: '等' },
    { path: 's6/筷/image.png', value: 's6/筷', hasCard: true, label: '筷' },
    { path: 's6/聊6/image.png', value: 's6/聊6', hasCard: true, label: '聊' },
    { path: 's6/舅/image.png', value: 's6/舅', hasCard: true, label: '舅' },
    { path: 's6/茶61/image.png', value: 's6/茶61', hasCard: true, label: '茶' },
    { path: 's6/茶62/image.png', value: 's6/茶62', hasCard: true, label: '茶' },
    { path: 's6/菜/image.png', value: 's6/菜', hasCard: true, label: '菜' },
    { path: 's6/蛋/image.png', value: 's6/蛋', hasCard: true, label: '蛋' },
    { path: 's6/蝦/image.png', value: 's6/蝦', hasCard: true, label: '蝦' },
    { path: 's6/袋/image.png', value: 's6/袋', hasCard: true, label: '袋' },
    { path: 's6/錶/image.png', value: 's6/錶', hasCard: true, label: '錶' },
    { path: 's6/雞/image.png', value: 's6/雞', hasCard: true, label: '雞' },
    { path: 's6/鞋6/image.png', value: 's6/鞋6', hasCard: true, label: '鞋' },
    { path: 's6/飯/image.png', value: 's6/飯', hasCard: true, label: '飯' },
    { path: 's6/麵/image.png', value: 's6/麵', hasCard: true, label: '麵' },
    { path: 's6/點61/image.png', value: 's6/點61', hasCard: true, label: '點' },
    { path: 's6/點62/image.png', value: 's6/點62', hasCard: true, label: '點' },
    { path: 's6/侍/image.png', value: 's6/侍', hasCard: false, label: '侍' },
    { path: 's6/動6/image.png', value: 's6/動6', hasCard: false, label: '動' },
    { path: 's6/盤/image.png', value: 's6/盤', hasCard: false, label: '盤' },
    { path: 's6/細/image.png', value: 's6/細', hasCard: false, label: '細' },
    { path: 's6/荷/image.png', value: 's6/荷', hasCard: false, label: '荷' },
    { path: 's6/轉/image.png', value: 's6/轉', hasCard: false, label: '轉' },
    { path: 's6/酒/image.png', value: 's6/酒', hasCard: false, label: '酒' },
    { path: 's6/鐘/image.png', value: 's6/鐘', hasCard: false, label: '鐘' },
];
