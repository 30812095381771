import { Box } from '@mui/material';
import React from 'react';
import HkcsQ2Textbubble from './HkcsQ2Textbubble';

type HKcsQ2TextbubblesProp = {
    sentence: string;
    chosenWord: string;
};
const HKcsQ2Textbubbles = (prop: HKcsQ2TextbubblesProp) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '8px',
                flexDirection: 'row',
            }}>
            {prop.sentence.split('').map((char, index) => (
                <HkcsQ2Textbubble text={char} isPressed={prop.sentence.indexOf(prop.chosenWord) <= index && prop.sentence.indexOf(prop.chosenWord) + prop.chosenWord.length - 1 >= index} />
            ))}
        </Box>
    );
};

export default HKcsQ2Textbubbles;
