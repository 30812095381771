import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { createDebouncedSaveHandler } from '../components/dataComponents/props/functions/debouncedSaveHandler';

const debouncedSaveHandler = createDebouncedSaveHandler();

const GET_LESSON_BY_PARENT_GQL = gql`
    query GetLessonVersionByParent($lessonID: ID!) {
        lessonVersions(where: { AND: [{ parent: { id: { equals: $lessonID } } }, { published: { equals: false } }] }, orderBy: { dateCreated: desc }) {
            id
        }
    }
`;

const UPDATE_LESSON_NAME_GQL = gql`
    mutation saveLessonVersionName($lessonName: String!, $lessonVersionID: ID!) {
        updateLessonVersion(data: { lessonName: $lessonName }, where: { id: $lessonVersionID }) {
            lessonName
        }
    }
`;

export default function useLessonUpdate(lessonID: string, value: string) {
    const { accessDenied } = useContext(UserAuthContext);
    const { t } = useTranslation();
    const [lessonName, setLessonName] = useState('');
    const [lessonNameError, setLessonNameError] = useState('');

    useEffect(() => {
        setLessonName(value);
    }, [value]);

    const [getLessonVersionByParent] = useLazyQuery(GET_LESSON_BY_PARENT_GQL);

    const [updateLessonNameMutation] = useMutation(UPDATE_LESSON_NAME_GQL, {
        variables: {
            lessonName: '',
            lessonVersionID: '',
        },
    });
    const updateLessonNameMutationValidation = Yup.object().shape({
        lessonName: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
    });

    const onChangeHandler = async (newValue: string) => {
        setLessonName(newValue);
        try {
            await validateLessonField(newValue);
            debouncedSaveHandler(async () => {
                await onBlurHandler(newValue);
            });
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            }
        }
    };

    const onBlurHandler = async (newValue: string) => {
        // console.log(event.target.value);
        if (lessonID) {
            try {
                await validateLessonField(newValue);
                const lessonVersion = await getLessonVersionByParent({
                    variables: { lessonID: lessonID ?? '' },
                });
                const lessonVersionId = lessonVersion.data && lessonVersion.data.lessonVersions.length > 0 && lessonVersion.data.lessonVersions[0].id;

                await updateLessonNameMutation({
                    variables: {
                        lessonName: newValue,
                        lessonVersionID: lessonVersionId,
                    },
                });
                // console.log('done');
            } catch (err) {
                console.log('errrr', err);
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                }
            }
        }
    };

    const validateLessonField = (name: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                await updateLessonNameMutationValidation.validate({
                    lessonName: name,
                });

                setLessonNameError('');
                resolve('');
            } catch (err: any) {
                setLessonNameError(err.message);
                reject(err.message);
            }
        });
    };

    return {
        lessonName,
        lessonNameError,
        onChangeHandler,
        onBlurHandler,
    };
}
