import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';
import { StudentProfileModel } from './StudentModel';

const QUERY_GQL = gql`
    query StudentProfiles($classID: ID!) {
        studentProfiles(where: { class: { id: { equals: $classID } }, NOT: [{ user: null }] }) {
            id
            user {
                id
            }
            givenName
            familyName
            studentNumber
            profilePic {
                url
            }
        }
    }
`;

type QueryVariableType = {
    classID: string;
};

type QueryType = {
    studentProfiles: StudentProfileModel[];
};

export const useStudentQuery = () => {
    const auth = useContext(UserAuthContext);
    const [getForm, { loading, data }] = useLazyQuery<QueryType>(QUERY_GQL, {
        fetchPolicy: 'network-only',
    });
    const fetchStudents = async (variables: QueryVariableType) => {
        try {
            const result = await getForm({
                variables,
            });

            if (!result || !result.data?.studentProfiles) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            return result?.data?.studentProfiles;
        } catch (err) {
            console.log(err);
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { fetchStudents, loading, data };
};
