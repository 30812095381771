import { Grid, Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

type SupportsData = {
    question: string;
    answer: string;
    id: string;
};

const Support_Query = gql`
    query SupportData {
        supports(orderBy: { question: asc }) {
            question
            answer
            id
        }
    }
`;

export const SupportPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { data, loading } = useQuery(Support_Query);
    if (loading) return <PopupCircularProgress isOpened={loading} />;
    return (
        <Stack direction='column' justifyContent='center' alignItems='center' className='pageCustom'>
            <Box
                width={{
                    xs: 300,
                    sm: 540,
                    md: 800,
                    lg: 1024,
                    xl: 1280,
                }}>
                <Grid container spacing={1} direction='column' key={data.id}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>{t('support-message')}</Typography>
                    </Grid>
                    {data.supports?.map((data: SupportsData) => (
                        <Grid key={data.id}>
                            <Accordion
                                sx={{
                                    color: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.paper,
                                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main,
                                }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography>{data.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{data.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    );
};
