import { Button, SxProps, Theme } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

export const DoSound = (props: { soundString: string; phrase: string; groupIndex: number; folder: 'jyutping' | 'pinyin'; sx?: SxProps<Theme>; children: ReactNode }) => {
    const { t } = useTranslation();

    const playAudio = () => {
        const phraseCharacterCount = props.phrase.replace(/[（）\(\)]/g, '').length;
        const sounds = props.soundString.split(' ');
        const yp = sounds.slice(props.groupIndex * phraseCharacterCount, props.groupIndex * phraseCharacterCount + phraseCharacterCount);

        const audio = new Audio();
        audio.src = '/characters/sounds/' + props.folder + '/' + yp?.[0] + '.mp3';
        var count = 0;
        audio.onended = function () {
            count++;
            audio.src = '/characters/sounds/' + props.folder + '/' + yp?.[count] + '.mp3';
            if (yp && count > yp.length - 1) {
                count = 0;
            } else {
                audio.play();
            }
        };
        audio.play();
    };

    // Render nothing, this component only plays sounds
    return (
        <Button
            variant='contained'
            className='hidden-button'
            startIcon={<VolumeUpIcon />}
            sx={props.sx}
            onClick={(e) => {
                playAudio();
            }}>
            {props.children}
        </Button>
    );
};
