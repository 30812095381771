import { Box, Card } from '@mui/material';
import { TopPerformanceCardProp } from 'pages/results/hook/useTopPerformanceCardHook';
import TopStudentPerformanceCard from '../../OverallResultPage/TopStudentPerformanceCard';
import { AvgScorePerStudent } from 'pages/results/hook/useOverallTestroomResult';
import { useEffect, useState } from 'react';
import moment from 'moment';
type StudentDataType = {
    studentData: AvgScorePerStudent[];
};
const OverallStudentRanking = (props: StudentDataType) => {
    const [topThreeStudents, setTop3Students] = useState<AvgScorePerStudent[]>([]);
    useEffect(() => {
        if (props.studentData != undefined) {
            let sortedArray = [...props.studentData].sort((a, b) => (typeof b.totalCorrect === 'string' ? 0 : b.totalCorrect) - (typeof a.totalCorrect === 'string' ? 0 : a.totalCorrect));
            setTop3Students(sortedArray);
        }
    }, [props]);

    return (
        <Box sx={{ flex: 1 }}>
            <Card
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'flex-start',
                    borderRadius: '4px',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                }}>
                <Box component='img' src='/images/main/trophy.png' sx={{ width: '100%', display: 'flex', flexShrink: 0 }} />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        flexDirection: 'column',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                        }}>
                        {topThreeStudents.length > 0 && (
                            <TopStudentPerformanceCard
                                studentName={topThreeStudents[0].studentName ? topThreeStudents[0].studentName : ''}
                                totalScore={topThreeStudents[0].totalCorrect ? topThreeStudents[0].totalCorrect : 0}
                                time={topThreeStudents[0].totalTime ? moment.utc(topThreeStudents[0].totalTime * 1000).format('mm:ss') : ''}
                                index={1}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                        }}>
                        {topThreeStudents.length > 1 && (
                            <TopStudentPerformanceCard
                                studentName={topThreeStudents[1].studentName ? topThreeStudents[1].studentName : ''}
                                totalScore={topThreeStudents[1].totalCorrect ? topThreeStudents[1].totalCorrect : 0}
                                time={topThreeStudents[1].totalTime ? moment.utc(topThreeStudents[1].totalTime * 1000).format('mm:ss') : ''}
                                index={2}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                        }}>
                        {topThreeStudents.length > 2 && (
                            <TopStudentPerformanceCard
                                studentName={topThreeStudents[2].studentName ? topThreeStudents[2].studentName : ''}
                                totalScore={topThreeStudents[2].totalCorrect ? topThreeStudents[2].totalCorrect : 0}
                                time={topThreeStudents[2].totalTime ? moment.utc(topThreeStudents[2].totalTime * 1000).format('mm:ss') : ''}
                                index={3}
                            />
                        )}{' '}
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default OverallStudentRanking;
