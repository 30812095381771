import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlipIcon from '@mui/icons-material/Flip';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import { SlideComponent, SlideComponentCharacter } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EditIcon from '@mui/icons-material/Edit';
import { SetStateAction, useEffect } from 'react';
import { DoSound } from './components/DoSound';
import { SelectImageBox } from './components/SelectImageBox';
import { SelectWordsBox, forceChangeGameType, isWritingOnlyType } from './components/SelectWordsBox';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export const LessonsSlidePropsCharacter = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentCharacter = item.content as SlideComponentCharacter;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const saveUploadedImage = (imageURL: string) => {
        item.size.width = 0;
        item.size.height = 0;
        itemContent.imageURL = imageURL;
    };

    const deleteUploadedImage = () => {
        itemContent.imageURL = '';
        itemContent.image = '';
    };

    useEffect(() => {
        setCanSave?.((itemContent.image !== '' || itemContent.imageURL !== '') && itemContent.characterList.length > 0);
    }, [item]);

    return (
        <Stack spacing={2} sx={{ mt: 2, m: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                }}>
                <Box sx={{ flex: '1 1 100px' }}>
                    <SelectImageBox
                        keepMargin={true}
                        imageURL={itemContent.imageURL}
                        image={itemContent.image}
                        flip={itemContent.flip}
                        onSelected={(imageURL) => {
                            saveUploadedImage(imageURL);
                            updateUI(true);
                        }}
                        handleDelete={() => {
                            deleteUploadedImage();
                            updateUI(true);
                        }}
                        onClickHandler={(option) => {
                            // Show the default character
                            // if (
                            //   itemContent.character === '' ||
                            //   itemContent.character === itemContent.image
                            // ) {
                            //   itemContent.character = option?.value ?? '';
                            // }
                            item.size.width = 0;
                            item.size.height = 0;
                            itemContent.image = option?.value ?? '';

                            itemContent.gameType = forceChangeGameType(itemContent.characterList[itemContent.character], itemContent.gameType);

                            updateUI(true);
                        }}
                        sx={{ alignSelf: 'flex-start' }}
                    />
                </Box>
                <Box sx={{ flex: '1 1 100px' }}>
                    <SelectWordsBox
                        singleWord={false}
                        canSelectQuestion={true}
                        questionLabel={t('question')}
                        characterList={itemContent?.characterList}
                        phrase={itemContent.phrase}
                        phraseSound={itemContent.phraseSound}
                        phraseSoundGroup={itemContent.phraseSoundGroup}
                        onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                            itemContent.phraseSoundGroup = phraseSoundGroup;
                            updateUI(true);
                        }}
                        selectedCharacterIndex={itemContent.character}
                        onCharacterPicked={(characterPhrase) => {
                            itemContent.characterList = characterPhrase?.character;
                            itemContent.character = 0;
                            itemContent.characterSound = {
                                jyutping: characterPhrase?.character?.[itemContent.character]?.jyutping,
                                pinyin: characterPhrase?.character?.[itemContent.character]?.pinyin,
                            };
                            itemContent.phrase = characterPhrase?.phrase;
                            itemContent.phraseSound = {
                                jyutping: characterPhrase?.jyutping,
                                pinyin: characterPhrase?.pinyin,
                            };
                            itemContent.gameType = forceChangeGameType(itemContent.characterList[itemContent.character], itemContent.gameType);
                            updateUI(true);
                        }}
                        onQuestionCharacterSelected={(index, character) => {
                            itemContent.character = index;

                            itemContent.characterSound = {
                                jyutping: character.jyutping,
                                pinyin: character.pinyin,
                            };

                            itemContent.gameType = forceChangeGameType(itemContent.characterList[itemContent.character], itemContent.gameType);

                            updateUI(true);
                        }}
                    />
                </Box>
            </Box>

            <Stack direction='row' alignItems='center' spacing={1}>
                <ToggleButton
                    value='1'
                    selected={itemContent.flip === -1}
                    onChange={() => {
                        if (itemContent.flip === -1) {
                            itemContent.flip = 1;
                        } else {
                            itemContent.flip = -1;
                        }
                        updateUI(true);
                    }}
                    aria-label='bold'
                    sx={{
                        borderRadius: '12px',
                        fontWeight: '700',
                        fontSize: '18px',
                        lineHeight: '150%',
                        color: '#FDFCFC',
                        backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                        '&:hover': {
                            backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                        },
                    }}>
                    <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                </ToggleButton>
            </Stack>

            <Stack direction='row' justifyContent='flex-start' spacing={2}>
                <DoSound
                    soundString={itemContent?.phraseSound?.jyutping?.[0]?.sound}
                    phrase={itemContent?.phrase}
                    groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                    folder='jyutping'
                    sx={{
                        bgcolor: '#23B1D3',
                        color: '#FAFAFA',
                        '&:hover': {
                            bgcolor: '#23B1D3',
                            color: '#FAFAFA',
                            opacity: '0.7',
                        },
                        pointerEvents: 'auto',
                    }}>
                    {t('cantonese')}
                </DoSound>

                <DoSound
                    soundString={itemContent?.phraseSound?.pinyin?.[0]?.sound}
                    phrase={itemContent?.phrase}
                    groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                    folder='pinyin'
                    sx={{
                        bgcolor: '#FFFFFF',
                        color: '#29C0E7',
                        '&:hover': {
                            bgcolor: '#FFFFFF',
                            color: '#29C0E7',
                            opacity: '0.7',
                        },
                        pointerEvents: 'auto',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#29C0E7',
                    }}>
                    {t('putonghua')}
                </DoSound>
            </Stack>

            <Stack direction='row' justifyContent='space-evenly' spacing={1}>
                <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>{t('cardOrWriting')}</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={itemContent.gameType}
                        label={t('cardOrWriting')}
                        onChange={(event: SelectChangeEvent) => {
                            itemContent.gameType = forceChangeGameType(itemContent.characterList[itemContent.character], event.target.value);

                            updateUI(true);
                        }}>
                        <MenuItem value='OneCard' disabled={isWritingOnlyType(itemContent?.characterList?.[itemContent?.character])}>
                            <Stack direction='row' spacing={1}>
                                <InsertPhotoIcon />
                                <Typography>{t('oneCard')}</Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem value='TwoCards' disabled={isWritingOnlyType(itemContent?.characterList?.[itemContent?.character])}>
                            <Stack direction='row' spacing={1}>
                                <PhotoLibraryIcon />
                                <Typography>{t('twoCards')}</Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem value='Writing'>
                            <Stack direction='row' spacing={1}>
                                <EditIcon />
                                <Typography>{t('writing')}</Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem value='Audio'>
                            <Stack direction='row' spacing={1}>
                                <VolumeUpIcon />
                                <Typography>{t('audio')}</Typography>
                            </Stack>
                        </MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label={t('layer')}
                    type='number'
                    sx={{ width: '100%' }}
                    value={itemContent.zIndex}
                    onChange={(e) => {
                        let v: number = parseInt(e.target.value === '' ? '0' : e.target.value);
                        if (v < 0) {
                            v = 0;
                        }
                        // console.log(v);
                        itemContent.zIndex = v;
                        updateUI(false);
                        debouncedSaveHandler(() => {
                            updateUI(true);
                        });
                    }}
                    // onBlur={() => {
                    //   updateUI(true);
                    // }}
                />
            </Stack>
        </Stack>
    );
};
