import { ERROR_TYPE } from 'models/ErrorHandling';
import { createContext, useState } from 'react';
import { Constants } from '../Constants';
import { useLocalStorage } from '../hooks/UseLocalStorage';
import { SchoolModel } from 'models/school/SchoolModel';

export type UserData = {
    id: string;
    name: string;
    email: string;
    role: string;
    schools?: SchoolModel[];
    teacherProfileId?: string;
    expiry?: number;
    googleId: string;
    googleEmail: string;
    googleProfilePicture: string;
};

const contextValues: UserContextType = {
    isUserLoggedIn: false,
    setUserData: () => {},
    logout: () => {},
    accessDenied: (err: string) => {
        return false;
    },
    setSelectedSchool: () => {},
    rememberMe: false,
    setRememberMe: () => {},
};

export type UserContextType = {
    isUserLoggedIn: boolean;
    userData?: UserData;
    setUserData: (userData: UserData) => void;
    logout: () => void;
    accessDenied: (err: string) => boolean;
    selectedSchool?: SchoolModel;
    setSelectedSchool: (school: SchoolModel) => void;
    rememberMe: boolean;
    setRememberMe: (rememberMe: boolean) => void;
};

export const UserAuthContext = createContext<UserContextType>(contextValues);

export const UserAuthContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [sessionValue, setSessionValue] = useLocalStorage<UserData | undefined>(Constants.localStorageKeys.userData, undefined);
    const [selectedSchoolValue, setSelectedSchoolValue] = useLocalStorage<SchoolModel | undefined>(Constants.localStorageKeys.selectedSchool, undefined);

    const logout = () => {
        localStorage.removeItem('token');
        setSessionValue(undefined);
        setSelectedSchoolValue(undefined);
    };

    const [rememberMe, setRememberMe] = useState<boolean>(false);

    return (
        <UserAuthContext.Provider
            value={{
                isUserLoggedIn: (() => {
                    if (sessionValue && sessionValue.expiry && Date.now() > sessionValue.expiry) {
                        logout(); // This will remove expired user data
                        return false;
                    }
                    return !!sessionValue;
                })(),
                userData: sessionValue,
                setUserData: setSessionValue,
                logout,
                accessDenied: (err) => {
                    if (err.startsWith('Error: Access denied:') || err === ERROR_TYPE.KS_ACCESS_DENIED) {
                        logout();
                        return true;
                    }
                    return false;
                },
                selectedSchool: selectedSchoolValue,
                setSelectedSchool: (school: SchoolModel) => setSelectedSchoolValue(school),

                rememberMe,
                setRememberMe,
            }}>
            {children}
        </UserAuthContext.Provider>
    );
};
