import React, { useContext, useState } from 'react';
import { PlanInfo } from 'pages/payment/components/PlanInfo';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { Stack, Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@mui/material';
import { InvoicePreview } from '../../payment/components/InvoicePreview';
import useSubscriptionHook from '../hooks/useSubscriptionHook';
import { NotPaymentUserDialog } from './NotPaymentUserDialog';
import { wait } from '@testing-library/user-event/dist/utils';
interface SubscriptionInfoProps {
    t: (key: string) => string;

    // Include other props as needed, like auth data or translation function
}

export const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({ t }) => {
    const auth = useContext(UserAuthContext);
    const [showContactPaymentUserDialog, setShowContactPaymentUserDialog] = useState(false);
    const [openInvoice, setOpenInvoice] = useState(false);
    const [selectedPlanName, setSelectedPlanName] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const {
        handleCancelPlan,
        handleUpcomingInvoiceClick,
        // handleCheckout,
        handleSyncStripeData,
    } = useSubscriptionHook(setLoading, setError);

    const handleCloseInvoice = () => {
        setOpenInvoice(false);
        // window.location.reload();
    };

    const handleCloseContactPaymentUserDialog = () => {
        setShowContactPaymentUserDialog(false);
    };

    const handleCancel = async () => {
        try {
            setLoading(true);
            await handleCancelPlan();
            // Update state or context instead of reloading the page
        } catch (error) {
            console.error('Failed to cancel plan:', error);
            // Handle error (e.g., show error message to the user)
        } finally {
            // handleSyncStripeData(true);
            await wait(2000);
            setLoading(false);
            window.location.reload();
        }
    };

    const handleSync = async () => {
        try {
            setLoading(true);
            await handleSyncStripeData(true);
            // Update state or context instead of reloading the page
        } catch (error) {
            console.error('Failed to sync with Stripe:', error);
            // Handle error (e.g., show error message to the user)
        } finally {
            setLoading(false);
            window.location.reload();
        }
    };

    return (
        <Stack spacing={2} minWidth={480} minHeight={350} alignItems={'left'}>
            {/* TODO: [TTEACH-300] Remove this after development work is done */}
            {auth.userData?.email.endsWith('kazootechnology.com') ? <Button onClick={handleSync}>{loading ? <CircularProgress size={20} /> : 'Sync with Stripe'}</Button> : <></>}

            <PlanInfo
                teachersAccountCount={auth.selectedSchool?.teachers.length ?? 0}
                paymentUser={auth.selectedSchool?.paymentUser?.name + ' (' + auth.selectedSchool?.paymentUser?.email + ')' || ''}
                onChangePlan={() => {}}
                planName={t(auth.selectedSchool?.subscriptionHistories?.[0]?.product?.internalName || '')}
                // isOnTrial={true} //auth.selectedSchool?.subscriptionHistories?.[0].isOnTrial || false}
                // trialDaysRemaining={3} //auth.selectedSchool?.subscriptionHistories?.[0].trialDaysRemaining || 0}
                expiryDate={auth.selectedSchool?.subscriptionHistories?.[0]?.expiryDate}
                autoRenew={auth.selectedSchool?.subscriptionHistories?.[0]?.autoRenew}
                onCancelPlan={handleCancel}
                onDownloadInvoice={handleUpcomingInvoiceClick}
                isFreePlan={auth.selectedSchool?.subscriptionHistories?.[0]?.product?.isFreePlan}
                isLoading={loading}
            />

            {/* Dialog for Invoice Preview */}
            <Dialog open={openInvoice} onClose={handleCloseInvoice} maxWidth='sm' fullWidth>
                <DialogTitle>{t('invoice.invoice-preview-title')}</DialogTitle>
                <DialogContent>
                    <InvoicePreview upgradePlanName={selectedPlanName} />
                </DialogContent>
            </Dialog>

            {/* Dialog for asking User to ask Payment User to handle changes to subscriptions */}

            <NotPaymentUserDialog t={t} open={showContactPaymentUserDialog} setOpen={setShowContactPaymentUserDialog} />
        </Stack>
    );
};
