import React, { useContext, useEffect, useState } from 'react';
import { PlanInfo } from 'pages/payment/components/PlanInfo';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { Stack, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
import { InvoicePreview } from '../../payment/components/InvoicePreview';
import { useSchoolQuery } from 'models/school/useSchoolQuery';

export default function useSubscriptionHook(setLoading: React.Dispatch<React.SetStateAction<boolean>>, setError: React.Dispatch<React.SetStateAction<string>>) {
    const auth = useContext(UserAuthContext);
    const [paymentUserId, setPaymentUserId] = useState(auth.selectedSchool?.paymentUser?.id || auth.userData?.id);
    const [paymentUserName, setPaymentUserName] = useState(auth.selectedSchool?.paymentUser?.name || auth.userData?.name);
    const [paymentUserEmail, setPaymentUserEmail] = useState(auth.selectedSchool?.paymentUser?.email || auth.userData?.email);
    // const [stripeCustomerId, setStripeCustomerId] = useState(
    //   auth.selectedSchool?.subscriptionHistories?.[0]?.stripeCustomerId,
    // );
    const [subscriptionId, setSubscriptionId] = useState(auth.selectedSchool?.subscriptionHistories?.[0]?.stripeSubscriptionId);
    const [schoolName, setSchoolName] = useState(auth.selectedSchool?.schoolName);
    const [schoolId, setSchoolId] = useState(auth.selectedSchool?.id);
    const { fetchSchool } = useSchoolQuery();

    const handleSyncStripeData = async (syncCurrentSchoolOnly?: boolean) => {
        try {
            setLoading(true);
            const requestData = {
                // paymentMode: 'subscription',
                // stripeCustomerId: auth.selectedSchool?.subscriptionHistories?.[0].stripeCustomerId,
                syncCurrentSchoolOnly: syncCurrentSchoolOnly || false,
                schoolId: auth.selectedSchool?.id || '',
            };

            // Send POST request to your server
            const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/sync-stripe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any authentication tokens if required
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            // Handle the response. For example, show a confirmation message
            console.log('Stripe data synced with database', responseData);

            // Update UI or redirect as needed
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            // Handle errors here, such as displaying a message to the user
        } finally {
            setLoading(false);
        }
    };

    const handleCancelPlan = async () => {
        try {
            const schoolId = auth.selectedSchool?.id;
            const requestData = {
                stripeCustomerId: auth.selectedSchool?.subscriptionHistories?.[0].stripeCustomerId,
                schoolId: schoolId,
            };

            // Send POST request to your server
            const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any authentication tokens if required
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            // Handle the response. For example, show a confirmation message
            console.log('Subscription cancelled successfully:', responseData);
            // Update UI or redirect as needed
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            // Handle errors here, such as displaying a message to the user
        }
    };

    const handleUpcomingInvoiceClick = async () => {
        const customerId = auth.selectedSchool?.subscriptionHistories?.[0]?.stripeCustomerId;
        const currentSubscriptionId = auth.selectedSchool?.subscriptionHistories?.[0]?.stripeSubscriptionId;
        const internalPlanName = auth.selectedSchool?.subscriptionHistories?.[0]?.product.internalName;
        // console.log('Kendall', customerId, currentSubscriptionId, newPriceId);

        try {
            // Send POST request to your server
            const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/upcoming-invoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId,
                    currentSubscriptionId,
                    internalPlanName,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const invoiceData = await response.json();

            // Process the invoice data
            console.log('Upcoming Invoice:', invoiceData);
            // Display the invoice details to the user, or handle them as needed
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here, such as displaying a message to the user
        }
    };

    const handleChangeSubscription = async (internalPlanName: string, setOpenInvoice?: React.Dispatch<React.SetStateAction<boolean>>) => {
        // Your logic to send the order to the backend
        console.log('auth.selectedSchool', auth.selectedSchool);
        const requestData = {
            planName: internalPlanName,
            paymentMode: 'subscription',
            stripeCustomerId: auth.selectedSchool?.subscriptionHistories?.[0].stripeCustomerId,
            schoolId: auth.selectedSchool?.id || '',
            schoolName: auth.selectedSchool?.schoolName || '',
        };
        console.log('handleChangeSubscription requestData', requestData);

        try {
            // Send POST request to your server
            const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/change-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('Response:', response);

            const sessionData = await response.json();
            // Redirect to Stripe's hosted checkout page, if available
            if (sessionData?.url) {
                window.location.href = sessionData.url;
            }
            return sessionData;
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here, such as displaying a message to the user
        }

        // Close the Invoice Preview dialog after the order is sent
        if (setOpenInvoice) {
            setOpenInvoice(false);
        }
    };

    const handleCheckout = async (internalPlanName: string, setOpenInvoice?: React.Dispatch<React.SetStateAction<boolean>>) => {
        // Your logic to send the order to the backend
        const requestData = {
            planName: internalPlanName,
            paymentMode: 'subscription',
            stripeCustomerId: auth.selectedSchool?.subscriptionHistories?.[0].stripeCustomerId,
            schoolId: auth.selectedSchool?.id || '',
            schoolName: auth.selectedSchool?.schoolName || '',
            // userId: auth.userData?.id,
            // userEmail: auth.userData?.email,
            // userName: auth.userData?.name,
        };
        console.log('requestData', requestData);

        try {
            // Send POST request to your server
            const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Response:', response);
            const sessionData = await response.json();
            // Redirect to Stripe's hosted checkout page
            window.location.href = sessionData.url;
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here, such as displaying a message to the user
        }

        // Close the Invoice Preview dialog after the order is sent
        if (setOpenInvoice) {
            setOpenInvoice(false);
        }
    };

    const fetchCustomerDetails = async () => {
        console.log('Inside fetchCustomerDetails..');
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/retrieve-customer-by-email-school`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: paymentUserEmail,
                metadata: {
                    school_id: schoolId,
                    school_name: schoolName,
                },
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer details');
        }

        return response.json();
    };

    const fetchPriceDifference = async (internalPlanName: string) => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/fetch-price-difference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                internalPlanName: internalPlanName,
                schoolId: auth.selectedSchool?.id,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch product details');
        }
        return response.json();
    };

    const fetchUnusedCredits = async () => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/fetch-unused-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                schoolId: auth.selectedSchool?.id,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch product details');
        }
        return response.json();
    };

    const fetchSubscriptionDetails = async () => {
        const schoolId = auth.selectedSchool?.id;
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/retrieve-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                schoolId: schoolId,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer details');
        }
        return response.json();
    };

    const fetchProductDetails = async (internalPlanName: string) => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/retrieve-product`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                internalPlanName: internalPlanName,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch product details');
        }
        return response.json();
    };

    const createCustomer = async () => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/create-stripe-customer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: paymentUserEmail,
                name: paymentUserName,
                metadata: {
                    school_id: schoolId,
                    school_name: schoolName,
                },
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create customer in Stripe');
        }

        return response.json();
    };

    const updateSubscriptionHistory = async (customerId: string, skipDuplicate: boolean) => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/update-subscription-history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                newSubscriptionRecord: {
                    schoolId: schoolId,
                    priceId: null,
                    expiryDate: null,
                    stripeSubscriptionId: null,
                    stripeCustomerId: customerId,
                },
                skipDuplicate: skipDuplicate,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update subscription history');
        }

        // Puts updated database data into userAuth context
        await fetchSchool({ userID: auth.userData?.id ?? '' });
        const responseData = await response.json();
        // console.log('Response Data:', responseData);
        return responseData;
    };

    const fetchInvoiceDetails = async (currentSubscriptionId: string, internalPlanName: string, setInvoiceDetails: React.Dispatch<React.SetStateAction<any>>) => {
        const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/upcoming-invoice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentUserId,
                currentSubscriptionId,
                internalPlanName,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch invoice details');
        }

        const data = await response.json();
        setInvoiceDetails(data);
    };

    return {
        fetchInvoiceDetails,
        handleCancelPlan,
        handleUpcomingInvoiceClick,
        handleCheckout,
        updateSubscriptionHistory,
        createCustomer,
        fetchCustomerDetails,
        fetchSubscriptionDetails,
        handleChangeSubscription,
        handleSyncStripeData,
        fetchProductDetails,
        fetchPriceDifference,
        fetchUnusedCredits,
    };
}
