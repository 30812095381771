import { Stack } from '@mui/material';
import { Moment } from 'moment';
import { useMemo } from 'react';
import { UserData } from '../../contexts/UserAuthContext';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AvTimer from '@mui/icons-material/AvTimer';
import { useTranslation } from 'react-i18next';

export interface LoginStatusBarProps {
    user: UserData;
    date?: Moment;
}

export const LoginStatusBar = (props: LoginStatusBarProps) => {
    const { user, date } = props;
    const { t } = useTranslation();

    const formattedDate = useMemo(() => {
        if (date) {
            const dayOfWeek = date.format('llll').split(',')[0];
            const dateStr = date.format('L');
            const time = date.format('LT');
            return `${dayOfWeek}, ${dateStr}, ${time}`;
        } else {
            return date;
        }
    }, [date]);

    return (
        <Stack
            direction='row'
            spacing={8}
            sx={{
                backgroundColor: '#F8F0E0',
                padding: '8px 12px',
                borderRadius: '50px',
                width: 'fit-content',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '150%',
                color: '#281010',
            }}>
            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                <AccountCircle />
                <div>
                    {t('userName')}: {user.name}
                </div>
            </Stack>
            {date && (
                <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                    <AvTimer />
                    <div>{formattedDate}</div>
                </Stack>
            )}
        </Stack>
    );
};
