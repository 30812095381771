import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';

export const GET_STUDENT_INFO = gql`
    query Class($where: ClassWhereUniqueInput!) {
        class(where: $where) {
            students {
                id
                familyName
                givenName
            }
        }
    }
`;

export const get_question_count = (classId: string) => {
    return {
        where: {
            id: classId,
        },
    };
};

export type ModelQueryType = {
    class?: StudentIds;
};

export type StudentId = {
    id: string;
    familyName: string;
    givenName: string;
};

type StudentIds = {
    students: StudentId[];
};

type QueryVariableType = {
    classId: string;
};

export const useGetClassStudentIds = () => {
    const auth = useContext(UserAuthContext);
    const [getStudentInfo, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_STUDENT_INFO);
    const getStudentIds = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getStudentInfo({
                variables: get_question_count(variables.classId),
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getStudentIds };
};
