import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

import useLessonSlide from '../hooks/useLessonSlide';
import { useContext } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';

export const LessonsAddSlideButton = ({ lessonID, lessonVersionID }: { lessonID: string; lessonVersionID: string }) => {
    const { t } = useTranslation();
    const { accessDenied } = useContext(UserAuthContext);

    const { addSlide } = useLessonSlide();

    const handleClick = async () => {
        // console.log(props.lessonID);
        try {
            await addSlide(lessonID, lessonVersionID);
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    return (
        <Button
            variant='contained'
            startIcon={<AddIcon />}
            sx={{
                borderRadius: '0px',
                borderBottomLeftRadius: '24px',
                width: '100%',
                height: '60px',
            }}
            onClick={handleClick}>
            {t('add-slide')}
        </Button>
    );
};
