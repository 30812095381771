import Typography from '@mui/material/Typography';

export const TermsConditionsContent = () => {
    const pt5 = () => {
        return (
            <ol type='a'>
                <li>Upload, post, or transmit any content that is illegal, obscene, defamatory, threatening, or otherwise objectionable;</li>
                <li>Infringe the intellectual property rights of any third party;</li>
                <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                <li>Interfere with or disrupt the operation of Turtle Teach or any servers or networks connected to Turtle Teach;</li>
                <li>Violate any applicable laws or regulations.</li>
            </ol>
        );
    };
    const items = [
        {
            header: 'Acceptance of Terms',
            content: `By accessing or using Turtle Teach, you agree to these Terms and any future updates. If you do not agree to these Terms, you may not use Turtle Teach.,`,
        },
        {
            header: 'Access and Use of Turtle Teach',
            content: `You may access and use Turtle Teach only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of Turtle Teach complies with all applicable laws and regulations.,`,
        },
        {
            header: 'Intellectual Property Rights',
            content: `The content on Turtle Teach, including all text, graphics, logos, images, and software, is the property of Kazoo and its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use or reproduce any of the content on Turtle Teach without prior written permission from Kazoo.,`,
        },
        {
            header: 'User Content',
            content: `Users of Turtle Teach may upload, post, or otherwise share content, including teaching materials. By uploading or sharing content, you grant Kazoo a non-exclusive, worldwide, royalty-free, and perpetual license to use, reproduce, modify, adapt, publish, and distribute the content in any form, media, or technology now known or later developed. You represent and warrant that you have all necessary rights to grant this license and that your content does not infringe the rights of any third party.,`,
        },
        {
            header: 'Prohibited Conduct',
            content: pt5(),
        },
        {
            header: 'Data Sharing with Third-Party Tools',
            content: `By signing up for Turtle Teach, you agree to explicitly consent to the sharing of your data with third-party tools, such as OpenAI's ChatGPT API, as described in our Privacy Policy. The data shared with these tools may include the question prompts and other non-personal information necessary for generating teaching materials. We do not share any personal information with these tools. You can withdraw your consent at any time by following the instructions provided in our Privacy Policy.,`,
        },
        {
            header: 'Disclaimers',
            content: `Turtle Teach is provided "as is" and without warranty of any kind, whether express, implied, or statutory. Kazoo does not warrant that Turtle Teach will be error-free or uninterrupted or that defects will be corrected. Kazoo disclaims all warranties, including any warranties of merchantability, fitness for a particular purpose, and non-infringement.,`,
        },
        {
            header: 'Limitation of Liability',
            content: `Kazoo will not be liable for any damages, including direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of Turtle Teach. This includes damages arising from any errors, omissions, or interruptions in Turtle Teach, as well as any viruses or other harmful components that may be transmitted through Turtle Teach.,`,
        },
        {
            header: 'Indemnification',
            content: `You agree to indemnify, defend, and hold harmless Kazoo, its officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, liabilities, expenses, damages, and costs, including reasonable attorneys' fees, arising from or related to your use of Turtle Teach or your breach of these Terms.,`,
        },
        {
            header: 'Termination',
            content: `Kazoo may terminate or suspend your access to Turtle Teach at any time for any reason. Upon termination, you must immediately cease all use of Turtle Teach.,`,
        },
        {
            header: 'Governing Law and Jurisdiction',
            content: `These Terms and any disputes arising out of or related to Turtle Teach will be governed by the laws of Hong Kong, without regard to its conflict of laws provisions. Any disputes arising out of or related to these Terms or Turtle Teach will be resolved exclusively in the courts of Hong Kong.,`,
        },
        {
            header: 'Modification',
            content: `Kazoo reserves the right to modify these Terms at any time by posting updated Terms on Turtle Teach. Your continued use of Turtle Teach after the posting of updated Terms constitutes your agreement to those updated Terms.,`,
        },
        {
            header: 'Entire Agreement',
            content: `These Terms constitute the entire agreement between you and Kazoo with respect to Turtle Teach and supersede all prior or contemporaneous communications and proposals, whether oral, written, or electronic, between you and Kazoo with respect to Turtle Teach.,`,
        },
        {
            header: 'Waiver',
            content: `The failure of Kazoo to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.,`,
        },
        {
            header: 'Severability',
            content: `If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.,`,
        },
    ];

    return (
        <div
            style={{
                color: '#858585',
                paddingLeft: '5%',
                paddingTop: '2%',
                paddingRight: '5%',
            }}>
            <Typography
                variant='h5'
                component='h1'
                style={{
                    fontWeight: 'bold',
                    marginBottom: '16px',
                }}>
                Terms and Conditions
            </Typography>
            <Typography textAlign={'justify'}>
                Welcome to Turtle Teach! This platform is owned and operated by Kazoo Technology (Hong Kong) Limited ("Kazoo"), a Hong Kong-based entity. These terms and conditions ("Terms") govern
                your use of the Turtle Teach website and any related services. By using Turtle Teach, you agree to be bound by these Terms.
            </Typography>
            <div>
                <ol>
                    {items.map((item, idx) => (
                        <div key={idx}>
                            <li
                                style={{
                                    fontWeight: 'bold',
                                    color: '#676767',
                                }}>
                                {item.header}
                            </li>
                            <dl>
                                <dt>{item.content}</dt>
                            </dl>
                        </div>
                    ))}
                    <li style={{ fontWeight: 'bold', color: '#676767' }}>
                        <h4>Contact Us</h4>
                    </li>
                    <dl>
                        <dt>
                            <Typography>If you have any questions or concerns about these Terms, please contact us at</Typography>
                            <Typography variant='caption' display='block'>
                                Kazoo Technology (Hong Kong) Limited
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Unit 307, 3/F, Building 19W
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Science Park West Avenue
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Hong Kong Science Park
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Shatin, New Territories
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Hong Kong
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Phone: +852 35654523
                            </Typography>
                            <Typography variant='caption' display='block'>
                                Email: support@turtleteach.com
                            </Typography>
                        </dt>
                    </dl>
                </ol>
            </div>
        </div>
    );
};

// export default TermsConditionsContent;
