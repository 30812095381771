import { Box, Button, Checkbox, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PopupTitle } from 'components/General/Popups/PopupTitle';

import ShareIcon from '@mui/icons-material/Share';
import { MultiInputEmailWithChips } from './sharePopupComponents/MultiInputEmailWithChips';
import { PermissionDropdownMenu } from './sharePopupComponents/PermissionDropdownMenu';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useShareHook } from '../hooks/useShareHook';
import { UpgradePlanPopup } from '../../../routes/UpgradePlanPopup';

type LessonsSharePopupProps = {
    lessonID: string;
    title: string;
    shareWithPublic: boolean;
    disabled?: boolean;
};
export const LessonsSharePopup = (props: LessonsSharePopupProps) => {
    const { updateShareWithPublic, isShareWithPublic, isPopupOpen, showUpgradePopup, handleOpen, handleClose, emailList, refreshList, resendLessonShare } = useShareHook(props.lessonID);
    const { t } = useTranslation();

    const shareWithPublicBoolean = isShareWithPublic !== null ? isShareWithPublic : props.shareWithPublic;

    return (
        <>
            <Button disabled={props.disabled} onClick={handleOpen} variant='outlined' color='inherit' sx={{ textTransform: 'none', borderRadius: '0px', ml: 1 }} startIcon={<ShareIcon />}>
                {t('share')}
            </Button>

            {isPopupOpen && (
                <Dialog open={isPopupOpen} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' PaperProps={{ style: { width: 800 } }}>
                    <DialogTitle id='alert-dialog-title'>
                        <PopupTitle>
                            {t('share')} "{props.title}"
                        </PopupTitle>
                    </DialogTitle>
                    <DialogContent sx={{ minWidth: 500 }}>
                        <DialogContentText id='alert-dialog-description'>
                            <Stack spacing={2}>
                                <Typography>{t('shareOptions')}</Typography>

                                <MultiInputEmailWithChips lessonID={props.lessonID} refreshList={refreshList} />

                                {/* <MultiInputSchoolWithChips /> */}

                                <Box>
                                    <Checkbox
                                        checked={shareWithPublicBoolean}
                                        onChange={() => {
                                            updateShareWithPublic(!shareWithPublicBoolean);
                                        }}
                                        inputProps={{
                                            'aria-label': 'Testing checkbox',
                                        }}
                                    />
                                    <label htmlFor='shareWithPublicCheckbox'>{t('shareWithPublic')}</label>
                                </Box>

                                <Divider
                                    sx={{
                                        width: '100%',
                                        alignSelf: 'center',
                                        mb: '20px',
                                        mt: '20px',
                                        backgroundColor: '#858585',
                                    }}
                                />

                                <Container maxWidth='lg'>
                                    <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
                                        <Grid item xs={10}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <PermIdentityIcon sx={{ mr: 1 }} />
                                                {t('anyoneWithLink')}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <PermissionDropdownMenu refreshList={refreshList} />
                                        </Grid>
                                    </Grid>
                                    {emailList.map((email) => (
                                        <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
                                            <Grid item xs={8}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    <PermIdentityIcon sx={{ mr: 1 }} />
                                                    {email.email}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {email.accepted ? (
                                                    <Typography>{t('lessonShare.invited')}</Typography>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                        }}>
                                                        <Button
                                                            onClick={() => {
                                                                resendLessonShare(email.id);
                                                            }}
                                                            sx={{ p: 0 }}>
                                                            [{t('lessonShare.resend')}]
                                                        </Button>
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={2}>
                                                <PermissionDropdownMenu id={email.id} canDelete refreshList={refreshList} />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Container>
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <Divider
                        sx={{
                            width: '90%',
                            alignSelf: 'center',
                            mb: '20px',
                            mt: '20px',
                            backgroundColor: '#858585',
                        }}
                    />
                    {/* <DialogActions sx={{ mb: '20px' }}>
            <Stack spacing={2} direction='row' sx={{ flex: 1, ml: 4, mr: 4 }}>
              <CancelButton onClick={handleClose} sx={{ border: 'none' }}>
                <LinkIcon sx={{ mr: 1 }} />
                {t('copyLink')}
              </CancelButton>
            </Stack>
          </DialogActions> */}
                </Dialog>
            )}

            {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={handleClose} reason='featureNotIncluded' />}
        </>
    );
};
