import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { ClearRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TopButton, TopButtonTypography } from '../TestroomTemplate';
import { useState } from 'react';

export type TestroomEndSessionProps = {
    onClick: () => void;
};

export const TestroomEndSession = (props: TestroomEndSessionProps) => {
    const { onClick } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <>
            <TopButton
                sx={{
                    bgcolor: '#E94B00',
                    color: '#FFF3E0',
                    paddingTop: '14px',
                    '&:hover': {
                        backgroundColor: '#F26800',
                    },
                }}
                onClick={() => {
                    setOpen(true);
                }}>
                <Grid
                    sx={{
                        display: 'inline-flex',
                        gap: '16px',
                    }}>
                    <ClearRounded />
                    <TopButtonTypography>{t('live-class-stop')}</TopButtonTypography>
                </Grid>
            </TopButton>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' sx={{ width: 300 }}>
                    {t('live-class-stop')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{t('live-class-stop-confirm')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
                    <Button
                        onClick={() => {
                            onClick();
                            setOpen(false);
                        }}
                        autoFocus>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
