import { Box, IconButton, TextField, Stack, Card, CardContent, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import {
    InteractionBase,
    DragTheWordsInteraction,
    SingleChoiceSetInteraction,
    MultipleChoiceInteraction,
    TrueFalseQuestionInteraction,
    DragAndDropInteraction,
    TextInteraction,
    H5PContent,
} from '../../../../../../types/h5pTypes';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../../../../../styles/Colors';
import { useTranslation, TFunction } from 'react-i18next';

type H5PProps = {
    id: string;
    index: number;
    question: DragTheWordsInteraction | SingleChoiceSetInteraction | MultipleChoiceInteraction | TrueFalseQuestionInteraction | DragAndDropInteraction | TextInteraction;
    // questionText: string;
    // answerText: string;
    // onTextQuestionFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    // onTextAnswerFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    // deleteItemChange: (idx: number) => void;
};
type InteractionRenderFunc = (interaction: InteractionBase, t: TFunction) => React.ReactNode;

const interactionRenderers: Record<string, InteractionRenderFunc> = {
    'Drag the Words': (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as DragTheWordsInteraction;
        console.log('Kendall:', specificInteraction.action.params.textField);
        return (
            <>
                <Stack
                    direction={'column'}
                    sx={{
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0',
                        padding: '24px 36px',
                        boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                    }}
                    spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.question')}</Typography>
                        </Grid>
                        <Card sx={{ height: '100%' }}>
                            <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: specificInteraction.action.params.textField.replace(/\*([^*]+)\*/g, ' [$1] ').replace(/\n/g, '<br />'),
                                    }}
                                />
                            </Grid>
                        </Card>
                    </Stack>
                </Stack>

                {/* Add more fields */}
            </>
        );
    },
    'Single Choice Set': (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as SingleChoiceSetInteraction;
        return (
            <>
                {specificInteraction.action.params.choices.map((choice) => (
                    <Stack
                        direction={'column'}
                        sx={{
                            justifyContent: 'space-between',
                            borderRadius: '12px 12px 0 0',
                            padding: '24px 36px',
                            boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                        }}
                        spacing={2}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Grid item xs={1}>
                                <Typography>{t('h5p.question')}</Typography>
                            </Grid>
                            <Card sx={{ height: '100%' }}>
                                <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: choice.question, //.replace(/\n/g, '<br />'),
                                        }}
                                    />
                                </Grid>
                            </Card>
                        </Stack>

                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Grid item xs={1}>
                                <Typography>{t('h5p.answers')}</Typography>
                            </Grid>
                            {choice.answers.map((a) => (
                                <Card sx={{ height: '100%' }}>
                                    <Grid
                                        container
                                        style={{
                                            height: '100%',
                                            padding: '10px',
                                        }}
                                        alignItems='center'
                                        justifyContent='center'>
                                        <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: a, //.replace(/\n/g, '<br />'),
                                            }}
                                        />{' '}
                                    </Grid>
                                </Card>
                            ))}
                        </Stack>
                    </Stack>
                ))}
            </>
        );
    },
    'Multiple Choice': (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as MultipleChoiceInteraction;
        return (
            <>
                <Stack
                    direction={'column'}
                    sx={{
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0',
                        padding: '24px 36px',
                        boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                    }}
                    spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.question')}</Typography>
                        </Grid>
                        <Card sx={{ height: '100%' }}>
                            <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: specificInteraction.action.params.question,
                                    }}
                                />
                            </Grid>
                        </Card>
                    </Stack>

                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.answers')}</Typography>
                        </Grid>
                        {specificInteraction.action.params.answers.map((a) => (
                            <Card
                                sx={{
                                    height: '100%',
                                    backgroundColor: a.correct ? 'green' : '',
                                }}>
                                <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: a.text,
                                        }}
                                    />
                                </Grid>
                            </Card>
                        ))}
                    </Stack>
                </Stack>
            </>
        );
    },
    'True/False Question': (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as TrueFalseQuestionInteraction;
        return (
            <>
                <Stack
                    direction={'column'}
                    sx={{
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0',
                        padding: '24px 36px',
                        boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                    }}
                    spacing={1}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.content')}</Typography>
                        </Grid>
                        <Card sx={{ height: '100%' }}>
                            <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: specificInteraction.action.params.question,
                                    }}
                                />
                            </Grid>
                        </Card>
                    </Stack>
                </Stack>
            </>
        );
    },
    'Drag and Drop': (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as DragAndDropInteraction;
        return (
            <>
                <Stack
                    direction={'column'}
                    sx={{
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0',
                        padding: '24px 36px',
                        boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                    }}
                    spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.question')}</Typography>
                        </Grid>
                        {specificInteraction.action.params.question.task.elements.map((element) =>
                            element.dropZones.length == 0 ? (
                                <Card sx={{ height: '100%' }}>
                                    <Grid
                                        container
                                        style={{
                                            height: '100%',
                                            padding: '10px',
                                        }}
                                        alignItems='center'
                                        justifyContent='center'>
                                        <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: element.type.params.text, //.replace(/\n/g, '<br />'),
                                            }}
                                        />{' '}
                                    </Grid>
                                </Card>
                            ) : (
                                <></>
                            ),
                        )}
                    </Stack>

                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.choices')}</Typography>
                        </Grid>
                        {specificInteraction.action.params.question.task.elements.map((element) =>
                            element.dropZones.length > 0 ? (
                                <Card sx={{ height: '100%' }}>
                                    <Grid
                                        container
                                        style={{
                                            height: '100%',
                                            padding: '10px',
                                        }}
                                        alignItems='center'
                                        justifyContent='center'>
                                        <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: element.type.params.text, //.replace(/\n/g, '<br />'),
                                            }}
                                        />{' '}
                                    </Grid>
                                </Card>
                            ) : (
                                <></>
                            ),
                        )}
                    </Stack>

                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={1}>
                            <Typography>{t('h5p.dropzones')}</Typography>
                        </Grid>
                        {specificInteraction.action.params.question.task.dropZones.map((dropzone) => (
                            <Card sx={{ height: '100%' }}>
                                <Grid
                                    container
                                    style={{
                                        height: '100%',
                                        padding: '10px',
                                    }}
                                    alignItems='center'
                                    justifyContent='center'>
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: dropzone.label, //.replace(/\n/g, '<br />'),
                                        }}
                                    />{' '}
                                </Grid>
                            </Card>
                        ))}
                    </Stack>
                </Stack>
            </>
        );
    },
    Text: (interaction: InteractionBase, t: TFunction) => {
        const specificInteraction = interaction as TextInteraction;
        return (
            <>
                <Stack
                    direction={'column'}
                    sx={{
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0',
                        padding: '24px 36px',
                        boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                    }}
                    spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={2}>
                            <Typography>{t('h5p.content')}</Typography>
                        </Grid>
                        <Card sx={{ height: '100%' }}>
                            <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: specificInteraction.action.params.text,
                                    }}
                                />
                            </Grid>
                        </Card>
                    </Stack>
                </Stack>
            </>
        );
    },
};

interface H5PQuestionComponentProps {
    h5pContent: H5PContent;
}

export const H5PQuestionComponent: React.FC<H5PProps> = (props) => {
    const { t } = useTranslation();

    const renderQuestion = () => {
        console.log('question: ', props.question);
        const interactionType = props.question.libraryTitle;
        let renderFunc = interactionRenderers[interactionType];
        // let renderFunc = undefined;
        // console.log('interactionType: ', interactionType);
        // console.log(
        //   'interactionType === Single Choice Set',
        //   interactionType === 'Single Choice Set',
        // );
        // if (interactionType === 'Single Choice Set') {
        //   renderFunc = interactionRenderers['Single Choice Set'];
        // }
        // const renderFunc = interactionRenderers[interactionType];

        if (renderFunc) {
            return renderFunc(props.question, t);
        } else {
            // Handle case where no renderer exists for the interaction type
            return <p>Unknown interaction type: {interactionType}</p>;
        }
    };
    return (
        <Box
            sx={{
                backgroundColor: '#F7EEDC',
                padding: 2,
                borderRadius: '12px',
                position: 'relative',
            }}>
            {/* ... */}
            <Box sx={{ color: '#533D18', fontSize: 12 }}>{t('h5p.question')}</Box>
            <Stack
                direction={'column'}
                sx={{
                    justifyContent: 'space-between',
                    borderRadius: '12px 12px 0 0',
                    padding: '24px 36px',
                    boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                }}
                spacing={2}>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={1}>
                        <Typography>{t('h5p.interaction-type')}</Typography>
                    </Grid>
                    <Card sx={{ height: '100%' }}>
                        <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                            <Typography>{props.question.libraryTitle}</Typography>
                        </Grid>
                    </Card>
                </Stack>

                <Stack direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={1}>
                        <Typography>{t('h5p.time-point')}</Typography>
                    </Grid>
                    <Card sx={{ height: '100%' }}>
                        <Grid container style={{ height: '100%', padding: '10px' }} alignItems='center' justifyContent='center'>
                            <Typography>{props.question.duration.from}</Typography>
                            {'s to\u00A0'}
                            <Typography>{props.question.duration.to}</Typography>
                            {'s'}
                        </Grid>
                    </Card>
                </Stack>
                {renderQuestion()}
            </Stack>
        </Box>
    );
};

export function shuffleArray(array: string[]) {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
}
