import { Box, Card } from '@mui/material';
import TopStudentPerformanceCard from './TopStudentPerformanceCard';
import { TopPerformanceCardProp, useTopPerformanceCardHook } from 'pages/results/hook/useTopPerformanceCardHook';

const TopPerformanceCard = (props: TopPerformanceCardProp) => {
    const { topThreeStudents } = useTopPerformanceCardHook(props);
    return (
        <Card
            sx={{
                display: 'flex',
                width: '100%',
                height: '144px',
                alignItems: 'flex-start',
                borderRadius: '4px',
                bgcolor: 'background.paper',
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
            }}>
            <Box component='img' src='/images/main/trophy.png' sx={{ width: '296px', display: 'flex', flexShrink: 0 }} />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    {topThreeStudents.length > 0 && (
                        <TopStudentPerformanceCard
                            studentName={topThreeStudents[0].student ? topThreeStudents[0].student : ''}
                            totalScore={topThreeStudents[0].totalScore ? topThreeStudents[0].totalScore : ''}
                            time={topThreeStudents[0].totalTestDuration ? topThreeStudents[0].totalTestDuration : ''}
                            index={1}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    {topThreeStudents.length > 1 && (
                        <TopStudentPerformanceCard
                            studentName={topThreeStudents[1].student ? topThreeStudents[1].student : ''}
                            totalScore={topThreeStudents[1].totalScore ? topThreeStudents[1].totalScore : ''}
                            time={topThreeStudents[1].totalTestDuration ? topThreeStudents[1].totalTestDuration : ''}
                            index={2}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    {topThreeStudents.length > 2 && (
                        <TopStudentPerformanceCard
                            studentName={topThreeStudents[2].student ? topThreeStudents[2].student : ''}
                            totalScore={topThreeStudents[2].totalScore ? topThreeStudents[2].totalScore : ''}
                            time={topThreeStudents[2].totalTestDuration ? topThreeStudents[2].totalTestDuration : ''}
                            index={3}
                        />
                    )}{' '}
                </Box>
            </Box>
        </Card>
    );
};

export default TopPerformanceCard;
