import { Stack, TextField, Select, MenuItem, Box, IconButton, Typography, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

import { SlideComponent, SlideComponentReadingComprehension, SlideComponentMultipleChoice, SlideComponentMultipleChoiceQuestionModel } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { MultipleChoiceQuestionComponent } from './components/MultipleChoiceQuestionComponent';

import { useLessonsSlidePropsMultipleChoiceHook } from './hooks/useLessonsSlidePropsMultipleChoiceHook';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsReadingComprehension = ({ item, index, handleCreate }: { item: SlideComponent; index: number; handleCreate?: (item: SlideComponent) => void }) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();
    const [genFillInTheBlank, setGenFillInTheBlank] = useState(true);
    const [genMultipleChoice, setGenMultipleChoice] = useState(true);

    let itemContent: SlideComponentReadingComprehension = item.content as SlideComponentReadingComprehension;

    // Kendall
    const placeHolderArticle = `    對每個小學生而言，筆盒或筆袋是不可或缺的用品，但文具店裏的筆盒全都不合我的心意，於是我設計了一個聰明筆盒。  
    所謂「聰明筆盒」，其實是由「智能筆盒」這個名稱修改而來。現今科技發達，有「智能門鎖」、「智能電話」等，我所設計的筆盒當然也要有「智能」吧！但我還嫌「智能筆盒」這個稱呼不夠好，「聰明筆盒」才顯得我的設計最有智慧。  
    究竟我的筆盒樣子怎樣？外面是全黑色的。這種設計很帥氣吧！遠看的話，我的筆盒就像黑色的鞋盒，但大小只有鞋盒的十分之一。實際尺寸是二十二厘米長、十厘米闊、五厘米高，而且無論從上向下看、從左向右看，還是從下向上看，你也不會找到一個開關位置。這種設計可以給人一種神秘莫測的感覺。  
    因為這是一個「聰明」的筆盒，不會用一般方法開啟。要打開筆盒，只要對着它說一聲「打開」就可以了。收到聲音指令後，筆盒上蓋會自動打開，內有一塊螢幕，就像智能電話一樣，你可以透過觸碰螢幕操控筆盒，使用各種功能。用完後，除了可以用手關好上蓋外，當然還可以說聲「關上」，讓上蓋自動關閉。    
   說到筆盒的功能，當然不止開蓋與關蓋那麼簡單。它還可以為你削鉛筆。上蓋打開後有一個小孔，只要把鉛筆插進去，內置的刀片會自行轉動，替你把鉛筆削尖，這樣比人手削筆節省了一半的手力，所花的時間只有五秒，比人手快五至七倍。而最重要的是那個螢幕，除了用來操作計算機、電子字典、電郵等功能外，還可以收看串流影片，為沉悶的課堂增添樂趣(但只可以偷偷看)。  
    這個筆盒不但外觀吸引，而且功能多樣，更能帶來樂趣，那麼要售多少價錢呢？這個問題我還未想到答案，大概是個比一般筆盒高而家長又能負擔的價錢吧。至於筆盒最終能否面世，那就要看我長大後能否成為科學家或電子產品設計師了。 
    也許有人認為我的構思不切實際，但我不怕被人嘲笑，更會證明這個筆盒對世界有貢獻，讓每個使用它的學生得到愉快的學習經歷。`;

    itemContent.prompt_fillInTheBlank = `

  請做以下的工作:
  從文章中抽取 ${itemContent.questionCount * 2} 句句子. 每句選取一個詞語

  從文章的句子, 生成 ${itemContent.questionCount * 2} 題填充題, 參考以下例子格式，但不要複製
  
  參考以下例子格式，詞語放在前面，含有詞語的原句放在後面。用 「｜」來分隔。原句必須含有詞語。
  電話 | 人與人可以用電話來溝通。
  
  生成後，請確保以上的要求達標, 否則不要供應該句子。`;

    itemContent.prompt_multipleChoice = `

  《選擇題》
  從文章的內容, 參考以下例子格式, 生成 ${itemContent.questionCount} 題選擇題, 適合小學生程度:
  這個筆盒的外觀是什麼顏色的？
  A) 白色
  B) 紅色
  C) 黑色
  D) 藍色
  答案：C) 黑色

  每題要有四個選擇。`;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    // let itemContent_fillInTheBlank: SlideComponentFillInTheBlank = itemContent.itemContent_fillInTheBlank as SlideComponentFillInTheBlank;

    let itemContent_multipleChoice: SlideComponentMultipleChoice = itemContent.itemContent_multipleChoice as SlideComponentMultipleChoice;

    // const { getTextFromServer, getTextFromServerGeneric, deleteQuestionArrayInfo, questionFieldChange, answerFieldChange, loading, hasGenerated, addQuestion } =
    //     useLessonsSlidePropsFillInTheBlankHook(itemContent_fillInTheBlank);

    const {
        getTextFromServerGeneric: getTextFromServerGeneric_mc,
        deleteQuestionArrayInfo: deleteQuestionArrayInfo_mc,
        questionFieldChange: questionFieldChange_mc,
        // answerFieldChange: answerFieldChange_mc,
        choicesFieldChange: choicesFieldChange_mc,
        answerIndexChange: answerIndexChange_mc,
        loading: loading_mc,
        addQuestion: addQuestion_mc,
        addChoice: addChoice_mc,
        deleteChoice: deleteChoice_mc,
    } = useLessonsSlidePropsMultipleChoiceHook(itemContent_multipleChoice);

    return (
        <>
            <Stack spacing={2} sx={{ mt: 2, width: '100%' }}>
                <Box sx={{ flex: 2, width: '100%' }}>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}>
                        {t('reading-comprehension-article')}
                    </Typography>
                    <Stack direction='row' spacing={8}>
                        <Box sx={{ width: '75%' }}>
                            <TextField
                                placeholder={placeHolderArticle}
                                fullWidth
                                multiline
                                rows={25}
                                value={itemContent.articleText}
                                onChange={(e) => {
                                    itemContent.articleText = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                        </Box>
                        <Stack direction='column' spacing={10} width={'50%'}>
                            <Stack direction='row' spacing={10} width={'100%'}>
                                <FormGroup>
                                    <Typography>{t('num-questions-to-generate')}</Typography>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={itemContent.questionCount.toString()}
                                        // label={t('cardOrWriting')}
                                        onChange={(event: SelectChangeEvent) => {
                                            itemContent.questionCount = parseInt(event.target.value);
                                            updateUI(true);
                                        }}>
                                        {[...Array(10)].map((_, index) => (
                                            <MenuItem key={index + 1} value={index + 1}>
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {/* <FormControlLabel
                                        control={<Checkbox checked={genFillInTheBlank} onChange={(event, checked) => setGenFillInTheBlank(!genFillInTheBlank)} />}
                                        label={t('fillInTheBlank.title')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={genMultipleChoice} onChange={(event, checked) => setGenMultipleChoice(!genMultipleChoice)} />}
                                        label={t('multipleChoice')}
                                    /> */}
                                </FormGroup>
                                <SubmitButton
                                    startIcon={<AddIcon />}
                                    type='button'
                                    onClick={() => {
                                        // if (genFillInTheBlank) {
                                        //     getTextFromServerGeneric(
                                        //         'fillInTheBlank',
                                        //         (itemContent.articleText == '' || itemContent.articleText == undefined ? placeHolderArticle : itemContent.articleText) +
                                        //             itemContent.prompt_fillInTheBlank,
                                        //         false,
                                        //         itemContent.questionCount,
                                        //     );
                                        // }
                                        if (genMultipleChoice) {
                                            getTextFromServerGeneric_mc(
                                                (itemContent.articleText === '' || itemContent.articleText === undefined ? placeHolderArticle : itemContent.articleText) +
                                                    itemContent.prompt_multipleChoice,
                                            );
                                        }
                                    }}
                                    sx={{
                                        top: -10,
                                        height: '48px',
                                        width: '150.5px',
                                    }}>
                                    {t('reading-comprehension-generate-article')}
                                </SubmitButton>
                            </Stack>
                            {/* <Stack spacing={1} sx={{ overflow: 'auto' }}>
                                {itemContent_fillInTheBlank && itemContent_fillInTheBlank.questions ? (
                                    <>
                                        <Typography>{t('fillInTheBlank.title')}</Typography>
                                        {itemContent_fillInTheBlank.questions.map((question: SlideComponentFillInTheBlankQuestionModel, index: number) => (
                                            <FillInTheBlankQuestionComponent
                                                id={question.id}
                                                key={'FillInTheBlankQuestionComponent' + index.toString()}
                                                index={index}
                                                questionText={question.questionText}
                                                answerText={question.answerText}
                                                onTextQuestionFieldChange={(idx: number, textContent: string) => {
                                                    questionFieldChange(idx, textContent);
                                                    updateUI(true);
                                                }}
                                                onTextAnswerFieldChange={(idx: number, textContent: string) => {
                                                    answerFieldChange(idx, textContent);
                                                    updateUI(true);
                                                }}
                                                deleteItemChange={(idx: number) => {
                                                    deleteQuestionArrayInfo(idx);
                                                    updateUI(true);
                                                }}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Stack>
                            {itemContent.questions.length < 10 && (
                                <IconButton // add an icon button for delete
                                    sx={{
                                        width: '120px',
                                        height: '18px',
                                    }}
                                    onClick={() => {
                                        addQuestion();
                                        updateUI(true);
                                    }}>
                                    <AddIcon />
                                    <Typography>{t('manual-add-question')}</Typography>
                                </IconButton>
                            )} */}

                            <Stack spacing={1} sx={{ overflow: 'auto', marginTop: '50px' }}>
                                {itemContent_multipleChoice && itemContent_multipleChoice.questions ? (
                                    <>
                                        <Typography>{t('multipleChoice')}</Typography>
                                        {itemContent_multipleChoice.questions.map((question: SlideComponentMultipleChoiceQuestionModel, index: number) => (
                                            <MultipleChoiceQuestionComponent
                                                id={question.id}
                                                key={'MultipleChoiceQuestionComponent' + index.toString()}
                                                index={index}
                                                questionText={question.questionText}
                                                answerIndex={question.answerIndex}
                                                choices={question.choices || []}
                                                onTextQuestionFieldChange={(idx: number, textContent: string) => {
                                                    questionFieldChange_mc(idx, textContent);
                                                    updateUI(true);
                                                }}
                                                onChoicesFieldChange={choicesFieldChange_mc}
                                                onAnswerIndexChange={answerIndexChange_mc}
                                                deleteItemChange={(idx: number) => {
                                                    deleteQuestionArrayInfo_mc(idx);
                                                    updateUI(true);
                                                }}
                                                onAddChoice={addChoice_mc}
                                                onDeleteChoice={deleteChoice_mc}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Stack>
                            {itemContent.questions.length < 10 && (
                                <IconButton // add an icon button for delete
                                    sx={{
                                        width: '120px',
                                        height: '18px',
                                    }}
                                    onClick={() => {
                                        addQuestion_mc();
                                        updateUI(true);
                                    }}>
                                    <AddIcon />
                                    <Typography>{t('manual-add-question')}</Typography>
                                </IconButton>
                            )}
                        </Stack>
                    </Stack>
                </Box>

                <Stack direction='row' justifyContent='space-evenly' spacing={2}></Stack>
            </Stack>

            <PopupCircularProgress isOpened={loading_mc} />
        </>
    );
};
