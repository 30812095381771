import { Box, Button, ClickAwayListener, Popper } from '@mui/material';
import { useState } from 'react';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

type LessonsSlideAddPopupDropdownProps = {
    text: string;
    disabled?: boolean;
    children?: JSX.Element;
};
export const LessonsSlideAddPopupDropdown = ({ text, disabled, children }: LessonsSlideAddPopupDropdownProps) => {
    const [isPopperFocusing, setIsPopperFocusing] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const isHKcsOpen = Boolean(anchorEl);
    const id = isHKcsOpen ? 'hkcs-popper' : undefined;

    const handleClickAway = () => {
        if (isPopperFocusing) {
            return;
        }

        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-block',
                }}>
                <Button
                    disabled={disabled}
                    variant='text'
                    color='secondary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '0px',
                        ml: 1,
                        color: '#89744D',
                    }}
                    startIcon={<ArrowDropDownCircleIcon />}
                    onClick={handleClick}>
                    {text}
                </Button>
            </Box>
            {isHKcsOpen ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Popper
                        id={id}
                        open={isHKcsOpen}
                        anchorEl={anchorEl}
                        sx={{ zIndex: 999 }}
                        onFocus={() => {
                            setIsPopperFocusing(true);
                        }}
                        onBlur={() => {
                            setIsPopperFocusing(false);
                        }}>
                        <Box
                            sx={{
                                border: 1,
                                p: 1,
                                bgcolor: 'background.paper',
                            }}>
                            {children}
                        </Box>
                    </Popper>
                </ClickAwayListener>
            ) : null}
        </>
    );
};
