import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WebcamContainer = () => {
    const { t } = useTranslation();

    // jacky: removed JitsiMeeting, add id jitsi_container to box
    // jitsi will be appended as child of jitsi_container
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
            }}>
            <Typography sx={{ color: '#FFF3E0' }}>{t('live-class-joined')}</Typography>
            <Box
                id='jitsi_container'
                sx={{
                    border: '9px solid #FFF3E0',
                    borderRadius: '32px',
                    marginTop: '9px',
                    height: '100%',
                    overflow: 'hidden',
                }}>
                {/*  <Typography
           variant='h5'
           sx={{
             color: '#FFF3E0',
             fontWeight: '400',
             lineHeight: '46.6vh',
           }}>
           {t('live-class-empty')}
         </Typography> */}
            </Box>
        </Stack>
    );
};
