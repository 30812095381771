import { Box } from '@mui/material';

import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentReorderSentenceExercise } from 'models/lesson/LessonModel';

export const SlideReorderSentence = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentReorderSentenceExercise = item.content as SlideComponentReorderSentenceExercise;

    return (
        <>
            {itemContent.questions.map((question, qIdx) => {
                return (
                    <Box
                        key={qIdx}
                        sx={{
                            position: 'absolute',
                            zoom: zoom,
                            left: question.pos.x,
                            top: question.pos.y,
                            zIndex: itemContent.zIndex,

                            borderRadius: 3,
                            backgroundColor: '#fff',
                            p: '18px',
                            color: '#533D18',
                            boxShadow: 2,
                        }}>
                        {question.questionBubbles.join('/')}
                    </Box>
                );
            })}
        </>
    );
};
