import { Stack, TextField, FormControl, Select, MenuItem, Box, IconButton, Button, InputLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { SlideComponent, SlideComponentFillInTheBlank, SlideComponentFillInTheBlankQuestionModel } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { useLessonsSlidePropsFillInTheBlankHook } from './hooks/useLessonsSlidePropsFillInTheBlankHook';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { FillInTheBlankQuestionComponent } from './components/FillInTheBlankQuestionComponent';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { useEffect, useState } from 'react';
import { Constants } from 'Constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FillInTheBlankAnswerBubble } from './components/FillInTheBlankAnswerBubble';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsFillInTheBlank = ({ item, index, handleCreate }: { item: SlideComponent; index: number; handleCreate?: (item: SlideComponent) => void }) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentFillInTheBlank = item.content as SlideComponentFillInTheBlank;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const { getTextFromServer, deleteQuestionArrayInfo, questionFieldChange, answerFieldChange, loading, hasGenerated, addQuestion } = useLessonsSlidePropsFillInTheBlankHook(itemContent);

    const [gridHeight, setGridHeight] = useState('40vh');
    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 440;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const randomDistribution = () => {
        assignSortValue(shuffleArray([...itemContent.questions]));
    };

    const onDragHandler = (textContent: SlideComponentFillInTheBlankQuestionModel[], isDrag: boolean) => {
        assignSortValue(textContent);
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    mt: 2,
                    height: gridHeight,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}>
                <Box>
                    <TextField
                        sx={{
                            height: '48px',
                            borderRadius: '12px',
                        }}
                        label={t('fillInTheBlank.topic')}
                        placeholder={t('fillInTheBlank.placeholder')}
                        type='string'
                        value={itemContent.questionTopic}
                        onChange={(e) => {
                            itemContent.questionTopic = e.target.value === '' ? '' : e.target.value;
                            updateUI(false);
                            debouncedSaveHandler(() => {
                                updateUI(true);
                            });
                        }}
                        // onBlur={(e) => {
                        //   updateUI(true);
                        // }}
                        fullWidth
                    />
                </Box>

                <Stack direction='row' justifyContent='space-evenly' alignItems='flex-end' spacing={2}>
                    <FormControl sx={{ flex: 1, height: '48px', borderRadius: '12px' }}>
                        <InputLabel id='demo-simple-select-label'>{t('fillInTheBlank.questionLength')}</InputLabel>
                        <Select
                            label={t('fillInTheBlank.questionLength')}
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={itemContent.questionLength}
                            onChange={(event: SelectChangeEvent) => {
                                itemContent.questionLength = event.target.value;
                                updateUI(true);
                            }}>
                            <MenuItem value={'short'}>{t('fillInTheBlank.short')}</MenuItem>
                            <MenuItem value={'long'}>{t('fillInTheBlank.long')}</MenuItem>
                        </Select>
                    </FormControl>

                    <SubmitButton
                        disabled={hasGenerated}
                        startIcon={<AddIcon />}
                        type='button'
                        onClick={() => {
                            getTextFromServer(itemContent.questionLength, itemContent.questionTopic, false);
                            updateUI(true);
                        }}
                        sx={{ width: '100.5px' }}>
                        {t('fillInTheBlank.add')}
                    </SubmitButton>

                    <Box
                        sx={{
                            display: 'flow',
                            // justifyContent: 'center',
                            overflow: 'visible',
                            flex: 1,
                        }}>
                        <Button
                            disabled={!hasGenerated}
                            startIcon={<AutorenewIcon />}
                            type='button'
                            onClick={() => {
                                getTextFromServer(itemContent.questionLength, itemContent.questionTopic, true);
                            }}
                            sx={{
                                top: 5,
                                backgroundColor: 'white',
                                width: '100.5px',
                                height: '48px',
                            }}>
                            {t('fillInTheBlank.regenerate')}
                        </Button>
                    </Box>
                </Stack>

                <Stack spacing={1} sx={{ height: '50vh', overflow: 'auto' }}>
                    {itemContent.questions.map((question: SlideComponentFillInTheBlankQuestionModel, index: number) => (
                        <FillInTheBlankQuestionComponent
                            id={question.id}
                            key={'ReorderQuestionComponent' + index.toString()}
                            index={index}
                            questionText={question.questionText}
                            answerText={question.answerText}
                            onTextQuestionFieldChange={(idx: number, textContent: string) => {
                                questionFieldChange(idx, textContent);
                                updateUI(true);
                            }}
                            onTextAnswerFieldChange={(idx: number, textContent: string) => {
                                answerFieldChange(idx, textContent);
                                updateUI(true);
                            }}
                            deleteItemChange={(idx: number) => {
                                deleteQuestionArrayInfo(idx);
                                updateUI(true);
                            }}
                        />
                    ))}
                </Stack>
            </Stack>
            {itemContent.questions.length < Constants.lesson.fillInTheBlankQuestionLimit && (
                <IconButton // add an icon button for delete
                    sx={{
                        width: '14px',
                        height: '18px',
                    }}
                    onClick={() => {
                        addQuestion();
                        updateUI(true);
                    }}>
                    <AddIcon />
                </IconButton>
            )}

            <Stack direction='column'>
                <Typography>{t('fillInTheBlank.questionBubbles')}</Typography>
                <FillInTheBlankAnswerBubble
                    questionBubbles={itemContent.questions}
                    onDragChange={(textContent: SlideComponentFillInTheBlankQuestionModel[], isDrag: boolean) => {
                        onDragHandler(textContent, isDrag);
                        updateUI(true);
                    }}
                />
                <IconButton
                    onClick={() => {
                        randomDistribution();
                        updateUI(true);
                    }}
                    sx={{
                        height: '18px',
                        marginLeft: 'auto',
                        color: '#B07E27',
                    }}>
                    <RefreshIcon />
                    <Typography sx={{ color: '#B07E27', ml: 1 }}>{t('matching.randomSort')}</Typography>
                </IconButton>
            </Stack>

            <PopupCircularProgress isOpened={loading} />
        </>
    );
};

const shuffleArray = (array: SlideComponentFillInTheBlankQuestionModel[]) => {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
};

const assignSortValue = (textContent: SlideComponentFillInTheBlankQuestionModel[]) => {
    for (let index in textContent) {
        let text = textContent[index];
        text.sort = parseInt(index);
    }
};
