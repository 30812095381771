import { useNavigate, useParams } from 'react-router-dom';
import { useOverallTestroom } from './useOverallTestroom';
import { useEffect } from 'react';
import { useGetAnswerCountByType } from './useGetAnswerCountByType';
// export type StudentProfileWithResults = StudentProfile & TestroomResults;
interface StudentResult {
    correctResult: number;
    studentId: string;
}
export type AvgScorePerStudent = {
    totalCorrect: number;
    count: number;
    averageCorrect?: number;
    totalQuestion: number;
    studentId: string;
    studentName: string;
    totalTime: number;
};

export type TestroomScore = {
    [key: string]: {
        totalCorrect: number;
        totalIncorrectResult: number;
        count: number;
        notAttemptedQuestion: number;
    };
};

type Result =
    | {
          avgScorePerStudent: AvgScorePerStudent[];
          testroomScore: TestroomScore;
          avgScoreAllStudent: number;
          studentCount: number;
      }
    | undefined;
export const useOverallTestroomResult = () => {
    const { getTestroomIds } = useOverallTestroom();
    const { getAnswerTypeCount } = useGetAnswerCountByType();
    const { classId } = useParams();

    const getOverallTestRoom = async () => {
        if (classId) {
            const result: Result = await getTestroomIds();
            console.log(result?.avgScoreAllStudent);
            const resultType = await getAnswerTypeCount();
            console.log(resultType);

            return result;
            // getAnswerTypeCount();
        }
    };
    const getOverallTestRoomByType = async () => {
        if (classId) {
            const result = await getAnswerTypeCount();
            const mergedResult: any = {};
            if (result?.totalQuestionCount && result?.correctAnswerCount) {
                let cardCount = 0;
                for (let key in result?.totalQuestionCount) {
                    if (key.includes('Card')) {
                        cardCount += result?.totalQuestionCount[key];
                        if (mergedResult['Card']) {
                            mergedResult['Card'] += result?.correctAnswerCount[key] ?? 0;
                        } else {
                            mergedResult['Card'] = result?.correctAnswerCount[key] ?? 0;
                        }
                    } else {
                        mergedResult[key] = Math.round(((result?.correctAnswerCount[key] ?? 0) / result?.totalQuestionCount[key]) * 100);
                    }
                }
                mergedResult['Card'] = Math.round((mergedResult['Card'] / cardCount) * 100);
            }
            return mergedResult;
            // getAnswerTypeCount();
        }
    };

    return { getOverallTestRoom, getOverallTestRoomByType };
};
