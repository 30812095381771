import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ImageIcon from '@mui/icons-material/Image';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentImage } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataImage = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex, selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentImage = item.content as SlideComponentImage;

    const compRef = useRef<HTMLImageElement>(null);
    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (index === selectedComponentIndex) {
            const compRefCurrent = compRef.current;

            if (canvasObserverRef.current) {
                canvasObserverRef.current.disconnect();
            }
            canvasObserverRef.current = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element ot be watched
                const { width, height } = entries[0].contentRect;

                if (item.size.height !== height) {
                    item.size.height = height;
                    updateItemProperties({ ...item }, false);
                }
            });

            if (compRefCurrent) {
                canvasObserverRef.current.observe(compRefCurrent);
            }
            return () => {
                if (compRefCurrent) {
                    canvasObserverRef.current?.unobserve(compRefCurrent);
                }
                canvasObserverRef.current?.disconnect();
            };
        }
    }, [item.size.width, itemContent.filename, selectedComponentIndex, updateItemProperties]);

    useEffect(() => {
        if (item.size.width === 0 && item.size.height === 0) {
            const compWidth = compRef.current?.naturalWidth!;
            const compHeight = compRef.current?.naturalHeight!;
            const defaultSize = 300;

            if (compWidth && compHeight) {
                const aspectRatio = compWidth / compHeight;

                if (compWidth > compHeight && compWidth > defaultSize) {
                    item.size.width = defaultSize;
                    item.size.height = defaultSize / aspectRatio;
                } else if (compHeight > compWidth && compHeight > defaultSize) {
                    item.size.width = defaultSize * aspectRatio;
                    item.size.height = defaultSize;
                } else {
                    item.size.width = compWidth;
                    item.size.height = compHeight;
                }
            }
            updateItemProperties({ ...item }, true);
        }
    }, []);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <strong
                style={{
                    cursor: 'move',
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                }}>
                <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<ImageIcon />} title={t('image-box')} resize={true} />

                <Box
                    ref={compRef}
                    component='img'
                    src={`/asset/images/${itemContent.filename || 'default.png'}`}
                    alt='bg'
                    sx={{
                        zoom: zoom,
                        aspectRatio: 'auto',
                        transform: `scaleX(${itemContent.flip ?? 1})`,
                        pointerEvents: 'none',
                    }}
                    width={item.size.width}
                />
            </strong>
        </Draggable>
    );
};
