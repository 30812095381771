import { Autocomplete, Box, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlipIcon from '@mui/icons-material/Flip';
import { SlideComponent, SlideComponentH5P } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { useEffect, useState } from 'react';
import { parseH5PContent } from 'utils/h5pUtils';
import { H5PQuestionComponent } from './components/H5PQuestionComponent';
import {
    InteractionBase,
    DragTheWordsInteraction,
    SingleChoiceSetInteraction,
    MultipleChoiceInteraction,
    TrueFalseQuestionInteraction,
    DragAndDropInteraction,
    TextInteraction,
} from '../../../../../types/h5pTypes';
import { removeHtmlTagsFromObject } from '../../../../../utils/h5pUtils';
import { H5PContainer } from '../views/H5PContainer';

const sceneOptions = [
    {
        path: '/workspace/00261',
        label: '00261《飲早茶》Breakfast with Grandparents (Lv 1): Interactive Vi',
        content: '/workspace/00261/content/content.json',
        thumbnail: 'hbl_00261.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00259',
        label: '00259《媽媽病咗》Mommy Got Sick (Child, Lv 1): Interactive Video: ',
        content: '/workspace/00259/content/content.json',
        thumbnail: 'hbl_00259.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00257',
        label: '00257《買嘢》Shopping (Child, Lv 1): Interactive Video: Cantones',
        content: '/workspace/00257/content/content.json',
        thumbnail: 'hbl_00257.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00268',
        label: '00268:《我有一隻狗》I Have a Puppy (Child, Lv1): Interactive Video:',
        content: '/workspace/00268/content/content.json',
        thumbnail: 'hbl_00268.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00267',
        label: '00267:《兔仔要走喇》Goodbye Rabbit! (Child, Lv1): Interactive Video',
        content: '/workspace/00267/content/content.json',
        thumbnail: 'hbl_00267.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00258',
        label: '00258《掛住婆婆》 I Miss You Nani (Child, Lv 1): Interactive Video',
        content: '/workspace/00258/content/content.json',
        thumbnail: 'hbl_00258.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00260',
        label: '00260:《搭巴士》Taking The Bus (Child, Lv 1): Interactive Video',
        content: '/workspace/00260/content/content.json',
        thumbnail: 'hbl_00260.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00014',
        label: '00014《我靚唔靚》Am I Pretty (Child, Lv 1): Interactive Video: Can',
        content: '/workspace/00014/content/content.json',
        thumbnail: 'hbl_00014.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00013',
        label: '00013《屋企嘅問題》Home problems (Child, Lv 1): Interactive Video: ',
        content: '/workspace/00013/content/content.json',
        thumbnail: 'hbl_00013.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00263',
        label: '00263《雀仔》Birds (Child, Lv 1): Interactive Video: Cantonese V',
        content: '/workspace/00263/content/content.json',
        thumbnail: 'hbl_00263.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00264',
        label: '00264《生日快樂》Little Brother&#039;s Birthday Gift (Child, Lv 1)',
        content: '/workspace/00264/content/content.json',
        thumbnail: 'hbl_00264.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00002',
        label: '00002《個個都唔一樣》Flowers of One Garden (Child, Lv 1): Interactiv',
        content: '/workspace/00002/content/content.json',
        thumbnail: 'hbl_00002.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00034',
        label: '00034《阿樂寫俾恐龍仔嘅信》Goodbye My Friend ---- 冚唪唥粵文讀本 HBL Cantonese',
        content: '/workspace/00034/content/content.json',
        thumbnail: 'hbl_00034.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00277p',
        label: '00277p《呢個係我哥哥》This is my Elder Brother (Child, Lv 1): Intera',
        content: '/workspace/00277p/content/content.json',
        thumbnail: 'hbl_00277p.jpg',
        category: '粵文分級 1 HBL Level 1',
    },
    {
        path: '/workspace/00270',
        label: '00270p《菜肉包》This is my Elder Brother (Child, Lv 1): Interacti',
        content: '/workspace/00270/content/content.json',
        thumbnail: 'hbl_00270.jpg',
        category: '粵文分級 1 HBL Level 1',
    },

    {
        path: '/workspace/00134p',
        label: '00134p《唔關我事架》Wasn&#039;t me! (Child, Lv 2): Interactive Vide',
        content: '/workspace/00134p/content/content.json',
        thumbnail: 'hbl_00134p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00139p',
        label: '00139p《小蜜蜂與大笨象》Bee and Elephant (Child, Lv 2): Interactive V',
        content: '/workspace/00139p/content/content.json',
        thumbnail: 'hbl_00139p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00133p',
        label: '00133p《阿十又發白日夢》What if ---- 冚唪唥粵文讀本 HBL Cantonese Graded Rea',
        content: '/workspace/00133p/content/content.json',
        thumbnail: 'hbl_00133p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00104p',
        label: '00104p《啲聲好嘈啊!》It&#039;s noisy! (Child, Lv 2): Interactive Vi',
        content: '/workspace/00104p/content/content.json',
        thumbnail: 'hbl_00104p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00041',
        label: '00041:《白白》Whitey The Cat (Child, Lv2): Interactive Video: Ca',
        content: '/workspace/00041/content/content.json',
        thumbnail: 'hbl_00041.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00147p',
        label: '00147p《伏匿匿》Lost and Found (Child, Lv 2): Interactive Video: ',
        content: '/workspace/00147p/content/content.json',
        thumbnail: 'hbl_00147p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00023',
        label: '00023《同公公婆婆食飯》Dinner With Grandparents (Child, Lv 2): Intera',
        content: '/workspace/00023/content/content.json',
        thumbnail: 'hbl_00023.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00024',
        label: '00024《問路》Asking For Direction (Child, Lv 2): Interactive Vid',
        content: '/workspace/00024/content/content.json',
        thumbnail: 'hbl_00024.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00128p',
        label: '00128p《彩虹嘅顏色》Home problems (Child, Lv 2): Interactive Video:',
        content: '/workspace/00128p/content/content.json',
        thumbnail: 'hbl_00128p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00107p',
        label: '00107p:《講畀邊個聽？》 Who Can I Tell? (Child, Lv2): Interactive Vi',
        content: '/workspace/00107p/content/content.json',
        thumbnail: 'hbl_00107p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00106p',
        label: '00106p:《高啲，矮啲》 Taller, Shorter (Child, Lv2): Interactive Vid',
        content: '/workspace/00106p/content/content.json',
        thumbnail: 'hbl_00106p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00145p',
        label: '00145p:《過嚟一齊食蛋糕喇!》A Big Cake (Child, Lv 2): Interactive Video: Cant',
        content: '/workspace/00145p/content/content.json',
        thumbnail: 'hbl_00145p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00123p',
        label: '00123p《老鼠仔同大山羊》It&#039;s Fun with Friends! (Child, Lv 2): In',
        content: '/workspace/00123p/content/content.json',
        thumbnail: 'hbl_00123p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00148',
        label: '00148p《我大個仔喇!》Archie Can Climb (Child, Lv 2): Interactive Vi',
        content: '/workspace/00148/content/content.json',
        thumbnail: 'hbl_00148.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00131p',
        label: '00103p《屋企人》Family Members (Child, Lv 2): Interactive Video: ',
        content: '/workspace/00131p/content/content.json',
        thumbnail: 'hbl_00131p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00132p',
        label: '00132p《唔好睇小螞蟻》Looking For A New Home (Child, Lv 2): Interact',
        content: '/workspace/00132p/content/content.json',
        thumbnail: 'hbl_00132p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00136p',
        label: '00136p《咩都鬥一餐》Let&#039;s Compete! (Child, Lv 2): Interactive ',
        content: '/workspace/00136p/content/content.json',
        thumbnail: 'hbl_00136p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00010',
        label: '00010《出街食飯》Going Out for a Meal (Child, Lv 2): Interactive V',
        content: '/workspace/00010/content/content.json',
        thumbnail: 'hbl_00010.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00108p',
        label: '00108p《邊個叫醒到阿高》Who can wake up him? (Child, Lv 2): Interacti',
        content: '/workspace/00108p/content/content.json',
        thumbnail: 'hbl_00108p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00141p',
        label: '00141p:《月亮與帽》My cap (Child, Lv2): Interactive Video: Cantone',
        content: '/workspace/00141p/content/content.json',
        thumbnail: 'hbl_00141p.jpg',
        category: '粵文分級 2 HBL Level 2',
    },
    {
        path: '/workspace/00126p',
        label: '00126p:《手》Hands (Child, Lv2): Interactive Video: Cantonese Version',
        content: '/workspace/00126p/content/content.json',
        thumbnail: 'hbl_00126p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00109p',
        label: '00109p:《玩乜好？》Let&#039;s Play (Child, Lv3): Cantonese Version',
        content: '/workspace/00109p/content/content.json',
        thumbnail: 'hbl_00109p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00166p',
        label: "00166p:《婆婆嚟咗！？》Grandma's Walk to the Park",
        content: '/workspace/00166p/content/content.json',
        thumbnail: 'hbl_00166p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00142',
        label: '00142p:《神秘的魔法石》Magic Block (Child, Lv3): Interactive Video: ',
        content: '/workspace/00142/content/content.json',
        thumbnail: 'hbl_00142.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00115p',
        label: '00115p《新雨褸》The New Raincoat (Child, Lv 3): Interactive Video',
        content: '/workspace/00115p/content/content.json',
        thumbnail: 'hbl_00115p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00130p',
        label: '00130p《聖誕禮物》Christmas Love (Child, Lv 3): Interactive Video:',
        content: '/workspace/00130p/content/content.json',
        thumbnail: 'hbl_00130p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00121p',
        label: '00121p《跳上太空》Jump into Space (Child, Lv 3): Interactive Video',
        content: '/workspace/00121p/content/content.json',
        thumbnail: 'hbl_00121p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00160p',
        label: '00160p:《俾個密碼嚟睇下》What is the password? (Child, Lv3): Interact',
        content: '/workspace/00160p/content/content.json',
        thumbnail: 'hbl_00160p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00172p',
        label: '00172p:《我愛睇書！》I Love Reading Books (Child, Lv3): Interactive',
        content: '/workspace/00172p/content/content.json',
        thumbnail: 'hbl_00172p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00176p',
        label: '00176p:《儲錢大計》The Money Saving Plan (Child, Lv3): Interactive',
        content: '/workspace/00176p/content/content.json',
        thumbnail: 'hbl_00176p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00101p',
        label: '00101p:《Rani返學喇！》A School For Everyone (Child, Lv3): Interac',
        content: '/workspace/00101p/content/content.json',
        thumbnail: 'hbl_00101p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00167p',
        label: '00167《數學?難我唔到啦!》The Right Angle (Child, Lv 3): Interactive V',
        content: '/workspace/00167p/content/content.json',
        thumbnail: 'hbl_00167p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00021',
        label: '00021:《恐龍樂園》Dinosaur Paradise (Lv3): Interactive Video: Cantonese Video',
        content: '/workspace/00021/content/content.json',
        thumbnail: 'hbl_00021.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00102p',
        label: '00102p:《Anand幾時嚟啊？》Where’s Anand? (Lv 3): Interactive Video: Cantonese Video',
        content: '/workspace/00102p/content/content.json',
        thumbnail: 'hbl_00102p.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
    {
        path: '/workspace/00019',
        label: '00019《九龍》Kowloon (Child, Lv 3): Interactive Video: Cantonese',
        content: '/workspace/00019/content/content.json',
        thumbnail: 'hbl_00019.jpg',
        category: '粵文分級 3 HBL Level 3',
    },
];

// const sceneOptions = [
//   // { path: 'scene1_2.png', label: 'scene1_2' },
//   {
//     path: '/workspace/00260',
//     label:
//       '00260《搭巴士》Taking The Bus (Lv 1): Interactive Video: Cantonese Version',
//     content: '/workspace/00260/content/content.json',
//     thumbnail: 'hbl_00260.jpg',
//   },
//   {
//     path: '/workspace/00261',
//     label:
//       '00261《飲早茶》Breakfast with Grandparents (Lv 1): Interactive Video: Cantonese Version',
//     content: '/workspace/00261/content/content.json',
//     thumbnail: 'hbl_00261.jpg',
//   },
//   {
//     path: '/workspace/00002',
//     label:
//       '00002《個個都唔一樣》Flowers of One Garden (Child, Lv 1): Interactive Video: Cantonese Version',
//     content: '/workspace/00002/content/content.json',
//     thumbnail: 'hbl_00002.jpg',
//   },
//   {
//     path: '/workspace/00257',
//     label:
//       '00257《買嘢》Shopping (Child, Lv 1): Interactive Video: Cantonese Version',
//     content: '/workspace/00257/content/content.json',
//     thumbnail: 'hbl_00257.jpg',
//   },
//   {
//     path: '/workspace/00115p',
//     label:
//       '00115p《新雨褸》The New Raincoat (Child, Lv 3): Interactive Video: Cantonese Version',
//     content: '/workspace/00115p/content/content.json',
//     thumbnail: 'hbl_00115p.jpg',
//   },
// ];

export const LessonsSlidePropsH5P = ({
    item,
    index,
    handleCreate,
    showH5PPreview = false,
}: {
    item: SlideComponent;
    index: number;
    handleCreate?: (item: SlideComponent) => void;
    showH5PPreview?: boolean;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();
    const [h5pContent, setH5pContent] = useState<string | null>(null);
    const [itemContent, setItemContent] = useState<SlideComponentH5P>(item.content as SlideComponentH5P);

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    useEffect(() => {
        if (itemContent.h5pContentJsonPath) {
            fetch(itemContent.h5pContentJsonPath)
                .then((response: any) => response.json())
                .then((data) => {
                    setH5pContent(data);
                    setItemContent((prev) => ({
                        ...prev,
                        questions: data.interactiveVideo.assets.interactions,
                    }));
                })
                .catch((err) => console.error(err));
        }
    }, [itemContent.h5pContentJsonPath]);

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Autocomplete
                fullWidth
                disablePortal
                id='image-select-box'
                options={sceneOptions}
                value={sceneOptions.filter((v) => v.path === itemContent.h5pPath)[0]}
                onChange={(event, newValue, reason) => {
                    // TODO: [TTEACH-229] this is ugly. setItemContent was used to trigger re-rendering of LessonsSlidePropsH5P,

                    setItemContent((prev) => ({
                        ...prev,
                        h5pPath: newValue?.path ?? '',
                        h5pContentJsonPath: newValue?.content ?? '',
                        thumbnail: newValue?.thumbnail ?? '',
                    }));

                    // but the follow two seem to be needed in order to update 'item' right away
                    // to Save. Need to refactor
                    itemContent.h5pPath = newValue?.path ?? '';
                    itemContent.h5pContentJsonPath = newValue?.content ?? '';
                    itemContent.thumbnail = newValue?.thumbnail ?? '';

                    updateUI(true);
                }}
                renderInput={(params) => <TextField {...params} label={t('selectH5P')} />}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Box component='img' src={`/asset/images/${option.thumbnail}`} sx={{ width: '160px' }} />
                            <Typography>{option.label}</Typography>
                        </Stack>
                    </li>
                )}
            />

            <Box display='flex' width='100%'>
                {showH5PPreview ? (
                    <Box sx={{ width: '50%', mr: 2 }}>
                        <H5PContainer containerId={'h5p_preview'} itemContent={itemContent} />
                    </Box>
                ) : (
                    <></>
                )}
                <Stack
                    spacing={1}
                    sx={{
                        width: showH5PPreview ? '50%' : '100%',
                        height: '50vh',
                        overflow: 'auto',
                    }}>
                    {itemContent &&
                        itemContent.questions &&
                        itemContent.questions.map(
                            (
                                question: DragTheWordsInteraction | SingleChoiceSetInteraction | MultipleChoiceInteraction | TrueFalseQuestionInteraction | DragAndDropInteraction | TextInteraction,
                                index: number,
                            ) => <H5PQuestionComponent id={question.id} key={'H5PQuestionComponent' + index.toString()} index={index} question={question} />,
                        )}
                </Stack>
            </Box>
            {/* <H5PContainer containerId={'h5p_preview'} itemContent={itemContent} />

      <Stack spacing={1} sx={{ height: '50vh', overflow: 'auto' }}>
        {itemContent.questions.map(
          (
            question:
              | DragTheWordsInteraction
              | SingleChoiceSetInteraction
              | MultipleChoiceInteraction
              | TrueFalseQuestionInteraction
              | DragAndDropInteraction
              | TextInteraction,
            index: number,
          ) => (
            <H5PQuestionComponent
              id={question.id}
              key={'H5PQuestionComponent' + index.toString()}
              index={index}
              question={question}
            />
          ),
        )}
      </Stack> */}
        </Stack>
    );
};
