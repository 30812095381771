import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { UpgradePath } from './utils';
type UpgradePlanPopupProps = {
    onClose?: () => void;
    open: boolean;
    reason: 'featureNotIncluded' | 'lessonLimitExceeded' | 'classLimitExceeded' | 'studentLimitExceeded' | 'characterNotIncluded';
};

export const UpgradePlanPopup: React.FC<UpgradePlanPopupProps> = ({ onClose, open, reason }) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Get the navigate function

    let message = '';
    switch (reason) {
        case 'featureNotIncluded':
            message = t('upgradePlanPopup.featureNotIncluded');
            break;
        case 'lessonLimitExceeded':
            message = t('upgradePlanPopup.lessonLimitExceeded');
            break;
        case 'classLimitExceeded':
            message = t('upgradePlanPopup.classLimitExceeded');
            break;
        case 'studentLimitExceeded':
            message = t('upgradePlanPopup.studentLimitExceeded');
            break;
        case 'characterNotIncluded':
            message = t('upgradePlanPopup.characterNotIncluded');
            break;
    }
    return (
        <Dialog
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }} // Set a higher z-index
            open={open}
            onClose={onClose}
            aria-labelledby='upgrade-plan-dialog-title'
            aria-describedby='upgrade-plan-dialog-description'>
            <DialogTitle id='upgrade-plan-dialog-title'>{t('upgradePlanPopup.upgradeRequired')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='upgrade-plan-dialog-description'>{message} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('upgradePlanPopup.close')}</Button>
                <Button
                    onClick={() => {
                        navigate('/' + UpgradePath);
                    }}
                    color='primary'>
                    {t('upgradePlanPopup.upgrade')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
