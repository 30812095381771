import { useState, useEffect } from 'react';
import { Recorder, Interval } from '../types/recorder';

const MicRecorder = require('mic-recorder-to-mp3');
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const initialState: Recorder = {
    recordingMinutes: 0,
    recordingSeconds: 0,
    initRecording: false,
    audio: null,
};

let onStopCallback: (file: File) => void;

export default function useRecorder() {
    const [recorderState, setRecorderState] = useState<Recorder>(initialState);

    useEffect(() => {
        const MAX_RECORDER_TIME = 5;
        let recordingInterval: Interval = null;

        if (recorderState.initRecording)
            recordingInterval = setInterval(() => {
                setRecorderState((prevState: Recorder) => {
                    if (prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0) {
                        typeof recordingInterval === 'number' && clearInterval(recordingInterval);
                        return prevState;
                    }

                    if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
                        return {
                            ...prevState,
                            recordingSeconds: prevState.recordingSeconds + 1,
                        };
                    else if (prevState.recordingSeconds === 59)
                        return {
                            ...prevState,
                            recordingMinutes: prevState.recordingMinutes + 1,
                            recordingSeconds: 0,
                        };
                    else return prevState;
                });
            }, 1000);
        else typeof recordingInterval === 'number' && clearInterval(recordingInterval);

        return () => {
            typeof recordingInterval === 'number' && clearInterval(recordingInterval);
        };
    });

    // useEffect(() => {
    //   setRecorderState((prevState) => {
    //     if (prevState.mediaStream)
    //       return {
    //         ...prevState,
    //         mediaRecorder: new MediaRecorder(prevState.mediaStream),
    //       };
    //     else return prevState;
    //   });
    // }, [recorderState.mediaStream]);

    // useEffect(() => {
    //   const recorder = recorderState.mediaRecorder;
    //   let chunks: Blob[] = [];

    //   if (recorder && recorder.state === 'inactive') {
    //     recorder.start();

    //     recorder.ondataavailable = (e: MediaRecorderEvent) => {
    //       chunks.push(e.data);
    //     };

    //     recorder.onstop = async () => {
    //       const blob = new Blob(chunks, {
    //         type: 'audio/mpeg;',
    //       });
    //       chunks = [];

    //       try {
    //         const audioFile = new File([blob], 'test.mp3', {
    //           type: blob.type,
    //         });
    //         console.log(audioFile);
    //       } catch (err) {
    //         console.log(err);
    //       }

    //       setRecorderState((prevState: Recorder) => {
    //         if (prevState.mediaRecorder)
    //           return {
    //             ...initialState,
    //             audio: window.URL.createObjectURL(blob),
    //           };
    //         else return initialState;
    //       });
    //     };
    //   }

    //   return () => {
    //     if (recorder)
    //       recorder.stream
    //         .getAudioTracks()
    //         .forEach((track: AudioTrack) => track.stop());
    //   };
    // }, [recorderState.mediaRecorder]);

    return {
        recorderState,
        setOnStopCallback: (cb: (file: File) => void) => {
            onStopCallback = cb;
        },
        startRecording: () => {
            Mp3Recorder.start()
                .then(() => {
                    setRecorderState(() => {
                        return {
                            ...initialState,
                            initRecording: true,
                        };
                    });
                })
                .catch((e: any) => {
                    console.error('Error starting recording:', e);
                    if (e.name === 'NotAllowedError') {
                        alert('Microphone access was denied. Please allow microphone access and try again.');
                    } else if (e.name === 'NotFoundError') {
                        alert('No microphone was found. Please ensure a microphone is connected.');
                    } else {
                        alert('An error occurred while trying to access the microphone.');
                    }
                });
        },
        cancelRecording: () => {
            Mp3Recorder.stop()
                .getMp3()
                .then(([blob]: any) => {
                    setRecorderState((prevState) => {
                        return {
                            ...prevState,
                            recordingSeconds: 0,
                            recordingMinutes: 0,
                            initRecording: false,
                        };
                    });
                })
                .catch((e: any) => console.log(e));
        },
        saveRecording: () => {
            Mp3Recorder.stop()
                .getMp3()
                .then(([blob]: any) => {
                    try {
                        const audioFile = new File([new Blob(blob)], 'recording.mp3', {
                            type: 'audio/mpeg',
                        });
                        onStopCallback(audioFile);
                    } catch (err) {
                        console.log(err);
                    }

                    setRecorderState((prevState) => {
                        return {
                            ...prevState,
                            initRecording: false,
                        };
                    });
                })
                .catch((e: any) => console.log(e));
        },
        handleFileUpload: (file: File) => {
            try {
                onStopCallback(file);
            } catch (err) {
                console.log(err);
            }
        },
    };
}
