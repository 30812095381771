import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { ResultTableProps } from 'components/ResultTable';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AverageScoreProp = {
    score?: number;
};
const AverageClassScore = (props: AverageScoreProp) => {
    const { t } = useTranslation();
    return (
        <Card
            sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                height: '100%',
            }}>
            <CardHeader
                title={
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>
                        {t('resultPage.classAverageScore')}
                    </Typography>
                }
            />
            <Divider orientation='horizontal' flexItem />
            <CardContent
                sx={{
                    display: 'flex',
                    padding: '16px 132px',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    gap: '16px',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                    }}>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '48px',
                        }}>
                        {Math.round(props.score ?? 0)}
                    </Typography>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                        }}>
                        {t('resultPage.marks')}
                    </Typography>
                </Box>
                <CircularProgressWithLabel value={60} />
            </CardContent>
        </Card>
    );
};
interface FilledCircularProgressProps {
    value: number;
}
const CircularProgressWithLabel: React.FC<FilledCircularProgressProps> = (studentAvarageMarks) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: 100,
                height: 100,
                borderRadius: '50%',
                backgroundColor: 'gray',
            }}>
            <Box
                sx={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    backgroundImage: `conic-gradient(#f57c00 ${studentAvarageMarks.value}%, transparent ${studentAvarageMarks.value}%, transparent 100%)`,
                }}
            />
        </Box>
    );
};
export default AverageClassScore;
