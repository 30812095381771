/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ClassesAdd } from './ClassesAdd';
import { ClassCreateForm } from './components/ClassCreateForm';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { useNavigate } from 'react-router-dom';
import { useClassesHook } from './hooks/useClassesHook';

export const ClassesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data, loading, refresh, pagination, setPagination, updateClass, editTargetValue, setEditTargetValue, handleClose } = useClassesHook();

    const {} = useAuthChecking(data);

    const columns: GridColDef[] = [
        {
            field: 'className',
            headerName: t('class-name'),
            width: 300,
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'SelectStudentByClassAction',
            headerName: '',
            sortable: false,
            filterable: false,
            width: 170,
            renderCell: (params) => {
                return (
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{
                            width: 170,
                            textTransform: 'none',
                            borderRadius: '8px',
                        }}
                        onClick={(event) => {
                            // Handle your button click event here
                            event.stopPropagation(); // Prevent cell click event
                            navigate('/students/' + params.row.id);
                        }}>
                        {t('classPage.check')}
                    </Button>
                );
            },
        },
    ];

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <Grid container sx={{ pb: 3 }}>
                <Grid item xs={6}>
                    <Typography variant='h4'>{t('menu-classes')}</Typography>
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6}>
                    <ClassesAdd
                        refresh={refresh}
                        currentNumClasses={data?.classes.length ?? 0} // Providing a default value of 0
                    />
                </Grid>
            </Grid>

            <DataGrid
                columns={columns}
                paginationMode='server'
                rows={data?.classes || []}
                loading={loading}
                page={pagination.page}
                rowCount={data?.classesCount || 0}
                onPageChange={(page) => {
                    setPagination((prev) => ({ ...prev, page: page }));
                }}
                autoPageSize
                onPageSizeChange={(pz) => {
                    setPagination((prev) => ({ ...prev, pageSize: pz }));
                }}
                onCellClick={(e) => {
                    const { row } = e;
                    setEditTargetValue({
                        id: row.id,
                        className: row.className,
                    });
                }}
                sx={{
                    border: 2,
                    borderColor: '#DCCDB5',
                    borderRadius: '16px',
                    backgroundColor: '#fff',
                }}
            />

            <ClassCreateForm
                isUpdate
                isOpened={editTargetValue !== null}
                onSubmit={async (values) => {
                    updateClass(values);
                }}
                initValues={editTargetValue}
                handleClose={handleClose}
            />
        </Stack>
    );
};
