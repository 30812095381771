import { useLazyQuery } from '@apollo/client';
import { PlayCircleOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LessonModel } from 'models/lesson/LessonModel';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SlideListContext } from '../lessons/contexts/LessonsSlideContext';
import { WebcamContainer } from './components/WebcamContainer';

import { TestroomResults } from '../results/models';
import { GET_RESULTS_BY_TESTROOM } from '../results/queries';
import { ClosePreview } from './components/ClosePreview';
import { TestroomAssignDevice } from './components/TestroomAssignDevice';
import { TestroomEndSession } from './components/TestroomEndSession';
import { TestroomFreeStyleMode } from './components/TestroomFreeStyleMode';
import { TestroomLink } from './components/TestroomLink';
import { TestroomPasscode } from './components/TestroomPasscode';
import { TestroomPasslock } from './components/TestroomPasslock';
import { TestroomScreenSharing } from './components/TestroomScreenSharing';
import { TestroomSlide } from './components/TestroomSlide';
import { TestroomSlideList } from './components/TestroomSlideList';
import { TestroomTitle } from './components/TestroomTitle';
import { TestroomWritingHints } from './components/TestroomWritingHints';
import { TestroomModel } from './models/TestroomModel';

// jacky: jitsi iframe api
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import jquery from 'jquery';
import { TestroomShowResult } from './components/TestroomShowResult';

import { ClassroomContext } from './contexts/ClassroomContextProvider';
import { requestUpdateRoomSettings, RoomSettings } from './hooks/classroomHooks/requestUpdateRoomSettings';
import { requestShowScore } from './hooks/classroomHooks/requestShowScore';
import { requestEndLesson } from './hooks/classroomHooks/requestEndLesson';
import { TestroomScreenSync } from './components/TestroomScreenSync';

declare global {
    interface Window {
        $: any;
        jQuery: any;
    }
}
window.$ = window.jQuery = jquery;

declare function getParticipants(): any;
declare function jitsiInit(room: string): any;
declare function sendEndpointTextMessage(msg: string): any;
declare function sendSingleEndpointTextMessage(receiver: string, msg: string): any;
declare function RegisterEndpointTextMessageReceivedCallback(
    cb: (
        sender: string,
        endpointMessage: {
            action: string;
            acknownledgeAction: string;
            ts: number;
        },
    ) => void,
): any;
declare function UnRegisterEndpointTextMessageReceivedCallback(): any;
declare function RegisterParticipantJoinedCallback(cb: (receiver: string) => void): any;
declare function UnRegisterParticipantJoinedCallback(): any;
declare function RegisterParticipantLeftCallback(cb: (receiver: string) => void): any;
declare function UnRegisterParticipantLeftCallback(): any;
declare function jitsiUnload(): any;

export const TopButton = styled(Button)({
    backgroundColor: '#FFF3E0',
    color: '#026572',
    borderRadius: '16px',
    display: 'inline',
    '&:hover': {
        backgroundColor: '#FFFBF6',
    },
    textTransform: 'none',
    minWidth: '120px',
});

export const ButtonTypography = styled(Typography)({
    fontWeight: '700',
    color: '#23B1D3',
});

export const TopButtonTypography = styled(Typography)({
    fontWeight: '700',
});

export const GridCaption = styled(Typography)({
    color: '#FFF3E0',
    fontWeight: '700',
});

export const SmallGridCaption = styled(Typography)({
    color: '#FFF3E0',
});

export const ColoredPCO = styled(PlayCircleOutlined)({
    color: '#FFF3E0',
    fontSize: 35,
});

const sendIndexMessage = (receiver: string, sendIndex: number) => {
    //jacky: sendEndpointTextMessage to students
    const msg: any = {
        action: 'changeSlide',
        selectedSlideIndex: sendIndex,
        ts: Date.now(),
    };
    if (receiver === 'all') {
        endpointMsgQueue.push({
            msg,
            receivers: getParticipants(),
            ts: msg.ts,
        });
    } else {
        endpointMsgQueue.push({ msg, receivers: [receiver], ts: msg.ts });
    }
};
export const sendEnableScreenSharingMessage = (receiver: string, enable: boolean) => {
    const msg: any = {
        action: 'screenSharing',
        enable: enable,
        ts: Date.now(),
    };
    if (receiver === 'all') {
        endpointMsgQueue.push({
            msg,
            receivers: getParticipants(),
            ts: msg.ts,
        });
    } else {
        endpointMsgQueue.push({ msg, receivers: [receiver], ts: msg.ts });
    }
};

export const sendEnableWritingHintsMessage = (receiver: string, enable: boolean) => {
    const msg: any = {
        action: 'writingHints',
        enable: enable,
        ts: Date.now(),
    };
    if (receiver === 'all') {
        endpointMsgQueue.push({
            msg,
            receivers: getParticipants(),
            ts: msg.ts,
        });
    } else {
        endpointMsgQueue.push({ msg, receivers: [receiver], ts: msg.ts });
    }
};

export const sendEnableFreeStyleModeMessage = (receiver: string, enable: boolean) => {
    const msg: any = {
        action: 'freeStyleMode',
        enable: enable,
        ts: Date.now(),
    };
    if (receiver === 'all') {
        endpointMsgQueue.push({
            msg,
            receivers: getParticipants(),
            ts: msg.ts,
        });
    } else {
        endpointMsgQueue.push({ msg, receivers: [receiver], ts: msg.ts });
    }
};

type receiverList = {
    msg: any;
    receivers: string[];
    ts: number;
};
let endpointMsgQueue: receiverList[] = [];

const removeParticipantFromQueue = (receiver: string) => {
    for (let command of endpointMsgQueue) {
        command.receivers = command.receivers.filter((value) => value !== receiver);
    }
};
const acknowledgeAndDequeue = (receiver: string, acknownledgeAction: string, ts: number) => {
    for (let command of endpointMsgQueue) {
        if (command.msg.action === acknownledgeAction && command.ts === ts) {
            command.receivers = command.receivers.filter((value) => value !== receiver);
        }
    }
};

export const TestroomTemplate = ({
    lessonData,
    testroomData,
    updateScreenSharing,
    updateWritingHints,
    updateFreeStyleMode,
    preview,
}: {
    lessonData?: LessonModel;
    testroomData?: TestroomModel;
    updateScreenSharing?: (testroomID: string, enable: boolean) => void;
    updateWritingHints?: (testroomID: string, enable: boolean) => void;
    updateFreeStyleMode?: (testroomID: string, enable: boolean) => void;
    preview: boolean;
}) => {
    const { t } = useTranslation();
    const [jitsiInitState, setJitsiInitState] = useState<boolean>(false);
    const [isEndLessons, setIsEndLessons] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedSlideIndex, setSelectedSlideIndex } = useContext(SlideListContext);
    const [getResultsByTestRoomQueryData] = useLazyQuery<TestroomResults>(GET_RESULTS_BY_TESTROOM);
    const navigate = useNavigate();

    const [openAssignModal, setOpenAssignModal] = useState(false);
    const { socket, page, screenSharing, writingHints, freeStyleMode, screenSync, handleEndLesson } = useContext(ClassroomContext);

    useEffect(() => {
        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
            if (page !== undefined) {
                setSelectedSlideIndex(page);
            }
        }
    }, [page]);

    const initScripts = async () => {
        await appendScript('https://meet.jit.si/external_api.js');
        await appendScript('/jitsi.js');
        setJitsiInitState(true);
    };

    const appendScript = async (scriptToAppend = '', async = false) => {
        let resolveLoadScriptPromise: any | null = null;

        const loadScriptPromise = new Promise(async (resolve) => {
            resolveLoadScriptPromise = resolve;
        });

        const script = document.createElement('script');
        script.src = scriptToAppend;
        script.async = async;
        script.onload = () => resolveLoadScriptPromise(true);
        document.body.appendChild(script);

        return loadScriptPromise;
    };

    const sendEndLesson = useCallback(() => {
        const msg: any = {
            action: 'endLesson',
            ts: Date.now(),
        };
        endpointMsgQueue.push({
            msg,
            receivers: getParticipants(),
            ts: msg.ts,
        });
        setIsLoading(true);
    }, [endpointMsgQueue, setIsLoading]);

    const sendShowScore = useCallback(
        (studentsData: string) => {
            const msg: any = {
                action: 'showScore',
                studentsData: studentsData,
                ts: Date.now(),
            };
            endpointMsgQueue.push({
                msg,
                receivers: getParticipants(),
                ts: msg.ts,
            });
            setIsLoading(true);
        },
        [endpointMsgQueue, setIsLoading],
    );

    const onClickEndLesson = async () => {
        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED !== 'true') {
            if (getParticipants()?.length === 0) {
                navigate('/calendar');
                return;
            }
        }
        try {
            if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
                setIsLoading(true);
                requestEndLesson(socket, async (success) => {
                    await handleEndLesson(testroomData?.id ?? '');
                    setIsLoading(false);
                    navigate('/lessons');
                });
            } else {
                sendEndLesson();
            }
        } catch (error) {
            setIsEndLessons(false);
            console.warn('Network issue, Please try again.');
        }
    };

    const onClickShowResult = async () => {
        try {
            setIsLoading(true);
            const testRoomResultsData = await getResultsByTestRoomQueryData({
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
                variables: {
                    testroom: { equals: testroomData?.id },
                },
            });
            const studentList = testRoomResultsData?.data?.results?.reduce((list: any[], item) => {
                if (list.filter((student) => student?.studentId === item?.student?.id).length > 0) {
                    return list;
                } else {
                    let correctCount = 0;
                    let finishTestTime = 0;
                    let checkedResults: string[] = [];
                    const studentResults = testRoomResultsData?.data?.results?.filter((result) => result?.student?.id === item?.student?.id);
                    if (studentResults) {
                        for (const result of studentResults) {
                            finishTestTime += moment.utc(moment(result?.endTime).diff(moment(result?.startTime))).seconds();
                            if (!checkedResults.includes(result?.componentId) && result?.correct) {
                                correctCount += 1;
                                checkedResults.push(result?.componentId);
                            }
                        }
                    }
                    if (item?.student && !isNaN(finishTestTime)) {
                        return [
                            ...list,
                            {
                                studentId: item?.student?.id,
                                name: `${item?.student?.familyName}${item?.student?.givenName}`,
                                correctCount: correctCount,
                                finishTestTime: finishTestTime,
                            },
                        ];
                    } else {
                        return list;
                    }
                }
            }, []);
            const rankedStudentList = studentList?.sort((a, b) => {
                return b.correctCount - a.correctCount || a.finishTestTime - b.finishTestTime;
            });
            if (rankedStudentList && rankedStudentList.length > 0) {
                const topStudents = rankedStudentList.slice(0, 3);
                const totalQuestionCount = lessonData?.latestLessonDraft?.slides.reduce((totalCount, item) => {
                    if (item?.questionCount) {
                        return totalCount + item?.questionCount;
                    } else {
                        return totalCount;
                    }
                }, 0);
                const data = {
                    totalQuestionCount: totalQuestionCount,
                    data: topStudents,
                };
                if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
                    requestShowScore(socket, data, (success) => {
                        setIsLoading(false);
                    });
                } else {
                    sendShowScore(JSON.stringify(data));
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.warn('Network issue, Please try again.');
        }
    };

    // jacky: init jitsi
    // Note: need to make sure initScripts is only called once

    useEffect(() => {
        initScripts();
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED !== 'true') {
            const slideIndexInterval = setInterval(() => {
                // send slide index to new user every sec
                if (jitsiInitState) {
                    if (endpointMsgQueue.length > 0) {
                        for (let command of endpointMsgQueue) {
                            if (command.receivers.length > 0) {
                                for (let receiver of command.receivers) {
                                    console.log('aaa', command.msg);
                                    sendSingleEndpointTextMessage(receiver, command.msg);
                                }
                            }
                        }

                        // clear empty queue
                        endpointMsgQueue = endpointMsgQueue.filter((value) => value.receivers.length > 0);
                    }
                }
                if (endpointMsgQueue.some((item) => item?.msg?.action === 'endLesson')) {
                    setIsEndLessons(true);
                }
                if (isLoading && endpointMsgQueue.length === 0) {
                    setIsLoading(false);
                }
                if (isEndLessons && endpointMsgQueue.length === 0) {
                    navigate('/calendar');
                }
            }, 1000);

            return () => {
                clearInterval(slideIndexInterval);
            };
        }
    }, [endpointMsgQueue, jitsiInitState, setIsEndLessons, setIsLoading, navigate]);

    useEffect(() => {
        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED !== 'true') {
            if (jitsiInitState) {
                sendIndexMessage('all', selectedSlideIndex);

                RegisterParticipantJoinedCallback((receiver) => {
                    sendIndexMessage(receiver, selectedSlideIndex);
                    sendEnableWritingHintsMessage(receiver, testroomData?.writingHints ?? false);
                    sendEnableFreeStyleModeMessage(receiver, testroomData?.freeStyleMode ?? false);
                });

                return () => {
                    UnRegisterParticipantJoinedCallback();
                };
            }
        }
    }, [selectedSlideIndex, testroomData]);

    useEffect(() => {
        if (jitsiInitState && !preview && testroomData && testroomData.id !== '') {
            jitsiUnload();

            let room = `kazoo_${testroomData.id}`;
            jitsiInit(room);
            RegisterEndpointTextMessageReceivedCallback((sender, endpointMessage) => {
                if (jitsiInitState) {
                    if (
                        endpointMessage.action === 'receivedSlideIndex' ||
                        endpointMessage.action === 'receivedScreenSharing' ||
                        endpointMessage.action === 'receivedWritingHints' ||
                        endpointMessage.action === 'receivedFreeStyleMode' ||
                        endpointMessage.action === 'receivedShowScore' ||
                        endpointMessage.action === 'receivedEndLesson'
                    ) {
                        acknowledgeAndDequeue(sender, endpointMessage.acknownledgeAction, endpointMessage.ts);
                    }
                }
            });

            RegisterParticipantLeftCallback((receiver) => {
                removeParticipantFromQueue(receiver);
            });

            return () => {
                UnRegisterEndpointTextMessageReceivedCallback();
                UnRegisterParticipantLeftCallback();
            };
        }
    }, [preview, testroomData?.id, jitsiInitState]);

    return (
        <Stack
            justifyContent='space-between'
            spacing={2}
            sx={{
                width: '100vw',
                height: '100vh',
                bgcolor: '#5A4522',
                textAlign: 'center',
                p: 1,
            }}>
            <PopupCircularProgress isOpened={isLoading} />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    // border: 1,
                }}>
                <TestroomTitle lessonName={lessonData?.latestLessonDraft?.lessonName ?? ''} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginBottom: '12px',
                        gap: '16px',
                    }}>
                    {preview ? (
                        <ClosePreview lessonID={lessonData?.id ?? ''} />
                    ) : (
                        <>
                            <TestroomEndSession onClick={onClickEndLesson} />
                            <TestroomShowResult onClick={onClickShowResult} />
                            {testroomData ? <TestroomPasscode testroomData={testroomData} /> : ''}
                            {testroomData ? <TestroomPasslock testroomData={testroomData} /> : ''}
                            {testroomData ? <TestroomLink testroomData={testroomData} /> : ''}
                            {testroomData && updateScreenSharing ? (
                                <TestroomScreenSharing
                                    screenSharing={screenSharing}
                                    onToggle={() => {
                                        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
                                            requestUpdateRoomSettings(socket, testroomData?.id ?? '', { screenSharing: !screenSharing }, true, (response: RoomSettings) => {});
                                        } else {
                                            updateScreenSharing(testroomData.id, !testroomData.screenSharing);
                                            sendEnableScreenSharingMessage('all', !testroomData.screenSharing);
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {testroomData && updateWritingHints ? (
                                <TestroomWritingHints
                                    writingHints={writingHints}
                                    onToggle={() => {
                                        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
                                            requestUpdateRoomSettings(socket, testroomData?.id ?? '', { writingHints: !writingHints }, true, (response: RoomSettings) => {});
                                        } else {
                                            updateWritingHints(testroomData.id, !testroomData.writingHints);
                                            sendEnableWritingHintsMessage('all', !testroomData.writingHints);
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {testroomData && updateFreeStyleMode ? (
                                <TestroomFreeStyleMode
                                    freeStyleMode={freeStyleMode}
                                    onToggle={() => {
                                        if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true') {
                                            requestUpdateRoomSettings(socket, testroomData?.id ?? '', { freeStyleMode: !freeStyleMode }, true, (response: RoomSettings) => {});
                                        } else {
                                            updateFreeStyleMode(testroomData.id, !testroomData.freeStyleMode);
                                            sendEnableFreeStyleModeMessage('all', !testroomData.freeStyleMode);
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {testroomData && process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true' ? (
                                <TestroomScreenSync
                                    screenSync={screenSync}
                                    onToggle={() => {
                                        requestUpdateRoomSettings(socket, testroomData?.id ?? '', { screenSync: !screenSync }, true, (response: RoomSettings) => {});
                                    }}
                                />
                            ) : (
                                ''
                            )}

                            {testroomData && process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true' && (
                                <TestroomAssignDevice open={openAssignModal} setOpen={setOpenAssignModal} testroomData={testroomData} />
                            )}
                        </>
                    )}
                </Box>
            </Box>
            <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                spacing={1}
                sx={{
                    height: '100%',
                    maxHeight: '100%',
                }}>
                <TestroomSlide data={lessonData} />
                {preview ? '' : <WebcamContainer />}
            </Stack>
            <TestroomSlideList data={lessonData} preview={preview} />
        </Stack>
    );
};
