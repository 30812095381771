import React, { ReactElement, useEffect, useState } from 'react';
import { UpgradePlanPopup } from './UpgradePlanPopup';

type ProtectedRouteProps = {
    hasAccess: () => boolean;
    render: () => ReactElement;
    path: string;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ hasAccess, render }) => {
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    useEffect(() => {
        if (!hasAccess()) {
            // console.log('User does NOT have access');
            setShowUpgradePopup(true);
        } else {
            // console.log('User has access');
            setShowUpgradePopup(false);
        }
    }, [hasAccess]);

    if (showUpgradePopup) {
        return <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='featureNotIncluded' />;
    }

    return render();
};
