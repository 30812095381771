import { Grid } from '@mui/material';
import { ClearRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TopButton, TopButtonTypography } from '../TestroomTemplate';
import { useNavigate } from 'react-router-dom';

export const ClosePreview = ({ lessonID }: { lessonID: string }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <TopButton
            sx={{
                bgcolor: '#E94B00',
                color: '#FFF3E0',
                paddingTop: '14px',
                '&:hover': {
                    backgroundColor: '#F26800',
                },
            }}
            onClick={handleGoBack}>
            <Grid
                sx={{
                    display: 'inline-flex',
                    gap: '16px',
                }}>
                <ClearRounded />
                <TopButtonTypography>{t('close')}</TopButtonTypography>
            </Grid>
        </TopButton>
    );
};
