import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import useWorksheetMutation from './hooks/useWorksheetMutation';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';

export const WorksheetDelete = ({ worksheetID }: { worksheetID: string }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { deleteWorksheet } = useWorksheetMutation();
    const { accessDenied } = useContext(UserAuthContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await deleteWorksheet(worksheetID);
            handleClose();
            navigate('/worksheets');
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    return (
        <Box>
            <Button
                disabled={!worksheetID}
                variant='outlined'
                color='error'
                sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                }}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}>
                {t('delete')}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleDelete} autoFocus>
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
