import { Stack, Box } from '@mui/material';
import { LessonModel, SlideModel } from 'models/lesson/LessonModel';
import { ratio } from '../../lessons/hooks/useLessonSlide';
import { useContext } from 'react';
import { SlideListContext } from '../../lessons/contexts/LessonsSlideContext';
import { SlidePreview } from '../views/SlidePreview';
import { SlidePreviewContextProvider } from '../../lessons/contexts/SlidePreviewContext';
import { SmallGridCaption } from '../TestroomTemplate';

export const TestroomSlideList = ({ data, preview }: { data?: LessonModel; preview: boolean }) => {
    const { setSelectedSlideIndex, selectedSlideIndex } = useContext(SlideListContext);

    return (
        <Box
            sx={{
                overflow: 'auto',
                '::-webkit-scrollbar': {
                    backgroundColor: '#89744d',
                    height: '4px',
                    borderRadius: '20px',
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#FFFBF6',
                    borderRadius: '20px',
                },
                height: '140px',
                minHeight: '140px',
            }}>
            <Stack
                sx={{
                    flexDirection: 'row',
                    gap: '24px',
                }}>
                {data?.latestLessonDraft?.slides?.map((slidesdata: SlideModel, index: number) => (
                    <Box key={slidesdata.id}>
                        <Box
                            sx={{
                                position: 'relative',
                                height: '100px',
                                aspectRatio: `${ratio}`,
                            }}
                            onClick={() => {
                                if (preview) {
                                    setSelectedSlideIndex(index);
                                }
                            }}>
                            <SlidePreviewContextProvider>
                                <SlidePreview data={data.latestLessonDraft?.slides[index].data} hasBorderColor={false} borderRadius='20px' />
                            </SlidePreviewContextProvider>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    m: 'inherit',
                                    p: 'inherit',
                                    border: '4px solid #FFFBF6',
                                    borderRadius: '16px',
                                    width: '100%',
                                    height: '100%',
                                    ...(selectedSlideIndex === index
                                        ? {
                                              border: '8px solid #29C0E7',
                                          }
                                        : {}),
                                }}
                            />
                        </Box>
                        <SmallGridCaption>{index + 1}</SmallGridCaption>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};
