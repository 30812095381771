import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';

export const GET_RESULT_COUNT = gql`
    query GetGroupedQuestionId($studentId: String!, $testroomId: String!) {
        getGroupedQuestionId(studentId: $studentId, testroomId: $testroomId) {
            correctResult
            incorrectResult
        }
    }
`;

export const get_overall_testroom_anwer_status = (grading: string, testroomId: string) => {
    return {
        where: {
            AND: [
                {
                    testroom: {
                        id: {
                            equals: testroomId,
                        },
                    },
                    grading: {
                        equals: grading,
                    },
                },
            ],
        },
    };
};

export type ModelQueryType = {
    getGroupedQuestionId?: GetGroupedQuestionId;
};
export type GetGroupedQuestionId = {
    correctResult?: number;
    incorrectResult?: number;
};
type QueryVariableType = {
    testroomId: string;
    studentId: string;
};

export const useTestroomAnswerStatus = () => {
    const auth = useContext(UserAuthContext);
    const [getTestRoomAnswerStatus, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_RESULT_COUNT);
    const getCorrectAttempts = async (_variable: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await getTestRoomAnswerStatus({
                variables: {
                    studentId: _variable.studentId,
                    testroomId: _variable.testroomId,
                },
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });
            console.log(result);
            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }
            return result?.data;
        } catch (err) {
            console.log(err);
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };
    // const getWrongAttempts = async (
    //   variables: QueryVariableType,
    // ): Promise<ModelQueryType | null> => {
    //   try {
    //     const result = await getTestRoomAnswerStatus({
    //       variables: get_overall_testroom_anwer_status(
    //         'incorrect',
    //         variables.testroomId,
    //       ),
    //       fetchPolicy: 'no-cache',
    //       notifyOnNetworkStatusChange: true,
    //     });
    //     console.log(result);

    //     if (!result?.data) {
    //       throw new TTError(ERROR_TYPE.NO_DATA);
    //     }
    //     return result?.data;
    //   } catch (err) {
    //     const errorMsg = handleException(err);
    //     if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
    //       auth.accessDenied(errorMsg);
    //     } else {
    //       enqueueSnackbar(errorMsg, {
    //         variant: 'error',
    //         anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    //       });
    //     }
    //     throw new TTError();
    //   }
    //   return null;
    // };
    // const getNotFinishAttempts = async (
    //   variables: QueryVariableType,
    // ): Promise<ModelQueryType | null> => {
    //   try {
    //     const result = await getTestRoomAnswerStatus({
    //       variables: get_overall_testroom_anwer_status(
    //         'nonFinish',
    //         variables.testroomId,
    //       ),
    //       fetchPolicy: 'no-cache',
    //       notifyOnNetworkStatusChange: true,
    //     });
    //     console.log(result);
    //     if (!result?.data) {
    //       throw new TTError(ERROR_TYPE.NO_DATA);
    //     }
    //     return result?.data;
    //   } catch (err) {
    //     const errorMsg = handleException(err);
    //     if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
    //       auth.accessDenied(errorMsg);
    //     } else {
    //       enqueueSnackbar(errorMsg, {
    //         variant: 'error',
    //         anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    //       });
    //     }
    //     throw new TTError();
    //   }
    //   return null;
    // };
    return { getCorrectAttempts };
};
