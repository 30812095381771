import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from 'styles/Colors';
type HkcsQ2TextbubbleProp = {
    isPressed: boolean;
    text: string;
};
const HkcsQ2Textbubble = (prop: HkcsQ2TextbubbleProp) => {
    return (
        <Box
            sx={{
                display: 'flex',
                padding: '8px 22px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'text.primary',
                backgroundColor: prop.isPressed ? '#FFDE2F' : 'FFFFFF',
            }}>
            <Typography
                sx={{
                    color: colors.yellow900, // Replace with theme color if available
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '26px',
                    letterSpacing: '0.46px',
                }}>
                {prop.text}
            </Typography>
        </Box>
    );
};

export default HkcsQ2Textbubble;
