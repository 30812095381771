import { Typography } from '@mui/material';

import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentText } from 'models/lesson/LessonModel';

export const SlideText = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentText = item.content as SlideComponentText;

    return (
        <Typography
            sx={{
                position: 'absolute',
                fontSize: itemContent.fontSize,
                zoom: zoom,
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
                m: 'inherit',
                p: 'inherit',
                lineHeight: 1,
                whiteSpace: 'pre-line',
                textAlign: 'left',
                padding: 2,
                width: item.size.width,
                maxWidth: item.size.width,
            }}>
            {itemContent.text}
        </Typography>
    );
};
