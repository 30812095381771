import { Autocomplete, Box, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlipIcon from '@mui/icons-material/Flip';
import { SlideComponent, SlideComponentBackground } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { ImageUpload } from './components/ImageUpload';
import { ImageUploadDeleteButton } from './components/ImageUploadDeleteButton';
import { SetStateAction, useEffect } from 'react';

const sceneOptions = [
    { path: 'scene1_2.png', label: 'scene1_2' },
    { path: 'scene1_3.png', label: 'scene1_3' },
    { path: 'scene2_2.png', label: 'scene2_2' },
    { path: 'scene2_3.png', label: 'scene2_3' },
    { path: 'scene3_2.png', label: 'scene3_2' },
    { path: 'scene3_3.png', label: 'scene3_3' },
    { path: 'scene4_2.png', label: 'scene4_2' },
    { path: 'scene4_3.png', label: 'scene4_3' },
    { path: 'scene5_2.png', label: 'scene5_2' },
    { path: 'scene5_3.png', label: 'scene5_3' },
    { path: 'scene6_2.png', label: 'scene6_2' },
    { path: 'scene6_3.png', label: 'scene6_3' },
];

export const LessonsSlidePropsBackground = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentBackground = item.content as SlideComponentBackground;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const saveUploadedImage = (imageURL: string) => {
        item.size.width = 0;
        item.size.height = 0;
        itemContent.imageURL = imageURL;
    };

    const deleteUploadedImage = () => {
        itemContent.imageURL = '';
        itemContent.filename = '';
    };

    const isNewForm = !itemContent.filename && itemContent.filename === '' && !itemContent.imageURL && itemContent.imageURL === '';

    useEffect(() => {
        setCanSave?.(itemContent.filename !== '' || itemContent.imageURL !== '');
    }, [item]);

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            {isNewForm ? (
                <Autocomplete
                    fullWidth
                    disablePortal
                    id='image-select-box'
                    options={sceneOptions}
                    value={sceneOptions.filter((v) => v.path === itemContent.filename)[0]}
                    onChange={(event, newValue, reason) => {
                        item.size.width = 0;
                        item.size.height = 0;
                        itemContent.filename = newValue?.path ?? '';
                        updateUI(true);
                    }}
                    renderInput={(params) => <TextField {...params} label={t('selectImage')} />}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Box component='img' src={`/asset/images/${option.path}`} sx={{ width: '80px' }} />
                                <Typography>{option.label}</Typography>
                            </Stack>
                        </li>
                    )}
                />
            ) : null}

            <Box
                sx={{
                    backgroundColor: '#F8F0E0',
                    width: 150,
                    height: 150,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                {itemContent.imageURL !== '' || itemContent.filename !== '' ? (
                    <>
                        <Box
                            component='img'
                            src={itemContent.imageURL && itemContent.imageURL !== '' ? itemContent.imageURL : `/asset/images/${itemContent.filename || 'default.png'}`}
                            sx={{
                                width: 140,
                                height: 140,
                                // visibility: itemContent.image ? 'visible' : 'hidden',
                                transform: itemContent.flip === -1 ? 'scaleX(-1)' : 'none',
                                objectFit: 'contain',
                            }}
                            loading='lazy'
                        />
                        <ImageUploadDeleteButton
                            handleDelete={() => {
                                deleteUploadedImage();
                                updateUI(true);
                            }}
                        />
                    </>
                ) : (
                    <ImageUpload
                        onSelected={(imageURL) => {
                            saveUploadedImage(imageURL);
                            updateUI(true);
                        }}
                        uploadedImageURL={itemContent.imageURL}
                    />
                )}
            </Box>

            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography>{t('flip')}:</Typography>
                <ToggleButton
                    value='1'
                    selected={itemContent.flip === -1}
                    onChange={() => {
                        if (itemContent.flip === -1) {
                            itemContent.flip = 1;
                        } else {
                            itemContent.flip = -1;
                        }
                        updateUI(true);
                    }}
                    aria-label='bold'>
                    <FlipIcon />
                </ToggleButton>
            </Stack>

            {/* <TextField
        label={t('layer')}
        type='number'
        sx={{ width: '100%' }}
        value={itemContent.zIndex}
        onChange={(e) => {
          let v: number = parseInt(
            e.target.value === '' ? '0' : e.target.value,
          );
          if (v < 0) {
            v = 0;
          }
          // console.log(v);
          itemContent.zIndex = v;
          updateUI(false);
        }}
        onBlur={() => {
          updateUI(true);
        }}
      /> */}
        </Stack>
    );
};
