import { Box, Card, CardContent, CardHeader, Divider, List, ListItem, Typography } from '@mui/material';
import { OverallResultByQuestion } from 'models/resultPage/ResultPageModel';
import moment from 'moment';
import { gameTypeStr, GameType } from 'pages/results/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

type DifficultQuestionProps = {
    difficultQuestion: OverallResultByQuestion[];
    absentStudents: number;
};
const DifficultQuestion = (props: DifficultQuestionProps) => {
    const { t } = useTranslation();

    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                alignSelf: 'stretch',
                padding: '16px',
                minWidth: '480px',
            }}>
            <CardHeader
                title={
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>
                        {t('resultPage.difficult-question')}
                    </Typography>
                }
            />
            <Divider orientation='horizontal' flexItem />
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    gap: '16px',
                }}>
                <List
                    sx={{
                        display: 'flex',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        padding: '8px ',
                        width: '100%',
                    }}>
                    {props.difficultQuestion?.map((question) => (
                        <ListItem
                            sx={{
                                display: 'flex',
                                padding: '16px',
                                gap: '16px',
                                flexDirection: 'column',
                                alignSelf: 'stretch',
                                width: '100%',
                                borderRadius: 1, // equivalent to 4px if the theme spacing unit is 8
                                border: 1, // equivalent to '1px solid', color defaults to black
                                borderColor: 'divider', // use theme.palette.divider
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    padding: '5px',
                                    gap: '16px',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignSelf: 'stretch',
                                    width: '100%',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}>
                                    <Typography
                                        fontSize='14px'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            alignSelf: 'stretch',
                                        }}>
                                        {gameTypeStr(question?.questionType as GameType)} {/* {question.questionType} */}
                                    </Typography>
                                    <Typography
                                        fontSize='16px'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            alignSelf: 'stretch',
                                        }}>
                                        {question.question}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' flexItem />
                            <List
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    flexDirection: 'column',
                                    padding: '8px ',
                                }}>
                                <ListItem
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        gap: '8px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                            <path
                                                d='M22.909 12C22.909 18.0249 18.0248 22.9091 11.9999 22.9091C5.97499 22.9091 1.09082 18.0249 1.09082 12C1.09082 5.97505 5.97499 1.09088 11.9999 1.09088C18.0248 1.09088 22.909 5.97505 22.909 12Z'
                                                fill='#EF5350'
                                            />
                                            <path
                                                d='M11.9999 1.09088C14.8932 1.09088 17.6679 2.24023 19.7138 4.28608C21.7597 6.33193 22.909 9.1067 22.909 12C22.909 14.8932 21.7597 17.668 19.7138 19.7139C17.668 21.7597 14.8932 22.9091 11.9999 22.9091L11.9999 12V1.09088Z'
                                                fill='#4CAF50'
                                            />
                                        </svg>
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: 'var(--text-primary, rgba(83, 61, 24, 0.87))',
                                            fontFeatureSettings: "'clig' off, 'liga' off",
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '150%',
                                            letterSpacing: '0.15px',
                                        }}
                                        align='left'>
                                        {t('resultPage.average-score') + '：' + (question.correct / (question.studentPerformances.length - props.absentStudents)).toFixed(1)}
                                    </Typography>
                                </ListItem>
                                <ListItem
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        gap: '8px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                            <path d='M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z' fill='#AA00FF' />
                                            <path
                                                d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
                                                fill='#AA00FF'
                                            />
                                        </svg>
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: 'var(--text-primary, rgba(83, 61, 24, 0.87))',
                                            fontFeatureSettings: "'clig' off, 'liga' off",
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '150%',
                                            letterSpacing: '0.15px',
                                        }}
                                        align='left'>
                                        {t('resultPage.average-answer-time') +
                                            '：' +
                                            (
                                                question.studentPerformances.reduce((prevValue, res) => {
                                                    return prevValue + res.questionAttempt;
                                                }, 0) /
                                                (question.studentPerformances.length - props.absentStudents)
                                            ).toFixed(1)}
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default DifficultQuestion;
