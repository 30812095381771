import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { ResultTableProps } from 'components/ResultTable';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AverageScoreProp = {
    studentData?: ResultTableProps;
    questionNumber: number;
};
const AverageScore = (props: AverageScoreProp) => {
    const [AverageScore, setAverageScore] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (props.studentData) {
            if (props.studentData?.data != undefined) {
                // get student total score
                const totalScore = props.studentData?.data?.reduce((prevValue, student) => prevValue + (typeof student.totalScore !== 'number' ? 0 : student.totalScore), 0);
                // get student count (not absent students)
                const studentCount = props.studentData?.data?.filter((student) => typeof student.totalScore === 'number').length;
                const avgScore = totalScore / (studentCount == 0 ? 1 : studentCount);
                let roundedNum = avgScore.toFixed(1);

                setAverageScore(roundedNum);
            }
        }
    }, [props.studentData]);

    return (
        <Card
            sx={{
                width: '480px',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}>
            <CardHeader
                title={
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            color: '#533D18',
                        }}>
                        {t('resultPage.classAverageScore')}
                    </Typography>
                }
            />
            {/* <Divider orientation='horizontal' flexItem /> */}
            <CardContent
                sx={{
                    display: 'flex',
                    padding: '32px 132px',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    gap: '16px',
                    alignSelf: 'center',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                    }}>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '48px',
                            color: '#533D18',
                        }}>
                        {AverageScore + '/' + props.questionNumber}
                    </Typography>
                </Box>
                {/* <CircularProgressWithLabel value={60} /> */}
            </CardContent>
        </Card>
    );
};
interface FilledCircularProgressProps {
    value: number;
}
const CircularProgressWithLabel: React.FC<FilledCircularProgressProps> = (studentAvarageMarks) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: 100,
                height: 100,
                borderRadius: '50%',
                backgroundColor: 'gray',
            }}>
            <Box
                sx={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    backgroundImage: `conic-gradient(#f57c00 ${studentAvarageMarks.value}%, transparent ${studentAvarageMarks.value}%, transparent 100%)`,
                }}
            />
        </Box>
    );
};
export default AverageScore;
