import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from '../../contexts/UserAuthContext';

import { gql, useMutation } from '@apollo/client';

import LogoutIcon from '@mui/icons-material/Logout';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { PopupBody } from 'components/General/Popups/PopupBody';

export const LogoutButton = () => {
    const { t } = useTranslation();
    const { logout } = useContext(UserAuthContext);
    const [open, setOpen] = useState(false);
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('xs');
    const [fullWidth, setFullWidth] = useState(true);

    const LOGOUT_GQL = gql`
        mutation Logout {
            endSession
        }
    `;

    const [doLogout, { data }] = useMutation(LOGOUT_GQL);

    const handleLogout = async () => {
        doLogout({
            fetchPolicy: 'no-cache',
        });

        // todo error handling
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (data) {
            logout();
        }
    }, [data]);

    return (
        <>
            <IconButton onClick={handleClickOpen} size='large' sx={{ color: 'inherit', p: 0 }}>
                <LogoutIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle sx={{ paddingTop: '60px' }} id='alert-dialog-title'>
                    <PopupTitle sx={{ textAlign: 'center' }}>{t('private-page-logout-button-title')}</PopupTitle>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <PopupBody sx={{ textAlign: 'center' }}>{t('confirm-logout-description')}</PopupBody>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: '60px' }}>
                    <CancelButton onClick={handleClose}>{t('cancel')}</CancelButton>
                    <SubmitButton onClick={handleLogout} autoFocus>
                        {t('private-page-logout-button-title')}
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
