import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldsIcon from '@mui/icons-material/TextFields';

import Draggable from 'react-draggable';
import { useContext } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentReorderSentenceExercise } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataReorderSentence = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentReorderSentenceExercise = item.content as SlideComponentReorderSentenceExercise;

    return (
        <>
            <Draggable
                disabled={disabled}
                bounds='parent'
                handle='strong'
                onStart={() => {
                    setSelectedComponentIndex(index);
                }}
                onStop={(e, position) => {
                    const newX = position.x / zoom;
                    const newY = position.y / zoom;
                    if (item.pos.x !== newX || item.pos.y !== newY) {
                        item.pos.x = newX;
                        item.pos.y = newY;
                        updateItemProperties({ ...item }, true);
                    }
                }}
                position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
                <strong
                    style={{
                        cursor: 'move',
                        width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                        height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                        position: 'absolute',
                        zIndex: itemContent.zIndex ?? 0,
                    }}>
                    <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<TextFieldsIcon />} title={t('reorder-sentence')} resize={true} />
                </strong>
            </Draggable>

            {itemContent.questions.map((question, qIdx) => {
                return (
                    <Draggable
                        disabled={disabled}
                        key={qIdx}
                        bounds='parent'
                        handle='strong'
                        onStart={() => {
                            setSelectedComponentIndex(index);
                        }}
                        onStop={(e, position) => {
                            const newX = position.x / zoom;
                            const newY = position.y / zoom;
                            if (question.pos?.x !== newX || question.pos?.y !== newY) {
                                question.pos.x = newX;
                                question.pos.y = newY;
                                updateItemProperties({ ...item }, true);
                            }
                        }}
                        position={{
                            x: question.pos?.x * zoom,
                            y: question.pos?.y * zoom,
                        }}>
                        <strong
                            style={{
                                cursor: 'move',
                                position: 'absolute',
                                zIndex: itemContent.zIndex ?? 0,
                            }}>
                            <Box
                                sx={{
                                    borderRadius: 3,
                                    backgroundColor: '#fff',
                                    p: '18px',
                                    color: '#533D18',
                                    boxShadow: 2,
                                    zoom: zoom,
                                }}>
                                {question.questionBubbles.join('/')}
                            </Box>
                        </strong>
                    </Draggable>
                );
            })}
        </>
    );
};
