import CryptoJS from 'crypto-js';
export function EncryptionAES(str: string, secretKey: string) {
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const iv = CryptoJS.enc.Hex.parse('0'.repeat(16));
    const data = CryptoJS.enc.Utf8.parse(str);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}
