import { gql, useMutation } from '@apollo/client';
import { ERROR_TYPE, TTError } from 'models/ErrorHandling';

const SAVE_GQL = gql`
    mutation SaveStudentProfile($userUpdateInput: UserUpdateInput!, $studentProfileUpdateInput: StudentProfileUpdateInput!, $id: ID!, $studentProfileId: ID!) {
        updateUser(data: $userUpdateInput, where: { id: $id }) {
            id
        }
        updateStudentProfile(data: $studentProfileUpdateInput, where: { id: $studentProfileId }) {
            id
        }
    }
`;

type StudentModelMutationVariableType = {
    id: string;
    studentProfileId: string;
    name: string;
    email: string;
    password: string;
    passwordAES: string;
    studentNumber: string;
    familyName: string;
    givenName: string;
    dob: string;
    class: string;
};

type StudentModelMutationType = {
    updateUser: StudentModelMutationVariableType;
};

export const useStudentModelUpdateMutation = () => {
    const [saveForm] = useMutation<StudentModelMutationType>(SAVE_GQL);

    const update = async (variables: StudentModelMutationVariableType) => {
        let userUpdateInput: Partial<StudentModelMutationVariableType> = {
            name: variables.name,
            email: variables.email,
        };
        if (variables.password !== '') {
            userUpdateInput.password = variables.password;
        }
        if (variables.passwordAES !== '') {
            userUpdateInput.passwordAES = variables.passwordAES;
        }
        // console.log('aes:', variables.passwordAES, userUpdateInput.passwordAES);

        let studentProfileUpdateInput: Partial<StudentModelMutationVariableType> | any = {
            familyName: variables.familyName,
            givenName: variables.givenName,
            studentNumber: variables.studentNumber,
            class: { connect: { id: variables.class } },
            dob: variables.dob,
        };

        const result = await saveForm({
            variables: {
                userUpdateInput,
                studentProfileUpdateInput,
                id: variables.id,
                studentProfileId: variables.studentProfileId,
            },
        });

        if (!result || !result.data?.updateUser) {
            throw new TTError(ERROR_TYPE.UPDATE_FAILED);
        }
    };

    return { update };
};
