/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, Grid, Card, CardMedia, CardContent, Button, CardActionArea, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { format } from 'react-string-format';
import AddIcon from '@mui/icons-material/Add';
import { WorksheetModel } from './models/Worksheet';
import { WorksheetQueryModel } from '../../queryModels/WorksheetQueryModel';
import { UserAuthContext } from '../../contexts/UserAuthContext';
// import { SlidePreview } from '../testroom/views/SlidePreview';
// import { SlidePreviewContextProvider } from './contexts/SlidePreviewContext';
import { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GoogleSigninButton } from 'components/GoogleSigninButton';
import { ResultType } from '../onBoarding/subPage/SignIn';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { UserPermissionContext } from '../../contexts/UserPermissionContext';
import { useNavigate } from 'react-router-dom';
import { UpgradePlanPopup } from '../../routes/UpgradePlanPopup';

const WORKSHEET_QUERY = gql`
    query GetRows {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
            }
        }
        worksheets(orderBy: [{ dateUpdated: desc }]) {
            id
            worksheetName
            googleDocId
            dateUpdated
        }
    }
`;

export const WorksheetsPage = () => {
    const { logout } = useContext(UserAuthContext);
    const { t, i18n } = useTranslation();

    const { loading, data, refetch } = useQuery<WorksheetQueryModel>(WORKSHEET_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const [worksheetData, SetWorksheetData] = useState<WorksheetModel[]>([]);
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }
    const { setUserData, userData } = useContext(UserAuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [userLinkedWithGoogle, setUserLinkedWithGoogle] = useState(false);

    const { authCheck } = useAuthChecking();
    const { userPermissions } = useContext(UserPermissionContext);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(data);
        if (data?.worksheets) {
            const worksheets = data.worksheets.map((worksheet) => ({
                ...worksheet,
                googleDocId: { id: worksheet.googleDocId },
            }));
            SetWorksheetData(data.worksheets);
        }
    }, [data]);

    const refresh = () => {
        refetch();
    };

    useEffect(() => {
        refresh();
    }, []);

    // check session expire
    useEffect(() => {
        if (data) {
            try {
                authCheck(data?.authenticatedItem);
            } catch (err) {
                logout();
            }
        }
    }, [data?.authenticatedItem]);

    useEffect(() => {
        if (userData?.googleEmail != undefined && userData?.googleEmail != null && userData?.googleEmail.length > 0) {
            setUserLinkedWithGoogle(true);
        } else {
            setUserLinkedWithGoogle(false);
        }
    }, [userData?.googleEmail]);

    const handleSubmitGoogle = async (result: ResultType | undefined) => {
        // console.log('handleSubmitGoogle result: ', result);
        // setUserData(result.data.authenticateUserWithGoogle.item);

        if (result?.data?.authenticateWithGoogle?.__typename === 'AuthenticateWithGoogleFailure') {
            setTimeout(() => {
                setErrorMessage(result?.data?.authenticateWithGoogle?.message?.toString() || '');
            }, 10000);
        }

        // console.log('result: ', result);

        if (result) {
            setUserData({
                ...userData,
                ...result.data.authenticateWithGoogle.item,
            });
        }
    };

    const currentLocale = require(`date-fns/locale/${i18n.language}`).default;

    const handleButtonClick = () => {
        if (userPermissions?.enableWorksheetFunction) {
            navigate('/worksheets/+');
        } else {
            setShowUpgradePopup(true);
        }
    };
    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <PopupCircularProgress isOpened={loading} />

            <Grid container sx={{ pb: 3 }}>
                <Grid item xs={6}>
                    <Typography variant='h4'>{t('menu-worksheet')}</Typography>
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6}>
                    <Button
                        disabled={!userLinkedWithGoogle}
                        variant='contained'
                        color='secondary'
                        sx={{ textTransform: 'none', borderRadius: '8px' }}
                        startIcon={<AddIcon />}
                        onClick={handleButtonClick}>
                        {t('add-worksheet')}
                    </Button>
                    {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='featureNotIncluded' />}
                </Grid>
            </Grid>
            {userLinkedWithGoogle ? (
                // Display worksheet data if userLinkedWithGoogle is true
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} justifyContent='flex-start' alignItems='flex-start'>
                    {worksheetData?.map((data: WorksheetModel) => (
                        <Grid item xl={3} key={data.id}>
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    borderRadius: '16px',
                                    border: 1,
                                    borderColor: '#5A4522',
                                    bgcolor: '#FFFBF6',
                                }}
                                elevation={0}>
                                {/* <CardActionArea href={'/worksheets/' + data.id}> */}
                                <CardActionArea href={'/worksheets/' + data.id} onClick={() => window.open('https://docs.google.com/document/d/' + data.googleDocId + '/edit', '_blank')}>
                                    <CardMedia
                                        component='div'
                                        sx={{
                                            ml: -0.5,
                                            aspectRatio: '2.1',
                                            alignContent: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                width: '350px',
                                                height: '160px',
                                                overflow: 'auto',
                                                overflowX: 'hidden',
                                                overflowY: 'hidden',
                                            }}></Box>
                                    </CardMedia>
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            component='div'
                                            sx={{
                                                fontSize: '1rem',
                                                color: '#5A4522',
                                                fontWeight: 'medium',
                                                lineHeight: '21px',
                                            }}>
                                            {data.worksheetName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 'regular',
                                                lineHeight: '16px',
                                                color: '#BFAC90',
                                                height: '10px',
                                            }}>
                                            {data?.dateUpdated &&
                                                format(
                                                    t('last-update'),
                                                    formatDistanceToNow(new Date(data?.dateUpdated), {
                                                        addSuffix: true,
                                                        locale: currentLocale,
                                                    }),
                                                )}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '50vh',
                    }}>
                    <Grid container justifyContent='center' alignItems='center' maxWidth='500px'>
                        <Grid item>
                            <Typography sx={{ marginBottom: 5 }}>{t('worksheet-need-google')}</Typography>
                            <div
                                style={{
                                    justifyContent: 'center',
                                }}>
                                <GoogleOAuthProvider clientId={clientId}>
                                    <GoogleSigninButton buttonTitle='Link with Google' onSuccess={handleSubmitGoogle} />
                                </GoogleOAuthProvider>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Stack>
    );
};
