import { Box } from '@mui/material';

import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentMultipleChoice, SlideComponentMultipleChoiceQuestionModel } from 'models/lesson/LessonModel';

export const SlideMultipleChoice = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);
    const { t } = useTranslation();

    let itemContent: SlideComponentMultipleChoice = item.content as SlideComponentMultipleChoice;

    return (
        <Box
            sx={{
                position: 'absolute',
                borderRadius: 3,
                backgroundColor: '#fff',
                p: '18px',
                color: '#533D18',
                boxShadow: 2,
                zoom: zoom,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
            }}>
            {itemContent.questions.map((question, qIdx) => (
                <span key={qIdx}>
                    {t('ui.question-num').replace('{num}', `${qIdx + 1}`)}
                    {':\t'} {question.questionText}
                </span>
            ))}
        </Box>
    );
};
