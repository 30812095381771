import { gql, useLazyQuery } from '@apollo/client';
import { SlideComponentReorderSentenceExercise, SlideComponentReorderSentenceExerciseQuestionModel } from 'models/lesson/LessonModel';
import { shuffleArray } from '../components/ReorderQuestionComponent';
const { v4: uuidv4 } = require('uuid');

const marginLeft = 100;
const marginTop = 200;

type SentenceModel = {
    text: string;
};

type GenerateTextQueryModel = {
    generateText: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionCount: Int!, $questionLength: String!) {
        generateText(questionTopic: $questionTopic, questionCount: $questionCount, questionLength: $questionLength) {
            text
        }
    }
`;

export const useLessonsSlidePropsReorderSentenceHook = (itemContent: SlideComponentReorderSentenceExercise) => {
    const [genText, { loading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, { fetchPolicy: 'no-cache' });

    const getTextFromServer = async (questionCount: number, questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }

        try {
            const result = await genText({
                variables: { questionCount, questionLength, questionTopic },
            });

            UpdateQuestionArrayInfo(result.data?.generateText ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const UpdateQuestionArrayInfo = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentReorderSentenceExerciseQuestionModel[] = [];

        let i = 0;
        for (const question of data) {
            const bubbles = ParseQuestionToBubbles(question.text);
            shuffleArray(bubbles);

            newQuestion.push({
                id: uuidv4(),
                answer: question.text,
                questionBubbles: [...bubbles],
                pos: {
                    x: marginLeft + 400 * (i % 2),
                    y: marginTop + 100 * Math.floor(i / 2),
                },
            });

            i++;
        }

        itemContent.questions = newQuestion;
    };

    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);

        itemContent.questions = newQuestion;
    };

    const updateQuestionBubblesArrayByIndex = (idx: number, content: string[], isDrag: boolean) => {
        if (!isDrag) {
            shuffleArray(content);
        }

        itemContent.questions[idx].questionBubbles = content;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            answer: '',
            questionBubbles: [],
            pos: {
                x: marginLeft + 400 * (currentIdx % 2),
                y: marginTop + 100 * Math.floor(currentIdx / 2),
            },
        });
    };

    const questionFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].answer = textContent;

        updateQuestionBubblesArrayByIndex(idx, ParseQuestionToBubbles(textContent), false);
    };

    return {
        getTextFromServer,
        deleteQuestionArrayInfo,
        updateQuestionBubblesArrayByIndex,
        questionFieldChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
    };
};

function ParseQuestionToBubbles(text: string) {
    const arr = text.split(/[／/]/);
    return arr;
}
