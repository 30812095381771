import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useLessonSlideMutation from '../hooks/useLessonSlideMutation';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';

export const LessonsSlideListDelete = ({ lessonID, slideID }: { lessonID: string; slideID: string }) => {
    const { t } = useTranslation();
    const { deleteSlide } = useLessonSlideMutation();
    const [open, setOpen] = useState(false);
    const { accessDenied } = useContext(UserAuthContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        // console.log(props.lessonID);
        try {
            await deleteSlide(lessonID, slideID);
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    return (
        <>
            <IconButton
                size='medium'
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    mr: -1,
                    mt: -1,
                    width: '20px',
                    height: '20px',
                    bgcolor: 'white',
                    '&:hover': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                }}
                onClick={handleClickOpen}>
                <CloseIcon fontSize='small' />
            </IconButton>

            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleDelete} autoFocus>
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
