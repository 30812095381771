import { Stack, TextField, FormControl, Select, MenuItem, Box, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { SlideComponent, SlideComponentPunctuationMark, SlideComponentPunctuationMarkQuestionModel } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { useLessonsSlidePropsPunctuationMarkHook } from './hooks/useLessonsSlidePropsPunctuationMarkHook';
import { PunctuationMarkComponent } from './components/PunctuationMarkComponent';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsPunctuationMark = ({ item, index, handleCreate }: { item: SlideComponent; index: number; handleCreate?: (item: SlideComponent) => void }) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentPunctuationMark = item.content as SlideComponentPunctuationMark;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const { getTextFromServer, deleteQuestionArrayInfo, questionFieldChange, loading, hasGenerated, addQuestion } = useLessonsSlidePropsPunctuationMarkHook(itemContent);

    return (
        <>
            <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack direction='row' justifyContent='space-evenly' spacing={2}>
                    <FormControl sx={{ flex: 2, height: '48px', borderRadius: '12px' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('reorder-sentence-number-of-questions')}
                        </Typography>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={itemContent.questionCount.toString()}
                            // label={t('cardOrWriting')}
                            onChange={(event: SelectChangeEvent) => {
                                itemContent.questionCount = parseInt(event.target.value);
                                updateUI(true);
                            }}>
                            {[...Array(10)].map((_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('reorder-sentence-number-of-questions-limit')}
                        </Typography>
                    </FormControl>
                    <FormControl sx={{ flex: 2, height: '48px', borderRadius: '12px' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('reorder-sentence-number-of-questions-length')}
                        </Typography>

                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={itemContent.questionLength}
                            onChange={(event: SelectChangeEvent) => {
                                itemContent.questionLength = event.target.value;
                                updateUI(true);
                            }}>
                            <MenuItem value={'short'}>{t('reorder-sentence-number-of-questions-short')}</MenuItem>
                            <MenuItem value={'long'}>{t('reorder-sentence-number-of-questions-long')}</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ flex: 2 }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('reorder-sentence-number-of-questions-keywords')}
                        </Typography>
                        <TextField
                            sx={{
                                height: '48px',
                                borderRadius: '12px',
                            }}
                            placeholder='運動/旅行'
                            type='string'
                            value={itemContent.questionTopic}
                            onChange={(e) => {
                                itemContent.questionTopic = e.target.value === '' ? '' : e.target.value;
                                updateUI(false);
                                debouncedSaveHandler(() => {
                                    updateUI(true);
                                });
                            }}
                            // onBlur={(e) => {
                            //   updateUI(true);
                            // }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flow',
                            // justifyContent: 'center',
                            overflow: 'visible',
                            flex: 1,
                        }}>
                        <SubmitButton
                            disabled={hasGenerated}
                            startIcon={<AddIcon />}
                            type='button'
                            onClick={() => {
                                getTextFromServer(itemContent.questionCount, itemContent.questionLength, itemContent.questionTopic, false);
                            }}
                            sx={{ top: -10, height: '48px', width: '100.5px' }}>
                            {t('reorder-sentence-number-of-questions-add')}
                        </SubmitButton>
                        <Button
                            disabled={!hasGenerated}
                            startIcon={<AutorenewIcon />}
                            type='button'
                            onClick={() => {
                                getTextFromServer(itemContent.questionCount, itemContent.questionLength, itemContent.questionTopic, true);
                            }}
                            sx={{
                                top: 5,
                                backgroundColor: 'white',
                                width: '100.5px',
                                height: '48px',
                            }}>
                            {t('reorder-sentence-number-of-questions-regenerate')}
                        </Button>
                    </Box>
                </Stack>
                <Stack spacing={1} sx={{ height: '50vh', overflow: 'auto' }}>
                    {itemContent.questions.map((question: SlideComponentPunctuationMarkQuestionModel, index: number) => (
                        <PunctuationMarkComponent
                            id={question.id}
                            key={'ReorderQuestionComponent' + index.toString()}
                            index={index}
                            text={question.text}
                            questionText={question.question}
                            answerArray={question.answer}
                            onTextFieldChange={(idx: number, textContent: string) => {
                                questionFieldChange(idx, textContent);
                                updateUI(true);
                            }}
                            deleteItemChange={(idx: number) => {
                                deleteQuestionArrayInfo(idx);
                                updateUI(true);
                            }}
                        />
                    ))}
                </Stack>
                {itemContent.questions.length < 10 && (
                    <IconButton // add an icon button for delete
                        sx={{
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            addQuestion();
                            updateUI(true);
                        }}>
                        <AddIcon />
                    </IconButton>
                )}
            </Stack>

            <PopupCircularProgress isOpened={loading} />
        </>
    );
};
