import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonsSlidePropsText } from './dataComponents/props/LessonsSlidePropsText';
import { SlideListContext } from '../contexts/LessonsSlideContext';
import { LessonsSlidePropsImage } from './dataComponents/props/LessonsSlidePropsImage';
import { LessonsSlidePropsCharacter } from './dataComponents/props/LessonsSlidePropsCharacter';
import { LessonsSlidePropsAudio } from './dataComponents/props/LessonsSlidePropsAudio';
import { LessonsSlidePropsBackground } from './dataComponents/props/LessonsSlidePropsBackground';
import { ComponentTypeMap } from 'models/lesson/LessonModel';
import { LessonsSlidePropsReorderSentence } from './dataComponents/props/LessonsSlidePropsReorderSentence';
import { LessonsSlidePropsFillInTheBlank } from './dataComponents/props/LessonsSlidePropsFillInTheBlank';
import { LessonsSlidePropsPunctuationMark } from './dataComponents/props/LessonsSlidePropsPunctuationMark';
import { LessonsSlidePropsH5P } from './dataComponents/props/LessonsSlidePropsH5P';
import useLessonRefresh from '../hooks/common/useLessonRefresh';
import { LessonsSlidePropsMatching } from './dataComponents/props/LessonsSlidePropsMatching';
import { LessonsSlidePropsHkcsQ1 } from './dataComponents/props/LessonsSlidePropsHkcsQ1';
import { LessonsSlidePropsHkcsQ2 } from './dataComponents/props/LessonsSlidePropsHkcsQ2';
import { LessonsSlidePropsHkcsQ3 } from './dataComponents/props/LessonsSlidePropsHkcsQ3';
import { LessonsSlidePropsMultipleChoice } from './dataComponents/props/LessonsSlidePropsMultipleChoice';
import { LessonsSlidePropsSasAudio } from './dataComponents/props/LessonsSlidePropsSasAudio';
import { LessonsSlidePropsSasFillInTheBlanks } from './dataComponents/props/LessonsSlidePropsSasFillInTheBlanks';
import { LessonsSlidePropsSasFillInTheBlanksWriting } from './dataComponents/props/LessonsSlidePropsSasFillInTheBlanksWriting';
import { LessonsSlidePropsSasRadicalMatching } from './dataComponents/props/LessonsSlidePropsSasRadicalMatching';
import { LessonsSlidePropsHkcsEnQ1 } from './dataComponents/props/LessonsSlidePropsHkcsEnQ1';
import { LessonsSlidePropsHkcsEnQ2 } from './dataComponents/props/LessonsSlidePropsHkcsEnQ2';

export const LessonsAddProps = () => {
    const { t } = useTranslation();
    const { selectedComponentIndex, selectedSlideIndex } = useContext(SlideListContext);
    const { lessonData } = useLessonRefresh();

    const componentSwitch = (componentType?: keyof ComponentTypeMap) => {
        const slideComponentData = lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data?.[selectedComponentIndex]!;
        switch (componentType) {
            case 'punctuationMark':
                return <LessonsSlidePropsPunctuationMark item={slideComponentData} index={selectedComponentIndex} />;
            case 'fillInTheBlank':
                return <LessonsSlidePropsFillInTheBlank item={slideComponentData} index={selectedComponentIndex} />;
            case 'reorderSentence':
                return <LessonsSlidePropsReorderSentence item={slideComponentData} index={selectedComponentIndex} />;
            case 'matching':
                return <LessonsSlidePropsMatching item={slideComponentData} index={selectedComponentIndex} />;
            case 'text':
                return <LessonsSlidePropsText item={slideComponentData} index={selectedComponentIndex} />;
            case 'image':
                return <LessonsSlidePropsImage item={slideComponentData} index={selectedComponentIndex} />;
            case 'background':
                return <LessonsSlidePropsBackground item={slideComponentData} index={selectedComponentIndex} />;
            case 'character':
                return <LessonsSlidePropsCharacter item={slideComponentData} index={selectedComponentIndex} />;
            case 'audio':
                return <LessonsSlidePropsAudio item={slideComponentData} index={selectedComponentIndex} />;
            case 'h5p':
                return <LessonsSlidePropsH5P item={slideComponentData} index={selectedComponentIndex} />;
            case 'hkcsQ1':
                return <LessonsSlidePropsHkcsQ1 item={slideComponentData} index={selectedComponentIndex} />;
            case 'hkcsQ2':
                return <LessonsSlidePropsHkcsQ2 item={slideComponentData} index={selectedComponentIndex} />;
            case 'hkcsQ3':
                return <LessonsSlidePropsHkcsQ3 item={slideComponentData} index={selectedComponentIndex} />;
            case 'hkcsEnQ1':
                return <LessonsSlidePropsHkcsEnQ1 item={slideComponentData} index={selectedComponentIndex} />;
            case 'hkcsEnQ2':
                return <LessonsSlidePropsHkcsEnQ2 item={slideComponentData} index={selectedComponentIndex} />;
            case 'multipleChoice':
                return <LessonsSlidePropsMultipleChoice item={slideComponentData} index={selectedComponentIndex} />;
            case 'sasAudio':
                return <LessonsSlidePropsSasAudio item={slideComponentData} index={selectedComponentIndex} />;
            case 'sasFillInTheBlanks':
                return <LessonsSlidePropsSasFillInTheBlanks item={slideComponentData} index={selectedComponentIndex} />;
            case 'sasFillInTheBlanksWriting':
                return <LessonsSlidePropsSasFillInTheBlanksWriting item={slideComponentData} index={selectedComponentIndex} />;
            case 'sasRadicalMatching':
                return <LessonsSlidePropsSasRadicalMatching item={slideComponentData} index={selectedComponentIndex} />;
            default:
                return '';
        }
    };
    return (
        <Stack
            sx={{
                display: 'flex',
                width: '100%',
                maxHeight: 'calc(100vh - 290px)', // 290px is the height of the top bar
                overflow: 'scroll',
                overflowX: 'hidden',
            }}>
            <Typography variant='h6'>{t('properties')}</Typography>
            {componentSwitch(lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data?.[selectedComponentIndex]?.type)}
        </Stack>
    );
};
