import { UserAuthContext } from 'contexts/UserAuthContext';
import { useLessonShareModelConfirmInvitationMutation } from 'models/lessonShare/useLessonShareModelConfirmInvitationMutation';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LessonsShareWithPublicPath } from 'routes/utils';

export const useConfirmLessonShareHook = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const { isUserLoggedIn } = useContext(UserAuthContext);

    const { t } = useTranslation();

    const { updateConfirmInvitation } = useLessonShareModelConfirmInvitationMutation();

    const [canNavigate, setCanNavigate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserLoggedIn && token) {
            confirm(token);
        }
    }, [isUserLoggedIn, token]);

    useEffect(() => {
        if (canNavigate) {
            navigate('/' + LessonsShareWithPublicPath);
        }
    }, [canNavigate, navigate]);

    const confirm = async (token: string) => {
        if (token && token !== '') {
            try {
                const result = await updateConfirmInvitation({ token });
                if (result) {
                    enqueueSnackbar(t('lessonShare.success'), {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }
            } catch (err) {
            } finally {
                setCanNavigate(true);
            }
        }
    };

    return {};
};
