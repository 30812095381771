import ToJyutping from 'to-jyutping';
import pinyin from 'chinese-to-pinyin';

export const jyutpingFunction = (text: string) => {
    let jyutping: [string, string | null][] = [];
    try {
        jyutping = ToJyutping.getJyutpingList(text);
        console.log('Text: ', text);
        console.log('jyutping: ');
        console.log(jyutping);
    } catch (e) {
        console.log(e);
    }
    return jyutping;
};

export const pinyinFunction = (text: string) => {
    // let myPinyin: [string, string | null][] = [];
    let myPinyin: string = '';
    try {
        myPinyin = pinyin(text, { keepRest: true });
        let myPinyinList = myPinyin.split(' ');
        console.log('myPinyin');
        console.log(myPinyinList);
        console.log(myPinyinList.length);

        for (let i = 0; i < text.length; i++) {
            let char = text[i];
            console.log(char);

            if (/[\p{Script=Han}]/u.test(char)) {
            }
        }
        console.log('Text: ', text);
        console.log('Pinyin: ');
        console.log(myPinyin);
    } catch (e) {
        console.log(e);
    }
    return myPinyin;
};

type InputArrayElement = [string, string | null];

export const appendJyutpingFunction = (arr: InputArrayElement[]): string => {
    let lines: string[] = [];
    let line1 = '';
    let line2 = '';

    for (let i = 0; i < arr.length; i++) {
        let [char, pronunciation] = arr[i];

        // Check for newline
        if (char === '\n') {
            // console.log(line1);
            // console.log(line2);
            lines.push(line1 + '\n');
            lines.push(line2 + '\n');
            line1 = '';
            line2 = '';
            continue;
        }

        // Check if char is Chinese
        if (/[\p{Script=Han}]/u.test(char)) {
            let line1Char = char; // + '   ';
            let line2Char = pronunciation ? pronunciation.padEnd(line1Char.length + 1, ' ') : ''.padEnd(line1Char.length + 1, ' ');
            line2 += line2Char + ' ';
            line1 += line1Char.padEnd(line2Char.length, ' ');
        } else {
            line1 += char;

            if (char === '\t') {
                line2 += pronunciation ? pronunciation + '\t' : '\t';
            } else {
                line2 += pronunciation ? pronunciation.padEnd(char.length, ' ') : ' '.repeat(char.length);
            }
        }
    }

    // Print any remaining lines
    if (line1.length > 0 || line2.length > 0) {
        // console.log(line1);
        // console.log(line2);
    }
    let singleLineOutput = lines.join('');
    return singleLineOutput;
};
