import { Stack, Grid, ToggleButton, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentSasAudio } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useEffect, useState } from 'react';
import FlipIcon from '@mui/icons-material/Flip';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsSasAudio = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentSasAudio = item.content as SlideComponentSasAudio;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.(
            (itemContent.exampleImage !== '' || itemContent.exampleImageURL !== '') &&
                (itemContent.questionImage !== '' || itemContent.questionImageURL !== '') &&
                itemContent.exampleText1 !== '' &&
                itemContent.exampleText2 !== '' &&
                itemContent.questionText !== '',
        );
    }, [item]);

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Stack spacing={1}>
                            {t('hksas.audio.example')}
                            <SelectImageBox
                                keepMargin={true}
                                imageURL={itemContent.exampleImageURL ?? ''}
                                image={itemContent.exampleImage ?? ''}
                                flip={itemContent.exampleImageFlip}
                                onSelected={(imageURL: string) => {
                                    itemContent.exampleImageURL = imageURL;
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    itemContent.exampleImageURL = '';
                                    itemContent.exampleImage = '';
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    itemContent.exampleImage = option?.value ?? '';
                                    updateUI(true);
                                }}
                            />
                            <ToggleButton
                                value='1'
                                selected={itemContent.exampleImageFlip === -1}
                                onChange={() => {
                                    if (itemContent.exampleImageFlip === -1) {
                                        itemContent.exampleImageFlip = 1;
                                    } else {
                                        itemContent.exampleImageFlip = -1;
                                    }
                                    updateUI(true);
                                }}
                                aria-label='bold'
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    borderRadius: '12px',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '150%',
                                    color: '#FDFCFC',
                                    backgroundColor: itemContent.exampleImageFlip === -1 ? '#efeae6' : '#F06E3C',
                                    '&:hover': {
                                        backgroundColor: itemContent.exampleImageFlip === -1 ? '#e2dddb' : '#C84522',
                                    },
                                }}>
                                <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                            </ToggleButton>

                            <TextField
                                label={t('hksas.audio.exampleText1')}
                                type='text'
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#F9CBE7',
                                }}
                                value={itemContent.exampleText1}
                                onChange={(e) => {
                                    itemContent.exampleText1 = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                            <TextField
                                label={t('hksas.audio.exampleText2')}
                                type='text'
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#E9E9C2',
                                }}
                                value={itemContent.exampleText2}
                                onChange={(e) => {
                                    itemContent.exampleText2 = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                        </Stack>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Stack spacing={1}>
                            {t('hksas.audio.question')}
                            <SelectImageBox
                                keepMargin={true}
                                imageURL={itemContent.questionImageURL ?? ''}
                                image={itemContent.questionImage ?? ''}
                                flip={itemContent.questionImageFlip}
                                onSelected={(imageURL: string) => {
                                    itemContent.questionImageURL = imageURL;
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    itemContent.questionImageURL = '';
                                    itemContent.questionImage = '';
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    itemContent.questionImage = option?.value ?? '';
                                    updateUI(true);
                                }}
                            />
                            <ToggleButton
                                value='1'
                                selected={itemContent.questionImageFlip === -1}
                                onChange={() => {
                                    if (itemContent.questionImageFlip === -1) {
                                        itemContent.questionImageFlip = 1;
                                    } else {
                                        itemContent.questionImageFlip = -1;
                                    }
                                    updateUI(true);
                                }}
                                aria-label='bold'
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    borderRadius: '12px',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '150%',
                                    color: '#FDFCFC',
                                    backgroundColor: itemContent.questionImageFlip === -1 ? '#efeae6' : '#F06E3C',
                                    '&:hover': {
                                        backgroundColor: itemContent.questionImageFlip === -1 ? '#e2dddb' : '#C84522',
                                    },
                                }}>
                                <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                            </ToggleButton>

                            <TextField
                                label={t('hksas.audio.question')}
                                type='text'
                                sx={{ width: '100%' }}
                                value={itemContent.questionText}
                                onChange={(e) => {
                                    itemContent.questionText = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </>
    );
};
