import { Backdrop, Box, Button, CircularProgress, FormControl, FormControlLabel, ImageList, Popper, Radio, RadioGroup, TextField } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyImageListItemWord } from './LazyImageListItemWord';
import { CharacterPhraseModel } from 'models/character/CharacterPhraseModel';
import { UpgradePlanPopup } from '../../../../../../routes/UpgradePlanPopup';
import { usePropsWordPickerHook } from './hooks/usePropsWordPickerHook';

export type OptionsType = {
    path: string;
    value: string;
    hasCard: boolean;
    label: string;
};
type PropsWordPickerProps = {
    enabled?: boolean;
    singleWord: boolean;
    textLabel: string;
    onClickHandler: (characterPhrase: CharacterPhraseModel) => void;
};
export const PropsWordPicker = (props: PropsWordPickerProps) => {
    const { t } = useTranslation();
    const imagePickerText = useRef(null);

    const { setIsImagePickerTextFocused, isImagePickerTextFocused, setTextSearch, textSearch, setSelectedLocale, selectedLocale, textPermitted, setShowUpgradePopup, showUpgradePopup, loading, data } =
        usePropsWordPickerHook();

    return (
        <Box sx={{ flex: 1 }}>
            <Button
                disabled={props.enabled !== undefined && !props.enabled}
                ref={imagePickerText}
                variant='text'
                color='secondary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '4px',
                    color: '#FFFFFF',
                    background: '#2196F3',
                    '&:hover': {
                        backgroundColor: '#2196F3B3', // replace with your desired color
                        color: '#000000', // replace with your desired color
                    },
                    width: '100%',
                }}
                onClick={() => setIsImagePickerTextFocused(true)}>
                {props.textLabel}
            </Button>

            <Backdrop
                sx={{
                    backgroundColor: 'transparent',
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isImagePickerTextFocused}
                onClick={() => setIsImagePickerTextFocused(false)}>
                <Popper
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    open={isImagePickerTextFocused}
                    anchorEl={imagePickerText.current}
                    placement='bottom'
                    sx={{
                        // zIndex: 99999,
                        zIndex: (theme) => theme.zIndex.modal,

                        borderRadius: '12px',
                        backgroundColor: '#533D18',
                        p: 1,
                    }}>
                    <TextField
                        label={t('search')}
                        InputLabelProps={{ style: { color: '#ffffff' } }}
                        inputProps={props.singleWord ? { maxLength: 1 } : {}}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onChange={(e) => setTextSearch(e.target.value)}
                        sx={{
                            width: '100%',
                            '.MuiInputBase-input': {
                                color: '#ffffff', // Change this to your preferred color
                            },
                            background: '#ffffff33',
                        }}
                    />
                    <FormControl component='fieldset' sx={{ color: '#ffffff' }}>
                        <RadioGroup
                            row
                            aria-label='options'
                            value={selectedLocale}
                            onChange={(e) => {
                                setSelectedLocale(e.target.value);
                            }}>
                            <FormControlLabel value='zh-HK' control={<Radio />} label={t('lesson.zh-HK')} />
                            <FormControlLabel value='zh-CN' control={<Radio />} label={t('lesson.zh-CN')} />
                        </RadioGroup>
                    </FormControl>
                    {loading && textSearch && <CircularProgress />}
                    <ImageList
                        sx={{
                            width: 800,
                            height: '50vh',
                            backgroundColor: '#533D18',
                            borderRadius: '12px',
                            p: 1,
                        }}
                        variant='quilted'
                        cols={5}
                        rowHeight={150}>
                        {(data?.characterPhrases ?? [])
                            .filter((characterPhrase: CharacterPhraseModel) => (props.singleWord && characterPhrase.phrase.length == 1) || !props.singleWord)
                            .map((characterPhrase: CharacterPhraseModel) => (
                                <LazyImageListItemWord
                                    key={characterPhrase.id}
                                    sx={{
                                        backgroundColor: textPermitted ? '#F8F0E0' : '#BDB9B5',
                                        width: 150,
                                        height: 150,
                                        borderRadius: '12px',
                                        position: 'relative',
                                        mb: '5px',
                                    }}
                                    onClick={() => {
                                        if (textPermitted) {
                                            props.onClickHandler(characterPhrase);
                                            setIsImagePickerTextFocused(false);
                                        } else {
                                            setShowUpgradePopup(true);
                                        }
                                    }}
                                    characterPhrase={characterPhrase}
                                />
                            ))}
                    </ImageList>
                </Popper>
            </Backdrop>
            {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='characterNotIncluded' />}
        </Box>
    );
};
