import { useTranslation } from 'react-i18next';
import { TestroomModel } from '../models/TestroomModel';
import { TopButton, TopButtonTypography, ButtonTypography } from '../TestroomTemplate';

export const TestroomPasscode = ({ testroomData }: { testroomData: TestroomModel }) => {
    const { t } = useTranslation();
    return (
        <TopButton>
            <TopButtonTypography>{t('live-class-code')}</TopButtonTypography>
            <ButtonTypography>{testroomData.otp}</ButtonTypography>
        </TopButton>
    );
};
