import { useContext, useEffect, useState } from 'react';
import { SlideListContext } from '../contexts/LessonsSlideContext';
import useLessonRefresh from './common/useLessonRefresh';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAuthContext } from 'contexts/UserAuthContext';

export const useLessonHook = () => {
    const navigate = useNavigate();
    let { id: lessonID } = useParams();
    const { lessonData, selectedSlideIndex } = useContext(SlideListContext);
    const { userData } = useContext(UserAuthContext);

    const [canEdit, setCanEdit] = useState(false);

    const isOwner = userData?.id === lessonData?.teacher?.user?.id;

    const { refreshOrCreateLesson, loading } = useLessonRefresh();

    useEffect(() => {
        if (lessonID) {
            refresh();
        }
    }, [lessonID]);

    useEffect(() => {
        setCanEdit(!lessonData?.latestLessonDraft?.published && isOwner && lessonData?.latestLessonDraft?.slides.length > 0 && selectedSlideIndex < lessonData?.latestLessonDraft?.slides.length);
    }, [isOwner, setCanEdit, lessonData?.latestLessonDraft?.slides, selectedSlideIndex]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const refresh = async () => {
        if (lessonID) {
            try {
                const id = await refreshOrCreateLesson(lessonID);
                if (id) {
                    navigate('/lessons/' + id, { replace: true });
                } else {
                    navigate('/lessons');
                }
            } catch (err) {}
        }
    };

    return { canEdit, loading, lessonID, lessonData, handleGoBack, isOwner };
};
