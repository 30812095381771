import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RegisterSuccessPath } from 'routes/utils';
import { useMutation } from '@apollo/client';
import { VERIFY_AND_CREATE_USER } from 'pages/onBoarding/queries';

export const RegistrationProcessPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();

    // const { token } = useParams();
    const [verifyAndCreateUser, { data, loading, error }] = useMutation(VERIFY_AND_CREATE_USER);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        verifyAndCreateUser({ variables: { token } })
            .then(({ data }) => {
                // console.log(data);
                if (data?.verifyAndCreateUser?.__typename === 'VerifyAndCreateUserFailure') {
                    console.error(data.verifyAndCreateUser.message);
                } else {
                    navigate(`../${RegisterSuccessPath}`, {
                        state: {
                            fallback: data?.verifyAndCreateUser?.fallback,
                        },
                    });
                }
            })
            .catch((err) => {
                // This handles any errors that may occur while trying to send the mutation.
                console.log(err);
                console.error('An error occurred:', err.message);
            });
    }, [token, verifyAndCreateUser]);

    useEffect(() => {
        if (!state?.forgotPasswordEmail) {
        }
    }, [state]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                minWidth: 360,
            }}>
            <Box
                sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#858585',
                }}>
                <Typography>{data?.verifyAndCreateUser?.message || t('reigstrationSuccess')}</Typography>
            </Box>
        </Box>
    );
};
