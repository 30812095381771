import { Chip, TextField, InputAdornment, styled, Stack } from '@mui/material';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { useTranslation } from 'react-i18next';
import { PermissionDropdownMenu } from './PermissionDropdownMenu';
import { useMultiInputEmailWithChipsHook } from './hooks/useMultiInputEmailWithChipsHook';

type MultiInputEmailWithChipsProps = {
    lessonID: string;
    refreshList: () => void;
};
export const MultiInputEmailWithChips = (props: MultiInputEmailWithChipsProps) => {
    const { t } = useTranslation();

    const { onBlur, onFocus, inputValue, emails, isFocused, handleDelete, handleInputChange, handleKeyDown, handleSubmit } = useMultiInputEmailWithChipsHook(props.lessonID, props.refreshList);

    return (
        <Stack
            direction='row'
            spacing={1}
            sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <InputWrapper className={isFocused ? 'focused' : ''} sx={{ flex: 1 }}>
                <ChipsContainer>
                    {emails.map((email, index) => (
                        <Chip key={index} label={email} onDelete={() => handleDelete(email)} style={{ marginRight: 8, marginBottom: 8 }} />
                    ))}
                    <TextField
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        fullWidth
                        placeholder='Enter multiple email addresses separated by commas or press Enter.'
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <PermissionDropdownMenu refreshList={props.refreshList} />
                                </InputAdornment>
                            ),
                        }}
                        variant='standard'
                        style={{ minWidth: 120 }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </ChipsContainer>
            </InputWrapper>

            <SubmitButton
                onClick={handleSubmit}
                autoFocus
                sx={{
                    minWidth: 100,
                    height: 40,
                    '&:disabled': {
                        backgroundColor: '#F0F0F0',
                        color: '#B3B3B3',
                    },
                }}>
                {t('share')}
            </SubmitButton>
        </Stack>
    );
};

const ChipsContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const InputWrapper = styled('div')({
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    padding: '8px 14px',
    '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.focused': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0 0 0 0.2rem rgba(0, 0, 0, 0.25)',
    },
});
