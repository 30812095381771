import Draggable from 'react-draggable';
import { useContext } from 'react';
import { LeftDot } from './styles';
import { SlideComponent } from 'models/lesson/LessonModel';
import useLessonSlide, { baseWidth } from '../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../contexts/SlidePreviewContext';

export const DraggableLeftDot = ({ item }: { item: SlideComponent }) => {
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    return (
        <>
            <LeftDot zoom={zoom} sx={{ cursor: 'nesw-resize' }} />
            <Draggable
                axis='x'
                onStart={(e) => {
                    e.stopPropagation();
                }}
                onDrag={(e, position) => {
                    let newX = position.x / zoom;
                    const finalWidth = item.size.width + -newX;
                    const minWidth = 50;
                    if (finalWidth < minWidth) {
                        newX -= minWidth - finalWidth;
                    }

                    if (newX !== 0) {
                        item.size.width += -newX;
                        item.pos.x += newX;

                        if (item.size.width > baseWidth) {
                            item.pos.x = 0;
                            item.pos.y = 0;
                            item.size.width = baseWidth;
                        }

                        updateItemProperties(
                            {
                                ...item,
                            },
                            false,
                        );
                    }
                }}
                onStop={(e) => {
                    updateItemProperties(
                        {
                            ...item,
                        },
                        true,
                    );
                }}
                position={{ x: 0, y: 0 }}>
                <LeftDot
                    zoom={zoom}
                    sx={{
                        cursor: 'nesw-resize',
                        backgroundColor: 'transparent',
                    }}
                />
            </Draggable>
        </>
    );
};
