import { Typography, Stack, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserPrefContext } from '../../contexts/UserPrefContext';
import VideoPlayer from '../../components/VideoPlayer';

// const YouTubeEmbed = ({ videoId, width, height }: any) => {
//   const url = `https://www.youtube.com/embed/${videoId}`;

//   return (
//     <iframe
//       width={width || '560'}
//       height={height || '315'}
//       src={url}
//       title='YouTube video player'
//       frameBorder='0'
//       allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
//       allowFullScreen></iframe>
//   );
// };

export const HomePage = () => {
    const { t } = useTranslation();

    const openVideoInNewTab = () => {
        const videoUrl = '/videos/turtle-teach-tutorial.mp4';
        window.open(videoUrl, '_blank');
    };

    return (
        <UserPrefContext.Consumer>
            {({ language, changeLanguage }) => {
                return (
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={5} className='pageCustom'>
                        <Stack
                            direction='column'
                            justifyContent='center'
                            alignItems='left'
                            sx={{
                                backgroundColor: '#FAEFC4',
                            }}>
                            <Box>
                                <Typography variant='h3'>{t('homepage-message')}</Typography>
                                <Typography sx={{ mt: 5 }}>{t('homepage-message-content')}</Typography>
                            </Box>
                        </Stack>

                        <Stack>
                            <Box component='img' src='./asset/images/main/mascots.png' sx={{ mb: -13 }} />
                            {/* <YouTubeEmbed videoId='cuiWtaFo9qU' width='640' height='360' /> */}
                            <VideoPlayer videoSrc='/asset/videos/turtle-teach-tutorial.mp4' width='640' height='360' />
                            {/* Button to open video in new tab */}
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={{
                                    borderBottomLeftRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                }}
                                onClick={openVideoInNewTab}>
                                {t('homepage-open-video-in-new-tab')}
                            </Button>
                        </Stack>
                    </Stack>
                );
            }}
        </UserPrefContext.Consumer>
    );
};
