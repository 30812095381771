import { Stack } from '@mui/material';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { ResultTable } from 'components/ResultTable';
import OverallQuestionResultDetailTab from 'pages/results/component/QuestionResultOverview/OverallQuestionResultDetailTab';
import QuestionResultOverview from 'pages/results/component/QuestionResultOverview/QuestionResultOverview';
import QuestionTabHeader from 'pages/results/component/QuestionResultOverview/QuestionTabHeader';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const QuestionResultByEachStudent = () => {
    const { questionId } = useParams();
    const { prepareStudentQuestionTable, questionTableFromEachStudentData, resultByQuestion, loadingOverallResultPage } = useTestroomResult();
    const [questionText, setQuestionText] = useState('');
    useEffect(() => {
        if (questionId && resultByQuestion) {
            if (resultByQuestion.length > 0) {
                const questionObj = resultByQuestion.filter((ans) => ans.questionID == questionId);
                if (questionObj.length > 0) {
                    setQuestionText(questionObj[0].question);
                }
                prepareStudentQuestionTable(questionId);
            }
        }
    }, [questionId, resultByQuestion]);

    return (
        <Stack direction='column' spacing={1} className='pageCustom' sx={{ backgroundColor: 'white' }}>
            <PopupCircularProgress isOpened={loadingOverallResultPage} />
            <QuestionTabHeader questionName={questionText} />
            <QuestionResultOverview data={questionTableFromEachStudentData} questionName={questionText} />
            <OverallQuestionResultDetailTab data={questionTableFromEachStudentData} />
            <ResultTable columns={questionTableFromEachStudentData?.columns} data={questionTableFromEachStudentData?.data} isLoading={false} />
        </Stack>
    );
};

export default QuestionResultByEachStudent;
