import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { PopupBody } from 'components/General/Popups/PopupBody';

import { useNavigate } from 'react-router-dom';
import useLatestTestroomQuery, { TestroomDataModel } from 'models/testroom/useLatestTestroomQuery';
import { useEndLessonMutation } from 'models/lesson/useEndLessonMutation';

export const RecoverTestroomDialog = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isConfirming, setIsConfirming] = useState(false);

    const [open, setOpen] = useState(false);

    const { latestTestroomData } = useLatestTestroomQuery();

    const [testroomData, setTestroomData] = useState<TestroomDataModel | undefined>(undefined);

    const { endLesson } = useEndLessonMutation();

    useEffect(() => {
        // console.log('testroomsData: ' + JSON.stringify(testroomsData));

        if (!open && latestTestroomData) {
            setTestroomData(latestTestroomData);
            setOpen(true);
        }
    }, [latestTestroomData]);

    const onConfirm = (b: boolean) => {
        setOpen(false);
        if (testroomData) {
            if (b) {
                navigate(`/testroom/${testroomData.otp}`);
            } else {
                endLesson({ where: { id: testroomData.id } });
            }
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth maxWidth='sm' disableEscapeKeyDown={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                {/* <Dialog fullWidth maxWidth='xl' open={props.open} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'> */}
                <DialogTitle sx={{ paddingTop: '24px' }} id='alert-dialog-title'>
                    <PopupTitle sx={{}}>{isConfirming ? t('recover-testroom-dialog.end-testroom') : t('recover-testroom-dialog.recover-testroom')}</PopupTitle>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <PopupBody sx={{}}>
                            {isConfirming
                                ? t('recover-testroom-dialog.end-testroom-msg')
                                : t('recover-testroom-dialog.recover-testroom-msg', {
                                      roomName: testroomData?.title,
                                  })}
                        </PopupBody>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ paddingBottom: '24px', paddingRight: '24px' }}>
                    <CancelButton
                        onClick={() => {
                            setIsConfirming(!isConfirming);
                        }}>
                        {isConfirming ? t('cancel') : t('recover-testroom-dialog.end-testroom')}
                    </CancelButton>
                    <SubmitButton
                        onClick={() => {
                            if (isConfirming) {
                                onConfirm(false);
                            } else {
                                onConfirm(true);
                            }
                        }}
                        autoFocus>
                        {isConfirming ? t('recover-testroom-dialog.end-testroom') : t('recover-testroom-dialog.recover-testroom')}
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
