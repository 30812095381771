import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const SAVE_GQL = gql`
    mutation CreateLessonShares($data: [LessonShareCreateInput!]!) {
        createLessonShares(data: $data) {
            id
            email
        }
    }
`;

type LessonShareModelMutationVariableType = {
    data: {
        email: string;
        lesson: { connect: { id: string } };
    }[];
};

type LessonShareModelMutationType = {
    createLessonShares: LessonShareModelMutationVariableType;
};

export const useLessonShareModelCreateMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation<LessonShareModelMutationType>(SAVE_GQL);

    const createLessonShares = async (variables: LessonShareModelMutationVariableType) => {
        try {
            const result = await saveForm({
                variables,
            });

            if (!result || !result.data?.createLessonShares) {
                throw new TTError(ERROR_TYPE.UPDATE_FAILED);
            }
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { createLessonShares };
};
