import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldsIcon from '@mui/icons-material/TextFields';

import Draggable from 'react-draggable';
import { useContext } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentFillInTheBlank } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataFillInTheBlank = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentFillInTheBlank = item.content as SlideComponentFillInTheBlank;

    return (
        <>
            <Draggable
                disabled={disabled}
                bounds='parent'
                handle='strong'
                onStart={() => {
                    setSelectedComponentIndex(index);
                }}
                onStop={(e, position) => {
                    const newX = position.x / zoom;
                    const newY = position.y / zoom;
                    if (item.pos.x !== newX || item.pos.y !== newY) {
                        item.pos.x = newX;
                        item.pos.y = newY;
                        updateItemProperties({ ...item }, true);
                    }
                }}
                position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
                <strong
                    style={{
                        cursor: 'move',
                        width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                        height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                        position: 'absolute',
                        zIndex: itemContent.zIndex ?? 0,
                    }}>
                    <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<TextFieldsIcon />} title={t('fillInTheBlank.title')} resize={false} />

                    <Typography sx={{ color: '#533D18DE', zoom: zoom, fontSize: 30 }}>{t('fillInTheBlank.instruction')}</Typography>

                    <Stack direction='row' sx={{ zoom: zoom, mt: 2, mb: 2 }}>
                        {itemContent.questions &&
                            [...itemContent.questions]
                                .sort((a, b) => a.sort - b.sort)
                                .map((question, qIdx) => {
                                    return (
                                        <Box
                                            key={qIdx}
                                            sx={{
                                                color: '#FFFFFF',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mr: 2,
                                                border: 1,
                                                backgroundColor: '#F05023',
                                                borderRadius: 1,
                                                p: 2,
                                            }}>
                                            {question.answerText}
                                        </Box>
                                    );
                                })}
                    </Stack>

                    {itemContent.questions.map((question, qIdx) => {
                        return (
                            <Box
                                key={qIdx}
                                sx={{
                                    p: '18px',
                                    color: '#533D18',
                                    zoom: zoom,
                                    fontSize: 30,
                                }}>
                                {qIdx + 1}. {question.questionText}
                            </Box>
                        );
                    })}
                </strong>
            </Draggable>
        </>
    );
};
