import React, { useState, ReactNode } from 'react';
import HelpIcon from '@mui/icons-material/Help';

interface HoverHelpProps {
    content: ReactNode;
}

export const HoverHelp = ({ content }: HoverHelpProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            <HelpIcon style={{ color: 'grey', width: 20 }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
            {isHovered && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 'calc(100% + 2px)',
                        right: 'calc(100% + 2px)',
                        backgroundColor: '#f1f1f1',
                        padding: '10px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        zIndex: 999,
                        minWidth: '300px',
                        textAlign: 'center',
                    }}>
                    {content}
                </div>
            )}
        </div>
    );
};
