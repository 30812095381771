/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Typography, Stack, IconButton, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState, forwardRef, useRef } from 'react';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { PaymentPanel } from 'pages/payment/PaymentPanel';
import { UserInfoDisplay } from './components/UserInfoDisplay';
import { SubscriptionInfo } from './components/SubscriptionInfo';

export const ProfilePage = ({
    onSubmit,
    isOpened,
    initValues,
    isUpdate,
    resetEmailExists,
}: {
    onSubmit: (values: any, createAnother: boolean, createAnotherCb: () => void) => void;
    isOpened: boolean;
    initValues: any;
    isUpdate?: boolean;
    resetEmailExists: () => void;
}) => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }

    // For handling payment success / failure
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cancelSucceeded = searchParams.get('cancelSucceeded');
    const paymentSucceeded = searchParams.get('paymentSucceeded');
    const paymendCancelled = searchParams.get('paymendCancelled');
    const planChanged = searchParams.get('planChanged');
    useEffect(() => {
        if (paymentSucceeded === 'true') {
            // Handle the success case
            setNormalMessage(t('profile.payment-succeeded'));
            setTimeout(() => {
                setNormalMessage('');
            }, 10000);
            // TODO: Then update subscription table and reflect the latest plan subscribed to
        } else if (paymendCancelled === 'true') {
            // Handle the canceled case
            setErrorMessage(t('profile.payment-cancelled'));
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
        } else if (planChanged === 'true') {
            // Handle the plan change
            // window.location.reload();
            setNormalMessage(t('profile.subscription-plan-changed"'));
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
        } else if (cancelSucceeded === 'true') {
            // Handle the success cancellation case
            setNormalMessage(t('profile.subscription-plan-cancel-success'));
            setTimeout(() => {
                setNormalMessage('');
            }, 10000);
            // TODO: Then update subscription table and reflect the latest plan subscribed to
        } else if (cancelSucceeded === 'false') {
            // Handle the failed cancellation case
            setErrorMessage(t('profile.subscription-plan-cancel-failed'));
            setTimeout(() => {
                setNormalMessage('');
            }, 10000);
            // TODO: Then update subscription table and reflect the latest plan subscribed to
        }
    }, [paymentSucceeded, paymendCancelled, planChanged, cancelSucceeded]);

    const { setUserData, userData } = useContext(UserAuthContext);
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState('');
    const [normalMessage, setNormalMessage] = useState('');

    return (
        <>
            {normalMessage && (
                <Alert
                    variant='filled'
                    severity='success'
                    sx={{
                        width: '100%',
                    }}>
                    {normalMessage}
                </Alert>
            )}

            {errorMessage && (
                <Alert
                    variant='filled'
                    severity='error'
                    sx={{
                        width: '100%',
                    }}>
                    {errorMessage}
                </Alert>
            )}

            <Stack direction='column' spacing={1} className='pageCustom'>
                <Typography variant='h4'>{t('menu-profile')}</Typography>

                <Card
                    sx={{
                        minHeight: 4000,
                        border: 2,
                        borderColor: '#DCCDB5',
                        borderRadius: '16px',
                        paddingLeft: '50px',
                        paddingRight: '50px',
                        paddingTop: '50px',
                        paddingBottom: '50px',
                    }}>
                    <Stack
                        direction='row'
                        spacing={8}
                        sx={{
                            marginBottom: '50px',
                        }}>
                        <UserInfoDisplay
                            onSubmit={onSubmit}
                            setUserData={setUserData}
                            userData={userData}
                            resetEmailExists={resetEmailExists}
                            t={t}
                            clientId={clientId}
                            setNormalMessage={setNormalMessage}
                            setErrorMessage={setErrorMessage}
                            // formik={formik}
                        ></UserInfoDisplay>
                    </Stack>

                    <SubscriptionInfo t={t}></SubscriptionInfo>

                    <PaymentPanel />
                </Card>
            </Stack>
        </>
    );
};
