import _ from 'lodash';
import { Stack, Grid, ToggleButton, TextField, Typography, IconButton, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentSasFillInTheBlankAnswerModels, SlideComponentSasFillInTheBlankQuestionModels, SlideComponentSasFillInTheBlanks } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import FlipIcon from '@mui/icons-material/Flip';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SasFillInTheBlankAnswerBubble } from './components/SasFillInTheBlankAnswerBubble';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

const { v4: uuidv4 } = require('uuid');
const questionLimit: number = 2;
const answerLimit: number = 6;

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsSasFillInTheBlanks = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentSasFillInTheBlanks = item.content as SlideComponentSasFillInTheBlanks;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const randomDistribution = () => {
        assignSortValue(shuffleArray(itemContent.answers));
    };

    const parseAllAnswers = useCallback(
        _.debounce((questions) => {
            const answerArray: SlideComponentSasFillInTheBlankAnswerModels[] = [];
            for (const question of questions) {
                parseTextToAnswerArray(question.questionText, answerArray);
            }
            syncAnswers(answerArray, itemContent.extraAnswers);
        }, 500),
        [itemContent.answers],
    );

    const parseTextToAnswerArray = (text: string, answerArray: SlideComponentSasFillInTheBlankAnswerModels[]) => {
        const regex = /\[([^\]]+)\]/g;
        let match: any;

        while ((match = regex.exec(text)) !== null) {
            const isAnswerExist = answerArray.some((answer) => answer.answerText === match[1]);

            if (!isAnswerExist) {
                answerArray.push({ answerText: match[1], sort: 0 });
            }
        }
    };

    const syncAnswers = (answerArray: SlideComponentSasFillInTheBlankAnswerModels[], extraAnswerArray: string[]) => {
        for (const answer of answerArray) {
            const isAnswerExist = itemContent.answers.some((itemAnswer) => itemAnswer.answerText === answer.answerText);

            if (!isAnswerExist) {
                itemContent.answers.push({ ...answer });
            }
        }

        const mergedAnswerArray = [...answerArray.map((answer) => answer.answerText), ...extraAnswerArray];

        if (mergedAnswerArray.length > answerLimit) {
            handleRemoveExtraAnswer(extraAnswerArray.length - 1);
        }

        for (const answer of extraAnswerArray) {
            const isAnswerExist = itemContent.answers.some((itemAnswer) => itemAnswer.answerText === answer);

            if (!isAnswerExist) {
                itemContent.answers.push({ answerText: answer, sort: 0 });
            }
        }

        // Then, remove answers from itemContent.answers if they don't exist in answerArray.
        itemContent.answers = itemContent.answers.filter((itemAnswer) => mergedAnswerArray.some((answer) => answer === itemAnswer.answerText));
    };

    const addQuestion = () => {
        itemContent.questions.push({
            id: uuidv4(),
            questionText: '',
            sort: 0,
        });
    };
    const handleRemoveQuestion = (index: number) => {
        // Handle the remove action here
        itemContent.questions.splice(index, 1);

        parseAllAnswers(itemContent.questions);
    };

    const addExtraAnswer = () => {
        itemContent.extraAnswers.push('');
    };
    const handleRemoveExtraAnswer = (index: number) => {
        // Handle the remove action here
        itemContent.extraAnswers.splice(index, 1);

        parseAllAnswers(itemContent.questions);
    };

    const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        parseAllAnswers(itemContent.questions);
    };

    const onDragHandler = (textContent: SlideComponentSasFillInTheBlankAnswerModels[], isDrag: boolean) => {
        assignSortValue(textContent);
        itemContent.answers = textContent;
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.(
            (itemContent.image !== '' || itemContent.imageURL !== '') &&
                itemContent.instructionText !== '' &&
                itemContent.questions.length > 0 &&
                !hasEmptyFieldInArray(itemContent.questions, itemContent.answers),
        );
    }, [item]);

    const hasEmptyFieldInArray = (questions: SlideComponentSasFillInTheBlankQuestionModels[], answers: SlideComponentSasFillInTheBlankAnswerModels[]) => {
        if (answers.length <= 0 || questions.length <= 0) {
            return true;
        }
        for (const question of questions) {
            if (question.questionText.trim() === '') {
                return true;
            }
        }
        for (const question of answers) {
            if (question.answerText.trim() === '') {
                return true;
            }
        }
    };

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Stack spacing={1}>
                            <SelectImageBox
                                keepMargin={true}
                                imageURL={itemContent.imageURL ?? ''}
                                image={itemContent.image ?? ''}
                                flip={itemContent.flip}
                                onSelected={(imageURL: string) => {
                                    itemContent.imageURL = imageURL;
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    itemContent.imageURL = '';
                                    itemContent.image = '';
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    itemContent.image = option?.value ?? '';
                                    updateUI(true);
                                }}
                            />
                            <ToggleButton
                                value='1'
                                selected={itemContent.flip === -1}
                                onChange={() => {
                                    if (itemContent.flip === -1) {
                                        itemContent.flip = 1;
                                    } else {
                                        itemContent.flip = -1;
                                    }
                                    updateUI(true);
                                }}
                                aria-label='bold'
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    borderRadius: '12px',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '150%',
                                    color: '#FDFCFC',
                                    backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                    '&:hover': {
                                        backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                    },
                                }}>
                                <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                            </ToggleButton>
                        </Stack>
                    </Box>
                    <Box sx={{ flex: '1 1 350px' }}>
                        <Stack spacing={1}>
                            <Typography>{t('hksas.fillInTheBlanks.instruction')}</Typography>
                            <TextField
                                label={t('hksas.fillInTheBlanks.inputInstruction')}
                                type='text'
                                sx={{ width: '100%' }}
                                value={itemContent.instructionText}
                                onChange={(e) => {
                                    itemContent.instructionText = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography>{t('hksas.fillInTheBlanks.questions')}</Typography>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        addQuestion();
                                        updateUI(true);
                                    }}
                                    startIcon={<AddIcon />}
                                    disabled={itemContent.questions.length >= questionLimit}
                                    sx={{
                                        height: '36px',
                                        background: '#2196F3',
                                        '&:hover': {
                                            backgroundColor: '#2196F3B3', // replace with your desired color
                                            color: '#000000', // replace with your desired color
                                        },
                                    }}>
                                    {t('hksas.fillInTheBlanks.question', {
                                        num: '',
                                    })}
                                </Button>
                            </Stack>
                            <Typography color='gray' fontSize={12}>
                                以 [ ] 分辨答案，如：天空[乌]云密布，好像快要下雨了。
                            </Typography>
                            {itemContent.questions &&
                                itemContent.questions.map((question, index) => (
                                    <Stack direction={'row'} spacing={1}>
                                        <TextField
                                            key={index}
                                            label={t('hksas.fillInTheBlanks.question', {
                                                num: index + 1,
                                            })}
                                            type='text'
                                            sx={{ width: '100%' }}
                                            value={question.questionText}
                                            onChange={(e) => {
                                                onQuestionChange(e);
                                                question.questionText = e.target.value;
                                                updateUI(false);
                                                debouncedSaveHandler(() => {
                                                    updateUI(true);
                                                });
                                            }}
                                            // onBlur={(e) => {
                                            //   updateUI(true);
                                            // }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveQuestion(index);
                                                updateUI(true);
                                            }}
                                            aria-label='remove'
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'white', // sets the color of the icon
                                                backgroundColor: 'red', // sets the background color of the button
                                                '&:hover': {
                                                    // sets the background color on hover
                                                    backgroundColor: 'darkred',
                                                },
                                                alignSelf: 'center',
                                            }}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Stack>
                                ))}

                            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography>{t('hksas.fillInTheBlanks.answers')}</Typography>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        addExtraAnswer();
                                        updateUI(true);
                                    }}
                                    startIcon={<AddIcon />}
                                    disabled={itemContent.answers.length >= answerLimit}
                                    sx={{
                                        height: '36px',
                                    }}>
                                    {t('hksas.fillInTheBlanks.answer')}
                                </Button>
                            </Stack>

                            {itemContent.extraAnswers &&
                                itemContent.extraAnswers.map((answer, index) => (
                                    <Stack direction={'row'} spacing={1} sx={{ width: '50%' }}>
                                        <TextField
                                            key={index}
                                            label={t('hksas.fillInTheBlanks.extraAnswer')}
                                            type='text'
                                            sx={{ width: '100%' }}
                                            value={answer}
                                            onChange={(e) => {
                                                onQuestionChange(e);
                                                itemContent.extraAnswers[index] = e.target.value;
                                                updateUI(false);
                                                debouncedSaveHandler(() => {
                                                    updateUI(true);
                                                });
                                            }}
                                            // onBlur={(e) => {
                                            //   updateUI(true);
                                            // }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveExtraAnswer(index);
                                                updateUI(true);
                                            }}
                                            aria-label='remove'
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'white', // sets the color of the icon
                                                backgroundColor: 'red', // sets the background color of the button
                                                '&:hover': {
                                                    // sets the background color on hover
                                                    backgroundColor: 'darkred',
                                                },
                                                alignSelf: 'center',
                                            }}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Stack>
                                ))}

                            <SasFillInTheBlankAnswerBubble
                                answerBubbles={itemContent.answers}
                                onDragChange={(textContent, isDrag) => {
                                    onDragHandler(textContent, isDrag);
                                    updateUI(true);
                                }}
                            />
                        </Stack>
                    </Box>
                </Box>
                <IconButton
                    onClick={() => {
                        randomDistribution();
                        updateUI(true);
                    }}
                    sx={{
                        height: '18px',
                        marginLeft: 'auto',
                        color: '#B07E27',
                    }}>
                    <RefreshIcon />
                    <Typography sx={{ color: '#B07E27', ml: 1 }}>{t('matching.randomSort')}</Typography>
                </IconButton>
            </Stack>
        </>
    );
};

const shuffleArray = (array: SlideComponentSasFillInTheBlankQuestionModels[] | SlideComponentSasFillInTheBlankAnswerModels[]) => {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        for (let index = 0; index < array.length; index++) {
            const item = array[index];
            item.sort = index;
        }
    } else {
        array = [];
    }
    return array;
};

const assignSortValue = (textContent: SlideComponentSasFillInTheBlankQuestionModels[] | SlideComponentSasFillInTheBlankAnswerModels[]) => {
    for (let index in textContent) {
        let text = textContent[index];
        text.sort = parseInt(index);
    }
    textContent.sort((a, b) => {
        return a.sort - b.sort;
    });
};
