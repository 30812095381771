import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface Step {
    text: string;
    href?: string;
    onClick?(): void;
    icon?: ReactElement;
}

export interface RouteStepperProps {
    step: Step[];
}

export const RouteStepper = (props: RouteStepperProps) => {
    const { step } = props;
    const navigate = useNavigate();

    const steps = useMemo(() => {
        const home = (
            <Link key={0} href='/' onClick={() => navigate('/')} sx={{ fontSize: '14px', padding: '4px', color: '#533D18' }} underline='none'>
                主頁
            </Link>
        );
        if (step.length > 0) {
            const routes = step.map((item, index) => (
                <Link
                    key={index + 1}
                    onClick={() => {
                        if (typeof item?.onClick !== 'undefined') {
                            item.onClick();
                        }
                        if (item?.href) {
                            navigate(item?.href);
                        }
                    }}
                    sx={{
                        padding: '4px',
                        color: '#533D18',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '150%',
                        cursor: 'pointer',
                        borderRadius: '4px',
                    }}
                    underline='none'>
                    {item?.icon && item.icon}
                    {item.text}
                </Link>
            ));
            routes.unshift(home);
            return routes;
        }
        return home;
    }, [step]);

    return (
        <Stack>
            <Breadcrumbs
                sx={{
                    gap: '24px',
                }}
                separator={<Typography sx={{ color: '#DDD0D0' }}>/</Typography>}>
                {steps}
            </Breadcrumbs>
        </Stack>
    );
};
