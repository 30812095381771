import { REQUEST_TEACHER_CLIENT_LIST } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';
import { TT_EVENT_RECEIVE_CLIENT_LIST } from '../customEvent';

export const requestClientList = (socket: Socket | null, callback?: () => void) => {
    if (socket == null) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';
    socket?.emit(REQUEST_TEACHER_CLIENT_LIST, { token: token }, (response: any) => {
        const eventMessage = new CustomEvent(TT_EVENT_RECEIVE_CLIENT_LIST, {
            detail: response,
        });
        window.dispatchEvent(eventMessage);

        if (callback) {
            callback();
        }
    });
};
