import { useTranslation } from 'react-i18next';
import { TermsConditionsContent } from './TermsConditionsContent';
import TermsDialog from './TermsDialog';

const TermsConditions = () => {
    const { t } = useTranslation();

    return <TermsDialog label={t('signup-card-terms-label')} buttonTitle={t('signup-card-terms-button-title')} dialogTitle={t('terms-conditions-card-title')} content={<TermsConditionsContent />} />;
};

export default TermsConditions;
