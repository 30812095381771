import { Backdrop, Box, Button, ImageList, Popper, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyImageListItem } from './LazyImageListItem';

export type OptionsType = {
    path: string;
    value: string;
    hasCard: boolean;
    label: string;
};
type PropsImagePickerProps = {
    imageOptions: OptionsType[];
    textLabel: string;
    onClickHandler: (option: OptionsType) => void;
};
export const PropsImagePicker = (props: PropsImagePickerProps) => {
    const { t } = useTranslation();
    const imagePickerText = useRef(null);

    const [isImagePickerTextFocused, setIsImagePickerTextFocused] = useState(false);

    const [textSearch, setTextSearch] = useState('');

    return (
        <Box sx={{ flex: 1 }}>
            <Button
                ref={imagePickerText}
                variant='text'
                color='secondary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '4px',
                    color: '#FFFFFF',
                    background: '#2196F3',
                    '&:hover': {
                        backgroundColor: '#2196F3B3', // replace with your desired color
                        color: '#000000', // replace with your desired color
                    },
                    width: '100%',
                }}
                onClick={() => setIsImagePickerTextFocused(true)}>
                {props.textLabel}
            </Button>

            <Backdrop
                sx={{
                    backgroundColor: 'transparent',
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isImagePickerTextFocused}
                onClick={() => setIsImagePickerTextFocused(false)}>
                <Popper
                    open={isImagePickerTextFocused}
                    anchorEl={imagePickerText.current}
                    placement='bottom'
                    sx={{
                        zIndex: 99999,
                        borderRadius: '12px',
                        backgroundColor: '#533D18',
                        p: 1,
                    }}>
                    <TextField
                        label={t('search')}
                        InputLabelProps={{ style: { color: '#ffffff' } }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onChange={(e) => setTextSearch(e.target.value)}
                        sx={{
                            width: '100%',
                            '.MuiInputBase-input': {
                                color: '#ffffff', // Change this to your preferred color
                            },
                        }}
                    />
                    <ImageList
                        sx={{
                            width: 800,
                            height: '50vh',
                            backgroundColor: '#533D18',
                            borderRadius: '12px',
                            p: 1,
                        }}
                        variant='quilted'
                        cols={5}
                        rowHeight={150}>
                        {props.imageOptions
                            .filter((v) => v.label.toLowerCase().includes(textSearch.toLowerCase()))
                            .map((option: OptionsType, index) => (
                                <LazyImageListItem
                                    key={option.path}
                                    sx={{
                                        backgroundColor: '#F8F0E0',
                                        width: 150,
                                        height: 150,
                                        borderRadius: '12px',
                                        position: 'relative',
                                        mb: '5px',
                                    }}
                                    onClick={() => {
                                        props.onClickHandler(option);
                                        setIsImagePickerTextFocused(false);
                                    }}
                                    option={option}
                                />
                            ))}
                    </ImageList>
                </Popper>
            </Backdrop>
        </Box>
    );
};
