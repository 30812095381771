import { Box, Stack, Typography } from '@mui/material';

import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentFillInTheBlank } from 'models/lesson/LessonModel';
import { useTranslation } from 'react-i18next';

export const SlideFillInTheBlank = ({ item }: { item: SlideComponent; index: number }) => {
    const { t } = useTranslation();
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentFillInTheBlank = item.content as SlideComponentFillInTheBlank;

    return (
        <Box
            sx={{
                position: 'absolute',
                zoom: zoom,
                aspectRatio: 'auto',
                pointerEvents: 'none',
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
                m: 'inherit',
                p: 'inherit',
            }}>
            <Typography sx={{ color: '#533D18DE' }}>{t('fillInTheBlank.instruction')}</Typography>

            <Stack direction='row' sx={{ mt: 2, mb: 2 }}>
                {itemContent.questions &&
                    [...itemContent.questions]
                        .sort((a, b) => a.sort - b.sort)
                        .map((question, qIdx) => {
                            return (
                                <Box
                                    key={qIdx}
                                    sx={{
                                        color: '#FFFFFF',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 2,
                                        border: 1,
                                        backgroundColor: '#F05023',
                                        borderRadius: 1,
                                        p: 2,
                                    }}>
                                    {question.answerText}
                                </Box>
                            );
                        })}
            </Stack>

            {itemContent.questions.map((question, qIdx) => {
                return (
                    <Box
                        sx={{
                            p: '18px',
                            color: '#533D18',
                        }}>
                        {qIdx + 1}. {question.questionText}
                    </Box>
                );
            })}
        </Box>
    );
};
