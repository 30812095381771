import { gql, useLazyQuery } from '@apollo/client';
import { SlideComponentPunctuationMark, SlideComponentPunctuationMarkQuestionModel } from 'models/lesson/LessonModel';
const { v4: uuidv4 } = require('uuid');

const marginLeft = 100;
const marginTop = 200;

type SentenceModel = {
    text: string;
};

type GenerateTextQueryModel = {
    generateTextPunctuationMark: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionCount: Int!, $questionLength: String!) {
        generateTextPunctuationMark(questionTopic: $questionTopic, questionCount: $questionCount, questionLength: $questionLength) {
            text
        }
    }
`;

export const useLessonsSlidePropsPunctuationMarkHook = (itemContent: SlideComponentPunctuationMark) => {
    const [genText, { loading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, { fetchPolicy: 'no-cache' });

    const getTextFromServer = async (questionCount: number, questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }

        try {
            const result = await genText({
                variables: { questionCount, questionLength, questionTopic },
            });

            UpdateQuestionArrayInfo(result.data?.generateTextPunctuationMark ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const UpdateQuestionArrayInfo = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentPunctuationMarkQuestionModel[] = [];

        let i = 0;
        for (const question of data) {
            const answerArray = parseTextToAnswerArray(question.text);
            const questionText = parseTextToQuestionText(question.text);

            newQuestion.push({
                id: uuidv4(),
                text: question.text,
                question: questionText,
                answer: [...answerArray],
                pos: {
                    x: marginLeft + 400 * (i % 2),
                    y: marginTop + 100 * Math.floor(i / 2),
                },
            });

            i++;
        }

        itemContent.questions = newQuestion;
    };

    const parseTextToAnswerArray = (text: string) => {
        const regex = /\(([^)]+)\)/g;
        let match;
        const punctuationMarks = [];

        while ((match = regex.exec(text)) !== null) {
            punctuationMarks.push(match[1]);
        }

        return punctuationMarks;
    };

    const parseTextToQuestionText = (text: string) => {
        const regex = /\(([^)]+)\)/g;
        const replacedText = text.replace(regex, '__');
        return replacedText;
    };

    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);

        itemContent.questions = newQuestion;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            text: '',
            question: '',
            answer: [],
            pos: {
                x: marginLeft + 400 * (currentIdx % 2),
                y: marginTop + 100 * Math.floor(currentIdx / 2),
            },
        });
    };

    const questionFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].text = textContent;
        const answerArray = parseTextToAnswerArray(textContent);
        itemContent.questions[idx].answer = answerArray;
        const questionText = parseTextToQuestionText(textContent);
        itemContent.questions[idx].question = questionText;
    };

    return {
        getTextFromServer,
        deleteQuestionArrayInfo,
        questionFieldChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
    };
};
