import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useLessonUpdate from '../hooks/useLessonUpdate';

export const LessonsTitle = ({ lessonID, value, disabled }: { lessonID: string; value: string; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { lessonName, onBlurHandler, onChangeHandler, lessonNameError } = useLessonUpdate(lessonID, value);

    return (
        <TextField
            disabled={disabled}
            id='lessonName'
            name='lessonName'
            value={lessonName}
            onChange={(event) => {
                onChangeHandler(event.target.value);
            }}
            // onBlur={(event) => {
            //   onBlurHandler(event.target.value);
            // }}
            error={!!lessonNameError}
            helperText={lessonNameError}
            hiddenLabel
            placeholder={t('lesson-title')}
            variant='outlined'
            size='small'
            inputProps={{
                min: 0,
                style: {
                    textAlign: 'center',
                    fontSize: '2rem',
                    fontFamily: 'Sofia Pro Soft',
                    fontWeight: 700,
                    color: '#5A4522',
                },
            }}
            sx={{
                width: '60%',
            }}
        />
    );
};
