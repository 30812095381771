import { gql, useLazyQuery } from '@apollo/client';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useContext } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ClassModel } from './ClassModel';
import { enqueueSnackbar } from 'notistack';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';

const QUERY_GQL = gql`
    query GetClasses($skip: Int!, $take: Int!, $schoolId: ID!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        classes(skip: $skip, take: $take, orderBy: { className: asc }, where: { school: { id: { equals: $schoolId } } }) {
            id
            name
            className
        }
        classesCount(where: { school: { id: { equals: $schoolId } } })
    }
`;

type QueryVariableType = {
    skip: number;
    take: number;
    schoolId: string;
};

type ModelQueryType = {
    authenticatedItem: AuthenticatedItemModel;
    classes: ClassModel[];
    classesCount: number;
};

export const useClassModelQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const getClasses = async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
        try {
            const result = await queryData({
                variables,
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
            });

            if (!result?.data) {
                throw new TTError(ERROR_TYPE.NO_DATA);
            }

            return result?.data;
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                auth.accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
        return null;
    };

    return { getClasses, data, loading };
};
