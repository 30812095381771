import { useStudentQuery } from 'models/student/useStudentQuery';
import { useContext, useEffect, useState } from 'react';
import { AssigningDevices, ClassroomContext } from '../contexts/ClassroomContextProvider';
import { requestAssignStart } from './classroomHooks/requestAssignStart';
import { requestClientList } from './classroomHooks/requestClientList';
import { requestConfirmAssign } from './classroomHooks/requestConfirmAssign';
import { TT_EVENT_ASSIGN, TT_EVENT_ASSIGN_END, TT_EVENT_ASSIGN_START } from './customEvent';
import { Socket } from 'socket.io-client';
import { AssignLabelRequest, requestAssignMultipleLabel } from './classroomHooks/requestAssignMultipleLabel';

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const useAssignDevice = (props: Props) => {
    const { fetchStudents, loading, data: studentData } = useStudentQuery();
    const { socket, testroomData } = useContext(ClassroomContext);
    const [assigningDevices, setAssigningDevices] = useState<AssigningDevices>({});

    useEffect(() => {
        const handleAssignEvent = (e: any) => {
            if (!e || !e.detail) {
                return;
            }

            setAssigningDevices((prev) => {
                const { [e.detail.socketId]: removedItem, ...rest } = prev;
                return rest;
            });
        };

        window.addEventListener(TT_EVENT_ASSIGN, handleAssignEvent);
        return () => {
            window.removeEventListener(TT_EVENT_ASSIGN, handleAssignEvent);
        };
    }, []);

    useEffect(() => {
        const handleAssignEvent = (e: any) => {
            props.setOpen(false);
        };

        window.addEventListener(TT_EVENT_ASSIGN_END, handleAssignEvent);
        return () => {
            window.removeEventListener(TT_EVENT_ASSIGN_END, handleAssignEvent);
        };
    }, []);

    useEffect(() => {
        const handleAssignEvent = (e: any) => {
            props.setOpen(true);
        };

        window.addEventListener(TT_EVENT_ASSIGN_START, handleAssignEvent);
        return () => {
            window.removeEventListener(TT_EVENT_ASSIGN_START, handleAssignEvent);
        };
    }, []);

    useEffect(() => {
        if (testroomData?.class && testroomData?.class?.id !== '') {
            fetchStudents({ classID: testroomData?.class?.id });
        }
    }, [testroomData?.class?.id]);

    useEffect(() => {
        if (socket?.connected) {
            requestClientList(socket);
        }
    }, [socket, socket?.connected]);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        if (result.source?.droppableId != 'student' || !result.destination.droppableId.startsWith('deviceId_')) {
            return;
        }

        const studentDataIdx = result.source.index;
        const studentProfileId = studentData?.studentProfiles?.[studentDataIdx]?.id ?? '';
        const socketId = result.destination.droppableId.split('deviceId_')[1];

        assignOne({
            socket: socket,
            studentName: studentData?.studentProfiles[studentDataIdx].familyName + '' + studentData?.studentProfiles[studentDataIdx].givenName,
            socketId: socketId,
            studentProfileId: studentData?.studentProfiles[studentDataIdx].id!,
            studentNumber: studentData?.studentProfiles[studentDataIdx].studentNumber!,
            profilePicURL: studentData?.studentProfiles[studentDataIdx].profilePic?.url || '',
            action: 'change',
        });
    };

    const assignOne = (props: { socket: Socket | null } & AssignLabelRequest) => {
        if (assigningDevices[props.socketId]) {
            return;
        }

        requestAssignMultipleLabel({
            socket: props.socket,
            labels: [
                {
                    studentName: props.studentName,
                    socketId: props.socketId,
                    studentProfileId: props.studentProfileId,
                    studentNumber: props.studentNumber,
                    profilePicURL: props.profilePicURL,
                    action: props.action,
                },
            ],
        });

        setAssigningDevices((prev) => ({
            ...prev,
            [props.socketId]: true,
        }));
    };

    const assignMany = (props: { socket: Socket | null; labels: AssignLabelRequest[] }) => {
        const nonAssignedDevices = props.labels.filter((label) => !assigningDevices[label.socketId]);

        for (const label of nonAssignedDevices) {
            setAssigningDevices((prev) => ({
                ...prev,
                [label.socketId]: true,
            }));
        }

        requestAssignMultipleLabel({
            socket: props.socket,
            labels: nonAssignedDevices,
        });
    };

    const clearAssignOne = (socketId: string) => {
        if (!socketId || socketId === '') {
            return;
        }

        requestAssignMultipleLabel({
            socket: socket,
            labels: [
                {
                    studentName: '',
                    socketId: socketId,
                    studentProfileId: '',
                    studentNumber: '',
                    profilePicURL: '',
                    action: 'delete',
                },
            ],
        });

        setAssigningDevices((prev) => ({
            ...prev,
            [socketId]: true,
        }));
    };

    const clearAssignMany = (socketIds: string[]) => {
        const labels: AssignLabelRequest[] = [];
        for (const socketId of socketIds) {
            if (!socketId || socketId === '') {
                return;
            }

            labels.push({
                studentName: '',
                socketId: socketId,
                studentProfileId: '',
                studentNumber: '',
                profilePicURL: '',
                action: 'delete',
            });

            setAssigningDevices((prev) => ({
                ...prev,
                [socketId]: true,
            }));
        }

        requestAssignMultipleLabel({
            socket: socket,
            labels: labels,
        });
    };

    const confirmAssign = () => {
        requestConfirmAssign({ socket: socket });
    };

    const startAssign = () => {
        requestAssignStart({ socket: socket });
    };

    return {
        loading,
        studentData,
        onDragEnd,
        assignOne,
        assignMany,
        assigningDevices,
        clearAssignOne,
        clearAssignMany,
        confirmAssign,
        startAssign,
    };
};
