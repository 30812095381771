import Cookies from 'js-cookie';
import { UserPermissionContext } from 'contexts/UserPermissionContext';
import { useContext, useEffect, useState } from 'react';
import { Constants } from 'Constants';
import { useCharacterPhrasesQuery } from 'models/character/useCharacterPhrasesQuery';

export const usePropsWordPickerHook = () => {
    const { userPermissions } = useContext(UserPermissionContext);
    const { searchPhrases, loading, data } = useCharacterPhrasesQuery();
    const [delayedTextSearch, setDelayedTextSearch] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [textPermitted, setTextPermitted] = useState(true);
    const [selectedLocale, setSelectedLocale] = useState(() => {
        return Cookies.get('selectedLocale') || Constants.lesson.defaultLocale;
    });
    const [isImagePickerTextFocused, setIsImagePickerTextFocused] = useState(false);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setDelayedTextSearch(textSearch);
        }, 500);

        // Determines if the searched text is included in plan
        // console.log(
        //   textSearch,
        //   ' permitted by plan? ',
        //   userPermissions.permittedHongKongLexCharacters?.includes(textSearch),
        // );
        setTextPermitted(userPermissions?.permittedHongKongLexCharacters?.includes(textSearch) || userPermissions?.permittedHongKongLexCharacters?.includes('*'));

        // Clean up function
        return () => clearTimeout(delayDebounceFn);
    }, [textSearch]);

    useEffect(() => {
        if (delayedTextSearch.trim() == '') {
            return;
        }

        searchPhrases({
            where: {
                phrase: {
                    contains: delayedTextSearch.trim(),
                },
                character: {
                    some: {
                        locale: {
                            equals: selectedLocale,
                        },
                    },
                },
            },
            characterWhere: {
                locale: {
                    equals: selectedLocale,
                },
            },
        });
    }, [delayedTextSearch, selectedLocale]);

    useEffect(() => {
        Cookies.set('selectedLocale', selectedLocale);
    }, [selectedLocale]);

    return {
        setIsImagePickerTextFocused,
        isImagePickerTextFocused,
        setTextSearch,
        textSearch,
        setSelectedLocale,
        selectedLocale,
        textPermitted,
        setShowUpgradePopup,
        showUpgradePopup,
        loading,
        data,
    };
};
