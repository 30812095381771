// RedirectPlaylist.tsx

import { useEffect } from 'react';

export const RedirectPlaylist = () => {
    useEffect(() => {
        // Extract the playlist ID from the query parameters
        const originalPlaylistId = new URLSearchParams(window.location.search).get('playlist');

        // Construct the new URL
        const newUrl = `ttwriting://?playlist=${originalPlaylistId}`;

        // Perform the redirection
        window.location.href = newUrl;
    }, []);

    return <div>return to the app</div>; // You can return null or any other component if needed
};
