import { REQUEST_TEACHER_SHOW_SCORE, REQUEST_TEACHER_UPDATE_ROOM_SETTINGS } from 'pages/testroom/constants/commands';
import { Socket } from 'socket.io-client';

export const requestShowScore = (socket: Socket | null, studentData: any, callback: (response: boolean) => void) => {
    if (!socket) {
        return;
    }

    const token = localStorage.getItem('token') ?? '';

    socket?.emit(REQUEST_TEACHER_SHOW_SCORE, { token, studentData }, callback);
};
