import { Button, ButtonProps } from '@mui/material';

export const CancelButton = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            sx={{
                px: '16px',
                py: '8px',
                borderRadius: '12px',

                fontWeight: '700',
                fontSize: '18px',
                lineHeight: '150%',

                color: '#F06E3C',
                '&:hover': {
                    color: '#C84522',
                    backgroundColor: 'transparent',
                },
                ...props.sx,
            }}>
            {props.children}
        </Button>
    );
};
