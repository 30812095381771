import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { WorksheetModel, QuestionModel } from '../models/Worksheet';
import { useTranslation } from 'react-i18next';
import { WorksheetContext } from '../contexts/WorksheetContext';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import { UserAuthContext } from 'contexts/UserAuthContext';

const CREATE_GQL = gql`
    mutation save($worksheetName: String!, $googleDocId: String!) {
        createWorksheet(data: { worksheetName: $worksheetName, googleDocId: $googleDocId }) {
            id
        }
    }
`;

const WORKSHEET_QUERY = gql`
    query GetRows($worksheetID: ID!) {
        worksheet(where: { id: $worksheetID }) {
            id
            worksheetName
            googleDocId
        }
    }
`;

// const WORKSHEET_QUERY = gql`
//   // GraphQL query for retrieving worksheet data
// `;

const DELETE_GQL = gql`
    mutation save($worksheetID: ID!) {
        deleteWorksheet(where: { id: $worksheetID }) {
            id
        }
    }
`;

export default function useWorksheetMutation() {
    const { t } = useTranslation();
    const { setLoading, dispatchWorksheetData, selectedQuestionIndex } = useContext(WorksheetContext);
    const { accessDenied } = useContext(UserAuthContext);

    const [createWorksheetMutation] = useMutation(CREATE_GQL, {
        variables: {
            worksheetName: '',
            googleDocId: '',
        },
    });

    const { refetch: getWorksheetQuery } = useQuery(WORKSHEET_QUERY, {
        variables: {
            worksheetID: '',
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const [deleteForm] = useMutation(DELETE_GQL);

    const deleteWorksheet = async (worksheetID: string) =>
        new Promise(async (resolve, reject) => {
            if (worksheetID) {
                try {
                    await deleteForm({
                        variables: {
                            worksheetID: worksheetID,
                        },
                    });
                    resolve('');
                } catch (err) {
                    reject(err);
                    console.log(err);
                }
            }
        });

    interface WorksheetResult {
        worksheetId: string | null;
        googleDocId: string | null;
        message?: string;
    }

    const refreshWorksheet = async (worksheetID: string) => {
        if (worksheetID && worksheetID !== '+') {
            // setLoading(true);
            try {
                let res = await getWorksheetQuery({ worksheetID: worksheetID });
                // setLoading(false);
                let newWorksheet = { ...res.data.worksheet };
                dispatchWorksheetData({
                    type: 'SetWorksheet',
                    payload: newWorksheet,
                });

                // console.log(res);
                return res.data.worksheet.googleDocId;
            } catch (err) {
                // setLoading(false);

                const errorMsg = handleException(err);
                return null;
            }
        }
    };

    const refreshOrCreateWorksheet = async (worksheetID: string, handleCreateGoogleDoc: (docName: string) => Promise<string>): Promise<WorksheetResult> => {
        if (worksheetID && worksheetID !== '+') {
            // console.log("Shouldn't be creating anything here...");
            const gdocId = await refreshWorksheet(worksheetID);
            return { worksheetId: worksheetID, googleDocId: gdocId };
        } else {
            try {
                // console.log('inside refreshOrCreateWorksheet');
                const googleDocId = await handleCreateGoogleDoc(t('default-worksheet-name'));

                if (googleDocId) {
                    // console.log(googleDocId);
                    let res = await createWorksheetMutation({
                        variables: {
                            worksheetName: t('default-worksheet-name'),
                            googleDocId: googleDocId,
                        },
                    });

                    // resolve(res?.data?.createWorksheet?.id);
                    return {
                        worksheetId: res?.data?.createWorksheet?.id,
                        googleDocId: googleDocId,
                    }; // return both IDs
                } else {
                    return {
                        worksheetId: null,
                        googleDocId: null,
                        message: 'Google Doc could not be created',
                    };
                }
            } catch (err: any) {
                console.log(err);
                //reject(err);
                return {
                    worksheetId: null,
                    googleDocId: null,
                    message: err.message,
                };
            }
        }
    };

    // Define your deleteWorksheet, refreshOrCreateWorksheet, refreshWorksheet functions here

    return {
        refreshOrCreateWorksheet,
        // refreshWorksheet,
        deleteWorksheet,
    };
}
