import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ImageIcon from '@mui/icons-material/Image';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentH5P } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';
import { H5PContainer } from './H5PContainer'; // Make sure to provide the correct path here

export const LessonsSlideDataH5P = ({ item, index, disabled, containerId }: { item: SlideComponent; index: number; disabled?: boolean; containerId: string }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex, selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentH5P = item.content as SlideComponentH5P;

    const compRef = useRef<HTMLDivElement>(null);
    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (index === selectedComponentIndex) {
            const compRefCurrent = compRef.current;

            if (canvasObserverRef.current) {
                canvasObserverRef.current.disconnect();
            }
            canvasObserverRef.current = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element ot be watched
                const { width, height } = entries[0].contentRect;
                if (item.size.height !== height) {
                    item.size.height = height;
                    updateItemProperties({ ...item }, false);
                }
            });

            if (compRefCurrent) {
                canvasObserverRef.current.observe(compRefCurrent);
            }
            return () => {
                if (compRefCurrent) {
                    canvasObserverRef?.current?.unobserve(compRefCurrent);
                }
                canvasObserverRef?.current?.disconnect();
            };
        }
    }, [item.size.width, itemContent.h5pPath, selectedComponentIndex, updateItemProperties]);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <strong
                style={{
                    cursor: 'move',
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                }}>
                <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<ImageIcon />} title={t('H5P-box')} resize={true} />

                <H5PContainer containerId={containerId} itemContent={itemContent} />
            </strong>
        </Draggable>
    );
};
