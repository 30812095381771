import React, { useEffect, useRef } from 'react';
import { Box, ImageListItem, SxProps, Typography } from '@mui/material';
import { CharacterPhraseModel } from 'models/character/CharacterPhraseModel';

type LazyImageListItemWordProps = {
    characterPhrase: CharacterPhraseModel;
    sx: SxProps;
    onClick: () => void;
};

export const LazyImageListItemWord = (props: LazyImageListItemWordProps) => {
    const listItemRef = useRef(null);
    const [isNearViewport, setIsNearViewport] = React.useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                // Check whether this list item is near the viewport
                if (entries[0].isIntersecting) {
                    setIsNearViewport(true);
                }
            },
            {
                threshold: 0.1, // trigger when at least 10% of the item is in the viewport
            },
        );

        // Start observing the list item
        if (listItemRef.current) {
            observer.observe(listItemRef.current);
        }

        return () => {
            // Stop observing the list item
            if (listItemRef.current) {
                observer.unobserve(listItemRef.current);
            }
        };
    }, []);

    return (
        <ImageListItem ref={listItemRef} sx={props.sx} onClick={props.onClick}>
            {isNearViewport && (
                <>
                    <Box
                        sx={{
                            width: '80%',
                            backgroundColor: '#533D18',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pl: 1.5,
                            pr: 1.5,
                            color: 'white',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Typography>{props.characterPhrase.phrase}</Typography>
                    </Box>
                </>
            )}
        </ImageListItem>
    );
};
