import { RefObject, useReducer, useState } from 'react';
import { createContext } from 'react';
import { WorksheetModel, QuestionModel } from '../models/Worksheet';

export type WorksheetContextType = {
    selectedQuestionIndex: number;
    setSelectedQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    worksheetData: WorksheetModel;
    dispatchWorksheetData: React.Dispatch<ReducerAction>;
    worksheetRoot?: RefObject<HTMLDivElement>;
    setWorksheetRoot: React.Dispatch<React.SetStateAction<RefObject<HTMLDivElement> | undefined>>;
};

type ReducerAction =
    | {
          type: 'SetWorksheet';
          payload: WorksheetModel;
      }
    | {
          type: 'SetQuestions';
          payload: QuestionModel[];
      }
    | {
          type: 'AddQuestion';
          payload: QuestionModel;
      }
    | {
          type: 'UpdateQuestion';
          payload: QuestionModel;
      }
    | {
          type: 'DeleteQuestion';
          payload: number;
      }
    | {
          type: 'UpdateWorksheet';
          payload: Partial<WorksheetModel>;
      };

// const initialGoogleDoc: GoogleDoc = {
//   id: '',
//   url: '',
// };

const initialQuestionModel: QuestionModel = {
    id: '',
    questionText: '',
    answers: [],
};

const initialState: WorksheetModel = {
    id: '',
    worksheetName: '',
    googleDocId: '',
    questions: [],
    dateUpdated: '',
};

function reducer(state: WorksheetModel, action: ReducerAction) {
    const { type } = action;
    switch (type) {
        case 'SetWorksheet': {
            return { ...state, ...action.payload };
        }
        case 'SetQuestions': {
            return { ...state, questions: [...action.payload] };
        }
        case 'AddQuestion': {
            return {
                ...state,
                questions: [...state.questions, action.payload],
            };
        }
        case 'UpdateQuestion': {
            return {
                ...state,
                questions: state.questions.map((question) => (question.id === action.payload.id ? action.payload : question)),
            };
        }
        case 'DeleteQuestion': {
            return {
                ...state,
                questions: state.questions.filter((_, index) => index !== action.payload),
            };
        }
        case 'UpdateWorksheet':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
}

export const WorksheetContext = createContext<WorksheetContextType>({} as WorksheetContextType);

export const WorksheetContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const initialState: WorksheetModel = {
        id: '',
        worksheetName: '',
        googleDocId: '',
        questions: [],
        dateUpdated: new Date().toISOString(),
    };

    const [worksheetData, dispatchWorksheetData] = useReducer(reducer, initialState);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [worksheetRoot, setWorksheetRoot] = useState<RefObject<HTMLDivElement>>();

    return (
        <WorksheetContext.Provider
            value={{
                selectedQuestionIndex,
                setSelectedQuestionIndex,
                loading,
                setLoading,
                worksheetData,
                dispatchWorksheetData,
                worksheetRoot,
                setWorksheetRoot,
            }}>
            {children}
        </WorksheetContext.Provider>
    );
};
