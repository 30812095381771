import { Typography, TypographyProps } from '@mui/material';

export const PopupBody = (props: TypographyProps) => {
    return (
        <Typography
            {...props}
            sx={{
                fontWeight: 'medium',
                fontSize: '20px',
                color: '#533D18',
                ...props.sx,
            }}>
            {props.children}
        </Typography>
    );
};
