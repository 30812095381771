import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SignInPath } from 'routes/utils';

export const ResetVerificationPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (!state?.forgotPasswordEmail) {
            navigate(`../${SignInPath}`);
        }
    }, [state]);

    const openMailApp = () => {
        // window.location.href = 'mailto:';
        const mailtoWindow = window.open('mailto:', '_blank');
        if (mailtoWindow) {
            mailtoWindow.opener = null;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                minWidth: 360,
            }}>
            <Box
                sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#858585',
                }}>
                <Typography>{`${t('weSentPasswordResetLinkTo')}`}</Typography>
                <Typography>{state?.forgotPasswordEmail}</Typography>
            </Box>
            <Box>
                <LoadingButton
                    onClick={openMailApp}
                    variant='contained'
                    disableElevation
                    fullWidth
                    size='large'
                    color='primary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '12px',
                        background: '#F06E3C',
                        boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                        fontWeight: '500',
                        lineHeight: '28px',
                        fontSize: '18px',
                        color: '#FDFCFC',
                    }}
                    type='submit'>
                    {t('openEmailApp')}
                </LoadingButton>
            </Box>
        </Box>
    );
};
