import { Box } from '@mui/material';
import React from 'react';
import useSasRadicalCardHook from './useSasRadicalCardHook';
import { url } from 'inspector';
type SasRadicalCardProp = {
    character: string;
    isRadical: boolean;
    url: string;
    isSimplified: boolean;
};
const SasRadicalCardComponent = (prop: SasRadicalCardProp) => {
    const { GetCardSprite } = useSasRadicalCardHook();

    return (
        <Box
            component='img'
            src={prop.url === '' ? GetCardSprite(prop.character, prop.isRadical, prop.isSimplified) : prop.url}
            alt='bg'
            sx={{
                borderRadius: 1,
                borderWidth: 1,
                borderColor: '#000000',
                borderStyle: 'solid',
                alignSelf: 'center',
                height: 150,
                maxWidth: 300,
                objectFit: 'contain',
                pointerEvents: 'none',
                filter: 'hue-rotate(90deg) brightness(0) contrast(2)', // Adjust these values as needed
            }}
        />
    );
};

export default SasRadicalCardComponent;
