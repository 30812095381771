import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';
import { SlideComponent, SlideComponentSasAudio } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

export const LessonsSlideDataSasAudio = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex, selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);
    const { updateItemProperties } = useLessonSlide();

    let itemContent: SlideComponentSasAudio = item.content as SlideComponentSasAudio;

    const compRef = useRef<HTMLImageElement>(null);

    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (index === selectedComponentIndex) {
            const compRefCurrent = compRef.current;

            if (canvasObserverRef.current) {
                canvasObserverRef.current.disconnect();
            }
            canvasObserverRef.current = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element to be watched
                const { width, height } = entries[0].contentRect;

                if (item.size.height !== height) {
                    item.size.height = height;
                    updateItemProperties({ ...item }, false);
                }
            });

            if (compRefCurrent) {
                canvasObserverRef.current.observe(compRefCurrent);
            }

            return () => {
                if (compRefCurrent) {
                    canvasObserverRef?.current?.unobserve(compRefCurrent);
                }
                canvasObserverRef?.current?.disconnect();
            };
        }
    }, [item, item.size.width, itemContent.questionImage, selectedComponentIndex, index]);

    useEffect(() => {
        if (item.size.width === 0 && item.size.height === 0) {
            const compWidth = compRef.current?.naturalWidth!;
            const compHeight = compRef.current?.naturalHeight!;
            const defaultSize = 300;

            if (compWidth && compHeight) {
                const aspectRatio = compWidth / compHeight;

                if (compWidth > compHeight && compWidth > defaultSize) {
                    item.size.width = defaultSize;
                    item.size.height = defaultSize / aspectRatio;
                } else if (compHeight > compWidth && compHeight > defaultSize) {
                    item.size.width = defaultSize * aspectRatio;
                    item.size.height = defaultSize;
                } else {
                    item.size.width = compWidth;
                    item.size.height = compHeight;
                }
            }
            updateItemProperties({ ...item }, true);
        }
    }, [compRef.current, compRef.current?.naturalWidth, compRef.current?.naturalHeight]);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <Box
                sx={{
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                    position: 'absolute',
                    zIndex: itemContent.zIndex ?? 0,
                    '& .hidden-button': {
                        display: 'none',
                    },
                    ...(selectedComponentIndex !== index && {
                        '&:hover': {
                            backgroundColor: 'rgba(134, 255, 248, 0.75)',
                        },
                        '&:hover .hidden-button': {
                            display: 'flex',
                        },
                    }),
                }}>
                <strong
                    style={{
                        cursor: 'move',
                        width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                        height: `calc(${item?.size?.height ?? 0}px * ${zoom})`,
                        position: 'absolute',
                        zIndex: itemContent.zIndex ?? 0,
                    }}>
                    <DraggableDotBorder disabled={disabled} item={item} index={index} icon={<HeadsetMicIcon />} title={t('hksas.audio.title')} resize={true} />

                    <Box
                        ref={compRef}
                        component='img'
                        src={
                            itemContent.questionImageURL && itemContent.questionImageURL !== ''
                                ? itemContent.questionImageURL
                                : `/characters/${itemContent.questionImage !== '' ? itemContent.questionImage + '/image.png' : 'default.png'}`
                        }
                        alt='bg'
                        sx={{
                            zoom: zoom,
                            aspectRatio: 'auto',
                            transform: `scaleX(${itemContent.questionImageFlip ?? 1})`,
                            pointerEvents: 'none',
                        }}
                        width={item.size.width}
                    />
                </strong>
            </Box>
        </Draggable>
    );
};
