import { Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { ClassCreateForm } from './components/ClassCreateForm';
import { UpgradePlanPopup } from '../../routes/UpgradePlanPopup';
import { useClassesAddHook } from './hooks/useClassesAddHook';

type ClassesAddProps = {
    refresh: () => void;
    currentNumClasses: number;
};

export const ClassesAdd = ({ refresh, currentNumClasses }: ClassesAddProps) => {
    const { t } = useTranslation();
    const auth = useContext(UserAuthContext);

    const { open, showUpgradePopup, maxNumClasses, classLimitReached, handleClickOpen, handleUpgradePopupClose, createClass, initValues, handleClose } = useClassesAddHook(currentNumClasses, refresh);

    return (
        <Box>
            <Button variant='contained' color='secondary' sx={{ textTransform: 'none', borderRadius: '8px' }} onClick={handleClickOpen} startIcon={<AddIcon />}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography component='span'>{t('add-class')}</Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            component='span'
                            sx={{
                                color: classLimitReached() ? 'red' : 'inherit',
                            }}>
                            {currentNumClasses}
                        </Typography>
                        <Typography component='span' sx={{ fontSize: '0.75rem' }}>
                            {' '}
                            {/* Same smaller font size */}
                            {' / '}
                            {maxNumClasses} {t('class-limit')}
                        </Typography>
                    </Box>
                </Box>{' '}
            </Button>
            {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={handleUpgradePopupClose} reason='classLimitExceeded' />}
            <ClassCreateForm isOpened={open} onSubmit={createClass} initValues={initValues} handleClose={handleClose} />
        </Box>
    );
};
