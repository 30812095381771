import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ResultTable, ResultTableProps } from 'components/ResultTable';
import { GET_TESTROOMS } from 'pages/results/queries';
import { TestroomsWithQuestionsQueryModel } from 'queryModels/TestroomsQueryModel';
import moment from 'moment';
import { OverallResultPath, SelectClassPath, SelectStudentPath, SelectTestroomPath } from 'routes/utils';
import { getDateSortDirection, getStringSortDirection } from 'pages/results/utils';
import { ResultsPageContext } from 'pages/results/models';

export const SelectTestroomPage = () => {
    const navigator = useNavigate();
    const { schoolId, classId } = useParams();
    const { userData, setSelectedTestRoom } = useOutletContext<ResultsPageContext>();
    const { t } = useTranslation();
    //TODO error handle
    const { data, loading } = useQuery<TestroomsWithQuestionsQueryModel>(GET_TESTROOMS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            teacher: { equals: userData?.teacherProfileId },
            class: { equals: classId },
        },
        skip: !userData,
    });
    const [testroomTableData, setTestroomTableData] = useState<ResultTableProps>();

    useEffect(() => {
        setTestroomTableData({
            data: data?.testrooms?.map((item) => {
                return {
                    testName: item.testroomName,
                    testDate: moment(item.startTime).format('L'),
                    id: item.id,
                    onClick: () => {
                        console.log(item);
                        setSelectedTestRoom(item);
                        navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${OverallResultPath}/${item.id}`);
                    },
                };
            }),
            columns: [
                {
                    name: 'testName',
                    nameStr: t('testName'),
                    sortCallback: () => {
                        setTestroomTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'testName');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.testName?.localeCompare(a?.testName));
                                } else {
                                    cloneState.sort((a, b) => a?.testName?.localeCompare(b?.testName));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
                {
                    name: 'testDate',
                    nameStr: t('testDate'),
                    sortCallback: () => {
                        setTestroomTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getDateSortDirection(cloneState, 'testDate');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => new Date(b?.testDate).valueOf() - new Date(a?.testDate).valueOf());
                                } else {
                                    cloneState.sort((a, b) => new Date(a?.testDate).valueOf() - new Date(b?.testDate).valueOf());
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
            ],
        });
    }, [data]);

    return <ResultTable columns={testroomTableData?.columns} data={testroomTableData?.data} isLoading={loading} showSearchTab={true} />;
};
