import { useTranslation } from 'react-i18next';

import Draggable from 'react-draggable';
import { useContext, useRef, useEffect } from 'react';
import { SlideListContext } from '../../../contexts/LessonsSlideContext';
import { DraggableDotBorder } from '../DraggableDotBorder';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import { SlideComponent, SlideComponentAudio, SlideComponentFileModel } from 'models/lesson/LessonModel';
import useLessonSlide from '../../../hooks/useLessonSlide';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SlidePreviewContext } from '../../../contexts/SlidePreviewContext';

export const LessonsSlideDataAudio = ({ item, index, disabled }: { item: SlideComponent; index: number; disabled?: boolean }) => {
    const { t } = useTranslation();
    const { setSelectedComponentIndex, selectedComponentIndex } = useContext(SlideListContext);
    const { zoom } = useContext(SlidePreviewContext);

    const { updateItemProperties } = useLessonSlide();
    const { deleteAudio } = useLessonSlideProps();

    let itemContent: SlideComponentAudio = item.content as SlideComponentAudio;

    const compRef = useRef<HTMLDivElement>(null);

    const canvasObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if (index === selectedComponentIndex) {
            const compRefCurrent = compRef.current;

            if (canvasObserverRef.current) {
                canvasObserverRef.current.disconnect();
            }
            canvasObserverRef.current = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element ot be watched
                const { width, height } = entries[0].contentRect;
                if (item.size.height !== height) {
                    item.size.height = height;
                    updateItemProperties({ ...item }, false);
                }
            });

            if (compRefCurrent) {
                canvasObserverRef.current.observe(compRefCurrent);
            }
            return () => {
                if (compRefCurrent) {
                    canvasObserverRef?.current?.unobserve(compRefCurrent);
                }
                canvasObserverRef?.current?.disconnect();
            };
        }
    }, [item.size.width, selectedComponentIndex, updateItemProperties]);

    return (
        <Draggable
            disabled={disabled}
            bounds='parent'
            handle='strong'
            onStart={() => {
                setSelectedComponentIndex(index);
            }}
            onStop={(e, position) => {
                const newX = position.x / zoom;
                const newY = position.y / zoom;
                if (item.pos.x !== newX || item.pos.y !== newY) {
                    item.pos.x = newX;
                    item.pos.y = newY;
                    updateItemProperties({ ...item }, true);
                }
            }}
            position={{ x: item.pos.x * zoom, y: item.pos.y * zoom }}>
            <strong
                style={{
                    cursor: 'move',
                    width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                    height: `calc(${item?.size?.width ?? 0}px * ${zoom})`, // sqaure
                    position: 'absolute',
                    zIndex: 100,
                }}>
                <DraggableDotBorder
                    disabled={disabled}
                    item={item}
                    index={index}
                    icon={<VolumeUpIcon />}
                    title={t('audio-box')}
                    resize={true}
                    deleteExtension={() => {
                        if (itemContent.audio.length > 0) {
                            itemContent.audio?.map((audio: SlideComponentFileModel, index) => {
                                return deleteAudio(audio.id, item);
                            });
                        }
                    }}
                />

                <VolumeUpIcon
                    sx={{
                        width: `calc(${item?.size?.width ?? 0}px * ${zoom})`,
                        height: `calc(${item?.size?.width ?? 0}px * ${zoom})`, // sqaure
                        bgcolor: 'white',
                        borderRadius: '50%',
                    }}
                />
            </strong>
        </Draggable>
    );
};
