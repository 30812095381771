import React, { useState, useContext } from 'react';
import { Box, Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { NotPaymentUserDialog } from 'pages/profile/components/NotPaymentUserDialog';

type CurrentPlanProps = {
    planName: string;
    isOnTrial?: boolean;
    trialDaysRemaining?: number;
    expiryDate: Date | undefined;
    amountDue?: number;
    onCancelPlan: () => void;
    onDownloadInvoice: () => void; // New prop for downloading the invoice
    isFreePlan?: boolean;
    teachersAccountCount: number;
    paymentUser: string;
    onChangePlan: (newAccountCount: number) => void;
    autoRenew: boolean | undefined;
    isLoading?: boolean;
};

export const PlanInfo: React.FC<CurrentPlanProps> = ({
    planName,
    isOnTrial = false,
    trialDaysRemaining = 0,
    expiryDate,
    amountDue,
    onCancelPlan,
    onDownloadInvoice,
    isFreePlan,
    teachersAccountCount,
    paymentUser,
    onChangePlan,
    autoRenew,
    isLoading = false,
}) => {
    const { t } = useTranslation();
    const [newAccountCount, setNewAccountCount] = useState(teachersAccountCount);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [showContactPaymentUserDialog, setShowContactPaymentUserDialog] = useState(false);
    const handleCancelClick = () => {
        // Check if the current user is the PaymentUser
        const isPaymentUser = auth.selectedSchool?.paymentUser.id === auth.userData?.id;
        if (!isPaymentUser) {
            setShowContactPaymentUserDialog(true);
            return;
        } else {
            setOpenConfirmDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmCancel = () => {
        onCancelPlan();
        setOpenConfirmDialog(false);
    };
    const handleAccountCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewAccountCount(Math.max(parseInt(event.target.value, 10), teachersAccountCount, 1) || 0);
    };

    const handleSubmitChange = () => {
        onChangePlan(newAccountCount);
    };
    const auth = useContext(UserAuthContext);

    return (
        <Box>
            {/* Plan details */}
            <Stack spacing={2}>
                <Typography variant='h6'>{t('planinfo.plan-currently-on')} :</Typography>
                <Typography variant='h6'>{planName === '' ? t('FREE_PLAN') : planName}</Typography>
                <Typography variant='h6'>
                    {t('subscription.payment-user')} : {paymentUser}
                </Typography>
                <Stack direction='row' spacing={2} justifyContent='left'>
                    <Typography variant='h6'>
                        {t('subscription.teachers-account-count', {
                            count: teachersAccountCount,
                        })}
                    </Typography>
                    {/* <TextField
            label={t('subscription.change-teachers-account')}
            type='number'
            value={newAccountCount}
            onChange={handleAccountCountChange}
            variant='outlined'
            sx={{ width: '100px' }}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmitChange}
            sx={{ width: '150px', height: '50px' }}>
            {t('subscription.change-plan')}
          </Button> */}
                </Stack>

                {isOnTrial && (
                    <Typography variant='body1'>
                        {t('subscription.trial-remaining', {
                            count: trialDaysRemaining,
                        })}
                    </Typography>
                )}
                <Typography variant='body1'>
                    {isFreePlan
                        ? ''
                        : t('subscription.expiry-date', {
                              date: expiryDate ? new Date(expiryDate).toDateString() : '',
                          })}
                    {autoRenew ? '' : ' (' + t('subscription.not-auto-renew') + ')'}
                </Typography>
                {/* <Typography variant='body1'>
          {t('subscription.chargeable-balance', { amount: amountDue })}
        </Typography> */}
            </Stack>

            {/* Confirmation dialog */}
            <Dialog open={openConfirmDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('subscription.confirm-cancel-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('subscription.confirm-cancel-message')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('subscription.cancel')}</Button>
                    <Button onClick={handleConfirmCancel} color='error'>
                        {t('subscription.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Buttons section */}
            <Box height={'100px'}>
                <Stack sx={{ height: '15%', borderRadius: 4, minHeight: '70px' }}>
                    <Stack direction='row' spacing={2} justifyContent='left'>
                        {isLoading ? (
                            <CircularProgress></CircularProgress>
                        ) : (
                            <Button
                                disabled={isFreePlan || auth.selectedSchool?.subscriptionHistories?.[0]?.autoRenew ? !auth.selectedSchool?.subscriptionHistories?.[0]?.autoRenew : true}
                                variant='contained'
                                color='error'
                                onClick={handleCancelClick}
                                sx={{ width: '150px', height: '50px' }}>
                                {t('subscription.cancel-plan')}
                            </Button>
                        )}

                        {/* <Button
              variant='contained'
              color='primary'
              onClick={onDownloadInvoice}
              sx={{ width: '150px', height: '50px' }}>
              {t('subscription.download-invoice')}
            </Button> */}
                    </Stack>
                    <NotPaymentUserDialog t={t} open={showContactPaymentUserDialog} setOpen={setShowContactPaymentUserDialog} />
                </Stack>
            </Box>
        </Box>
    );
};
