import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useWorksheetUpdate from '../hooks/useWorksheetUpdate';

export const WorksheetsTitle = ({ worksheetID, value }: { worksheetID: string; value: string }) => {
    const { t } = useTranslation();
    const { worksheetName, onBlurHandler, onChangeHandler, worksheetNameError } = useWorksheetUpdate(worksheetID, value);

    return (
        <TextField
            id='worksheetName'
            name='worksheetName'
            value={worksheetName}
            onChange={(event) => {
                onChangeHandler(event.target.value);
            }}
            onBlur={(event) => {
                onBlurHandler(event.target.value);
            }}
            error={!!worksheetNameError}
            helperText={worksheetNameError}
            hiddenLabel
            placeholder={t('worksheet-title')}
            variant='outlined'
            size='small'
            inputProps={{
                min: 0,
                style: {
                    textAlign: 'center',
                    fontSize: '2rem',
                    fontFamily: 'Sofia Pro Soft',
                    fontWeight: 700,
                    color: '#5A4522',
                },
            }}
            sx={{
                width: '60%',
            }}
        />
    );
};
