import { useTranslation } from 'react-i18next';
import { TestroomModel } from '../models/TestroomModel';
import { TopButton, TopButtonTypography, ButtonTypography } from '../TestroomTemplate';

export const TestroomLink = ({ testroomData }: { testroomData: TestroomModel }) => {
    const { t } = useTranslation();
    return (
        <TopButton>
            <TopButtonTypography>{t('live-class-name')}</TopButtonTypography>
            <ButtonTypography
                sx={{
                    maxWidth: 300,
                    padding: '0px 8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                {testroomData.testroomName}
            </ButtonTypography>
        </TopButton>
    );
};
