import { RefObject, useReducer, useState } from 'react';
import { createContext } from 'react';
import { LessonModel, SlideComponent, SlideModel } from 'models/lesson/LessonModel';

export type SlideListContextType = {
    selectedSlideIndex: number;
    setSelectedSlideIndex: React.Dispatch<React.SetStateAction<number>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    lessonData: LessonModel;
    dispatchLessonData: React.Dispatch<ReducerAction>;
    selectedComponentIndex: number;
    setSelectedComponentIndex: (index: number) => void;
    slideRoot?: RefObject<HTMLDivElement>;
    setSlideRoot: React.Dispatch<React.SetStateAction<RefObject<HTMLDivElement> | undefined>>;
};

type ReducerAction =
    | {
          type: 'SetLesson';
          payload: LessonModel;
      }
    | {
          type: 'SetSlides';
          payload: {
              slideIndex: number;
              data: SlideModel[];
          };
      }
    | {
          type: 'SetSlideComponent';
          payload: {
              slideIndex: number;
              data: SlideComponent[];
          };
      }
    | {
          type: 'AddSlideComponent';
          payload: {
              slideIndex: number;
              data: SlideComponent;
          };
      }
    | {
          type: 'UpdateSlideComponent';
          payload: {
              slideIndex: number;
              data: SlideComponent;
          };
      }
    | {
          type: 'DeleteSlideComponent';
          payload: {
              slideIndex: number;
              componentIndex: number;
          };
      };
function reducer(state: LessonModel, action: ReducerAction) {
    const { type } = action;
    switch (type) {
        case 'SetLesson': {
            return { ...state, ...action.payload };
        }
        case 'SetSlides': {
            let slides = [...action.payload.data];
            return {
                ...state,
                latestLessonDraft: { ...state.latestLessonDraft, slides },
            };
        }
        case 'SetSlideComponent': {
            let slides = [...state.latestLessonDraft?.slides];

            slides[action.payload.slideIndex] = {
                ...slides[action.payload.slideIndex],
                data: action.payload.data,
            };

            return {
                ...state,
                latestLessonDraft: { ...state.latestLessonDraft, slides },
            };
        }
        case 'AddSlideComponent': {
            let slides = [...state.latestLessonDraft?.slides];

            let slideComponents = [...slides?.[action.payload.slideIndex]?.data, action.payload.data];

            slides[action.payload.slideIndex] = {
                ...slides[action.payload.slideIndex],
                data: slideComponents,
            };

            return {
                ...state,
                latestLessonDraft: { ...state.latestLessonDraft, slides },
            };
        }
        case 'UpdateSlideComponent': {
            let slides = [...state.latestLessonDraft?.slides];

            if (slides?.[action.payload.slideIndex]?.data) {
                let slideComponents = [...slides[action.payload.slideIndex].data]?.map((slideComp: SlideComponent) => {
                    if (slideComp.id === action.payload.data.id) {
                        return action.payload.data;
                    } else {
                        return slideComp;
                    }
                });
                slides[action.payload.slideIndex] = {
                    ...slides[action.payload.slideIndex],
                    data: slideComponents,
                };
            }

            return {
                ...state,
                latestLessonDraft: { ...state.latestLessonDraft, slides },
            };
        }
        case 'DeleteSlideComponent': {
            let slides = [...state.latestLessonDraft?.slides];

            if (slides?.[action.payload.slideIndex]?.data) {
                let slideComponents = [...slides[action.payload.slideIndex].data].filter((slideItem: SlideComponent, idx: number) => idx !== action.payload.componentIndex);

                slides[action.payload.slideIndex] = {
                    ...slides[action.payload.slideIndex],
                    data: slideComponents,
                };
            }

            return {
                ...state,
                latestLessonDraft: { ...state.latestLessonDraft, slides },
            };
        }
        default:
            throw new Error();
    }
}

export const SlideListContext = createContext<SlideListContextType>({} as SlideListContextType);

export const LessonsSlideContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    // const [lessonData, setLessonData] = useState<LessonModel>();
    // const [lessonData, dispatchLessonData] = useReducer(
    //   reducer,
    //   {} as LessonModel,
    // );

    const initialState: LessonModel = {
        id: '',
        latestLessonDraft: {
            id: '',
            lessonName: '',
            slides: [],
            dateUpdated: '',
            published: false,
            parent: {} as LessonModel,
        },
        shareWithPublic: false,
        sharesCount: 0,
        dateUpdated: '',
        teacher: { user: { id: '' } },
    };

    const [lessonData, dispatchLessonData] = useReducer(reducer, initialState);

    const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
    const [selectedComponentIndex, setSelectedComponentIndex] = useState(-1);
    const [slideRoot, setSlideRoot] = useState<RefObject<HTMLDivElement>>();

    return (
        <SlideListContext.Provider
            value={{
                selectedSlideIndex,
                setSelectedSlideIndex: (index) => {
                    setSelectedSlideIndex(index);
                    setSelectedComponentIndex(-1);
                },
                loading,
                setLoading,
                lessonData,
                dispatchLessonData,
                selectedComponentIndex,
                setSelectedComponentIndex,
                slideRoot,
                setSlideRoot,
            }}>
            {children}
        </SlideListContext.Provider>
    );
};
