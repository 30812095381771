import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PunctuationMarkComponentProps = {
    id: string;
    index: number;
    text: string;
    questionText: string;
    answerArray: string[];
    onTextFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    deleteItemChange: (idx: number) => void;
};
export const PunctuationMarkComponent: React.FC<PunctuationMarkComponentProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                backgroundColor: '#F7EEDC',
                padding: 2,
                borderRadius: '12px',
                position: 'relative',
            }}>
            <Box sx={{ color: '#533D18', fontSize: 14 }}>{t('ui.question-num').replace('{num}', `${props.index + 1}`)}</Box>
            <Box sx={{ color: '#533D18', fontSize: 9 }}>id:{props.id}</Box>

            <Box sx={{ color: '#533D18', fontSize: 12 }}>輸入：</Box>
            <TextField
                // label='Questions'
                type='string'
                placeholder='山川秀麗(，)心情舒暢(。)'
                sx={{ width: '100%', marginBottom: 1, color: '#533D18' }}
                value={props.text}
                onChange={(e) => {
                    props.onTextFieldChange(props.index, e.target.value);
                }}
            />
            <Box sx={{ color: '#533D18', fontSize: 12 }}>{t('ui.question')}：</Box>
            <Typography>{props.questionText}</Typography>
            <Box sx={{ color: '#533D18', fontSize: 12 }}>答案：</Box>
            <Typography>{props.answerArray.join(' ')}</Typography>
        </Box>
    );
};
