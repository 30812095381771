import React, { useEffect, useRef } from 'react';
import { Box, ImageListItem, SxProps, Typography } from '@mui/material';
import { OptionsType } from './PropsImagePicker';

type LazyImageListItemProps = {
    option: OptionsType;
    sx: SxProps;
    onClick: () => void;
};

export const LazyImageListItem = (props: LazyImageListItemProps) => {
    const listItemRef = useRef(null);
    const [isNearViewport, setIsNearViewport] = React.useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                // Check whether this list item is near the viewport
                if (entries[0].isIntersecting) {
                    setIsNearViewport(true);
                }
            },
            {
                threshold: 0.1, // trigger when at least 10% of the item is in the viewport
            },
        );

        // Start observing the list item
        if (listItemRef.current) {
            observer.observe(listItemRef.current);
        }

        return () => {
            // Stop observing the list item
            if (listItemRef.current) {
                observer.unobserve(listItemRef.current);
            }
        };
    }, []);

    return (
        <ImageListItem ref={listItemRef} sx={props.sx} onClick={props.onClick}>
            {isNearViewport && (
                <>
                    <Box
                        component='img'
                        src={`/characters/${props.option.path}`}
                        sx={{
                            width: 140,
                            height: 140,
                            m: 1,
                            objectFit: 'contain',
                        }}
                        loading='lazy'
                    />
                    <Box
                        sx={{
                            backgroundColor: '#533D18',
                            position: 'absolute',
                            top: 6,
                            right: 6,
                            pl: 1.5,
                            pr: 1.5,
                            color: 'white',
                            borderRadius: '12px',
                        }}>
                        <Typography>{props.option.label}</Typography>
                    </Box>
                </>
            )}
        </ImageListItem>
    );
};
