import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const UPDATE_GQL = gql`
    mutation ConfirmLessonShareInvitation($token: String!) {
        confirmLessonShareInvitation(token: $token)
    }
`;

type MutationVariableType = {
    token: string;
};

type MutationType = {
    confirmLessonShareInvitation: boolean;
};

export const useLessonShareModelConfirmInvitationMutation = () => {
    const [saveForm] = useMutation<MutationType>(UPDATE_GQL);
    const { accessDenied } = useContext(UserAuthContext);

    const updateConfirmInvitation = async (variables: MutationVariableType) => {
        try {
            const result = await saveForm({
                variables,
            });

            if (!result || !result.data?.confirmLessonShareInvitation) {
                throw new TTError(ERROR_TYPE.UNKNOWN_ERROR);
            }

            return result?.data?.confirmLessonShareInvitation;
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { updateConfirmInvitation };
};
