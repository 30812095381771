import { OverallResultByQuestion } from 'models/resultPage/ResultPageModel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// export type StudentProfileWithResults = StudentProfile & TestroomResults;
export type QuestionTypeResult = {
    [key: string]: QuestionTypeResultData;
};
export type DisplayQuestionTypeResult = {
    [key: string]: string;
};

export type QuestionTypeResultData = {
    totalQuestions: number;
    totalCorrect: number;
};

export const useQuestionType = () => {
    const [questionTypeResult, setQuestionTypeResult] = useState<QuestionTypeResult>({});
    const { t } = useTranslation();
    const convertGameTypeToStr = (questionType: string): string => {
        const typeMapping = {
            Card: t('resultPage.card'),
            Writing: t('resultPage.writing'),
            Audio: t('resultPage.audio'),
            hkcsQ3: t('hkcs.q3.title'),
            hkcsQ2: t('hkcs.q2.title'),
            hkcsQ1: t('hkcs.q1.title'),
            hkcsEnQ1: t('hkcs.en.q1.title'),
            hkcsEnQ2: t('hkcs.en.q2.title'),
            multipleChoice: t('hksas.mctitle'),
            sasFillInTheBlanks: t('hksas.fillInTheBlanks.title'),
            sasAudio: t('hksas.audio.title'),
            sasFillInTheBlanksWriting: t('hksas.fillInTheBlanksWriting.title'),
            sasRadicalMatching: t('hksas.radicalMatching.title'),
        };

        type QuestionType = keyof typeof typeMapping;

        const keys = Object.keys(typeMapping) as QuestionType[];

        for (const key of keys) {
            if (questionType.includes(key)) {
                return typeMapping[key];
            }
        }

        return '';
    };

    const getQuestionTypeResult = (questionResultPerStudents: OverallResultByQuestion[], absentStudents: number) => {
        if (questionResultPerStudents) {
            const questionTypeResultObj: QuestionTypeResult = {};
            questionResultPerStudents.forEach((result) => {
                if (questionTypeResultObj.hasOwnProperty(convertGameTypeToStr(result.questionType))) {
                    questionTypeResultObj[convertGameTypeToStr(result.questionType)].totalCorrect += result.correct / (result.studentPerformances.length - absentStudents);
                    questionTypeResultObj[convertGameTypeToStr(result.questionType)].totalQuestions += 1;
                } else {
                    questionTypeResultObj[convertGameTypeToStr(result.questionType)] = {
                        totalCorrect: 0,
                        totalQuestions: 1,
                    };
                    questionTypeResultObj[convertGameTypeToStr(result.questionType)].totalCorrect = result.correct / (result.studentPerformances.length - absentStudents);
                    questionTypeResultObj[convertGameTypeToStr(result.questionType)].totalQuestions = 1;
                }
            });
            setQuestionTypeResult(questionTypeResultObj);
        }
    };

    return { getQuestionTypeResult, questionTypeResult };
};
