import {
    Button,
    Dialog,
    TextField,
    DialogActions,
    DialogTitle,
    DialogContent,
    Stack,
    IconButton,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';
import { format, getMonth, getYear } from 'date-fns';
import { ClassesQueryData, TimeSlotDialogState } from '../types/timeSlotTypes';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { PopupBody } from 'components/General/Popups/PopupBody';
import { FormikProps } from 'formik';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';

const years = range(getYear(new Date()) - 1, getYear(new Date()) + 2, 1);
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

type CalendarAddUIProps = {
    currentCalendarState: TimeSlotDialogState;
    formik: FormikProps<{
        id: string;
        name: string;
        otp: string;
        lessonVersion: string;
        class: string;
        passlock: string;
        offlineMode: boolean;
        writingHints: boolean;
        freeStyleMode: boolean;
        startTime: Date;
        endTime: Date;
    }>;
    handleDelete?: (id: string) => Promise<void>;
    lessonName: string;
    classesQueryData?: ClassesQueryData;
    handleClose: () => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CalendarAddUI = ({ currentCalendarState, formik, handleDelete, lessonName, classesQueryData, handleClose, loading, setLoading }: CalendarAddUIProps) => {
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    const IconButtonInput = forwardRef(({ value, onClick }: { value?: any; onClick?: any }, ref: any) => (
        <IconButton onClick={onClick} ref={ref}>
            <DateRangeIcon />
        </IconButton>
    ));

    return (
        <>
            <Dialog open={currentCalendarState.isDialogOpen && !loading}>
                <DialogTitle>
                    <PopupTitle>{t('add-testroom')}</PopupTitle>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2} minWidth={480}>
                            {formik.values.otp !== '' && (
                                <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                                    <PopupBody>{t('otp')}:</PopupBody>
                                    <PopupBody>
                                        {formik.values.otp}{' '}
                                        <Button variant='outlined' href={`/testroom/${formik.values.otp}`}>
                                            Enter Testroom
                                        </Button>
                                    </PopupBody>
                                </Stack>
                            )}
                            <TextField
                                id='name'
                                name='name'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                label={t('name')}
                                fullWidth
                                variant='outlined'
                                error={formik.touched.name && !!formik.errors.name}
                                helperText={formik.touched.name ? formik.errors.name : ''}
                            />

                            {lessonName && lessonName !== '' && (
                                <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                                    <PopupBody>{t('menu-lesson')}</PopupBody>
                                    <PopupBody>{lessonName}</PopupBody>
                                </Stack>
                            )}

                            <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                                <PopupBody>{t('startTime')}</PopupBody>
                                <PopupBody>{format(formik.values.startTime, 'dd-MM-yyyy hh:mma')}</PopupBody>
                                <DatePicker
                                    selected={formik.values.startTime}
                                    onChange={(date: Date) => formik.setFieldValue('startTime', date)}
                                    customInput={<IconButtonInput />}
                                    withPortal
                                    showTimeSelect
                                    selectsStart
                                    startDate={formik.values.startTime}
                                    endDate={formik.values.endTime}
                                    renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {'<'}
                                            </button>
                                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
                                                {years.map((option: any) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {'>'}
                                            </button>
                                        </div>
                                    )}
                                />
                            </Stack>
                            <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                                <PopupBody>{t('endTime')}</PopupBody>
                                <PopupBody>{format(formik.values.endTime, 'dd-MM-yyyy hh:mma')}</PopupBody>
                                <DatePicker
                                    selected={formik.values.endTime}
                                    onChange={(date: Date) => formik.setFieldValue('endTime', date)}
                                    customInput={<IconButtonInput />}
                                    withPortal
                                    showTimeSelect
                                    selectsEnd
                                    startDate={formik.values.startTime}
                                    endDate={formik.values.endTime}
                                    renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {'<'}
                                            </button>
                                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
                                                {years.map((option: any) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {'>'}
                                            </button>
                                        </div>
                                    )}
                                />
                            </Stack>
                            <TextField
                                id='passlock'
                                type='number'
                                name='passlock'
                                onChange={(event) => {
                                    formik.setFieldValue('passlock', `${event.target.value}`);
                                }}
                                onBlur={(event) => {
                                    formik.setFieldValue('passlock', `${event.target.value}`);
                                }}
                                value={formik.values.passlock}
                                label={t('passlock')}
                                fullWidth
                                variant='outlined'
                                error={!!formik.errors.passlock}
                                helperText={formik.errors.passlock}
                            />

                            <FormControl fullWidth error={formik.touched.class && !!formik.errors.class}>
                                <InputLabel id='select-class-label'>{t('class')}</InputLabel>
                                <Select
                                    labelId='select-class-label'
                                    id='select-class'
                                    value={formik.values.class}
                                    label={t('class')}
                                    onChange={(e) => {
                                        formik.setFieldValue('class', e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}>
                                    {classesQueryData?.classes.map((option) => (
                                        <MenuItem value={option.id} key={option.id}>
                                            {option.school.schoolName} {option.className}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formik.touched.class ? formik.errors.class : ''}</FormHelperText>
                            </FormControl>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.offlineMode}
                                        onChange={(e) => {
                                            formik.setFieldValue('offlineMode', e.target.checked);
                                        }}
                                        inputProps={{
                                            'aria-label': 'Testing checkbox',
                                        }}
                                    />
                                }
                                label={t('offlineMode')}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.writingHints}
                                        onChange={(e) => {
                                            formik.setFieldValue('writingHints', e.target.checked);
                                        }}
                                        inputProps={{
                                            'aria-label': 'Testing checkbox',
                                        }}
                                    />
                                }
                                label={t('writingHints')}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!formik.values.freeStyleMode}
                                        onChange={(e) => {
                                            formik.setFieldValue('freeStyleMode', !e.target.checked);
                                        }}
                                        inputProps={{
                                            'aria-label': 'Testing checkbox',
                                        }}
                                    />
                                }
                                label={t('freeStyleMode')}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex' }}>
                        {currentCalendarState.calendarData?.data?.id && (
                            <CancelButton onClick={() => setDeleteOpen(true)} disabled={formik.isSubmitting} color='error' variant='outlined' sx={{ mr: 'auto' }}>
                                {t('delete')}
                            </CancelButton>
                        )}

                        <CancelButton onClick={handleClose} disabled={formik.isSubmitting}>
                            {t('cancel')}
                        </CancelButton>

                        <SubmitButton type='submit' loading={formik.isSubmitting}>
                            {t('save')}
                        </SubmitButton>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>{t('cancel')}</Button>
                    {handleDelete && (
                        <Button
                            onClick={() => {
                                setDeleteOpen(false);
                                handleDelete?.(currentCalendarState.calendarData?.data?.id);
                            }}
                            autoFocus>
                            {t('delete')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <PopupCircularProgress isOpened={loading} />
        </>
    );
};
