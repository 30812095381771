import { useArchiveLessonMutation } from 'models/lesson/useArchiveLessonMutation';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useDeleteLessonHook = (lessonID: string) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { archiveLesson } = useArchiveLessonMutation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await archiveLesson({ where: { id: lessonID } });
            handleClose();
            navigate('/lessons');
        } catch (err) {}
    };

    return { handleDelete, handleClickOpen, handleClose, open };
};
