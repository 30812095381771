import { Box, Button, IconButton, TextField, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

type MultipleChoiceProps = {
    id: string;
    index: number;
    questionText: string;
    answerIndex: number;
    choices: string[];
    onTextQuestionFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    onAnswerIndexChange: (idx: number, answerIndex: number) => void;
    onChoicesFieldChange: (idx: number, choiceIndex: number, textContent: string) => void;
    // onTextAnswerFieldChange: (idx: number, textContent: string) => void; // replace `any` with the actual type for `result`
    deleteItemChange: (idx: number) => void;

    onAddChoice: (idx: number) => void;
    onDeleteChoice: (idx: number, choiceIndex: number) => void;
};
export const MultipleChoiceQuestionComponent: React.FC<MultipleChoiceProps> = (props) => {
    // const [checked, setChecked] = useState([true, false]);
    // useEffect(() => {
    //   checked[props.answe]
    // }, [checked]);

    const { t, i18n } = useTranslation();
    const [deleteBubble, setDeleteBubble] = useState(false);
    const handleChoiceChange = (index: number, checked: boolean) => {
        props.onAnswerIndexChange(props.index, index);
    };
    const currentLocale = require(`date-fns/locale/${i18n.language}`).default;

    const handleAddChoice = () => {
        if (props.choices.length < 5) {
            props.onAddChoice(props.index);
        }
    };

    const handleDeleteChoice = () => {
        if (props.choices.length > 2) {
            props.onDeleteChoice(props.index, props.choices.length - 1);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: '#F7EEDC',
                padding: 2,
                borderRadius: '12px',
                position: 'relative',
            }}>
            <Box sx={{ color: '#533D18', fontSize: 14 }}>{t('ui.question-num').replace('{num}', `${props.index + 1}`)}</Box>
            <Box sx={{ color: '#533D18', fontSize: 9 }}>id:{props.id}</Box>
            {!deleteBubble && (
                <IconButton // add an icon button for delete
                    sx={{
                        position: 'absolute', // make the button position absolute
                        top: '20px',
                        right: '10px',
                        width: '14px',
                        height: '18px',
                    }}
                    onClick={() => {
                        setDeleteBubble(true);
                    }}>
                    <DeleteIcon />
                </IconButton>
            )}
            {deleteBubble && (
                <>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '10px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            setDeleteBubble(false);
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <IconButton // add an icon button for delete
                        sx={{
                            position: 'absolute', // make the button position absolute
                            top: '20px',
                            right: '40px',
                            width: '14px',
                            height: '18px',
                        }}
                        onClick={() => {
                            props.deleteItemChange(props.index);
                        }}>
                        <DoneIcon />
                    </IconButton>
                </>
            )}

            <Box sx={{ color: '#533D18', fontSize: 12 }}>{t('ui.question')}：</Box>

            <TextField
                type='string'
                placeholder={t('placeholder.multipleChoice.question')}
                sx={{
                    //width: '410px',
                    width: '80%',
                    marginLeft: '32px',
                    marginBottom: 1,
                    color: '#533D18',
                }}
                value={props.questionText}
                onChange={(e) => {
                    props.onTextQuestionFieldChange(props.index, e.target.value);
                }}
            />

            <FormGroup>
                {props.choices.map((label, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <>
                                <Checkbox
                                    checked={props.answerIndex === index}
                                    onChange={(e) => {
                                        handleChoiceChange(index, e.target.checked);
                                    }}
                                />
                                <TextField
                                    type='string'
                                    placeholder={
                                        [
                                            t('placeholder.multipleChoice.choice1'),
                                            t('placeholder.multipleChoice.choice2'),
                                            t('placeholder.multipleChoice.choice3'),
                                            t('placeholder.multipleChoice.choice4'),
                                            t('placeholder.multipleChoice.choice5'),
                                            t('placeholder.multipleChoice.choice6'),
                                            t('placeholder.multipleChoice.choice7'),
                                        ][index]
                                    }
                                    sx={{
                                        width: '80%',
                                        marginBottom: 1,
                                        color: '#533D18',
                                    }}
                                    value={props.choices[index]}
                                    onChange={(e) => {
                                        props.onChoicesFieldChange(props.index, index, e.target.value);
                                    }}
                                />
                                {props.answerIndex === index ? <Typography sx={{ marginLeft: 3, color: 'red' }}>{t('answer')}</Typography> : <></>}
                            </>
                        }
                        label={''}
                    />
                ))}
            </FormGroup>

            <Button
                variant='contained'
                onClick={handleDeleteChoice}
                disabled={props.choices.length <= 2}
                sx={{
                    mt: 2,
                    mr: 2,
                    backgroundColor: 'red',
                    '&:hover': {
                        backgroundColor: 'darkred', // Darker red on hover
                    },
                }}>
                {t('ui.delete-choice')}
            </Button>
            <Button variant='contained' onClick={handleAddChoice} disabled={props.choices.length >= 5} sx={{ mt: 2, mr: 2 }}>
                {t('ui.add-choice')}
            </Button>
        </Box>
    );
};

export function shuffleArray(array: string[]) {
    if (array !== null) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        array = [];
    }
    return array;
}
