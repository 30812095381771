import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

type ImageUploadDeleteButtonProps = {
    handleDelete: () => void;
};
export const ImageUploadDeleteButton = (props: ImageUploadDeleteButtonProps) => {
    return (
        <IconButton
            size='medium'
            sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                mr: -1,
                mt: -1,
                width: '20px',
                height: '20px',
                bgcolor: '#F06E3C',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'red',
                    color: 'white',
                },
            }}
            onClick={props.handleDelete}>
            <CloseIcon fontSize='small' />
        </IconButton>
    );
};
