import { useRef, useEffect } from 'react';

interface VideoPlayerProps {
    videoSrc: string;
    width: string;
    height: string;
}

const VideoPlayer = ({ videoSrc, width, height }: VideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handlePiPChange = () => {
            if (document.pictureInPictureElement === null && videoElement) {
                videoElement.pause();
            }
        };

        const handlePlay = async () => {
            if (videoElement && document.pictureInPictureElement !== videoElement) {
                await videoElement.requestPictureInPicture();
            }
        };

        if (videoElement) {
            videoElement.addEventListener('play', handlePlay);
            videoElement.addEventListener('enterpictureinpicture', handlePiPChange);
            videoElement.addEventListener('leavepictureinpicture', handlePiPChange);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('play', handlePlay);
                videoElement.removeEventListener('enterpictureinpicture', handlePiPChange);
                videoElement.removeEventListener('leavepictureinPicture', handlePiPChange);
            }
        };
    }, []);

    return (
        <>
            <video ref={videoRef} width={width} height={height} controls>
                <source src={videoSrc} type='video/mp4' />
                Your browser does not support the video tag.
            </video>
        </>
    );
};

export default VideoPlayer;
