import { gql, useMutation } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SlideListContext } from 'pages/lessons/contexts/LessonsSlideContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';
import { useUserPermissionContext } from '../../../../../../contexts/UserPermissionContext';
import { UpgradePlanPopup } from '../../../../../../routes/UpgradePlanPopup';

const UPLOAD_GQL = gql`
    mutation uploadImage($slide: ID!, $file: Upload!) {
        createSlideImage(data: { file: { upload: $file }, slides: { connect: [{ id: $slide }] } }) {
            id
            file {
                url
            }
        }
    }
`;

const resizeFile = (file: File) =>
    new Promise((resolve) => {
        const fileType = file.type.split('/')[1].toUpperCase();
        // Get the file type (e.g., 'jpeg', 'png')

        const outputFormat = fileType === 'JPEG' ? 'JPEG' : 'PNG';
        // Set output format based on input file type

        Resizer.imageFileResizer(
            file,
            1024, // todo maxWidth, move to env file
            1024, // todo maxHeight, move to env file
            outputFormat,
            60, // todo quality, move to env file
            0,
            (uri) => {
                resolve(uri);
            },
            'file',
        );
    });

type ImageUploadProps = {
    onSelected: (imageURL: string) => void;
    uploadedImageURL: string;
};

export const ImageUpload = (props: ImageUploadProps) => {
    const { t } = useTranslation();
    const { selectedSlideIndex, lessonData } = useContext(SlideListContext);
    const [uploadFile] = useMutation(UPLOAD_GQL);

    const [isLoading, setIsLoading] = useState(false);

    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const { userPermissions } = useUserPermissionContext();
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const handleImageAction = (triggerUpload: () => void) => {
        if (!userPermissions?.enableUploadImage) {
            setShowUpgradePopup(true);
        } else {
            triggerUpload();
        }
    };

    const uploadFileCallback = async (imageFile: File) => {
        try {
            // todo delete unused image later

            let res = await uploadFile({
                variables: {
                    slide: lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id,
                    file: imageFile,
                },
            });

            props.onSelected(res.data.createSlideImage.file.url);
            setImages([]);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const onChange: any = async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        if (!userPermissions?.enableUploadImage) {
            setShowUpgradePopup(true);
            return;
        }
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList as never[]);

        setIsLoading(true);
        if (imageList.length > 0) {
            const newFile = await resizeFile(imageList[imageList.length - 1].file as File);
            uploadFileCallback(newFile as File);
        }
    };

    if (isLoading) {
        return <Typography>Uploading...</Typography>;
    }

    return (
        <>
            {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='featureNotIncluded' />}
            <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey='data_url'>
                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    // write your building UI
                    <Box className='upload__image-wrapper' sx={{ height: '100%' }}>
                        <CancelButton
                            // onClick={onImageUpload}
                            onClick={() => handleImageAction(onImageUpload)}
                            sx={{
                                flex: 1,
                                border: '1px dashed #F06E3C',
                                width: '100%',
                                height: '100%',
                            }}
                            style={isDragging ? { color: 'red' } : undefined}
                            {...dragProps}>
                            <Stack direction='column' spacing={1}>
                                {t('uploadImage.drag-to-upload-image')}
                            </Stack>
                        </CancelButton>
                    </Box>
                )}
            </ImageUploading>
        </>
    );
};
