import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CalendarData, TimeSlotDialogState } from './types/timeSlotTypes';
import useTimeSlotMutation from './hooks/useTimeSlotMutation';
import { CalendarAddUI } from './components/CalendarAddUI';

export const CalendarAdd = ({
    currentCalendarState,
    setCurrentCalendarState,
}: {
    currentCalendarState: TimeSlotDialogState;
    setCurrentCalendarState: Dispatch<SetStateAction<TimeSlotDialogState>>;
}) => {
    const [lessonName, setLessonName] = useState('');
    const [loading, setLoading] = useState(false);
    const { formik, handleDelete, classesQueryData, refetchTestroom } = useTimeSlotMutation((data, action) => {
        handleClose();

        if (currentCalendarState.calendarData.calendarAPI) {
            currentCalendarState.calendarData.calendarAPI.unselect(); // clear date selection
            if (action === 'update' || action === 'delete') {
                currentCalendarState.calendarData.calendarAPI.getEventById(data.id)?.remove();
            }
            if (action === 'create' || action === 'update') {
                currentCalendarState.calendarData.calendarAPI.addEvent({
                    id: data.id,
                    title: data.testroomName,
                    start: data.startTime,
                    end: data.endTime,
                    allDay: false,
                });
            }
        }
    });

    const handleClose = () => {
        setCurrentCalendarState({
            isDialogOpen: false,
            calendarData: {} as CalendarData,
        });
    };

    useEffect(() => {
        const fetechData = async () => {
            if (currentCalendarState.isDialogOpen && currentCalendarState.calendarData.data) {
                const isNewData = currentCalendarState.calendarData.data.id === '';
                if (isNewData) {
                    handleClose();
                    return;
                }

                let { startTime, endTime } = currentCalendarState.calendarData.data;
                formik.resetForm();
                // if (isNewData) {

                //   endTime.setDate(endTime.getDate() - 1);
                //   if (startTime.toString() === endTime.toString()) {
                //     endTime.setTime(endTime.getTime() + 60 * 60 * 2 * 1000);
                //   }
                // } else {
                setLoading(true);
                let data = await refetchTestroom({
                    id: currentCalendarState.calendarData.data.id,
                });
                setLoading(false);
                formik.setFieldValue('id', currentCalendarState.calendarData.data.id);
                formik.setFieldValue('name', data.data.testroom.testroomName);
                formik.setFieldValue('otp', data.data.testroom.otp);
                formik.setFieldValue('passlock', data.data.testroom.passlock);
                formik.setFieldValue('offlineMode', data.data.testroom.offlineMode);
                formik.setFieldValue('writingHints', data.data.testroom.writingHints);
                formik.setFieldValue('freeStyleMode', data.data.testroom.freeStyleMode);
                formik.setFieldValue('lessonVersion', data.data.testroom.lessonVersion.id);
                formik.setFieldValue('class', data.data.testroom.class.id);
                // }

                formik.setFieldValue('startTime', startTime);
                formik.setFieldValue('endTime', endTime);

                setLessonName(data.data.testroom.lessonVersion.lessonName);
            }
        };
        fetechData();
    }, [currentCalendarState.isDialogOpen]);

    return (
        <CalendarAddUI
            currentCalendarState={currentCalendarState}
            formik={formik}
            handleDelete={handleDelete}
            lessonName={lessonName}
            classesQueryData={classesQueryData}
            handleClose={handleClose}
            loading={loading}
            setLoading={setLoading}
        />
    );
};
