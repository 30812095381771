import { LoadingButton } from '@mui/lab';
import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { useNavigate, useOutletContext, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { BootstrapStyleInput } from 'components/BootstrapStyleInput';
import { ResetPasswordRules } from 'pages/onBoarding/utils';
import { OnBoardingPageContext } from 'pages/onBoarding/models';
import { RemoveRedEye } from '@mui/icons-material';
import { useEffect } from 'react';
import { ResetSuccessPath } from 'routes/utils';
// import { RESET_PASSWORD } from 'pages/onBoarding/queries';
import { useMutation, gql } from '@apollo/client';

const REDEEM_USER_PASSWORD_RESET_TOKEN = gql`
    mutation RedeemUserPasswordResetToken($email: String!, $token: String!, $password: String!) {
        redeemUserPasswordResetToken(email: $email, token: $token, password: $password) {
            code
            message
        }
    }
`;

export const SetNewPasswordPage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    const navigate = useNavigate();
    // let { token } = useParams();
    const { t } = useTranslation();
    const { setErrorMessage, setIsLoading } = useOutletContext<OnBoardingPageContext>();

    const [redeemUserPasswordResetToken, { loading, error }] = useMutation(REDEEM_USER_PASSWORD_RESET_TOKEN);

    const handleSubmit = async ({ password }: { password: string }) => {
        try {
            // console.log('email', email);
            // console.log('token', token);
            // console.log('password', password);
            const { data } = await redeemUserPasswordResetToken({
                variables: { email, token, password },
            });
            // console.log(data);

            if (data?.redeemUserPasswordResetToken?.code == null) {
                navigate(`../${ResetSuccessPath}`);
            } else {
                setErrorMessage(data?.redeemUserPasswordResetToken?.message || 'Unexpected error');
            }
        } catch (error: any) {
            setErrorMessage(error?.message);
        }
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setErrorMessage(error?.message ? error?.message : '');
    }, [error]);

    useEffect(() => {
        console.log(token);
        console.log(email);
    }, [token, email]);

    return (
        <Formik
            initialValues={{
                password: '',
                confirmPassword: '',
            }}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
            validationSchema={ResetPasswordRules}>
            {({ errors, handleChange }) => (
                <Form>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            minWidth: 360,
                        }}>
                        <Box
                            sx={{
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '24px',
                                textAlign: 'center',
                                color: '#858585',
                            }}>
                            <Typography>{`${t('newPasswordMustBeDifferent')}`}</Typography>
                            {/* <Typography>{`${t('clickBelowToLogIn')}.`}</Typography> */}
                        </Box>
                        <Box>
                            <FormControl variant='standard' fullWidth>
                                <InputLabel
                                    shrink
                                    htmlFor='password'
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#533D18',
                                    }}>
                                    {`${t('password')}*`}
                                </InputLabel>
                                <BootstrapStyleInput
                                    type='password'
                                    id='password'
                                    name='password'
                                    onChange={handleChange}
                                    fullWidth
                                    inputProps={{
                                        endAdornment: (
                                            // <InputAdornment position='end'>
                                            <RemoveRedEye />
                                            // </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('enterPassword')}
                                />
                                <FormHelperText
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#858585',
                                    }}>
                                    {t('must8Characters')}
                                </FormHelperText>
                                {errors?.password && <Box sx={{ color: 'red' }}>{errors.password ? <div>{errors.password}</div> : null}</Box>}
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl variant='standard' fullWidth>
                                <InputLabel
                                    shrink
                                    htmlFor='password'
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#533D18',
                                    }}>
                                    {`${t('password')}*`}
                                </InputLabel>
                                <BootstrapStyleInput
                                    type='password'
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    onChange={handleChange}
                                    fullWidth
                                    inputProps={{
                                        endAdornment: (
                                            // <InputAdornment position='end'>
                                            <RemoveRedEye />
                                            // </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('enterPassword')}
                                />
                                {errors?.confirmPassword && <Box sx={{ color: 'red' }}>{errors.confirmPassword ? <div>{errors.confirmPassword}</div> : null}</Box>}
                            </FormControl>
                        </Box>

                        <Box>
                            <LoadingButton
                                variant='contained'
                                disableElevation
                                fullWidth
                                size='large'
                                color='primary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '12px',
                                    background: '#F06E3C',
                                    boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    fontSize: '18px',
                                    color: '#FDFCFC',
                                }}
                                type='submit'>
                                {t('resetPassword')}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
