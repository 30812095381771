import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TestroomTemplate } from '../testroom/TestroomTemplate';
import useLessonRefresh from 'pages/lessons/hooks/common/useLessonRefresh';

export const PreviewPage = () => {
    const { t } = useTranslation();
    let { id: lessonID } = useParams();

    const { refreshLesson, lessonData } = useLessonRefresh();

    useEffect(() => {
        refreshLesson(lessonID ?? '');
    }, []);

    return (
        <>
            <TestroomTemplate lessonData={lessonData!} preview={true} />
        </>
    );
};
