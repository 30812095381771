import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TestroomTemplate } from './TestroomTemplate';
import useTestroom from './hooks/useTestroom';
import useLessonRefresh from 'pages/lessons/hooks/common/useLessonRefresh';
import { ClassroomContextProvider } from './contexts/ClassroomContextProvider';

export const TestroomPage = () => {
    let { otp } = useParams();
    const { refreshLessonVersion, lessonData } = useLessonRefresh();
    const { enterTestroom, testroomData, updateTestroomScreenSharing, updateTestroomWritingHints, updateTestroomFreeStyleMode } = useTestroom();
    const [canAccessLesson, setCanAccessLesson] = useState(false);

    useEffect(() => {
        (async () => {
            if (otp && otp !== '') {
                const canEnter = await enterTestroom(otp);
                setCanAccessLesson(canEnter);
            }
        })();
    }, [otp]);

    useEffect(() => {
        if (testroomData && testroomData.lessonVersion && testroomData.lessonVersion.id !== '') {
            refreshLessonVersion(testroomData.lessonVersion.id);
        }
    }, [testroomData]);

    return (
        <>
            {!testroomData.offlineMode && canAccessLesson && (
                <ClassroomContextProvider testroomData={testroomData}>
                    <TestroomTemplate
                        testroomData={testroomData}
                        updateScreenSharing={updateTestroomScreenSharing}
                        updateWritingHints={updateTestroomWritingHints}
                        updateFreeStyleMode={updateTestroomFreeStyleMode}
                        lessonData={lessonData}
                        preview={false}
                    />
                </ClassroomContextProvider>
            )}
        </>
    );
};
