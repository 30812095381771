import { Stack, Box, ToggleButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentHkcsEnQ2 } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useEffect, useState } from 'react';
import FlipIcon from '@mui/icons-material/Flip';
import RecorderControls from 'components/voiceRecorder/RecorderControls';
import useRecorder from 'components/voiceRecorder/hooks/use-recorder';
import { RecordingsList } from 'components/voiceRecorder/RecordingsList';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

type ComponentProps = {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
    isCreate?: boolean;
};

export const LessonsSlidePropsHkcsEnQ2 = (props: ComponentProps) => {
    const { t } = useTranslation();
    const { slideComponentHandler, uploadFileOnStopCallback, deleteAudio } = useLessonSlideProps();

    const { recorderState, setOnStopCallback, handleFileUpload, ...handlers } = useRecorder();

    let itemContent: SlideComponentHkcsEnQ2 = props.item.content as SlideComponentHkcsEnQ2;

    const updateUI = (save: boolean) => {
        if (props.handleCreate) {
            props.handleCreate(props.item);
        } else {
            slideComponentHandler({ ...props.item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        props.setCanSave?.(
            (itemContent.image !== '' || itemContent.imageURL !== '') && itemContent?.phrase?.trim() !== '' && itemContent?.sampleSentence?.trim() !== '' && itemContent?.audio?.length > 0,
        );
    }, [props.item, itemContent?.audio]);

    setOnStopCallback(async (voFile: File) => {
        await uploadFileOnStopCallback(voFile, props.item);
    });

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px', mb: 1 }}>
                        <SelectImageBox
                            keepMargin={true}
                            imageURL={itemContent.imageURL ?? ''}
                            image={itemContent.image ?? ''}
                            flip={itemContent.flip}
                            onSelected={(imageURL: string) => {
                                itemContent.imageURL = imageURL;
                                updateUI(true);
                            }}
                            handleDelete={() => {
                                itemContent.imageURL = '';
                                itemContent.image = '';
                                updateUI(true);
                            }}
                            onClickHandler={(option: OptionsType) => {
                                itemContent.image = option?.value ?? '';
                                updateUI(true);
                            }}
                        />
                        <ToggleButton
                            value='1'
                            selected={itemContent.flip === -1}
                            onChange={() => {
                                if (itemContent.flip === -1) {
                                    itemContent.flip = 1;
                                } else {
                                    itemContent.flip = -1;
                                }
                                updateUI(true);
                            }}
                            aria-label='bold'
                            sx={{
                                width: '100%',
                                mt: 1,
                                borderRadius: '12px',
                                fontWeight: '700',
                                fontSize: '18px',
                                lineHeight: '150%',
                                color: '#FDFCFC',
                                backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                '&:hover': {
                                    backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                },
                            }}>
                            <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                        </ToggleButton>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <TextField
                            label={t('hkcs.en.q2.phrase')}
                            type='text'
                            sx={{ width: '100%', mb: 1 }}
                            value={itemContent.phrase}
                            onChange={(e) => {
                                itemContent.phrase = e.target.value;
                                updateUI(false);
                                debouncedSaveHandler(() => {
                                    updateUI(true);
                                });
                            }}
                        />

                        <RecorderControls isCreate={props.isCreate} recorderState={recorderState} handlers={{ ...handlers, handleFileUpload }} />
                        <RecordingsList item={props.item} index={props.index} audio={itemContent.audio} deleteAudio={deleteAudio} deleteSlideItem={() => {}} />
                    </Box>
                    <TextField
                        label={t('hkcs.en.q2.enterSampleSentence')}
                        type='text'
                        sx={{ width: '100%', mb: 1 }}
                        value={itemContent.sampleSentence}
                        onChange={(e) => {
                            itemContent.sampleSentence = e.target.value;
                            updateUI(false);
                            debouncedSaveHandler(() => {
                                updateUI(true);
                            });
                        }}
                    />
                </Box>
            </Stack>
        </>
    );
};
