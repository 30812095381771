import { useEffect, useState, useRef } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// import QRCode from 'qrcode.react';
import { QRCode } from 'react-qrcode-logo';
import * as htmlToImage from 'html-to-image';
import { Box, Typography } from '@mui/material';

export const QrCodeComponent = ({ initValues, increaseLoadingCount }: { initValues: any; increaseLoadingCount: any }) => {
    const [qrData, setQrData] = useState('');
    const [qrPic, setQrPic] = useState('');
    const qrContainerRef = useRef(null);

    useEffect(() => {
        let qrData;
        if (initValues.password !== '') {
            qrData = JSON.stringify({
                email: initValues.email,
                password: initValues.passwordAES,
            });
        }
        if (qrData) {
            setQrData(qrData);
        }
    }, [initValues]);

    useEffect(() => {
        const ToImage = async () => {
            setTimeout(async () => {
                if (qrContainerRef.current) {
                    const dataUrl = await htmlToImage.toPng(qrContainerRef.current);
                    setQrPic(dataUrl);
                }
                increaseLoadingCount();
            }, 800);
        };
        ToImage();
    }, [initValues]);

    return (
        <Box
            border={1}
            height={500} // Set the height as per your requirement
            borderColor='white'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'>
            <div
                ref={qrContainerRef}
                style={{
                    position: 'absolute',
                    top: '1px',
                    left: '-1px',
                    width: 300,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid black',
                    borderRadius: '4px',
                    fontSize: '2rem',
                    padding: '2px',
                    margin: '0',
                    textAlign: 'center',
                    zIndex: '-1',
                    backgroundColor: 'white',
                }}>
                <span
                    style={{
                        width: '150px',
                        wordWrap: 'break-word',
                        textAlign: 'center',
                        color: 'black',
                    }}>
                    {initValues.studentProfile.familyName + ' ' + initValues.studentProfile.givenName}
                </span>
            </div>
            <QRCode ecLevel='H' value={qrData} logoImage={qrPic} logoHeight={80} logoWidth={80} size={300} fgColor={'#000000'} />
            <Typography textAlign='center'>{initValues.studentProfile.class?.school?.schoolName ?? ''}</Typography>
            <Typography textAlign='center'>
                {initValues.studentProfile?.class?.className ?? ''} ,{initValues.studentProfile.studentNumber ?? ''}
            </Typography>
        </Box>
    );
};
