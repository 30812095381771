import { useContext } from 'react';
import { useLessonQuery } from 'models/lesson/useLessonQuery';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { deepCopy } from 'utils/function';
import { SlideComponent, SlideModel } from 'models/lesson/LessonModel';
import { useLessonCreateMutation } from 'models/lesson/useLessonCreateMutation';
import { useTranslation } from 'react-i18next';
import { useLessonVersionQuery } from 'models/lesson/useLessonVersionQuery';
import { SlideListContext } from 'pages/lessons/contexts/LessonsSlideContext';

export default function useLessonRefresh() {
    const { t } = useTranslation();
    const { lessonData, dispatchLessonData, selectedSlideIndex } = useContext(SlideListContext);

    const { getLesson, loading: lessonLoading, data } = useLessonQuery();
    const { createLesson } = useLessonCreateMutation();
    const { getLessonVersion, loading: lessonVersionLoading } = useLessonVersionQuery();

    const {} = useAuthChecking(data);

    const refreshOrCreateLesson = (lessonID: string) =>
        new Promise(async (resolve, reject) => {
            if (lessonID && lessonID !== '+') {
                refreshLesson(lessonID);
            } else {
                try {
                    let res = await createLesson({
                        lessonName: t('default-title'),
                    });

                    resolve(res?.createLesson?.id);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            }
        });

    const refreshLesson = async (lessonID: string) => {
        if (lessonID && lessonID !== '+') {
            // check session expiry
            try {
                let res = await getLesson({ lessonID: lessonID });
                if (res) {
                    // const isOwner = userData?.id === res?.lesson?.teacher?.user?.id;

                    // parse json to object
                    let newLesson = deepCopy(res.lesson);

                    // if (!isOwner) {
                    // if the current user is not the owner, show the latest published version for perview
                    // newLesson.latestLessonDraft = newLesson.latestLessonDraft;
                    // }

                    newLesson.latestLessonDraft.slides = newLesson.latestLessonDraft?.slides.map((item: SlideModel) => {
                        return {
                            ...item,
                            data: !Array.isArray(item.data) ? (typeof item.data === 'string' ? JSON.parse(item.data) : []) : item.data,
                        };
                    });
                    dispatchLessonData({
                        type: 'SetLesson',
                        payload: newLesson,
                    });
                    dispatchLessonData({
                        type: 'SetSlides',
                        payload: {
                            slideIndex: selectedSlideIndex,
                            data: sortSlides(newLesson.latestLessonDraft?.slides),
                        },
                    });
                }
            } catch (err) {}
        }
    };

    const refreshLessonVersion = async (lessonVersionID: string) => {
        if (lessonVersionID && lessonVersionID !== '+') {
            try {
                let res = await getLessonVersion({
                    lessonVersionID: lessonVersionID,
                });

                if (res) {
                    let newLesson = {
                        ...res?.lessonVersion?.parent,
                    };

                    // parse json to object
                    newLesson.latestLessonDraft = deepCopy(res.lessonVersion);

                    newLesson.latestLessonDraft.slides = newLesson.latestLessonDraft?.slides.map((item: SlideModel) => {
                        return {
                            ...item,
                            data: !Array.isArray(item.data) ? (typeof item.data === 'string' ? JSON.parse(item.data) : []) : item.data,
                        };
                    });

                    dispatchLessonData({
                        type: 'SetLesson',
                        payload: newLesson,
                    });
                    dispatchLessonData({
                        type: 'SetSlides',
                        payload: {
                            slideIndex: selectedSlideIndex,
                            data: sortSlides(newLesson.latestLessonDraft?.slides),
                        },
                    });
                }
            } catch (err) {
                console.log('error:', err);
            }
        }
    };

    const sortSlides = (slides: SlideModel[]): SlideModel[] => {
        let result = [...slides];
        return result.sort((a, b) => a.sort - b.sort);
    };

    return {
        refreshOrCreateLesson,
        refreshLesson,
        refreshLessonVersion,
        loading: lessonLoading || lessonVersionLoading,
        lessonData,
    };
}
