import { useTranslation } from 'react-i18next';
import { PrivacyPolicyContent } from './PrivacyPolicyContent';
import TermsDialog from './TermsDialog';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return <TermsDialog label={t('signup-card-privacy-label')} buttonTitle={t('signup-card-privacy-button-title')} dialogTitle={t('privacy-policy-card-title')} content={<PrivacyPolicyContent />} />;
};

export default PrivacyPolicy;
