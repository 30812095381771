import { useState, useEffect } from 'react';
import { AudioAnswerModel } from 'pages/results/models/audioAnswer/AudioAnswerModel';

export function useAudioResultAnswer(audioAnswerState: AudioAnswerModel) {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(audioAnswerState.audio);
    const [playingIdx, setPlayingIdx] = useState(audioAnswerState.playingIdx);
    const [isPlaying, setIsPlaying] = useState(audioAnswerState.isPlaying);

    const handleAudioEnded = () => {
        console.log('Audio has finished playing.');
        setIsPlaying(false);
        setPlayingIdx(-1);
    };

    const playSound = (audioUrl: string, index: number) => {
        console.log(index);
        console.log(isPlaying);

        if (index === playingIdx) {
            if (audio) {
                if (audio?.paused) {
                    audio
                        .play()
                        .then(() => {
                            setIsPlaying(true);
                            setPlayingIdx(index);
                        })
                        .catch((error) => {
                            console.error('Error playing audio:', error);
                        });
                } else {
                    audio.pause();
                    setIsPlaying(false);
                    setPlayingIdx(index);
                }
            }
            return;
        }

        if (audio) {
            // If there's an existing audio element, pause it and reset its time to 0
            audio.pause();
            audio.currentTime = 0;
            audio.removeEventListener('ended', handleAudioEnded);
        }

        const newAudio = new Audio(audioUrl);
        newAudio.addEventListener('ended', handleAudioEnded);

        newAudio
            .play()
            .then(() => {
                setPlayingIdx(index);
                setIsPlaying(true);
                setAudio(newAudio);
            })
            .catch((error) => {
                console.error('Error playing audio:', error);
            });
    };

    useEffect(() => {
        if (audioAnswerState.audio) {
            audioAnswerState.audio.addEventListener('ended', handleAudioEnded);

            // Clean up the event listener when the component unmounts
            return () => {
                audioAnswerState.audio?.removeEventListener('ended', handleAudioEnded);
            };
        }
    }, [audioAnswerState.audio]);

    return {
        playingIdx,
        isPlaying,
        audio,
        playSound,
    };
}
