import React, { useEffect, useState, useContext } from 'react';
import { Box, Stack, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import useSubscriptionHook from '../../profile/hooks/useSubscriptionHook';

type InvoicePreviewProps = {
    upgradePlanName: string;
};

export const InvoicePreview: React.FC<InvoicePreviewProps> = ({ upgradePlanName }) => {
    const { t } = useTranslation();
    const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [confirmButtonLoading, setConfirmButtonLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const auth = useContext(UserAuthContext);
    const {
        fetchInvoiceDetails,
        fetchCustomerDetails,
        createCustomer,
        updateSubscriptionHistory,
        fetchSubscriptionDetails,
        fetchProductDetails,
        fetchPriceDifference,
        handleCheckout,
        handleChangeSubscription,
        fetchUnusedCredits,
    } = useSubscriptionHook(setLoading, setError);

    // useEffect(() => {
    //   setLoading(true);
    // });
    useEffect(() => {
        const processCustomerData = async () => {
            try {
                let customerData = await fetchCustomerDetails();
                let customerId;

                if (!customerData) {
                    customerId = await createAndSetupNewCustomer();
                    console.log('CustomerId created', customerId);
                } else {
                    customerId = customerData.id;
                    await updateSubscriptionHistory(customerId, true);
                    console.log('CustomerId already exists:', customerId);
                }

                setInvoiceDetails({
                    ...invoiceDetails,
                    customerId: customerId,
                });

                const subscriptionDetails = await fetchSubscriptionDetails();
                console.log('processCustomerData: subscriptionDetails:', subscriptionDetails);
                const newPlanDetails = await fetchProductDetails(upgradePlanName);
                console.log('processCustomerData: newPlanDetails:', newPlanDetails);

                const priceDifference = await fetchPriceDifference(upgradePlanName);
                console.log('processCustomerData: priceDifference:', priceDifference);

                const unusedCredits = await fetchUnusedCredits();
                console.log('unusedCredits:', unusedCredits);
                const expiryDate = subscriptionDetails?.current_period_end;
                console.log('Subscription Details:', subscriptionDetails);
                console.log('newPlanDetails:', newPlanDetails);
                console.log('priceDifference:', priceDifference);
                setInvoiceDetails({
                    ...invoiceDetails,
                    amount_due: priceDifference / 100,
                    amount_current: subscriptionDetails?.items?.data?.[0].plan.amount / 100 || 'N/A',
                    amount_new: newPlanDetails.unit_amount / 100,
                    expiry_date: expiryDate,
                    unused_credits: unusedCredits / 100,
                });
                setLoading(false);
                // await processSubscription(customerId);
            } catch (error) {
                handleErrors(error);
            } finally {
                setLoading(false);
            }
        };

        const createAndSetupNewCustomer = async () => {
            console.log('Stripe customer does not exist, creating one...');
            const newCustomer = await createCustomer();
            console.log('Stripe customer created:', newCustomer);

            const customerId = newCustomer.customer.id;
            await updateSubscriptionHistory(customerId, false);
            console.log('Subscription history updated for customer:', customerId);

            // Fetch updated customer details
            const updatedCustomer = await fetchCustomerDetails();
            return updatedCustomer.id;
        };

        const handleErrors = (error: any) => {
            console.error('Error:', error.message);
            // Additional error handling logic here
        };

        processCustomerData();
    }, [upgradePlanName]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color='error'>{error}</Typography>;
    }

    const processSubscription = async () => {
        setConfirmButtonLoading(true);
        const subscriptionDetails = await fetchSubscriptionDetails();
        console.log('Subscription Details:', subscriptionDetails);

        if (subscriptionDetails) {
            const result = await handleChangeSubscription(upgradePlanName);
            // console.log(result);

            if (!result?.url) {
                // if no redirect url coming back, then reload window to refresh data
                window.location.reload();
            }
        } else {
            handleCheckout(upgradePlanName);
        }
    };

    const handleConfirm = () => {
        processSubscription();
    };

    return (
        <Box>
            {invoiceDetails ? (
                <Stack spacing={2} alignItems='center' mt={2}>
                    <Typography variant='h6'>{t('invoice.upgrade-to', { plan: t(upgradePlanName) })}</Typography>
                    <Typography variant='body1'>
                        {t('invoice.expiry-date', {
                            date: invoiceDetails.expiry_date ? new Date(invoiceDetails.expiry_date * 1000).toDateString() : 'N/A',
                        })}
                    </Typography>
                    <Typography variant='body1'>
                        {t('invoice.amount-current', {
                            amount: invoiceDetails.amount_current,
                        })}
                    </Typography>
                    <Typography variant='body1'>
                        {t('invoice.unused-credits', {
                            amount: invoiceDetails.unused_credits,
                        })}
                    </Typography>
                    <Typography variant='body1'>
                        {t('invoice.amount-new', {
                            amount: invoiceDetails.amount_new,
                        })}
                    </Typography>
                    <Typography variant='body1'>
                        {t('invoice.amount-due', {
                            amount: invoiceDetails.amount_due,
                        })}
                    </Typography>

                    <Typography variant='body1' color={'error'}>
                        {invoiceDetails.amount_due <= 0 ? <>{t('invoice.no-refund-warning')}</> : <>{t('invoice.effecitve-immediate-warning')}</>}
                    </Typography>
                    {/* <Button onClick={() => {}}>{t('invoice.cancel')}</Button> */}
                    <Button onClick={handleConfirm}>{confirmButtonLoading ? <CircularProgress /> : t('invoice.confirm')}</Button>
                </Stack>
            ) : loading ? (
                <Typography>{t('subscription.no-invoice-details')}</Typography>
            ) : (
                <></>
            )}
        </Box>
    );
};
