import { UseStudentInfoQuery } from 'models/resultPage/useStudentResult';
import { useTestRoomResultQuery } from 'models/resultPage/useTestRoomResultQuery';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ResultsPageContext } from '../models';
import { OverallResultByQuestion } from 'models/resultPage/ResultPageModel';
import { useQuestionTableResult } from './useQuestionTableResult';
import { useHardQuestionHook } from './useHardQuestionHook';
import { useSpecificQuestionStudentResultHook } from './useSpecificQuestionStudentResultHook';
import { useStudentTableHook } from './useStudentTableHook';
import { useQuestionType } from './useQuestionType';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useTestroomResult = () => {
    const { getStudentData, studentLoading } = UseStudentInfoQuery();
    const { getTestRoomsResultData, resultsLoading } = useTestRoomResultQuery();
    const { selectedTestRoom } = useOutletContext<ResultsPageContext>();
    const [resultByQuestion, setResultByQuestion] = useState<OverallResultByQuestion[]>([]);

    const [allStudentsAverageAnswerTime, setAllStudentsAverageAnswerTime] = useState(0);
    const {
        prepareStudentTable,
        studentTableData,
        numberOfQuestion,
        numberOfAttempt,
        failedCompeteStudents,
        absentStudents,
        allStudentsAverageScore,
        allStudentsAnswerTime,
        setStudentTableData,
        studentDataWithResult,
        badPerformanceStudents,
        sortedStudentData,
    } = useStudentTableHook(selectedTestRoom);
    const { getQuestionTable, prepareQuestionTable, questionTableData } = useQuestionTableResult();
    const { prepareStudentQuestionTable, questionTableFromEachStudentData } = useSpecificQuestionStudentResultHook(resultByQuestion);
    const { getHardQuestions, hardQuestions } = useHardQuestionHook();
    const { getQuestionTypeResult, questionTypeResult } = useQuestionType();
    const [loadingOverallResultPage, setLoadingOverallResultPage] = useState(false);
    // const [loadingOverallResultPage, setLoadingOverallResultPage] =
    //   useState(false);
    useEffect(() => {
        if (selectedTestRoom) {
            prepareStudentTableCoroutine();
        }
    }, [selectedTestRoom]);
    const prepareStudentTableCoroutine = async () => {
        setLoadingOverallResultPage(true);
        await prepareStudentTable();
        setLoadingOverallResultPage(false);
    };
    useEffect(() => {
        if (studentDataWithResult) {
            const questionResultPerStudents = getQuestionTable(selectedTestRoom, studentDataWithResult);
            prepareQuestionTable(questionResultPerStudents);
            setResultByQuestion(questionResultPerStudents);
            getHardQuestions(numberOfQuestion, questionResultPerStudents, absentStudents.length);
            getQuestionTypeResult(questionResultPerStudents, absentStudents.length);
        }
    }, [studentDataWithResult, numberOfQuestion, absentStudents]);

    useEffect(() => {
        if (numberOfAttempt > 0) {
            setAllStudentsAverageAnswerTime(allStudentsAnswerTime / numberOfAttempt);
        }
    }, [allStudentsAnswerTime, numberOfAttempt]);

    return {
        prepareStudentTable,
        studentTableData,
        allStudentsAnswerTime,
        setStudentTableData,
        allStudentsAverageScore,
        studentLoading,
        allStudentsAverageAnswerTime,
        resultsLoading,
        numberOfQuestion,
        absentStudents,
        badPerformanceStudents,
        failedCompeteStudents: failedCompeteStudents.filter((x) => !absentStudents.includes(x)),
        resultByQuestion,
        hardQuestions,
        questionTableData,
        prepareStudentQuestionTable,
        questionTableFromEachStudentData,
        questionTypeResult,
        loadingOverallResultPage,
        sortedStudentData,
    };
};
