/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { useState } from 'react';
import { LoginStatusBar } from 'components/LoginStatusBar';
import { ResultStepper, ResultStep, initStep } from 'components/ResultStepper';
import { RouteStepper, Step } from 'components/RouteStepper';
import styled from '@emotion/styled';
import { TestroomModel } from 'pages/testroom/models/TestroomModel';
import { ClassModel } from '../../models/class/ClassModel';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClassOverallResultPath, GradingPath, OverallResultPath, ResultPath, SelectClassPath, SelectQuestionPath, SelectStudentPath, SelectTestroomPath } from 'routes/utils';
import { ClassQueryModel, Results, StudentProfileWithResults, StudentQueryModel, TestroomQueryModel, TestroomResults } from './models';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_CLASS, GET_RESULTS_BY_TESTROOM_AND_STUDENT, GET_STUDENT, GET_TESTROOM, GET_TESTROOMS, UPDATE_RESULTS } from './queries';
import ResultPageTabs from './component/ResultPageTab/ResultPageTabs';
import TestRoomSelector from './component/OverallResultPage/TestRoomSelector';
import { TestroomsWithQuestionsQueryModel } from 'queryModels/TestroomsQueryModel';
import { ResultPageProp } from './component/ResultPageTab/ResultPageTab';

export const ResultsPage = () => {
    const { userData, selectedSchool } = useContext(UserAuthContext);
    const { classId, testroomId, studentId, questionIndex } = useParams();
    const navigator = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [selectedTestRoom, setSelectedTestRoom] = useState<Partial<TestroomModel>>();
    const [selectedClass, setSelectedClass] = useState<ClassModel>();
    const [selectedStudent, setSelectedStudent] = useState<StudentProfileWithResults>();
    const [studentResults, setStudentResults] = useState<Results[]>();
    const [studentsResults, setStudentsResults] = useState<Results[]>();
    const [studentGrading, setStudentGrading] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState<any>();
    const [gradedItems, setGradedItems] = useState<any>([]);
    const defaultStepperData = useMemo(
        () => [
            {
                text: t('menu-classes'),
                href: `${SelectClassPath}/${selectedSchool?.id}`,
            },
            {
                text: t('testroom'),
                href: `${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${selectedClass?.id}`,
            },
            {
                text: t('menu-students'),
                href: `${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${selectedClass?.id}/${SelectStudentPath}/${selectedTestRoom?.id}`,
            },
            {
                text: t('question'),
                href: `${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${selectedClass?.id}/${SelectStudentPath}/${selectedTestRoom?.id}/${SelectQuestionPath}/${selectedStudent?.id}`,
            },
            {
                text: t('answer'),
                href: `${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${selectedClass?.id}/${SelectStudentPath}/${selectedTestRoom?.id}/${SelectQuestionPath}/${selectedStudent?.id}/${GradingPath}/${questionIndex}`,
            },
        ],
        [selectedSchool, selectedClass, selectedTestRoom, selectedStudent, selectedQuestion],
    );
    const [stepper, setStepper] = useState<Step[]>(defaultStepperData.slice(0, 1));
    const [resultStep, setResultStep] = useState<ResultStep>(initStep);
    const [updateResults, { loading, error }] = useMutation(UPDATE_RESULTS);
    const [open, setOpen] = useState(false);
    const resultPageTabProp: ResultPageProp[] = [
        {
            label: t('resultPage.overview'),
            navigate: () => {
                navigator(`../${ResultPath}/${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${classId}/${OverallResultPath}/${testroomId}`);
            },
        },
        {
            label: t('resultPage.student'),
            navigate: () => {
                navigator(`../${ResultPath}/${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${classId}/${SelectStudentPath}/${testroomId}`);
            },
        },
        {
            label: t('resultPage.question'),
            navigate: () => {
                navigator(`../${ResultPath}/${SelectClassPath}/${selectedSchool?.id}/${SelectTestroomPath}/${classId}/${SelectQuestionPath}/${testroomId}`);
            },
        },
    ];
    const { data: testRoomsData, loading: testRoomsDataLoading } = useQuery<TestroomsWithQuestionsQueryModel>(GET_TESTROOMS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            teacher: { equals: userData?.teacherProfileId },
            class: { equals: classId },
        },
        skip: !userData || !classId,
    });
    const { data: classData, loading: classLoading } = useQuery<ClassQueryModel>(GET_CLASS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            classId: classId,
        },
        skip: !userData || !!selectedClass || !classId,
    });

    const { data: testroomData, loading: testroomLoading } = useQuery<TestroomQueryModel>(GET_TESTROOM, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            testroomId: testroomId,
        },
        skip: !userData || !!selectedTestRoom || !testroomId,
    });

    const { data: studentData, loading: studentLoading } = useQuery<StudentQueryModel>(GET_STUDENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            studentId: studentId,
        },
        skip: !userData || !!selectedStudent || !studentId,
    });

    const [getStudentResults, { loading: resultsLoading }] = useLazyQuery<TestroomResults>(GET_RESULTS_BY_TESTROOM_AND_STUDENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            testroomId: { equals: testroomId },
            studentId: { equals: studentId },
        },
    });

    useEffect(() => {
        if (classId && classData) {
            setSelectedClass(classData.class);
        }
    }, [classData]);

    useEffect(() => {
        if (testroomId && testroomData) {
            setSelectedTestRoom(testroomData?.testroom);
        }
    }, [testroomData]);

    useEffect(() => {
        if (testroomId && studentId && studentData) {
            prepareStudentResults();
        }
    }, [studentData]);

    useEffect(() => {
        if (selectedSchool) {
            const path = location.pathname.includes(SelectTestroomPath) ? location : `${SelectClassPath}/${selectedSchool?.id}`;
            navigator(path);
        }
    }, [selectedSchool]);

    const prepareStudentResults = async () => {
        if (studentData?.studentProfile) {
            try {
                const results = await getStudentResults();
                const studentWithResults = {
                    ...studentData.studentProfile,
                    results: results.data?.results,
                };
                setSelectedStudent(studentWithResults);
            } catch (error) {
                console.log('prepareStudentResults', error);
            }
        }
    };

    const prepareClassesPage = () => {
        if (selectedSchool) {
            setResultStep((prevState) => ({
                ...prevState,
                schoolName: selectedSchool.schoolName,
                className: '',
                testroomName: '',
                studentName: '',
                score: '',
            }));
        }
        setStepper(defaultStepperData.slice(0, 1));
    };

    const prepareTestroomPage = () => {
        if (selectedClass) {
            setResultStep((prevState) => ({
                ...prevState,
                className: selectedClass.className,
                testroomName: '',
                studentName: '',
                score: '',
            }));
        }
        setStepper(defaultStepperData.slice(0, 2));
    };

    const prepareStudentPage = () => {
        if (selectedTestRoom?.testroomName) {
            setResultStep((prevState) => ({
                ...prevState,
                testroomName: selectedTestRoom?.testroomName as string,
                studentName: '',
                score: '',
            }));
        }
        setStepper(defaultStepperData.slice(0, 3));
    };

    const prepareQuestionPage = () => {
        if (selectedStudent) {
            setResultStep((prevState) => ({
                ...prevState,
                studentName: `${selectedStudent?.studentNumber}. ${selectedStudent?.familyName}${selectedStudent?.givenName}` as string,
                score: studentGrading,
            }));
        }
        setStepper(defaultStepperData.slice(0, 4));
    };
    const prepareGradingPage = () => {
        setStepper(defaultStepperData.slice(0, 5));
    };

    useEffect(() => {
        if (location.pathname.includes(GradingPath)) {
            prepareGradingPage();
        } else if (location.pathname.includes(SelectQuestionPath)) {
            prepareQuestionPage();
        } else if (location.pathname.includes(SelectStudentPath)) {
            prepareStudentPage();
        } else if (location.pathname.includes(SelectTestroomPath)) {
            prepareTestroomPage();
        } else if (location.pathname.includes(SelectClassPath)) {
            prepareClassesPage();
        }
    }, [location, selectedClass, selectedTestRoom, selectedStudent, selectedQuestion]);

    const onSaveGrading = async () => {
        const data = gradedItems
            ?.map((item: any) => {
                return item.items.map((result: any) => {
                    return {
                        where: { id: result.id },
                        data: { grading: item.grade },
                    };
                });
            })
            .flat();
        try {
            const result = await updateResults({
                variables: {
                    data: data,
                },
            });
            if (result.data) {
                handleClickOpen();
            }
        } catch (error) {
            console.log('error:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dialog = (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText sx={{ fontSize: '24px' }}>Grading Saved!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        navigator(SelectStudentPath);
                    }}>
                    Back to Student Screen
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Container>
            <HeaderContainer>
                <RouteStepper step={stepper} />
                {!location.pathname.includes(SelectStudentPath) && (
                    <ResultTitleContainer>
                        <ResultTitle>{t('menu-results')}</ResultTitle>
                        {userData && <LoginStatusBar user={userData} />}
                    </ResultTitleContainer>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        minHeight: '76px',
                        alignItems: 'center',
                        gap: '64px',
                        flex: '1 0 0',
                        flexDirection: 'row',
                    }}>
                    <Typography fontSize='16px'>{t('resultPage.school') + '：' + selectedSchool?.schoolName}</Typography>
                    <Typography fontSize='16px'>{t('resultPage.class') + '：' + selectedClass?.className}</Typography>
                    {location.pathname.includes(OverallResultPath) && !location.pathname.includes(ClassOverallResultPath) && <TestRoomSelector testRooms={testRoomsData} />}
                </Box>
                {!location.pathname.includes(ClassOverallResultPath) &&
                    (location.pathname.includes(SelectStudentPath) || location.pathname.includes(OverallResultPath) || location.pathname.includes(SelectQuestionPath)) && (
                        <ResultPageTabs resultTabsProp={resultPageTabProp} />
                    )}
            </HeaderContainer>
            <ResultTableContainer>
                <Outlet
                    context={{
                        userData: userData,
                        selectedSchool: selectedSchool,
                        selectedClass,
                        setSelectedClass,
                        selectedTestRoom,
                        setSelectedTestRoom,
                        studentResults,
                        setStudentResults,
                        studentsResults,
                        setStudentsResults,
                        selectedStudent,
                        setSelectedStudent,
                        selectedQuestion,
                        setSelectedQuestion,
                        studentGrading,
                        setStudentGrading,
                        gradedItems,
                        setGradedItems,
                    }}
                />
            </ResultTableContainer>
            {dialog}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 48px 0px;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    background: white;
`;

const ResultTitleContainer = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ResultStepContainer = styled.div`
    padding: 48px 24px 0px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
`;

const ResultTableContainer = styled.div`
    padding: 16px;
`;

const ResultTitle = styled.span`
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
`;
