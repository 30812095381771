import { Box } from '@mui/material';
import React from 'react';

type FeedbackIconProp = {
    isCorrect: boolean;
    top: number;
    right: number;
};
const FeedbackIcon = (prop: FeedbackIconProp) => {
    return (
        <Box
            sx={{
                position: 'absolute', // Add this
                top: prop.top, // Adjust as needed
                right: prop.right, // Adjust as needed
                backgroundColor: prop.isCorrect ? '#23D680' : '#F04438', // Adjust as needed
                width: '32px',
                height: '32px',
                borderRadius: '16px',
                justifyContent: 'center',
            }}>
            {prop.isCorrect ? (
                <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 25' fill='none'>
                    <path
                        d='M9.00014 16.7421L5.53014 13.2721C5.14014 12.8821 4.51014 12.8821 4.12014 13.2721C3.73014 13.6621 3.73014 14.2921 4.12014 14.6821L8.30014 18.8621C8.69014 19.2521 9.32014 19.2521 9.71014 18.8621L20.2901 8.28209C20.6801 7.89209 20.6801 7.26209 20.2901 6.87209C19.9001 6.48209 19.2701 6.48209 18.8801 6.87209L9.00014 16.7421Z'
                        fill='white'
                    />
                </svg>
            ) : (
                <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 25' fill='none'>
                    <path
                        d='M18.3002 6.28248C17.9102 5.89248 17.2802 5.89248 16.8902 6.28248L12.0002 11.1625L7.11022 6.27248C6.72022 5.88248 6.09021 5.88248 5.70021 6.27248C5.31021 6.66248 5.31021 7.29248 5.70021 7.68248L10.5902 12.5725L5.70021 17.4625C5.31021 17.8525 5.31021 18.4825 5.70021 18.8725C6.09021 19.2625 6.72022 19.2625 7.11022 18.8725L12.0002 13.9825L16.8902 18.8725C17.2802 19.2625 17.9102 19.2625 18.3002 18.8725C18.6902 18.4825 18.6902 17.8525 18.3002 17.4625L13.4102 12.5725L18.3002 7.68248C18.6802 7.30248 18.6802 6.66248 18.3002 6.28248Z'
                        fill='white'
                    />
                </svg>
            )}
        </Box>
    );
};

export default FeedbackIcon;
