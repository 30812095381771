import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';

const SAVE_GQL = gql`
    mutation UpdateLesson($where: LessonWhereUniqueInput!) {
        updateLesson(where: $where, data: { archived: true }) {
            id
        }
    }
`;

type MutationVariableType = {
    where: { id: string };
};

type MutationType = {
    updateLesson: { id: string };
};

export const useArchiveLessonMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation<MutationType>(SAVE_GQL);

    const archiveLesson = async (variables: MutationVariableType) => {
        try {
            const result = await saveForm({
                variables,
            });

            if (!result || !result.data?.updateLesson) {
                throw new TTError(ERROR_TYPE.UPDATE_FAILED);
            }
        } catch (err) {
            const errMessage = handleException(err);
            if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errMessage);
            } else {
                enqueueSnackbar(errMessage, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
            throw new TTError();
        }
    };

    return { archiveLesson };
};
