import { Box, Stack } from '@mui/material';
import { SlideComponentFillInTheBlankQuestionModel } from 'models/lesson/LessonModel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

type FillInTheBlankAnswerBubbleProps = {
    questionBubbles: SlideComponentFillInTheBlankQuestionModel[];
    onDragChange: (textContent: SlideComponentFillInTheBlankQuestionModel[], isDrag: boolean) => void;
};
export const FillInTheBlankAnswerBubble = (props: FillInTheBlankAnswerBubbleProps) => {
    const handleOnDragEnd1 = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = [...props.questionBubbles].sort((a, b) => a.sort - b.sort);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        props.onDragChange(items, true);
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd1}>
            <Droppable droppableId={'matchingAnswerBubbles'} direction='horizontal' type='QUESTION' isCombineEnabled={true}>
                {(provided) => (
                    <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        direction='row'
                        flexWrap='wrap'
                        justifyContent='flex-start' // align bubbles to the left
                        spacing={0.5}>
                        {props.questionBubbles.length > 1 &&
                            [...props.questionBubbles]
                                .sort((a, b) => a.sort - b.sort)
                                .map((bubble, index) => (
                                    <Draggable key={index + 'question12'} draggableId={index + 'question'} index={index}>
                                        {(provided) => (
                                            <Box
                                                key={index + 'question1112'}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    color: '#533D18',
                                                    backgroundColor: 'white',
                                                    borderRadius: '5px',
                                                    border: '1px solid grey',
                                                    width: 'auto',
                                                    px: 1,
                                                    py: 0.5,
                                                    height: '48px', // set a fixed height
                                                    marginBottom: 1,
                                                    textAlign: 'center', // add this line
                                                    display: 'flex', // enable flexbox layout
                                                    alignItems: 'center', // vertically center the text
                                                }}>
                                                {bubble.answerText}
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                        {provided.placeholder}
                    </Stack>
                )}
            </Droppable>
        </DragDropContext>
    );
};
