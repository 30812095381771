import styled from '@emotion/styled';
import { Circle } from '@mui/icons-material';
import { ReactNode } from 'react';

type Props = {
    variants?: string;
    children?: ReactNode;
};

export const GradingStatus = ({ variants, children }: Props) => (
    <DotContainer>
        <Circle
            sx={{
                color: variants,
                width: '12px',
                height: '12px',
            }}
            stroke={'#533D18'}
            stroke-width={4}
        />
        {children}
    </DotContainer>
);

export const DotContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;
