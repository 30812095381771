import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ResultTable, ResultTableProps } from 'components/ResultTable';
import { GET_RESULTS_BY_TESTROOM, GET_STUDENTS } from 'pages/results/queries';
import { ClassStudentQueryModel, ResultsPageContext, StudentProfileWithResults, TestroomResults } from 'pages/results/models';
import { SelectClassPath, SelectQuestionPath, SelectStudentPath, SelectTestroomPath } from 'routes/utils';
import moment from 'moment';
import { ScoreCard } from 'components/ScoreCard';
import { Stack } from '@mui/material';
import { getStringSortDirection } from 'pages/results/utils';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';

export const SelectStudentPage = () => {
    const { studentLoading, resultsLoading, studentTableData } = useTestroomResult();

    return (
        <Stack direction='column' spacing={6} className='pageCustom'>
            {/* <ScoreCard
        score={
          studentData?.studentProfiles
            ? '' +
              (
                allStudentsAverageScore / studentData?.studentProfiles?.length
              )?.toFixed(2)
            : ''
        }
        time={
          studentData?.studentProfiles
            ? moment
                .utc(
                  (allStudentsAnswerTime /
                    studentData?.studentProfiles?.length) *
                    1000,
                )
                .format('mm:ss')
            : ''
        }
      /> */}
            <ResultTable columns={studentTableData?.columns} data={studentTableData?.data} isLoading={!studentTableData && resultsLoading} showSearchTab={true} />
        </Stack>
    );
};
