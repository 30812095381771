import { ResultTableProps } from 'components/ResultTable';
import { OverallResultByQuestion, StudentPerformance, StudentProfileWithResults } from 'models/resultPage/ResultPageModel';
import moment from 'moment';
import { useState } from 'react';
import { GameType, gameTypeStr, getStringSortDirection } from '../utils';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectClassPath, SelectTestroomPath, SelectQuestionPath, SelectQuestionId } from 'routes/utils';
// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useQuestionTableResult = () => {
    const { t } = useTranslation();
    const navigator = useNavigate();
    const [questionTableData, setQuestionData] = useState<ResultTableProps>();

    const { schoolId, classId, testroomId } = useParams();

    const questionResultsTableRow = (question: OverallResultByQuestion, index: number) => {
        if (question?.question) {
            const questionType = gameTypeStr(question?.questionType as GameType);
            return {
                question: `${question?.question}`,
                questionType: questionType,
                //TODO handle HH:mm:ss
                correctPercentage: ((question.correct / question.studentPerformances.length) * 100).toFixed(1).toString() + '%',

                onClick: async () => {
                    navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${SelectQuestionPath}/${testroomId}/${SelectQuestionId}/${question.questionID}`);
                },
            };
        } else {
            return {
                question: `NA`,
                questionType: `NA`,
                //TODO handle HH:mm:ss
                correctPercentage: '0',
                onClick: async () => {},
            };
        }
    };
    const prepareQuestionTable = (questionData: OverallResultByQuestion[]) => {
        const tableDataFromQuery = {
            data: questionData?.map((student: OverallResultByQuestion, index) => {
                return questionResultsTableRow(student, index);
            }),
            columns: [
                {
                    name: 'question',
                    nameStr: t('question'),
                    sortCallback: () => {
                        setQuestionData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'question');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.student?.localeCompare(a?.student));
                                } else {
                                    cloneState.sort((a, b) => a?.student?.localeCompare(b?.student));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
                {
                    name: 'questionType',
                    nameStr: t('type'),
                    sortCallback: () => {
                        setQuestionData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'questionType');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.correctCount?.localeCompare(a?.correctCount));
                                } else {
                                    cloneState.sort((a, b) => a?.correctCount?.localeCompare(b?.correctCount));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
                {
                    name: 'correctPercentage',
                    nameStr: t('resultPage.average-correct-percentage'),
                    showPercentage: true,
                    sortCallback: () => {
                        setQuestionData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'correctPercentage');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.totalTestDuration?.localeCompare(a?.totalTestDuration));
                                } else {
                                    cloneState.sort((a, b) => a?.totalTestDuration?.localeCompare(b?.totalTestDuration));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
            ],
        };
        setQuestionData(tableDataFromQuery);
    };
    const getQuestionResultByStudent = (questionID: string, student: StudentProfileWithResults): StudentPerformance => {
        const studentResultByQuestion = student?.results?.filter((result) => result.componentId === questionID);
        const studentName = student.familyName + student.givenName;
        if (studentResultByQuestion && studentResultByQuestion.length > 0) {
            const answersTimeSec = studentResultByQuestion?.reduce((prevValue, answer) => {
                // Check if both endTime and startTime are valid dates
                if (moment(answer?.endTime).isValid() && moment(answer?.startTime).isValid()) {
                    return prevValue + moment(answer?.endTime).diff(moment(answer?.startTime));
                } else {
                    // If either endTime or startTime is not a valid date, return 0
                    return prevValue + 0;
                }
            }, 0);
            const questionAttempt = studentResultByQuestion.length;

            for (const result of studentResultByQuestion) {
                if (result?.componentId === questionID && (result?.grading === 'correct' || (result?.correct && result?.grading !== 'incorrect'))) {
                    return {
                        grading: true,
                        questionAttempt,
                        studentName,
                        studentScore: 1, //TODO variable
                        averageTime: answersTimeSec / studentResultByQuestion.length,
                    };
                }
            }

            return {
                grading: false,
                questionAttempt,
                studentName,
                studentScore: 0, //TODO variable
                averageTime: answersTimeSec / studentResultByQuestion.length,
            };
        }
        return {
            grading: false,
            questionAttempt: 0,
            studentName,
            studentScore: 0, //TODO variable
            averageTime: 0,
        };
    };
    const getQuestionTable = (selectedTestRoom: TestroomWithQuestions, studentDataWithResult: StudentProfileWithResults[]): OverallResultByQuestion[] => {
        const answers: OverallResultByQuestion[] = selectedTestRoom?.lessonVersion?.lessonQuestions?.map((question, index) => {
            let questionName;
            if (isNullOrEmpty(question?.content?.phrase)) {
                questionName =
                    typeof question?.content?.character === 'number'
                        ? question?.content?.characterList[question?.content?.character].character
                        : question?.content?.character?.split('/')[1].replace(/[0-9]/g, '');
            } else {
                questionName = question?.content?.phrase;
            }
            if (question.type === 'multipleChoice') {
                questionName = t('hksas.mctitle');
            } else if (question.type === 'sasAudio') {
                questionName = t('hksas.audio.title');
            } else if (question.type === 'sasFillInTheBlanks') {
                questionName = t('hksas.fillInTheBlanks.title');
            } else if (question.type === 'sasFillInTheBlanksWriting') {
                questionName = t('hksas.fillInTheBlanksWriting.title');
            }
            let correctStudent = 0;
            let totalTime = 0;
            let numberOfStudentAttemptThisQuestion = 0;
            const studentPerformancePerQuestions: StudentPerformance[] = [];
            studentDataWithResult?.forEach((student, index) => {
                const studentPerformancePerQuestion: StudentPerformance = getQuestionResultByStudent(question.id, student);
                if (studentPerformancePerQuestion.grading) {
                    correctStudent += 1;
                }
                if (studentPerformancePerQuestion.averageTime > 0) {
                    numberOfStudentAttemptThisQuestion += 1;
                }

                totalTime += studentPerformancePerQuestion.averageTime;
                studentPerformancePerQuestions.push(studentPerformancePerQuestion);
            });
            let questionType = (question?.content?.gameType as GameType) ?? question.type;
            return {
                index: index,
                question: questionName ?? '',
                questionID: question.id ?? '',
                questionType,
                correct: correctStudent,
                studentPerformances: studentPerformancePerQuestions,
                averageTime: totalTime / numberOfStudentAttemptThisQuestion,
            };
        });
        return answers;
    };
    return {
        getQuestionResultByStudent,
        getQuestionTable,
        questionTableData,
        prepareQuestionTable,
        setQuestionData,
    };
};
function isNullOrEmpty(str: string | undefined): boolean {
    if (str === undefined) {
        return true;
    }
    if (str.trim() === '') {
        return true;
    }
    return false;
}
