import { Box, Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useLessonSlide from '../../../hooks/useLessonSlide';
import { useState, useMemo } from 'react';
import { SlideComponent, ComponentTypeMap } from 'models/lesson/LessonModel';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PetsIcon from '@mui/icons-material/Pets';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { LessonsSlidePropsAudio } from '../props/LessonsSlidePropsAudio';
import { LessonsSlidePropsCharacter } from '../props/LessonsSlidePropsCharacter';
import { LessonsSlidePropsImage } from '../props/LessonsSlidePropsImage';
import { LessonsSlidePropsText } from '../props/LessonsSlidePropsText';
import { LessonsSlidePropsBackground } from '../props/LessonsSlidePropsBackground';
import { LessonsSlidePropsReorderSentence } from '../props/LessonsSlidePropsReorderSentence';

import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { LessonsSlidePropsFillInTheBlank } from '../props/LessonsSlidePropsFillInTheBlank';
import { LessonsSlidePropsMultipleChoice } from '../props/LessonsSlidePropsMultipleChoice';
import { LessonsSlidePropsPunctuationMark } from '../props/LessonsSlidePropsPunctuationMark';
import { LessonsSlidePropsReadingComprehension } from '../props/LessonsSlidePropsReadingComprehension';
import { LessonsSlidePropsH5P } from '../props/LessonsSlidePropsH5P';
import { UpgradePlanPopup } from '../../../../../routes/UpgradePlanPopup';
import { LessonsSlidePropsMatching } from '../props/LessonsSlidePropsMatching';
import { LessonsSlidePropsHkcsQ1 } from '../props/LessonsSlidePropsHkcsQ1';
import { LessonsSlidePropsHkcsQ2 } from '../props/LessonsSlidePropsHkcsQ2';
import { LessonsSlidePropsHkcsQ3 } from '../props/LessonsSlidePropsHkcsQ3';
import { LessonsSlidePropsSasAudio } from '../props/LessonsSlidePropsSasAudio';
import { LessonsSlidePropsSasFillInTheBlanks } from '../props/LessonsSlidePropsSasFillInTheBlanks';
import { LessonsSlidePropsSasFillInTheBlanksWriting } from '../props/LessonsSlidePropsSasFillInTheBlanksWriting';
import { LessonsSlidePropsSasRadicalMatching } from '../props/LessonsSlidePropsSasRadicalMatching';
import { LessonsSlidePropsHkcsEnQ1 } from '../props/LessonsSlidePropsHkcsEnQ1';
import { LessonsSlidePropsHkcsEnQ2 } from '../props/LessonsSlidePropsHkcsEnQ2';

type LessonSlideAddPopupProps = {
    type: keyof ComponentTypeMap;
    text: string;
    disabled?: boolean;
    iconPath?: string;
    fullWidth?: boolean;
    maxWidth?: Breakpoint | false;
    hasPermission?: boolean;
};
export const LessonsSlideAddPopup = ({ type, text, disabled, iconPath, fullWidth, maxWidth, hasPermission = true }: LessonSlideAddPopupProps) => {
    const { t } = useTranslation();
    const {
        newAudioProps,
        newCharacterProps,
        newImageProps,
        newBackgroundProps,
        newTextProps,
        newReorderSentenceProps,
        newMatchingProps,
        newFillInTheBlankProps,
        newPunctuationMark,
        newReadingComprehension,
        newMultipleChoiceProps,
        newH5PProps,
        newHkcsQ1Props,
        newHkcsQ2Props,
        newHkcsQ3Props,
        newHkcsEnQ1Props,
        newHkcsEnQ2Props,
        newSasAudioProps,
        newSasFillInTheBlanksProps,
        newSasFillInTheBlanksWritingProps,
        newSasRadicalMatchingProps,
        createComponent,
    } = useLessonSlide();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newProps, setNewProps] = useState<SlideComponent>({} as SlideComponent);
    const [canSave, setCanSave] = useState(true);

    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const handleOpen = () => {
        if (!hasPermission) {
            // console.log(showUpgradePopup);
            setShowUpgradePopup(true);
        } else {
            setCanSave(true);
            setIsPopupOpen(true);
            InitProps();
        }
    };
    const handleClose = () => {
        setIsPopupOpen(false);
    };

    const handleSave = () => {
        createComponent(newProps);
        handleClose();
    };

    const InitProps = () => {
        switch (type) {
            case 'audio':
                setNewProps({ ...newAudioProps });
                break;
            case 'character':
                setNewProps({ ...newCharacterProps });
                break;
            case 'image':
                setNewProps({ ...newImageProps });
                break;
            case 'background':
                setNewProps({ ...newBackgroundProps });
                break;
            case 'text':
                setNewProps({ ...newTextProps });
                break;
            case 'reorderSentence':
                setNewProps({ ...newReorderSentenceProps });
                break;
            case 'matching':
                setNewProps({ ...newMatchingProps });
                break;
            case 'fillInTheBlank':
                setNewProps({ ...newFillInTheBlankProps });
                break;
            case 'punctuationMark':
                setNewProps({ ...newPunctuationMark });
                break;
            case 'readingComprehension':
                setNewProps({ ...newReadingComprehension });
                break;
            case 'multipleChoice':
                setNewProps({ ...newMultipleChoiceProps });
                break;
            case 'h5p':
                setNewProps({ ...newH5PProps });
                break;
            case 'hkcsQ1':
                setNewProps({ ...newHkcsQ1Props });
                break;
            case 'hkcsQ2':
                setNewProps({ ...newHkcsQ2Props });
                break;
            case 'hkcsQ3':
                setNewProps({ ...newHkcsQ3Props });
                break;
            case 'hkcsEnQ1':
                setNewProps({ ...newHkcsEnQ1Props });
                break;
            case 'hkcsEnQ2':
                setNewProps({ ...newHkcsEnQ2Props });
                break;
            case 'sasAudio':
                setNewProps({ ...newSasAudioProps });
                break;
            case 'sasFillInTheBlanks':
                setNewProps({ ...newSasFillInTheBlanksProps });
                break;
            case 'sasFillInTheBlanksWriting':
                setNewProps({ ...newSasFillInTheBlanksWritingProps });
                break;
            case 'sasRadicalMatching':
                setNewProps({ ...newSasRadicalMatchingProps });
                break;
        }
    };

    const showPropsIcon = useMemo(() => {
        switch (type) {
            case 'audio':
                return <VolumeUpIcon />;
            case 'character':
                return <PetsIcon />;
            case 'image':
                return <ImageIcon />;
            case 'background':
                return <ImageIcon />;
            case 'text':
                return <TextFieldsIcon />;
            case 'reorderSentence':
                return <TextFieldsIcon />;
            case 'matching':
                return <JoinInnerIcon />;
            case 'fillInTheBlank':
                return <TextFieldsIcon />;
            case 'punctuationMark':
                return <TextFieldsIcon />;
            case 'readingComprehension':
                return <TextFieldsIcon />;
            case 'multipleChoice':
                return <LibraryAddCheckIcon />;
            case 'h5p':
                return <YouTubeIcon />;
            case 'hkcsQ1':
                return <JoinInnerIcon />;
            case 'hkcsQ2':
                return <LibraryAddCheckIcon />;
            case 'hkcsQ3':
                return <BorderColorIcon />;
            case 'hkcsEnQ1':
                return <BorderColorIcon />;
            case 'hkcsEnQ2':
                return <BorderColorIcon />;
            case 'sasAudio':
                return <HeadsetMicIcon />;
            case 'sasFillInTheBlanks':
                return <FormatShapesIcon />;
            case 'sasFillInTheBlanksWriting':
                return <FormatShapesIcon />;
            case 'sasRadicalMatching':
                return <JoinInnerIcon />;
        }
    }, [type]);

    const showPropsContent = useMemo(() => {
        switch (type) {
            case 'audio':
                return <LessonsSlidePropsAudio item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} setCanSave={setCanSave} isCreate />;
            case 'character':
                return <LessonsSlidePropsCharacter item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'image':
                return <LessonsSlidePropsImage item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'background':
                return <LessonsSlidePropsBackground item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'text':
                return <LessonsSlidePropsText item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'reorderSentence':
                return <LessonsSlidePropsReorderSentence item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'matching':
                return <LessonsSlidePropsMatching item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'fillInTheBlank':
                return <LessonsSlidePropsFillInTheBlank item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'multipleChoice':
                return <LessonsSlidePropsMultipleChoice item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'punctuationMark':
                return <LessonsSlidePropsPunctuationMark item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'readingComprehension':
                return <LessonsSlidePropsReadingComprehension item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'h5p':
                return <LessonsSlidePropsH5P showH5PPreview={true} item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'hkcsQ1':
                return <LessonsSlidePropsHkcsQ1 item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'hkcsQ2':
                return <LessonsSlidePropsHkcsQ2 item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'hkcsQ3':
                return <LessonsSlidePropsHkcsQ3 item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'hkcsEnQ1':
                return <LessonsSlidePropsHkcsEnQ1 item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} isCreate />;
            case 'hkcsEnQ2':
                return <LessonsSlidePropsHkcsEnQ2 item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} isCreate />;
            case 'sasAudio':
                return <LessonsSlidePropsSasAudio item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'sasFillInTheBlanks':
                return <LessonsSlidePropsSasFillInTheBlanks item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'sasFillInTheBlanksWriting':
                return <LessonsSlidePropsSasFillInTheBlanksWriting item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'sasRadicalMatching':
                return <LessonsSlidePropsSasRadicalMatching item={newProps} index={0} setCanSave={setCanSave} handleCreate={(item) => setNewProps({ ...item })} />;
        }
    }, [newProps, type]);

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-block',
                }}>
                <Button
                    disabled={disabled}
                    variant='text'
                    color='secondary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '0px',
                        ml: 1,
                        color: '#89744D',
                    }}
                    startIcon={showPropsIcon}
                    onClick={handleOpen}>
                    {text}
                </Button>
                {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='featureNotIncluded' />}

                {iconPath && (
                    <Box
                        component='img'
                        src={iconPath}
                        alt='AI power'
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 12,
                        }}
                    />
                )}
            </Box>

            <Dialog open={isPopupOpen} fullWidth={fullWidth} maxWidth={maxWidth} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' sx={{ minWidth: 800 }}>
                    <PopupTitle>{text}</PopupTitle>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{showPropsContent}</DialogContentText>
                </DialogContent>
                <Divider
                    sx={{
                        width: '90%',
                        alignSelf: 'center',
                        mb: '20px',
                        mt: '20px',
                        backgroundColor: '#858585',
                    }}
                />
                <DialogActions sx={{ mb: '20px' }}>
                    <Stack spacing={2} direction='row' sx={{ flex: 1, ml: 4, mr: 4 }}>
                        <CancelButton onClick={handleClose} sx={{ flex: 1, border: '1px solid #F06E3C' }}>
                            {t('cancel')}
                        </CancelButton>
                        <SubmitButton
                            onClick={handleSave}
                            autoFocus
                            disabled={!canSave}
                            sx={{
                                flex: 1,
                                '&:disabled': {
                                    backgroundColor: '#F0F0F0',
                                    color: '#B3B3B3',
                                },
                            }}>
                            {t('save')}
                        </SubmitButton>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
