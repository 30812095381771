export const colors = {
    primary050: '#FFF3E0',
    primary100: '#FFDFB1',
    primary200: '#FFCA7F',
    primary300: '#FFB44C',
    primary400: '#FFA425',
    primary500: '#FF9500',
    primary600: '#FB8901',
    primary700: '#F57902',
    primary800: '#F26800',
    primary900: '#E94B00',
    secondary050: '#E2F7FE',
    secondary100: '#B6ECFB',
    secondary200: '#86E1F8',
    secondary300: '#57D4F2',
    secondary400: '#37CAED',
    secondary500: '#29C0E7',
    secondary600: '#23B1D3',
    secondary700: '#1A9CB8',
    secondary800: '#12889F',
    secondary900: '#026572',
    dark050: '#F4ECD7',
    dark100: '#DCCDB5',
    dark200: '#BFAC90',
    dark300: '#A08C6B',
    dark400: '#89744D',
    dark500: '#725C31',
    dark600: '#68522B',
    dark700: '#5A4522',
    dark800: '#4E381A',
    dark900: '#3F2A10',
    materialRed050: '#FFEBEE',
    materialRed100: '#FFCDD2',
    materialRed200: '#EF9A9A',
    materialRed300: '#E57373',
    materialRed400: '#EF5350',
    materialRed500: '#F44336',
    materialRed600: '#E53935',
    materialRed700: '#D32F2F',
    materialRed800: '#C62828',
    materialRed900: '#B71C1C',

    reallyWhite: '#FFFFFF',
    reallyBlack: '#000000',
    cookieFrosting: '#FFFBF6',
    tangerine: '#FFA400',
    vowelARed: '#F9423A',
    vowelEBlue: '#307FE2',
    vowelIPurple: '#7D55C7',
    vowelOGreen: '#44D62C',
    vowelUYellow: '#FFD700',

    gray500: '#856666',
    yellow050: '#FAE6C2',
    yellow900: '#533D18',
    red400: '#F06E3C',
    red500: '#F05023',

    success400: '#23D680',
    error500: '#F04438',
};
