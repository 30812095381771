import { Typography } from '@mui/material';

export const TestroomTitle = ({ lessonName }: { lessonName: string }) => {
    return (
        <Typography
            variant='h4'
            sx={{
                color: '#FFF3E0',
                textAlign: 'left',
            }}>
            {lessonName}
        </Typography>
    );
};
