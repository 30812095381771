import { Button } from '@mui/material';
import { CalendarAddUI } from 'pages/calendar/components/CalendarAddUI';
import { CalendarData, TimeSlotDialogState } from 'pages/calendar/types/timeSlotTypes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import usePublishLessonHook from '../hooks/usePublishLessonHook';
import { useSnackbar, OptionsObject } from 'notistack';
import { useNavigate } from 'react-router-dom';

export const LessonsPublishButton = ({ lessonID, lessonVersionID, lessonName }: { lessonID: string; lessonVersionID: string; lessonName: string }) => {
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const openSuccessSnack = (message: string, variant: OptionsObject['variant']) => {
        enqueueSnackbar(message, {
            variant,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        navigate('/calendar');
    };

    const [currentCalendarState, setCurrentCalendarState] = useState<TimeSlotDialogState>({
        isDialogOpen: false,
        calendarData: {} as CalendarData,
    });

    const [loading, setLoading] = useState(false);
    const { formik, classesQueryData } = usePublishLessonHook(lessonID, (data, action) => {
        openSuccessSnack(t('publishedMessage'), 'success');
        handleClose();
    });

    const handleClose = () => {
        setCurrentCalendarState({
            isDialogOpen: false,
            calendarData: {} as CalendarData,
        });
    };

    const handleOpen = () => {
        setCurrentCalendarState({
            isDialogOpen: true,
            calendarData: {} as CalendarData,
        });
    };

    useEffect(() => {
        const fetechData = async () => {
            if (currentCalendarState.isDialogOpen) {
                let startTime = new Date();
                let endTime = new Date();
                formik.resetForm();

                endTime.setTime(endTime.getTime() + 60 * 60 * 2 * 1000);

                formik.setFieldValue('startTime', startTime);
                formik.setFieldValue('endTime', endTime);
                formik.setFieldValue('offlineMode', false);
                formik.setFieldValue('writingHints', false);
                formik.setFieldValue('freeStyleMode', false);

                formik.setFieldValue('lessonVersion', lessonVersionID);
            }
        };
        fetechData();
    }, [currentCalendarState.isDialogOpen]);

    return (
        <>
            <Button
                onClick={handleOpen}
                variant='contained'
                color='primary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '50px',
                    mr: 1,
                    color: 'white',
                }}
                startIcon={<PublishedWithChangesIcon />}>
                {t('publish')}
            </Button>

            <CalendarAddUI
                currentCalendarState={currentCalendarState}
                formik={formik}
                lessonName={lessonName}
                classesQueryData={classesQueryData}
                handleClose={handleClose}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    );
};
