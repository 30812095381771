import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RecorderControls from '../../../../../components/voiceRecorder/RecorderControls';
import useRecorder from '../../../../../components/voiceRecorder/hooks/use-recorder';
import { SlideComponent, SlideComponentAudio } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SetStateAction, useEffect } from 'react';
import useLessonSlide from 'pages/lessons/hooks/useLessonSlide';
import { RecordingsList } from 'components/voiceRecorder/RecordingsList';

type ComponentProps = {
    item: SlideComponent;
    index: number;
    handleCreate?: (item: SlideComponent) => void;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    isCreate?: boolean;
};

export const LessonsSlidePropsAudio = (props: ComponentProps) => {
    const { t } = useTranslation();
    const { deleteSlideItem } = useLessonSlide();
    const { uploadFileOnStopCallback, deleteAudio } = useLessonSlideProps();
    const { recorderState, setOnStopCallback, handleFileUpload, ...handlers } = useRecorder();

    let itemContent: SlideComponentAudio = props.item.content as SlideComponentAudio;

    setOnStopCallback(async (voFile: File) => {
        await uploadFileOnStopCallback(voFile, props.item);
        props.setCanSave?.(itemContent.audio.length > 0);
    });

    useEffect(() => {
        props.setCanSave?.(itemContent.audio.length > 0);
    }, [itemContent.audio.length, props.setCanSave]);

    return (
        <Stack
            spacing={2}
            sx={{
                mt: 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <RecorderControls isCreate={props.isCreate} recorderState={recorderState} handlers={{ ...handlers, handleFileUpload }} />
            <RecordingsList item={props.item} index={props.index} audio={itemContent.audio} deleteAudio={deleteAudio} deleteSlideItem={deleteSlideItem} />
        </Stack>
    );
};
