import { gql, useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { TestroomModel } from '../../testroom/models/TestroomModel';
import { useContext } from 'react';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { ClassesQueryData } from 'pages/calendar/types/timeSlotTypes';
import { enqueueSnackbar } from 'notistack';

const SAVE_GQL = gql`
    mutation save(
        $name: String!
        $lessonVersion: ID!
        $class: ID!
        $startTime: DateTime!
        $passlock: String!
        $offlineMode: Boolean!
        $writingHints: Boolean!
        $freeStyleMode: Boolean!
        $endTime: DateTime!
    ) {
        createTestroom(
            data: {
                testroomName: $name
                lessonVersion: { connect: { id: $lessonVersion } }
                class: { connect: { id: $class } }
                passlock: $passlock
                offlineMode: $offlineMode
                writingHints: $writingHints
                freeStyleMode: $freeStyleMode
                startTime: $startTime
                endTime: $endTime
            }
        ) {
            id
            testroomName
            otp
            lessonVersion {
                id
            }
            class {
                id
            }
            passlock
            offlineMode
            writingHints
            freeStyleMode
            startTime
            endTime
        }
    }
`;

const LESSON_PUBLISH_QUERY = gql`
    mutation LessonPublish($lessonID: ID!) {
        publishLesson(lessonID: $lessonID) {
            id
        }
    }
`;

const CLASSES_QUERY = gql`
    query GetRows {
        classes(orderBy: { className: asc }) {
            id
            school {
                schoolName
            }
            className
        }
    }
`;

export default function usePublishLessonHook(lessonID: string, cb: (data: TestroomModel, action: 'published') => void) {
    const { accessDenied } = useContext(UserAuthContext);
    const [saveForm] = useMutation(SAVE_GQL, {
        variables: {
            name: '',
            lessonVersion: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
            startTime: new Date(),
            endTime: new Date(),
        },
    });
    const [publishLesson] = useMutation(LESSON_PUBLISH_QUERY, {
        variables: {
            lessonID: '',
        },
    });

    const { refetch: refetchClasses, data: classesQueryData } = useQuery<ClassesQueryData>(CLASSES_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
        lessonVersion: Yup.string().required(t('required')),
        class: Yup.string().required(t('required')),
        startTime: Yup.string().required(t('required')),
        endTime: Yup.string().required(t('required')),
    });
    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            otp: '',
            lessonVersion: '',
            class: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
            startTime: new Date(),
            endTime: new Date(new Date().getTime() + 12 * 60 * 60 * 1000), // default 12 hours
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (!lessonID || lessonID === '') {
                    throw new TTError(ERROR_TYPE.KS_VALIDATION_FAILURE);
                }

                const publishResult = await publishLesson({
                    variables: {
                        lessonID: lessonID,
                    },
                });

                const res = await saveForm({
                    variables: {
                        ...values,
                        lessonVersion: publishResult?.data?.publishLesson?.id,
                    },
                });
                cb(res.data.createTestroom, 'published');
            } catch (err) {
                console.log(err);
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    enqueueSnackbar(errorMsg, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                }
            }
        },
    });

    return {
        formik,
        refetchClasses,
        classesQueryData,
    };
}
