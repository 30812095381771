import { useEffect, useState } from 'react';
import { createContext } from 'react';
import { baseHeight, baseWidth, ratio } from '../hooks/useLessonSlide';

export type SlidePreviewContextType = {
    zoom: number;
    setZoom: (zoomLevel: number) => void;
    setParentCanvas: (width: number, height: number) => void;
    setCanvas: (width: number, height: number) => void;
    targetCanvasWidth: string;
    targetCanvasHeight: string;
};

export const SlidePreviewContext = createContext<SlidePreviewContextType>({} as SlidePreviewContextType);

export const SlidePreviewContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [zoom, setZoom] = useState(1);
    const [parentCanvasWidth, setParentCanvasWidth] = useState<number>(0);
    const [parentCanvasHeight, setParentCanvasHeight] = useState<number>(0);
    const [canvasWidth, setCanvasWidth] = useState<number>(0);
    const [canvasHeight, setCanvasHeight] = useState<number>(0);
    const [targetCanvasWidth, setTargetCanvasWidth] = useState<string>('100%');
    const [targetCanvasHeight, setTargetCanvasHeight] = useState<string>('none');

    const resizeCanvas = () => {
        // resize canvas
        if (parentCanvasWidth && parentCanvasHeight && canvasWidth > 0 && canvasHeight > 0) {
            const calculatedWidth = parentCanvasHeight * ratio;
            const calculatedHeight = parentCanvasWidth / ratio;

            if (calculatedWidth > parentCanvasWidth) {
                setZoom(canvasWidth / baseWidth);
                setTargetCanvasWidth('calc(100% - 20px)');
                setTargetCanvasHeight('none');
            } else if (calculatedHeight > parentCanvasHeight) {
                setZoom(canvasHeight / baseHeight);
                setTargetCanvasWidth('none');
                setTargetCanvasHeight(`calc(100% - 20px)`);
            }
        }
    };
    const setParentCanvas = (width: number, height: number) => {
        setParentCanvasWidth(width);
        setParentCanvasHeight(height);
    };
    const setCanvas = (width: number, height: number) => {
        setCanvasWidth(width);
        setCanvasHeight(height);
    };

    useEffect(() => {
        // TODO: move out zoom calibration in resizeCanvas as it is not correctly setting the zoom level
        const zoomX = canvasWidth / baseWidth > 1 ? 1 : canvasWidth / baseWidth;
        const zoomY = canvasHeight / baseHeight > 1 ? 1 : canvasHeight / baseHeight;

        if (zoomX > zoomY) {
            setZoom(zoomY);
        } else {
            setZoom(zoomX);
        }
    }, [canvasWidth, canvasHeight]);

    useEffect(() => {
        resizeCanvas();
    }, [canvasWidth, canvasHeight]);

    return (
        <SlidePreviewContext.Provider
            value={{
                zoom,
                setZoom,
                setParentCanvas,
                setCanvas,
                targetCanvasWidth,
                targetCanvasHeight,
            }}>
            {children}
        </SlidePreviewContext.Provider>
    );
};
