import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Typography, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { UserAuthContext, UserData } from 'contexts/UserAuthContext';
import { BootstrapStyleInput } from 'components/BootstrapStyleInput';
import { SIGN_IN } from 'pages/onBoarding/queries';
import { OnBoardingPageContext } from 'pages/onBoarding/models';
import { SignInRules } from 'pages/onBoarding/utils';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { ForgotPasswordPath } from 'routes/utils';
import Cookies from 'universal-cookie';
import { GoogleSigninButton } from 'components/GoogleSigninButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TermsDialog from '../SignUp/TermsDialog';
import { TermsConditionsContent } from '../SignUp/TermsConditionsContent';
import ReactDOM from 'react-dom';
import { CleverLoginButton } from 'components/CleverLoginButton/CleverLoginButton';
import axios, { isAxiosError } from 'axios';

export type ResultType = {
    data: {
        [key: string]: {
            __typename: string;
            message?: string;
            item: UserData;
            sessionToken: string;
        };
    };
};

export const SignInPage = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }

    // provider
    const { t } = useTranslation();
    const { setUserData, setRememberMe } = useContext(UserAuthContext);
    const { setErrorMessage, setIsLoading } = useOutletContext<OnBoardingPageContext>();

    // ui state
    const [doLogin] = useMutation(SIGN_IN);
    const [showPassword, setShowPassword] = useState(false);

    // params
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const fallback = params.get('fallback');
    const code = params.get('code');

    // cookies
    const cookies = useMemo(() => new Cookies(), []);

    const handleResponse = useCallback(
        async (result: ResultType | undefined, rememberMe: boolean, type: string) => {
            return new Promise<void>((resolve, reject) => {
                if (result?.data?.[type]?.__typename === 'UserAuthenticationWithPasswordFailure' || result?.data?.[type]?.__typename === 'AuthenticateWithGoogleFailure') {
                    return reject(result.data[type].message || 'An error occurred');
                }

                if (!result?.data?.[type]?.item) {
                    throw new Error('User data is missing');
                }

                if (result?.data[type].item && result?.data[type].item?.role !== 'teacher') {
                    throw new Error('This user is not a teacher');
                }

                const setup = () => {
                    const userData: UserData = {
                        id: result.data[type].item.id,
                        email: result.data[type].item.email,
                        name: result.data[type].item.name,
                        role: result.data[type].item.role,
                        googleId: result.data[type].item.googleId,
                        googleEmail: result.data[type].item.googleEmail,
                        googleProfilePicture: result.data[type].item.googleProfilePicture,
                    };

                    if (rememberMe && result?.data?.[type]?.item) {
                        const inThirtyDays = new Date();
                        inThirtyDays.setDate(inThirtyDays.getDate() + 30);

                        cookies.set('userData', JSON.stringify(userData), {
                            expires: inThirtyDays,
                        });
                    }

                    localStorage.setItem('token', result?.data?.[type].sessionToken);
                    setUserData(userData);
                };

                if (result?.data?.[type]?.__typename === 'SignUpWithGoogleSuccess') {
                    const onClose = () => {
                        setup();
                        resolve(); // Resolve the promise when the dialog is closed
                    };
                    const dialogRoot = document.getElementById('dialog-root');
                    ReactDOM.render(
                        <TermsDialog label={''} buttonTitle={''} dialogTitle={t('terms-conditions-card-title')} content={<TermsConditionsContent />} onClose={onClose} isOpen={true} />,
                        dialogRoot,
                    );
                } else {
                    setup();
                }
            });
        },
        [cookies, setUserData, t],
    );

    // username password login
    const handleSubmit = useCallback(
        async (data: { email: string; password: string; rememberMe: boolean }) => {
            try {
                setIsLoading(true);
                const result = await doLogin({
                    variables: {
                        email: data.email.toLowerCase(),
                        password: data.password,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (result && 'data' in result) {
                    await handleResponse(result as ResultType, data.rememberMe, 'authenticateUserWithPassword');
                } else {
                    throw new Error('An error occurred during login');
                }
            } catch (error) {
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage(`${error}`);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [doLogin, handleResponse, setErrorMessage, setIsLoading],
    );

    // Google login
    const handleSubmitGoogle = useCallback(
        async (result: ResultType) => {
            try {
                setIsLoading(true);
                await handleResponse(result, false, 'authenticateWithGoogle');
            } catch (error) {
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage('An error occurred.');
                }
            } finally {
                setIsLoading(false);
            }
        },
        [handleResponse, setErrorMessage, setIsLoading],
    );

    // Clever login
    const handleCleverLogin = useCallback(
        async (code: string) => {
            try {
                setIsLoading(true);
                await axios<{ id: string; email: string; name: string; role: string; sessionToken: string }>({
                    method: 'post',
                    url: `${process.env.REACT_APP_MAIN_SERVICE_URL}/oauth/clever`,
                    data: {
                        code: code,
                        redirect: window.location.origin + window.location.pathname,
                    },
                }).then((response) => {
                    // TODO: response validation
                    const user = response.data;
                    localStorage.setItem('token', response.data.sessionToken);
                    setUserData({
                        ...user,
                        googleId: '',
                        googleEmail: '',
                        googleProfilePicture: '',
                    });
                });
            } catch (error) {
                if (isAxiosError(error) && error.response?.data?.message && typeof error.response.data.message === 'string') {
                    setErrorMessage(`${error.response.data.message}`);
                } else {
                    setErrorMessage(`${error}`);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [setErrorMessage, setIsLoading, setUserData],
    );

    // oauth redirect handler
    useEffect(() => {
        // for now only support clever login for oauth redirect
        if (document.referrer.includes('clever.com') && code) {
            handleCleverLogin(code);
        }
    }, [handleCleverLogin, code]);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    minWidth: 360,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <GoogleSigninButton onSuccess={handleSubmitGoogle} />
                {process.env.REACT_APP_CLEVER_LOGIN_ENABLED === 'true' && <CleverLoginButton />}

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: '0px 0',
                    }}>
                    <hr
                        style={{
                            flexGrow: 1,
                            borderTop: '1px solid silver',
                        }}
                    />
                    <span
                        style={{
                            margin: '0 10px',
                        }}>
                        {t('sign-in-or')}
                    </span>
                    <hr
                        style={{
                            flexGrow: 1,
                            borderTop: '1px solid silver',
                        }}
                    />
                </div>

                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        rememberMe: false,
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validationSchema={SignInRules}>
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '24px',
                                    minWidth: 360,
                                }}>
                                <Box>
                                    <FormControl variant='standard' fullWidth>
                                        <InputLabel
                                            shrink
                                            htmlFor='email'
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}>
                                            <Typography>{t('userNameOrEmail')}</Typography>
                                        </InputLabel>
                                        <BootstrapStyleInput id='email' name='email' onChange={handleChange} fullWidth placeholder={t('enterUserNameOrEmail')} />
                                        {errors?.email && <Box sx={{ color: 'red' }}>{errors.email ? <div>{errors.email}</div> : null}</Box>}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='standard' fullWidth>
                                        <InputLabel
                                            shrink
                                            htmlFor='password'
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}>
                                            {t('password')}
                                        </InputLabel>
                                        <BootstrapStyleInput
                                            type={showPassword ? 'text' : 'password'} // Toggle between text and password type based on showPassword state
                                            id='password'
                                            name='password'
                                            onChange={handleChange}
                                            fullWidth
                                            inputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                                            {showPassword ? <RemoveRedEyeOutlined /> : <RemoveRedEye />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#858585',
                                            }}>
                                            {t('must8Characters')}
                                        </FormHelperText>
                                        {errors?.password && <Box sx={{ color: 'red' }}>{errors.password ? <div>{errors.password}</div> : null}</Box>}
                                    </FormControl>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.rememberMe}
                                                    onChange={(e) => {
                                                        handleChange(e); // Update the form value
                                                        setRememberMe(e.target.checked); // Update the UserAuthContext
                                                    }}
                                                    name='rememberMe'
                                                />
                                            }
                                            label={t('remember30Days')}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}
                                        />
                                    </FormControl>
                                    <Typography variant='body2'>
                                        <Link
                                            to={`../${ForgotPasswordPath}?fallback=${encodeURIComponent(fallback || '')}`}
                                            style={{
                                                textDecoration: 'none',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#F06E3C',
                                            }}>
                                            {t('forgot-password')}?
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box>
                                    <LoadingButton
                                        variant='contained'
                                        disableElevation
                                        fullWidth
                                        size='large'
                                        color='primary'
                                        sx={{
                                            textTransform: 'none',
                                            borderRadius: '12px',
                                            background: '#F06E3C',
                                            boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                            fontWeight: '500',
                                            lineHeight: '28px',
                                            fontSize: '18px',
                                            color: '#FDFCFC',
                                        }}
                                        type='submit'>
                                        {t('login-card-submit-button-title')}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </GoogleOAuthProvider>
    );
};
