import React, { useEffect, useState } from 'react';
import { IconButton, Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { formatMinutes, formatSeconds } from './utils/format-time';
import { RecorderControlsProps } from './types/recorder';

export default function RecorderControls({ recorderState, handlers, isCreate }: RecorderControlsProps & { isCreate?: boolean }) {
    const { t } = useTranslation();
    const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
    const { startRecording, saveRecording, cancelRecording, handleFileUpload } = handlers;
    const [file, setFile] = useState<File | null>(null);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const uploadedFile = event.target.files[0];
            if (uploadedFile.type === 'audio/mpeg') {
                // Validate file type
                setFile(uploadedFile);
                handleFileUpload(uploadedFile);
            } else {
                // Optionally, alert the user about the incorrect file type
                alert(t('onlyMp3FilesAllowed'));
            }
        }
    };

    useEffect(() => {
        return () => {
            // cancel recording when user click the popup's cancel button
            cancelRecording();
        };
    }, []);

    return (
        <Stack direction='row' alignItems='center' spacing={1}>
            <Stack
                spacing={2}
                sx={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {initRecording ? (
                    <>
                        <Stack direction='row' spacing={2}>
                            <IconButton
                                onClick={cancelRecording}
                                sx={{
                                    bgcolor: '#F04438',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#ee6960',
                                    },
                                    p: 3,
                                }}>
                                <CloseIcon sx={{ fontSize: 50 }} />
                            </IconButton>
                            <IconButton
                                disabled={recordingSeconds === 0 && !file}
                                onClick={saveRecording}
                                sx={{
                                    bgcolor: '#00D06C',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#41f49e',
                                    },
                                    p: 3,
                                }}>
                                <DoneIcon sx={{ fontSize: 50 }} />
                            </IconButton>
                        </Stack>
                        <Typography>{t('confirmRecording')}</Typography>
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={startRecording}
                            sx={{
                                bgcolor: '#ff4258',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'red',
                                },
                                p: 3,
                            }}>
                            <MicIcon sx={{ fontSize: 50 }} />
                        </IconButton>
                        <Button
                            component='label'
                            sx={{
                                bgcolor: '#555',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#666',
                                },
                                p: 2,
                            }}>
                            <UploadFileIcon sx={{ fontSize: 35 }} />
                            <input type='file' hidden accept='.mp3' onChange={onFileChange} />
                            {t('uploadMp3File')}
                        </Button>
                        {isCreate ? <Typography>{t('pressToRecordAudio')}</Typography> : <Typography>{t('recordAgain')}</Typography>}
                    </>
                )}
                {initRecording ? `${formatMinutes(recordingMinutes)}:${formatSeconds(recordingSeconds)}` : null}
            </Stack>
        </Stack>
    );
}
