import { Dialog, TextField, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CancelButton } from '../../../components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { PopupTitle } from '../../../components/General/Popups/PopupTitle';

export const ClassCreateForm = ({
    onSubmit,
    isOpened,
    initValues,
    handleClose,
    isUpdate,
}: {
    onSubmit: (values: any) => void;
    isOpened: boolean;
    initValues: any;
    handleClose: () => void;
    isUpdate?: boolean;
}) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        className: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
    });
    const formik = useFormik({
        initialValues: {
            className: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            onSubmit(values);
        },
    });

    useEffect(() => {
        formik.setFieldValue('className', initValues?.className ?? '');
        formik.setFieldTouched('className', false);
    }, [initValues]);

    return (
        <Dialog open={isOpened}>
            <DialogTitle>
                <PopupTitle>{isUpdate ? t('update-class') : t('add-class')}</PopupTitle>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Stack spacing={2} minWidth={480}>
                        <TextField
                            id='className'
                            name='className'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.className}
                            label={t('class-name')}
                            fullWidth
                            variant='outlined'
                            error={!!formik.errors.className && formik.touched.className}
                            helperText={formik.touched.className && formik.errors.className}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleClose} disabled={formik.isSubmitting}>
                        {t('cancel')}
                    </CancelButton>
                    <SubmitButton type='submit' loading={formik.isSubmitting}>
                        {t('save')}
                    </SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};
