import { Box, Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useLessonSlide from '../../../hooks/useLessonSlide';
import { useState, useMemo } from 'react';
import { SlideComponent, ComponentTypeMap } from 'models/lesson/LessonModel';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PetsIcon from '@mui/icons-material/Pets';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { LessonsSlidePropsAudio } from '../props/LessonsSlidePropsAudio';
import { LessonsSlidePropsCharacter } from '../props/LessonsSlidePropsCharacter';
import { LessonsSlidePropsImage } from '../props/LessonsSlidePropsImage';
import { LessonsSlidePropsText } from '../props/LessonsSlidePropsText';
import { LessonsSlidePropsBackground } from '../props/LessonsSlidePropsBackground';
import { LessonsSlidePropsReorderSentence } from '../props/LessonsSlidePropsReorderSentence';

import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { LessonsSlidePropsFillInTheBlank } from '../props/LessonsSlidePropsFillInTheBlank';
import { LessonsSlidePropsPunctuationMark } from '../props/LessonsSlidePropsPunctuationMark';
import { LessonsSlidePropsReadingComprehension } from '../props/LessonsSlidePropsReadingComprehension';

type WorksheetAddPopupProps = {
    type: keyof ComponentTypeMap;
    text: string;
    disabled?: boolean;
    iconPath?: string;
    fullWidth?: boolean;
    maxWidth?: Breakpoint | false;
    onSave?: (text: any) => void;
};
export const WorksheetAddPopup = ({ type, text, disabled, iconPath, fullWidth, maxWidth, onSave }: WorksheetAddPopupProps) => {
    const { t } = useTranslation();
    const {
        newAudioProps,
        newCharacterProps,
        newImageProps,
        newBackgroundProps,
        newTextProps,
        newReorderSentenceProps,
        newFillInTheBlankProps,
        newPunctuationMark,
        newReadingComprehension,
        newMultipleChoiceProps,
        createComponent,
    } = useLessonSlide();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newProps, setNewProps] = useState<SlideComponent>({} as SlideComponent);
    const [canSave, setCanSave] = useState(true);
    const [isAppendJyutping, setIsAppendJyutping] = useState(false);
    const [isAppendPinyin, setIsAppendPinyin] = useState(false);
    const [isAppendZhuyin, setIsAppendZhuyin] = useState(false);

    const handleOpen = () => {
        setCanSave(true);
        setIsPopupOpen(true);
        setIsAppendJyutping(false);
        setIsAppendPinyin(false);
        setIsAppendZhuyin(false);
        InitProps();
    };
    const handleClose = () => {
        setIsPopupOpen(false);
    };

    const handleSave = () => {
        // console.log('WorksheetAddPopup handleSave');
        // console.log(newProps);
        // console.log(onSave);
        //createComponent(newProps);
        console.log('handleSave!');

        if (onSave) {
            console.log('onSave!');
            console.log(newProps);
            onSave({
                ...newProps,
                isAppendJyutping: isAppendJyutping,
                isAppendPinyin: isAppendPinyin,
                isAppendZhuyin: isAppendZhuyin,
            });
        }
        handleClose();
    };

    const InitProps = () => {
        switch (type) {
            case 'audio':
                setNewProps({ ...newAudioProps });
                break;
            case 'character':
                setNewProps({ ...newCharacterProps });
                break;
            case 'image':
                setNewProps({ ...newImageProps });
                break;
            case 'background':
                setNewProps({ ...newBackgroundProps });
                break;
            case 'text':
                setNewProps({ ...newTextProps });
                break;
            case 'reorderSentence':
                setNewProps({ ...newReorderSentenceProps });
                break;
            case 'fillInTheBlank':
                setNewProps({ ...newFillInTheBlankProps });
                break;
            case 'punctuationMark':
                setNewProps({ ...newPunctuationMark });
                break;
            case 'readingComprehension':
                setNewProps({ ...newReadingComprehension });
                break;
            case 'multipleChoice':
                setNewProps({ ...newMultipleChoiceProps });
                break;
        }
    };

    const showPropsIcon = useMemo(() => {
        switch (type) {
            case 'audio':
                return <VolumeUpIcon />;
            case 'character':
                return <PetsIcon />;
            case 'image':
                return <ImageIcon />;
            case 'background':
                return <ImageIcon />;
            case 'text':
                return <TextFieldsIcon />;
            case 'reorderSentence':
                return <TextFieldsIcon />;
            case 'fillInTheBlank':
                return <TextFieldsIcon />;
            case 'punctuationMark':
                return <TextFieldsIcon />;
            case 'readingComprehension':
                return <TextFieldsIcon />;
            case 'multipleChoice':
                return <TextFieldsIcon />;
        }
    }, [type]);

    const showPropsContent = useMemo(() => {
        switch (type) {
            case 'audio':
                return <LessonsSlidePropsAudio item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} setCanSave={setCanSave} isCreate />;
            case 'character':
                return <LessonsSlidePropsCharacter item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'image':
                return <LessonsSlidePropsImage item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'background':
                return <LessonsSlidePropsBackground item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'text':
                return <LessonsSlidePropsText item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'reorderSentence':
                return <LessonsSlidePropsReorderSentence item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'fillInTheBlank':
                return <LessonsSlidePropsFillInTheBlank item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'punctuationMark':
                return <LessonsSlidePropsPunctuationMark item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
            case 'readingComprehension':
                return <LessonsSlidePropsReadingComprehension item={newProps} index={0} handleCreate={(item) => setNewProps({ ...item })} />;
        }
    }, [newProps, type]);

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-block',
                }}>
                <Button
                    disabled={disabled}
                    variant='text'
                    color='secondary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '0px',
                        ml: 1,
                        color: '#89744D',
                    }}
                    startIcon={showPropsIcon}
                    onClick={handleOpen}>
                    {text}
                </Button>
                {iconPath && (
                    <Box
                        component='img'
                        src={iconPath}
                        alt='AI power'
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 12,
                        }}
                    />
                )}
            </Box>

            <Dialog open={isPopupOpen} fullWidth={fullWidth} maxWidth={maxWidth} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' sx={{ minWidth: 500 }}>
                    <PopupTitle>{text}</PopupTitle>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{showPropsContent}</DialogContentText>
                </DialogContent>
                <Divider
                    sx={{
                        width: '90%',
                        alignSelf: 'center',
                        mb: '20px',
                        mt: '20px',
                        backgroundColor: '#858585',
                    }}
                />
                <DialogActions sx={{ mb: '20px' }}>
                    <Stack spacing={2} direction='column' sx={{ flex: 1, ml: 4, mr: 4 }}>
                        <Stack spacing={2} direction='row' sx={{ flex: 1, ml: 4, mr: 4 }} alignItems='center'>
                            <Checkbox
                                checked={isAppendJyutping}
                                onChange={(e) => {
                                    // formik.setFieldValue('writingHints', e.target.checked);
                                    setIsAppendJyutping(e.target.checked);
                                }}
                                inputProps={{
                                    'aria-label': 'Testing checkbox',
                                }}
                            />
                            {t('jyutping-annotation')}
                            <Checkbox
                                disabled={true}
                                checked={isAppendPinyin}
                                onChange={(e) => {
                                    // formik.setFieldValue('writingHints', e.target.checked);
                                    setIsAppendPinyin(e.target.checked);
                                }}
                                inputProps={{
                                    'aria-label': 'Testing checkbox',
                                }}
                            />
                            {t('pinyin-annotation')}
                            <Checkbox
                                disabled={true}
                                checked={isAppendZhuyin}
                                onChange={(e) => {
                                    // formik.setFieldValue('writingHints', e.target.checked);
                                    setIsAppendZhuyin(e.target.checked);
                                }}
                                inputProps={{
                                    'aria-label': 'Testing checkbox',
                                }}
                            />
                            {t('zhuyin-annotation')}
                        </Stack>

                        <Stack spacing={2} direction='row' sx={{ flex: 1, ml: 4, mr: 4 }}>
                            <CancelButton onClick={handleClose} sx={{ flex: 1, border: '1px solid #F06E3C' }}>
                                {t('cancel')}
                            </CancelButton>
                            <SubmitButton
                                onClick={handleSave}
                                autoFocus
                                disabled={!canSave}
                                sx={{
                                    flex: 1,
                                    '&:disabled': {
                                        backgroundColor: '#F0F0F0',
                                        color: '#B3B3B3',
                                    },
                                }}>
                                {t('save')}
                            </SubmitButton>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
