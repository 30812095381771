import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { EventInput } from '@fullcalendar/react';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { TestroomsQueryModel } from 'queryModels/TestroomsQueryModel';
import { useAuthChecking } from 'hooks/useAuthChecking';

const TESTROOMS_QUERY = gql`
    query GetRows($now: DateTime!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        testrooms(take: 1, orderBy: { endTime: desc }, where: { endTime: { gte: $now } }) {
            id
            testroomName
            startTime
            endTime
            otp
        }
    }
`;

export type TestroomDataModel = {
    id: string;
    title: string;
    startTime: Date;
    endTime: Date;
    otp: string;
};

export default function useLatestTestroomQuery() {
    // const now = new Date().toISOString();

    const { logout } = useContext(UserAuthContext);
    const [now, setNow] = useState<string>(new Date().toISOString());

    const { data: testroomsQueryData } = useQuery<TestroomsQueryModel>(TESTROOMS_QUERY, {
        variables: {
            now: now,
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const [latestTestroomData, setLatestTestroomData] = useState<TestroomDataModel>();

    const { authCheck } = useAuthChecking();

    useEffect(() => {
        if (testroomsQueryData?.testrooms && testroomsQueryData?.testrooms.length > 0) {
            const data = testroomsQueryData.testrooms[0];

            setLatestTestroomData({
                id: data.id,
                title: data.testroomName,
                startTime: data.startTime,
                endTime: data.endTime,
                otp: data.otp,
            });
        }
    }, [testroomsQueryData?.testrooms]);

    // check session expire
    useEffect(() => {
        if (testroomsQueryData) {
            try {
                authCheck(testroomsQueryData?.authenticatedItem);
            } catch (err) {
                logout();
            }
        }
    }, [logout, testroomsQueryData, testroomsQueryData?.authenticatedItem]);

    return { latestTestroomData };
}
