import { Box } from '@mui/material';
import { EasyWritingAnswer } from 'components/EasyWritingAnswer';
import { WritingAnswer } from 'components/WritingAnswer';
import React, { useState } from 'react';
import { HkcsEasyWritingAnswer } from './HkcsEasyWritingAnswer';
import { HkcsWritingAnswer } from './HkcsWritingAnswer';
import { Word } from '../WordSvg/character/word';
type HkcsQ3WritingGridProp = {
    item: any;
    textContent: string;
};
const HkcsQ3WritingGrid = (prop: HkcsQ3WritingGridProp) => {
    const [characterJson, setCharacterJson] = useState<any>();
    const [selectedStrokeIndex, setSelectedStrokeIndex] = useState<number>(0);
    return (
        <Box
            sx={{
                justifyContent: 'center',
                display: 'flex',
                // padding: '1px',
                // backgroundColor: '#000',
            }}>
            <Box
                sx={{
                    width: '512px', // take full width of parent
                    display: 'grid',
                    padding: '1px',
                    gridTemplateColumns: 'repeat(7, 72px)', // 7 columns, each of 128px
                    gridTemplateRows: 'repeat(2, 72px)', // 2 rows, each of 128px
                    gap: '1px', // gap between grid items
                    backgroundColor: '#000', // background color of the grid container
                }}>
                {Array.from({ length: 14 }, (_, i) => {
                    const items: any[] = JSON.parse(prop.item.data).hkcsQ3.answer;
                    const item = items.length > i ? items[i] : null;

                    //   console.log(JSON.parse(prop.item));
                    if (item) {
                        const writing: any = item;
                        return (
                            <Box
                                key={i}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%', // take full width of parent
                                    height: '100%', // take full height of parent
                                    backgroundColor: '#FFF', // this will be the color of the grid items
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}>
                                {prop.textContent.length > i && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%', // take full width of parent
                                            height: '100%', // take full height of parent
                                            backgroundColor: '#fff', // background color of the grid item
                                            justifyContent: 'center',
                                        }}>
                                        <Word
                                            character={prop.textContent[i]}
                                            selectedStrokeIndex={selectedStrokeIndex}
                                            setSelectedStrokeIndex={setSelectedStrokeIndex}
                                            characterJson={characterJson}
                                            setCharacterJson={setCharacterJson}
                                        />
                                    </Box>
                                )}
                                {writing.normalMode ? (
                                    <HkcsEasyWritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                ) : (
                                    <HkcsWritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                )}
                            </Box>
                        );
                    } else {
                        // Render a blank box
                        return (
                            <Box
                                key={i}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%', // take full width of parent
                                    height: '100%', // take full height of parent
                                    backgroundColor: '#fff', // background color of the grid item
                                    justifyContent: 'center',
                                }}>
                                <HkcsWritingAnswer strokes={[]} writingVersion={2} />
                            </Box>
                        );
                    }
                })}
            </Box>
        </Box>
    );
};

export default HkcsQ3WritingGrid;
