import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TopButton, TopButtonTypography } from '../TestroomTemplate';

export type TestroomShowResultProps = {
    onClick: () => void;
};

export const TestroomShowResult = (props: TestroomShowResultProps) => {
    const { onClick } = props;
    const { t } = useTranslation();
    return (
        <TopButton
            sx={{
                bgcolor: '#FFA400',
                color: '#FFF3E0',
                paddingTop: '14px',
                '&:hover': {
                    backgroundColor: '#ecab2f',
                },
            }}
            onClick={() => {
                onClick();
            }}>
            <TopButtonTypography>{t('live-class-show-result')}</TopButtonTypography>
        </TopButton>
    );
};
