import { Box } from '@mui/material';
import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentH5P } from 'models/lesson/LessonModel';

export const SlideH5P = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentH5P = item.content as SlideComponentH5P;

    return (
        <Box
            component='img'
            src={`/asset/images/${itemContent.thumbnail || 'default.png'}`}
            alt='bg'
            sx={{
                position: 'absolute',
                zoom: zoom,
                aspectRatio: 'auto',
                pointerEvents: 'none',
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
                m: 'inherit',
                p: 'inherit',
            }}
            width={item.size.width}
        />
    );
};
