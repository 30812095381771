import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Divider } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { SchoolModel } from 'models/school/SchoolModel';
import { useSchoolHook } from 'hooks/useSchoolHook';

type WorkspaceProps = {
    isParentOpen?: boolean;
};

export default function Workspace(props: WorkspaceProps) {
    const auth = useContext(UserAuthContext);
    const { open, handleClickOpen, handleClose, handleSelect } = useSchoolHook();

    const { isParentOpen = true } = props;
    const { t } = useTranslation();

    const dialog = (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ minWidth: 400 }}>
                <Box
                    sx={{
                        backgroundColor: '#44C4D9',
                        borderRadius: 1,
                        p: 1,
                        color: '#FDFCFC',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                    <DoneIcon sx={{ mr: 1 }} />
                    <Typography>{t('select-school')}</Typography>
                </Box>
            </DialogTitle>

            <List sx={{ pt: 0 }}>
                {auth.userData?.schools?.map((schoolData: SchoolModel, index: number) => (
                    <Box key={schoolData.id}>
                        <ListItem component={Button} onClick={() => handleSelect(schoolData)}>
                            <ListItemText
                                primary={schoolData.schoolName}
                                sx={{
                                    color: '#533D18',
                                    textAlign: 'center',
                                }}
                            />
                        </ListItem>
                        {auth?.userData?.schools && index < auth?.userData?.schools?.length - 1 && (
                            <Divider
                                sx={{
                                    backgroundColor: '#533D18',
                                    ml: 2,
                                    mr: 2,
                                }}
                            />
                        )}
                    </Box>
                ))}
            </List>
        </Dialog>
    );

    return auth.isUserLoggedIn ? (
        <Div isMenuOpen={isParentOpen}>
            <Button
                variant='contained'
                color='secondary'
                onClick={handleClickOpen}
                sx={{
                    p: 0,
                    borderRadius: 50,
                    aspectRatio: 1,
                    minWidth: 40,
                    minHeight: 40,
                }}>
                <Typography
                    variant='subtitle1'
                    component='div'
                    sx={{
                        ...(isParentOpen ? { pl: 3, pr: 3 } : {}),
                    }}>
                    {isParentOpen ? auth.selectedSchool?.schoolName : auth.selectedSchool?.schoolName.charAt(0)}
                </Typography>
                {isParentOpen && <ArrowDropDownIcon sx={{ mr: 1 }} />}
            </Button>
            {dialog}
        </Div>
    ) : (
        <></>
    );
}

const Div = styled.div((props: { isMenuOpen: boolean }) => ({
    // ...(!props.isMenuOpen && {
    //   borderRadius: '50%',
    //   backgroundColor: '#ffffff',
    //   textAlign: 'center',
    // }),
}));
