import React from 'react';
import { Box } from '@mui/material';

type ImageProp = {
    imageURL: string;
    image: string;
    width: string; // width in pixels
    height: string;
};
const HkcsQ3PrintImage = (prop: ImageProp) => {
    return (
        <Box
            component='img'
            src={prop.imageURL && prop.imageURL !== '' ? prop.imageURL : `/characters/${prop.image !== '' ? prop.image + '/image.png' : 'default.png'}`}
            alt='bg'
            sx={{
                width: prop.width, // width in pixels
                height: prop.height, // height in pixels
                flexShrink: 0,
                objectFit: 'contain',
                // borderRadius: 12.5 / 2, // border radius in pixels
                // border: '1.5px solid #000', // border style
                background: '#FFF',
            }}
            // width={item.size.width}
        />
    );
};

export default HkcsQ3PrintImage;
