import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
type QuestionTypeResult = {
    gameType: string;
    correct: number;
    question: number;
};

const QuestionTypeCorrectPercentageChart = (props: QuestionTypeResult) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Box
                sx={{
                    top: 5,
                    left: 5,
                    bottom: 5,
                    right: 5,
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: (props.correct / props.question) * 100 >= 50 ? '#E8F5E9' : '#FEEBEE',
                    width: '90px',
                    height: '90px',
                    borderRadius: '90px',
                }}></Box>
            <CircularProgress
                sx={{
                    color: (props.correct / props.question) * 100 >= 50 ? '#4CAF50' : '#EF5350',
                    '& .MuiCircularProgress-svg circle': {
                        strokeWidth: '2px',
                    },
                }}
                size={'100px'}
                variant='determinate'
                value={(props.correct / props.question) * 100}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '0.1px',
                }}>
                <Typography fontSize={20} lineHeight={1.5} variant='caption' component='div' color='text.secondary'>
                    {props.correct.toFixed(1) + '/' + Math.round(props.question)}
                </Typography>
                <Typography fontSize={12} variant='caption' component='div' color='text.secondary'>
                    {props.gameType}
                </Typography>
            </Box>
        </Box>
    );
};

export default QuestionTypeCorrectPercentageChart;
