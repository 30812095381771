import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import { AUTHENTICATE_WITH_GOOGLE } from 'pages/onBoarding/queries';
import btnNormal from './img/google-btn-normal.png';
import btnHover from './img/google-btn-hover.png';
import { ResultType } from 'pages/onBoarding/subPage/SignIn';
import { t } from 'i18next';

interface AuthenticateWithGoogleResponse {
    authenticateWithGoogle: {
        __typename: string;
        [key: string]: any;
    };
}

interface AuthenticateWithGoogleMutationVariables {
    token: string;
}

export const GoogleSigninButton = (props: { buttonTitle?: string; onSuccess: (result: ResultType) => Promise<void> }) => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }

    const [hover, setHover] = useState(false);

    const [authenticateWithGoogle] = useMutation<AuthenticateWithGoogleResponse, AuthenticateWithGoogleMutationVariables>(AUTHENTICATE_WITH_GOOGLE);

    const login = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/documents profile email',
        onSuccess: (codeResponse) => {
            // assuming authurization code is in the response
            authenticateWithGoogle({
                variables: { token: codeResponse.code.toString() },
            })
                .then((result: any) => {
                    props.onSuccess(result);
                })
                .catch((error: any) => {
                    console.error('authenticateWithGoogle error:', error);
                    props.onSuccess(error);
                });
        },
        flow: 'auth-code',
    });

    return (
        <button
            name='google-login-button'
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            onClick={() => {
                setHover(false);
                login();
            }}
            style={{
                border: 'none',
                backgroundImage: `url(${hover ? btnHover : btnNormal})`,
                padding: 0,
                height: '40px',
                width: '360px',
                fontSize: '14px',
                backgroundSize: 'cover',
            }}>
            {t('sso.google')}
        </button>
    );
};
