import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { EmailVerificationPath, SignInPath, SignUpPath, ForgotPasswordPath, ResetVerificationPath, SetNewPasswordPath, ResetSuccessPath } from 'routes/utils';
import * as Yup from 'yup';

export const SignInRules = Yup.object().shape({
    email: Yup.string().email('Invalid email format.').required('Please input email address.'),
    password: Yup.string().required('Please input password'),
});

export const SignUpRules = Yup.object().shape({
    name: Yup.string().required('Please input name'),
    email: Yup.string().email('Invalid email format.').required('Please input email address.'),
    password: Yup.string().min(8, t('passwordTooShort')).required('Please input password'),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    // ),
});

export const EmailRules = Yup.object().shape({
    email: Yup.string().email('Invalid email format.').required('Please input email address.'),
});

export const ResetPasswordRules = Yup.object().shape({
    password: Yup.string().required('Please input password'),
    confirmPassword: Yup.string()
        .required('Please confirm password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const onBoardingTitle = (path: string) => {
    if (path.includes(SignInPath)) {
        return t('loginHeader');
    } else if (path.includes(SignUpPath)) {
        return t('createAnAccount');
    } else if (path.includes(EmailVerificationPath) || path.includes(ResetVerificationPath)) {
        return t('checkYourEmail');
    } else if (path.includes(ForgotPasswordPath)) {
        return `${t('forgotPassword')}?`;
    } else if (path.includes(SetNewPasswordPath)) {
        return t('setNewPassword');
    } else if (path.includes(ResetSuccessPath)) {
        return t('passwordReset');
    }
};

export const onBoardingFooterText = (path: string) => {
    if (path.includes(SignInPath)) {
        return t('dontHaveAccount');
    } else if (path.includes(SignUpPath)) {
        return t('alreadyHaveAccount');
    } else if (path.includes(EmailVerificationPath) || path.includes(ResetVerificationPath)) {
        return t('dontReceiveEmail');
    }
};

export const onBoardingFooterHighLightedText = (path: string) => {
    if (path.includes(SignInPath)) {
        return t('signUp');
    } else if (path.includes(SignUpPath)) {
        return t('signIn');
    } else if (path.includes(EmailVerificationPath) || path.includes(ResetVerificationPath)) {
        return t('clickToResend');
    }
};

export const isDisplayFooter = (path: string) => {
    if (path.includes(SignInPath)) {
        return true;
    } else if (path.includes(SignUpPath)) {
        return true;
    } else if (path.includes(EmailVerificationPath)) {
        return true;
    } else if (path.includes(ResetVerificationPath)) {
        return true;
    }
};
