import { UserAuthContext } from 'contexts/UserAuthContext';
import { useLessonShareModelCreateMutation } from 'models/lessonShare/useLessonShareModelCreateMutation';
import { useLessonShareModelQuery } from 'models/lessonShare/useLessonShareModelQuery';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useState, KeyboardEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useMultiInputEmailWithChipsHook = (lessonID: string, refreshList: () => void) => {
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();
    const { createLessonShares } = useLessonShareModelCreateMutation();
    const { getData } = useLessonShareModelQuery();
    const auth = useContext(UserAuthContext);

    const [emails, setEmails] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const schema = Yup.object().shape({
        email: Yup.string().email(t('invalid-email')),
    });

    const handleDelete = (emailToDelete: string) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleInsertEmail();
        }
    };

    const handleInsertEmail = async () => {
        const inputEmails = inputValue.split(',');

        for (const email of inputEmails) {
            const trimmedEmail = email.trim();

            try {
                await schema.validate({
                    email: inputValue,
                });

                if (trimmedEmail) {
                    setEmails((prevEmails) => [...prevEmails.filter((email) => email !== trimmedEmail), trimmedEmail]);
                }

                setInputValue('');
            } catch (err: any) {
                const errorMsg: string = err.errors.join(',');
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        handleInsertEmail();
        setIsFocused(false);
    };

    const handleSubmit = async () => {
        try {
            const filteredArray = await filterNonDuplicatedEmail(emails);

            const data = [];
            for (const email of filteredArray) {
                data.push({ email, lesson: { connect: { id: lessonID } } });
            }

            if (data.length > 0) {
                await createLessonShares({
                    data,
                });

                refreshList();
                setEmails([]);

                enqueueSnackbar(t('lessonShare.sent'), {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
            }
        } catch (err) {}
    };

    const filterNonDuplicatedEmail = async (newList: string[]): Promise<string[]> => {
        const existingList = await getData({
            where: { lesson: { id: { equals: lessonID } } },
        });

        const existingEmails = new Set(existingList.map((item) => item.email));

        const filteredArray = newList.filter((email) => !existingEmails.has(email));

        // console.log(filteredArray);
        return filteredArray;
    };

    return {
        handleDelete,
        handleKeyDown,
        handleInputChange,
        isFocused,
        emails,
        inputValue,
        onFocus,
        onBlur,
        handleSubmit,
    };
};
