import React from 'react';
import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';
interface PopupCircularProgressProps {
    isOpened: boolean;
}
export const PopupCircularProgress = (props: PopupCircularProgressProps) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.isOpened}>
            <CircularProgress color='inherit' />
        </Backdrop>
    );
};
