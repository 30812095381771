import { gql } from '@apollo/client';

export const GET_TESTROOM = gql`
    query GetTestroom($testroomId: ID) {
        testroom(where: { id: $testroomId }) {
            id
            testroomName
            startTime
            lessonVersion {
                id
                lessonQuestions
                slides {
                    id
                    questionCount
                }
            }
        }
    }
`;

export const GET_TESTROOMS = gql`
    query GetTestrooms($teacher: IDFilter, $class: IDFilter) {
        testrooms(where: { teacher: { id: $teacher }, class: { id: $class } }, orderBy: [{ startTime: desc }]) {
            id
            testroomName
            startTime
            lessonVersion {
                id
                lessonQuestions
                slides {
                    id
                    questionCount
                }
            }
        }
    }
`;

export const GET_CLASS = gql`
    query GetClass($classId: ID) {
        class(where: { id: $classId }) {
            id
            name
            className
            students {
                id
                givenName
                familyName
            }
        }
    }
`;

export const GET_CLASSES = gql`
    query GetClasses($school: IDFilter, $teacher: IDFilter) {
        classes(where: { AND: { school: { id: $school }, teacher: { id: $teacher } } }, orderBy: [{ className: asc }]) {
            id
            name
            className
        }
    }
`;

export const GET_STUDENT = gql`
    query GetStudent($studentId: ID) {
        studentProfile(where: { id: $studentId }) {
            id
            studentNumber
            givenName
            familyName
        }
    }
`;

export const GET_STUDENTS = gql`
    query GetStudents($class: IDFilter) {
        studentProfiles(where: { class: { id: $class } }) {
            id
            studentNumber
            givenName
            familyName
        }
    }
`;

export const GET_RESULTS = gql`
    query GetResults($filter: ResultWhereInput) {
        results(where: $filter) {
            id
            message
            student {
                id
            }
            testroom {
                id
            }
            files {
                file {
                    url
                }
            }
            data
            correct
            startTime
            endTime
        }
    }
`;

export const GET_RESULTS_BY_TESTROOM = gql`
    query GetResultsByTestroom($testroom: IDFilter) {
        results(where: { testroom: { id: $testroom } }) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            startTime
            endTime
            componentId
            correct
            grading
        }
    }
`;

export const GET_RESULTS_BY_TESTROOM_AND_STUDENT = gql`
    query GetResultsByTestroomAndStudent($testroomId: IDFilter, $studentId: IDFilter) {
        results(where: { testroom: { id: $testroomId }, student: { id: $studentId } }, orderBy: [{ startTime: asc }]) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            files {
                file {
                    url
                }
            }
            startTime
            endTime
            componentId
            correct
            grading
        }
    }
`;

export const UPDATE_RESULTS = gql`
    mutation UpdateResults($data: [ResultUpdateArgs!]!) {
        updateResults(data: $data) {
            id
            grading
            data
        }
    }
`;
export const GET_SCHOOL_NAME = gql`
    query School($where: SchoolWhereUniqueInput!) {
        school(where: $where) {
            id
            schoolName
        }
    }
`;
