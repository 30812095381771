import { Box, Button, Input, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Search, Sort } from '@mui/icons-material/';
import styled from '@emotion/styled';
import { colors } from '../../styles/Colors';
import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { ResultIndicatorEnum } from 'models/resultPage/ResultPageModel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectClassPath, SelectTestroomPath, OverallResultPath, ClassOverallResultPath } from 'routes/utils';
import CsvGenerator from 'pages/results/component/CsvGenerator';
function getSvgComponent(resultIndicator: ResultIndicatorEnum, correct: string, notAttempted: string) {
    let indicator = resultIndicator.toString() === correct ? ResultIndicatorEnum.correct : ResultIndicatorEnum.wrong;
    indicator = resultIndicator.toString() === notAttempted ? ResultIndicatorEnum.notAtttempted : indicator;
    const correctIcon = (
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
            <circle cx='16' cy='16' r='6' fill='#4CAF50' />
            <circle cx='16' cy='16' r='5' stroke='#533D18' stroke-opacity='0.87' stroke-width='2' />
        </svg>
    );
    const wrongIcon = (
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
            <circle cx='16' cy='16' r='6' fill='#EF5350' />
            <circle cx='16' cy='16' r='5' stroke='#533D18' stroke-opacity='0.87' stroke-width='2' />
        </svg>
    );
    const notAttemptedIcon = (
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
            <circle cx='16' cy='16' r='6' fill='#FFCA28' />
            <circle cx='16' cy='16' r='5' stroke='#533D18' stroke-opacity='0.87' stroke-width='2' />
        </svg>
    );
    switch (indicator) {
        case ResultIndicatorEnum.correct:
            return correctIcon;
        case ResultIndicatorEnum.wrong:
            return wrongIcon;
        case ResultIndicatorEnum.notAtttempted:
            return notAttemptedIcon;

        default:
            return null;
    }
}
export interface ResultTableProps {
    data: any[] | undefined;
    columns?: {
        name: string;
        nameStr: string;
        sortCallback?(): void;
        customCell?: FunctionComponent<{ variants?: string }>;
        showPercentage?: boolean;
        resultIndicator?: boolean;
        // customCellVariants?: string;
    }[];
    isLoading?: boolean;
    showOverallResult?: boolean;
    showSearchTab?: boolean;
    showGenerateCsv?: boolean;
}
interface FilledCircularProgressProps {
    value: number;
}

function percentageStringToNumber(percentageString: string) {
    // Remove the '%' character and convert the string to a number
    const number = Number(percentageString.replace('%', ''));

    // Check if the result is a valid number
    if (isNaN(number)) {
        return 0;
    }

    return number;
}
const CircularProgressWithLabel: React.FC<FilledCircularProgressProps> = (studentAvarageMarks) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: '#EF5350',
            }}>
            <Box
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundImage: `conic-gradient(#4CAF50 ${studentAvarageMarks.value}%, transparent ${studentAvarageMarks.value}%, transparent 100%)`,
                }}
            />
        </Box>
    );
};
export const filterIt = (array: any, searchKey: string) => {
    return array.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
            if (typeof obj[key] === 'string' && key !== 'id') {
                return obj[key]?.toLowerCase()?.includes(searchKey.toLowerCase());
            } else {
                return false;
            }
        });
    });
};

export const ResultTable = (props: ResultTableProps) => {
    const { data, columns, isLoading = false } = props;
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const navigator = useNavigate();
    const { schoolId, classId } = useParams();
    const location = useLocation();

    const rows = useMemo(() => {
        if (search) {
            return filterIt(data, search);
        } else {
            return data;
        }
    }, [data, search]);

    return data && !isLoading && columns && columns?.length > 0 ? (
        <Box
            sx={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                flex: '1 0 0',
                borderRadius: '4px',
                backgroundColor: 'white',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    padding: '16px 0px',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                {props.showSearchTab && (
                    <TextField
                        sx={{
                            display: 'flex',
                            width: '220px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            outlineColor: '#533D18',
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        color='primary'
                        placeholder={t('resultPage.search')}
                        variant='outlined'></TextField>
                )}
                {props.showOverallResult && (
                    <Button
                        sx={{
                            display: 'flex',
                            p: 2,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            bgcolor: '#F05023',
                            boxShadow: 2,
                            color: 'white', // This line changes the text color to white
                        }}
                        onClick={() => {
                            navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${ClassOverallResultPath}/`);
                        }}>
                        {t('resultPage.check-overall-score')}
                    </Button>
                )}
                {props.showGenerateCsv && <CsvGenerator />}
            </Box>
            <TableContainer component={Box}>
                <Table
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: '1 0 0',
                        borderRadius: '4px',
                        border: '1px solid',
                        borderColor: 'rgba(83, 61, 24, 0.12)',
                    }}>
                    <TableHead
                        sx={{
                            display: 'flex',
                            flexDirection: 'center',
                            alignSelf: 'stretch',
                            border: '1px solid',
                            borderColor: 'rgba(83, 61, 24, 0.12)',
                        }}>
                        <TableRow sx={{ border: 0 }}>
                            {columns?.map((item) => (
                                <TableCell
                                    sx={{
                                        border: 0,
                                        fontSize: 14,
                                        lineHeight: '0',
                                        minWidth: '221px',
                                    }}
                                    align='left'>
                                    <Box display='flex' alignItems='center' flexDirection='row'>
                                        {item.nameStr}
                                        {/* {item?.sortCallback && (
                    <Sort
                      sx={{
                        verticalAlign: 'top',
                        marginLeft: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (typeof item?.sortCallback !== 'undefined') {
                          item?.sortCallback();
                        }
                      }}
                    />
                  )} */}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any) => (
                            <TableRow
                                key={row?.name}
                                sx={{
                                    cursor: 'pointer',
                                }}>
                                {columns.map((col) =>
                                    col.customCell ? (
                                        <TableCell
                                            sx={{
                                                border: 0,
                                                minWidth: '221px',
                                            }}
                                            onClick={() => {
                                                if (typeof row?.onClick !== 'undefined') {
                                                    row?.onClick();
                                                }
                                            }}>
                                            <col.customCell
                                                {...(row?.customCellVariants && {
                                                    variants: row.customCellVariants,
                                                })}>
                                                {row[col.name] as ReactNode}
                                            </col.customCell>
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            sx={{
                                                border: 0,
                                                minWidth: '221px',
                                            }}
                                            align='left'
                                            onClick={() => {
                                                if (typeof row?.onClick !== 'undefined') {
                                                    row?.onClick();
                                                }
                                            }}>
                                            <Box display='flex' alignItems='center' flexDirection='row' gap='8px'>
                                                {col?.showPercentage && <CircularProgressWithLabel value={percentageStringToNumber(row[col.name])} />}
                                                {col?.resultIndicator && getSvgComponent(row[col.name], t('resultPage.correct'), t('resultPage.not-attempted'))}
                                                {row[col.name]}
                                            </Box>
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    ) : (
        <Container>
            <Stack
                direction={'row'}
                sx={{
                    background: colors.gray500,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '12px 12px 0 0',
                    padding: '24px 36px',
                    boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                }}>
                <Skeleton />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '12px',
                        background: '#FFFFFF',
                        padding: '8px',
                        gap: '8px',
                    }}>
                    <Search
                        sx={{
                            color: colors.gray500,
                            width: '18px',
                            height: '18px',
                        }}
                    />
                    <Input
                        placeholder={t('search')}
                        disableUnderline={true}
                        sx={{
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '150%',
                            color: '#B39898',
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Box>
            </Stack>
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: '0 0 12px 12px',
                    boxShadow: '0px 1px 2px rgba(109, 38, 19, 0.35)',
                }}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <Skeleton />
                    </TableHead>
                    <TableBody>
                        <Skeleton />
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const Container = styled.div``;
