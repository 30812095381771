import { Box } from '@mui/material';
import { useContext } from 'react';
import { SlidePreviewContext } from '../../lessons/contexts/SlidePreviewContext';
import { SlideComponent, SlideComponentBackground } from 'models/lesson/LessonModel';

export const SlideBackground = ({ item }: { item: SlideComponent; index: number }) => {
    const { zoom } = useContext(SlidePreviewContext);

    let itemContent: SlideComponentBackground = item.content as SlideComponentBackground;

    return (
        <Box
            component='img'
            src={itemContent.imageURL && itemContent.imageURL !== '' ? itemContent.imageURL : `/asset/images/${itemContent.filename || 'default.png'}`}
            alt='bg'
            sx={{
                position: 'absolute',
                zoom: zoom,
                aspectRatio: 'auto',
                transform: `scaleX(${itemContent.flip ?? 1})`,
                pointerEvents: 'none',
                left: item.pos.x,
                top: item.pos.y,
                zIndex: itemContent.zIndex,
                m: 'inherit',
                p: 'inherit',
            }}
            width={item.size.width}
        />
    );
};
