import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { ResultPageProp, ResultPageTab } from './ResultPageTab';
import { useNavigate } from 'react-router-dom';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface ResulTabsProp {
    resultTabsProp: ResultPageProp[];
}
const ResultPageTabs = (props: ResulTabsProp) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                paddingLeft: 1,
                marginTop: 1,
                backgroundColor: 'white',
            }}>
            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                <Tab sx={{ width: '160px' }} label={props.resultTabsProp[0].label} {...a11yProps(0)} onClick={props.resultTabsProp[0].navigate} />
                <Tab sx={{ width: '160px' }} label={props.resultTabsProp[1].label} {...a11yProps(0)} onClick={props.resultTabsProp[1].navigate} />
                <Tab sx={{ width: '160px' }} label={props.resultTabsProp[2].label} {...a11yProps(0)} onClick={props.resultTabsProp[2].navigate} />
            </Tabs>
        </Box>
    );
};

export default ResultPageTabs;
